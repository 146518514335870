import React, { Component } from "react";
import { withRouter } from "react-router";
import axios from "axios";
import { Form, Table, Alert, InputGroup } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
const URL = process.env.REACT_APP_API_URL;
class AddBlock extends Component {
  constructor(props) {
    super(props);
    var currentDate = new Date();
    var dd = String(currentDate.getDate()).padStart(2, "0");
    var mm = String(currentDate.getMonth() + 1).padStart(2, "0");
    var yyyy = currentDate.getFullYear();
    currentDate = yyyy + "-" + mm + "-" + dd;
    this.state = {
      name: "",
      status: "",
      mobile_no: "",
      email: "",
      avatar: null,
      staff_code: "",
      nfc_code: "",
      username: "",
      country: "",
      city: "",
      state1: "",
      post_code: "",
      address: "",
      addditional_information: null,
      password: "",
      password_confirmation: "",
      role_id: "0",
      image: null,
      roles: [],
      msg: "",
      error: "",
      password_expiry: currentDate,
      imagePreview1: null,
      imagePreview2: null,
    };
    this.Save = this.Save.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  CheckKey = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      this.Save();
    }
  };

  AddData = () => {
    this.setState({
      name: "",
      status: "",
    });
    this.props.history.push("/project/block");
  };

  componentWillMount() {}

  async Save() {
    if (this.state.name === "") {
      toast.error("Please enter name first!");
      return this.setState({
        msg: "",
        error: "Name is empty",
      });
    } else if (this.state.status === "") {
      toast.error("Please select status first!");

      return this.setState({
        msg: "",
        error: "Status is Empty",
      });
    } else {
      console.log(
        `${URL}/api/staff/block`,
        "sxs",
        process.env.REACT_APP_API_URL
      );
      axios
        .post(`${URL}/api/staff/block`, {
          name: this.state.name,
          status: this.state.status,
        })
        .then((res) => {
          toast.success("Block Created Succesfully!");
          this.props.history.push("/project/block");
        })
        .catch((err) => console.log(err));
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const customStyles = {
      control: (base) => ({
        ...base,
        height: 33,
        minHeight: 33,
        border: "1px solid #E4E6EF",
        borderRadius: "3px",
        fontSize: 10.5,
        fontWeight: 400,
        paddingTop: -25,
      }),
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <div className="row">
            <div className="col-sm-6 col-md-8 col-lg-8"></div>
            <div
              className="col-sm-6 col-md-4 col-lg-4"
              style={{ float: "right" }}
            >
              {/* {this.state.error && this.state.error ? (
                <Alert variant="danger">{this.state.error}</Alert>
              ) : null}
              {this.state.msg && this.state.msg ? (
                <Alert variant="success">{this.state.msg}</Alert>
              ) : null} */}
            </div>
          </div>
          <div
            className="row"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="col-sm-12 col-md-9 col-lg-9 ">
              <Card>
                <CardHeader
                  title="Add Block"
                  //   icon={
                  //     <i
                  //       className="flaticon-employees mr-3 mb-3"
                  //       style={{ fontSize: "35px", color: "#3F4254" }}
                  //     ></i>
                  //   }
                >
                  <CardHeaderToolbar>
                    <Link
                      type="button"
                      className="btn btn-primary"
                      name="add_button"
                      to="/project/block"
                    >
                      <span className="svg-icon menu-icon mr-2">
                        <svg
                          viewBox="0 0 24 24"
                          version="1.1"
                          className="kt-svg-icon"
                        >
                          <g
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <rect
                              id="bound"
                              x="0"
                              y="0"
                              width="24"
                              height="24"
                            />
                            <path
                              d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
                              id="Combined-Shape"
                              fill="#000000"
                              opacity="0.3"
                            />
                            <path
                              d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
                              id="Combined-Shape"
                              fill="#000000"
                            />
                            <rect
                              id="Rectangle-152"
                              fill="#000000"
                              opacity="0.3"
                              x="10"
                              y="9"
                              width="7"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-152-Copy-2"
                              fill="#000000"
                              opacity="0.3"
                              x="7"
                              y="9"
                              width="2"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-152-Copy-3"
                              fill="#000000"
                              opacity="0.3"
                              x="7"
                              y="13"
                              width="2"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-152-Copy"
                              fill="#000000"
                              opacity="0.3"
                              x="10"
                              y="13"
                              width="7"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-152-Copy-5"
                              fill="#000000"
                              opacity="0.3"
                              x="7"
                              y="17"
                              width="2"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-152-Copy-4"
                              fill="#000000"
                              opacity="0.3"
                              x="10"
                              y="17"
                              width="7"
                              height="2"
                              rx="1"
                            />
                          </g>
                        </svg>
                      </span>
                      List
                    </Link>
                  </CardHeaderToolbar>
                </CardHeader>
                <CardBody className="row">
                  <h4 className="col-12 mt-2 mb-4">Block Detail</h4>

                  <div className="col-12">
                    <div className="row">
                      <Form.Group
                        className="col-12 "
                        style={{ marginTop: "-10px" }}
                      >
                        {/* <Form.Label>Image</Form.Label> */}
                      </Form.Group>

                      <Form.Group className="col-12">
                        <Form.Label>Block Name</Form.Label>
                        <div className="input-group ">
                          <input
                            onKeyPress={this.CheckKey}
                            className="form-control form-control-sm"
                            type="text"
                            placeholder="Block Name"
                            value={this.state.name}
                            name="name"
                            onChange={this.onChange}
                          ></input>
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text"
                              id="basic-addon1"
                            >
                              <i
                                className="flaticon-qr-code"
                                style={{ fontSize: "12.5px" }}
                              ></i>
                            </span>
                          </div>
                        </div>
                      </Form.Group>
                      <Form.Group className="col-12">
                        <Form.Label>Status</Form.Label>
                        <select
                          onChange={this.onChange}
                          name="status"
                          className="form-control form-control-sm"
                          aria-label="Default select example"
                          value={this.state.status}
                        >
                          <option value="" selected="true" disabled="disabled">
                            Select status
                          </option>

                          <option value="Active">Active</option>
                          <option value="Inactive">In Active</option>
                        </select>
                      </Form.Group>
                    </div>
                  </div>
                </CardBody>
                <div>
                  <button
                    className="btn btn-primary  mr-4 mb-2"
                    onClick={() => this.Save()}
                    style={{ float: "right" }}
                  >
                    <span className="svg-icon menu-icon mr-2">
                      <svg
                        viewBox="0 0 24 24"
                        version="1.1"
                        className="kt-svg-icon"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <polygon id="Shape" points="0 0 24 0 24 24 0 24" />
                          <path
                            d="M17,4 L6,4 C4.79111111,4 4,4.7 4,6 L4,18 C4,19.3 4.79111111,20 6,20 L18,20 C19.2,20 20,19.3 20,18 L20,7.20710678 C20,7.07449854 19.9473216,6.94732158 19.8535534,6.85355339 L17,4 Z M17,11 L7,11 L7,4 L17,4 L17,11 Z"
                            id="Shape"
                            fill="#000000"
                            fillRule="nonzero"
                          />
                          <rect
                            id="Rectangle-16"
                            fill="#000000"
                            opacity="0.3"
                            x="12"
                            y="4"
                            width="3"
                            height="5"
                            rx="0.5"
                          />
                        </g>
                      </svg>
                    </span>
                    Create
                  </button>

                  <button
                    className="btn btn-danger mr-3 mb-2"
                    onClick={() => this.AddData()}
                    name="cancel"
                    style={{ float: "right" }}
                  >
                    <span className="svg-icon menu-icon mr-2">
                      <svg
                        viewBox="0 0 24 24"
                        version="1.1"
                        className="kt-svg-icon"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <g
                            id="Group"
                            transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)"
                            fill="#000000"
                          >
                            <rect
                              id="Rectangle-185"
                              x="0"
                              y="7"
                              width="16"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-185-Copy"
                              opacity="0.3"
                              transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) "
                              x="0"
                              y="7"
                              width="16"
                              height="2"
                              rx="1"
                            />
                          </g>
                        </g>
                      </svg>
                    </span>
                    Cancel
                  </button>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(AddBlock);
