import React, { Component } from "react";
import { withRouter } from "react-router";
import axios from "axios";
import isEmpty from "../../../../../Validations/is-empty";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../../_metronic/_partials/controls";
import { Link } from "react-router-dom";
import { TextInput, Select, CheckInput } from "../../../../Component/Form";
import { toast } from "react-toastify";
import EnterFormElement from "../../../../../Utils/CreateForm";
import { createArray } from "../../../../../Utils";
const URL = process.env.REACT_APP_API_URL;

var currentDate = new Date();
var dd = String(currentDate.getDate()).padStart(2, "0");
var mm = String(currentDate.getMonth() + 1).padStart(2, "0");
var yyyy = currentDate.getFullYear();
currentDate = yyyy + "-" + mm + "-" + dd;
var formData = new FormData();
class AddParkingType extends Component {
  componentWillMount() {}
  constructor(props) {
    super(props);
    this.state = {
      customParkingType: "",
      availableInVisitor: false,
      availableInVisitorValue: "on",
      startTime: "00:00",
      endTime: "00:00",
      allowTimeHour: "0",
      allowTimeMinute: "0",
      timeFlexibilityHour: "0",
      timeFlexibilityMinute: "0",
      overstayPenalty: 0,
      parkingFee: 0,
      status: "Active",
      statuses: [
        {
          value: "Active",
          label: "Active",
        },
        {
          value: "In Active",
          label: "Inactive",
        },
      ],
      hours: createArray(24, "hour", "s"),
      minutes: createArray(60, "minute", "s"),
    };
    this.Save = this.Save.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  CheckKey = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      this.Save();
    }
  };

  AddData = () => {
    this.setState({
      customParkingType: "",
      availableInVisitor: false,
      startTime: "",
      endTime: "",
      allowTimeHour: "",
      allowTimeMinute: "",
      timeFlexibilityHour: "",
      timeFlexibilityMinute: "",
      overstayPenalty: 0,
      parkingFee: 0,
      status: "Active",
    });
  };

  Validation = () => {
    if (isEmpty(this.state.customParkingType)) {
      toast.error("Please enter parking type!");
      return false;
    } else {
      return true;
    }
  };

  SendDataToForm = (name, type) => {
    formData = EnterFormElement(name, type, this.state[name], formData);
  };

  CreateForm = () => {
    this.SendDataToForm("customParkingType", "string");
    this.SendDataToForm("startTime", "time24HC");
    this.SendDataToForm("endTime", "time24HC");
    this.SendDataToForm("allowTimeHour", "string");
    this.SendDataToForm("allowTimeMinute", "string");
    this.SendDataToForm("timeFlexibilityHour", "string");
    this.SendDataToForm("timeFlexibilityMinute", "string");
    this.SendDataToForm("overstayPenalty", "string");
    this.SendDataToForm("parkingFee", "string");
    this.SendDataToForm("status", "string");
    this.SendDataToForm("availableInVisitor", "bool");
  };

  async Save() {
    var isValid = await this.Validation();
    if (isValid) {
      toast.success("Creating parking type...");
      formData = new FormData();
      this.CreateForm();
      axios
        .post(`${URL}/api/staff/parking_type`, formData)
        .then(async (res) => {
          toast.success("Parking Type Saved Successfully!");
          this.props.history.push("/parking/parking-type");
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleAvailableInVisitor = () => {
    var val = this.state.availableInVisitor;
    this.setState({ availableInVisitor: !val });
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <div className="row">
            <div className="col-sm-12 col-md-2 col-lg-3"></div>
            <div className="col-sm-12 col-md-8 col-lg-6">
              <Card>
                <CardHeader
                  title="Add Parking Type"
                  icon={
                    <span class="svg-icon svg-icon-primary svg-icon-2x mr-2">
                      <svg
                        viewBox="0 0 24 24"
                        version="1.1"
                        className="kt-svg-icon"
                      >
                        <g
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <rect x="0" y="0" width="24" height="24" />
                          <rect
                            fill="#000000"
                            x="2"
                            y="4"
                            width="19"
                            height="4"
                            rx="1"
                          />
                          <path
                            d="M3,10 L6,10 C6.55228475,10 7,10.4477153 7,11 L7,19 C7,19.5522847 6.55228475,20 6,20 L3,20 C2.44771525,20 2,19.5522847 2,19 L2,11 C2,10.4477153 2.44771525,10 3,10 Z M10,10 L13,10 C13.5522847,10 14,10.4477153 14,11 L14,19 C14,19.5522847 13.5522847,20 13,20 L10,20 C9.44771525,20 9,19.5522847 9,19 L9,11 C9,10.4477153 9.44771525,10 10,10 Z M17,10 L20,10 C20.5522847,10 21,10.4477153 21,11 L21,19 C21,19.5522847 20.5522847,20 20,20 L17,20 C16.4477153,20 16,19.5522847 16,19 L16,11 C16,10.4477153 16.4477153,10 17,10 Z"
                            fill="#000000"
                            opacity="0.3"
                          />
                        </g>
                      </svg>
                    </span>
                  }
                >
                  <CardHeaderToolbar>
                    <Link
                      type="button"
                      className="btn btn-primary"
                      name="add_button"
                      to="/parking/parking-type"
                    >
                      <span className="svg-icon menu-icon mr-2">
                        <svg
                          viewBox="0 0 24 24"
                          version="1.1"
                          className="kt-svg-icon"
                        >
                          <g
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <rect
                              id="bound"
                              x="0"
                              y="0"
                              width="24"
                              height="24"
                            />
                            <path
                              d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
                              id="Combined-Shape"
                              fill="#000000"
                              opacity="0.3"
                            />
                            <path
                              d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
                              id="Combined-Shape"
                              fill="#000000"
                            />
                            <rect
                              id="Rectangle-152"
                              fill="#000000"
                              opacity="0.3"
                              x="10"
                              y="9"
                              width="7"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-152-Copy-2"
                              fill="#000000"
                              opacity="0.3"
                              x="7"
                              y="9"
                              width="2"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-152-Copy-3"
                              fill="#000000"
                              opacity="0.3"
                              x="7"
                              y="13"
                              width="2"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-152-Copy"
                              fill="#000000"
                              opacity="0.3"
                              x="10"
                              y="13"
                              width="7"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-152-Copy-5"
                              fill="#000000"
                              opacity="0.3"
                              x="7"
                              y="17"
                              width="2"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-152-Copy-4"
                              fill="#000000"
                              opacity="0.3"
                              x="10"
                              y="17"
                              width="7"
                              height="2"
                              rx="1"
                            />
                          </g>
                        </svg>
                      </span>
                      List
                    </Link>
                  </CardHeaderToolbar>
                </CardHeader>
                <CardBody className="row">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-md-6 col-lg-8 col-xl-8 col-sm-12"></div>
                      <Select
                        width="col-md-6 col-12 col-lg-4 col-xl-4 "
                        label="Status"
                        name="status"
                        onChange={this.onChange}
                        defaultValue={this.state.status}
                        data={this.state.statuses}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="row">
                      <TextInput
                        label="Parking Type *"
                        name="customParkingType"
                        width="col-12"
                        CheckKey={this.CheckKey}
                        type="text"
                        placeholder="Enter parking type"
                        value={this.state.customParkingType}
                        icon="flaticon-user"
                        onChange={this.onChange}
                        includeIcon={true}
                      />
                      <CheckInput
                        label="Available in Visitor"
                        name="availableInVisitor"
                        width="col-12"
                        checked={this.state.availableInVisitor}
                        onChange={this.handleAvailableInVisitor}
                      />
                      <TextInput
                        label="Start Time"
                        name="startTime"
                        width="col-md-6 col-lg-6 col-sm-12"
                        CheckKey={this.CheckKey}
                        type="time"
                        placeholder="Enter start time"
                        value={this.state.startTime}
                        onChange={this.onChange}
                      />
                      <TextInput
                        label="End Time"
                        name="endTime"
                        width="col-md-6 col-lg-6 col-sm-12"
                        CheckKey={this.CheckKey}
                        type="time"
                        placeholder="Enter end time"
                        value={this.state.endTime}
                        onChange={this.onChange}
                      />
                      <div className="col-md-6 col-sm-12 col-lg-6">
                        <div className="row">
                          <h6 className="col-12">Allowed Time</h6>
                          <Select
                            width="col-md-6 col-sm-12"
                            label="Hours"
                            name="allowTimeHour"
                            onChange={this.onChange}
                            defaultValue={this.state.allowTimeHour}
                            data={this.state.hours}
                          />{" "}
                          <Select
                            width="col-md-6 col-sm-12"
                            label="Minutes"
                            name="allowTimeMinute"
                            onChange={this.onChange}
                            defaultValue={this.state.allowTimeMinute}
                            data={this.state.minutes}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12 col-lg-6">
                        <div className="row">
                          <h6 className="col-12">Time Flexibility </h6>
                          <Select
                            width="col-md-6 col-sm-12"
                            label="Hours"
                            name="timeFlexibilityHour"
                            onChange={this.onChange}
                            defaultValue={this.state.timeFlexibilityHour}
                            data={this.state.hours}
                          />{" "}
                          <Select
                            width="col-md-6 col-sm-12"
                            label="Minutes"
                            name="timeFlexibilityMinute"
                            onChange={this.onChange}
                            defaultValue={this.state.timeFlexibilityMinute}
                            data={this.state.minutes}
                          />
                        </div>
                      </div>

                      <TextInput
                        label="Overstay Penalty"
                        name="overstayPenalty"
                        width="col-md-6 col-lg-6 col-sm-12"
                        CheckKey={this.CheckKey}
                        type="number"
                        placeholder="Enter parking penalty fee"
                        value={this.state.overstayPenalty}
                        onChange={this.onChange}
                      />
                      <TextInput
                        label="Parking Fee"
                        name="parkingFee"
                        width="col-sm-12 col-lg-6 col-md-6"
                        CheckKey={this.CheckKey}
                        type="number"
                        placeholder="Enter parking fee"
                        value={this.state.parkingFee}
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                </CardBody>
                <div>
                  <button
                    className="btn btn-primary   mr-4 mb-2"
                    onClick={() => this.Save()}
                    style={{ float: "right" }}
                  >
                    <span className="svg-icon menu-icon mr-2">
                      <svg
                        viewBox="0 0 24 24"
                        version="1.1"
                        className="kt-svg-icon"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <polygon id="Shape" points="0 0 24 0 24 24 0 24" />
                          <path
                            d="M17,4 L6,4 C4.79111111,4 4,4.7 4,6 L4,18 C4,19.3 4.79111111,20 6,20 L18,20 C19.2,20 20,19.3 20,18 L20,7.20710678 C20,7.07449854 19.9473216,6.94732158 19.8535534,6.85355339 L17,4 Z M17,11 L7,11 L7,4 L17,4 L17,11 Z"
                            id="Shape"
                            fill="#000000"
                            fillRule="nonzero"
                          />
                          <rect
                            id="Rectangle-16"
                            fill="#000000"
                            opacity="0.3"
                            x="12"
                            y="4"
                            width="3"
                            height="5"
                            rx="0.5"
                          />
                        </g>
                      </svg>
                    </span>
                    Create
                  </button>

                  <button
                    className="btn btn-danger mr-3 mb-2"
                    onClick={() => this.AddData()}
                    name="cancel"
                    style={{ float: "right" }}
                  >
                    <span className="svg-icon menu-icon mr-2">
                      <svg
                        viewBox="0 0 24 24"
                        version="1.1"
                        className="kt-svg-icon"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <g
                            id="Group"
                            transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)"
                            fill="#000000"
                          >
                            <rect
                              id="Rectangle-185"
                              x="0"
                              y="7"
                              width="16"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-185-Copy"
                              opacity="0.3"
                              transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) "
                              x="0"
                              y="7"
                              width="16"
                              height="2"
                              rx="1"
                            />
                          </g>
                        </g>
                      </svg>
                    </span>
                    Cancel
                  </button>
                </div>
              </Card>
            </div>
            <div className="col-sm-12 col-md-2 col-lg-3"></div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(AddParkingType);
