import React, { useState, useRef, useEffect } from "react";
import JoditEditor from "jodit-react";
import { toast } from "react-toastify";
import axios from "axios";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import isEmpty from "../../../Validations/is-empty";
const RTEScreen = (props) => {
  const URL = process.env.REACT_APP_API_URL;
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const config = {
    readonly: false,
    placeholder: "Start typings...",
  };

  const save = () => {
    toast.success(`Updating ${props.short_title}...`);
    axios
      .post(`${URL}/api/staff/${props.end_point}`, {
        description: content,
      })
      .then((res) => {
        toast.success(`${props.title} updated successfully!`);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    axios
      .get(`${URL}/api/staff/${props.end_point}`)
      .then((res) => {
        if (!isEmpty(res.data.data[0])) {
          setContent(res.data.data[0].description);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="page-content">
      <div
        className="row"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div className="col-sm-12 col-md-9 col-lg-9 ">
          <Card>
            <CardHeader title={props.title}></CardHeader>
            <CardBody className="row">
              <h4 className="col-12 mt-2 mb-4">Description</h4>
              <div className="col-12">
                <JoditEditor
                  style={{ width: "100%" }}
                  ref={editor}
                  value={content}
                  config={config}
                  tabIndex={1}
                  onBlur={(newContent) => setContent(newContent)}
                  onChange={(newContent) => {}}
                />
              </div>
            </CardBody>
            <div>
              <button
                className="btn btn-primary  mr-4 mb-2"
                onClick={save}
                style={{ float: "right" }}
              >
                <span className="svg-icon menu-icon mr-2">
                  <svg
                    viewBox="0 0 24 24"
                    version="1.1"
                    className="kt-svg-icon"
                  >
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <polygon id="Shape" points="0 0 24 0 24 24 0 24" />
                      <path
                        d="M17,4 L6,4 C4.79111111,4 4,4.7 4,6 L4,18 C4,19.3 4.79111111,20 6,20 L18,20 C19.2,20 20,19.3 20,18 L20,7.20710678 C20,7.07449854 19.9473216,6.94732158 19.8535534,6.85355339 L17,4 Z M17,11 L7,11 L7,4 L17,4 L17,11 Z"
                        id="Shape"
                        fill="#000000"
                        fillRule="nonzero"
                      />
                      <rect
                        id="Rectangle-16"
                        fill="#000000"
                        opacity="0.3"
                        x="12"
                        y="4"
                        width="3"
                        height="5"
                        rx="0.5"
                      />
                    </g>
                  </svg>
                </span>
                Save
              </button>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};
export default RTEScreen;
