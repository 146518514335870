import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";
import {
  Member,
  Block,
  Checkpoint,
  Contact,
  Facility,
  Gate,
  Item,
  Level,
  Log,
  Move,
  Notification,
  Operation,
  Owner,
  Parking,
  PatrolPreDefinedMessages,
  Race,
  Religion,
  PatrolSchedule,
  Reports,
  Schedule,
  Settings,
  Staff,
  Unit,
} from "./modules/ECommerce/pages";

const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);
const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {<Redirect exact from="/" to="/dashboard" />}
        <ContentRoute exact path="/dashboard" component={DashboardPage} />
        <ContentRoute exact path="/builder" component={BuilderPage} />
        <ContentRoute exact path="/my-page" component={MyPage} />
        <Route exact path="/google-material" component={GoogleMaterialPage} />
        <Route exact path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route exact path="/e-commerce" component={ECommercePage} />
        <Route exact path="/notification" component={ECommercePage} />
        <Route exact path="/staff/log-types" component={Log.LogTypes} />
        <Route
          exact
          path="/staff/log-pre-defined-messages"
          component={Log.LogMessages}
        />
        <Route
          exact
          path="/staff/operation-types"
          component={Operation.OperationType}
        />
        <Route
          exact
          path="/staff/operation-pre-defined-messages"
          component={Operation.OperationMessage}
        />
        <Route exact path="/staff/items" component={Item.Items} />
        <Route exact path="/staff/assign-items" component={Item.AssignItem} />
        <Route exact path="/staff/staff" component={Staff.Staff} />
        <Route exact path="/staff/permission" component={Staff.Role} />
        {/* <Route exact path="/staff/roles" component={Staff.Permission} /> */}
        <Route exact path="/staff/add-staff" component={Staff.AddStaff} />
        <Route exact path="/staff/update-staff" component={Staff.UpdateStaff} />
        <Route
          exact
          path="/staff/patrol-pre-defined-messages"
          component={PatrolPreDefinedMessages}
        />
        {/* block */}
        <Route exact path="/project/block" component={Block.Block} />
        <Route exact path="/project/add-block" component={Block.AddBlock} />
        <Route
          exact
          path="/project/update-block"
          component={Block.UpdateBlock}
        />
        {/* Level  */}
        <Route exact path="/project/level" component={Level.Level} />
        <Route exact path="/project/add-level" component={Level.AddLevel} />
        <Route
          exact
          path="/project/update-level"
          component={Level.UpdateLevel}
        />
        <Route exact path="/user-profile" component={UserProfilepage} />
        <Route exact path="/setup/emergency-contacts" component={Contact} />
        <Route
          exact
          path="/staff/checkpoint"
          component={Checkpoint.Checkpoints}
        />
        <Route
          exact
          path="/staff/add-checkpoint"
          component={Checkpoint.AddCheckpoint}
        />
        <Route
          exact
          path="/staff/update-checkpoint"
          component={Checkpoint.UpdateCheckpoint}
        />
        <Route exact path="/staff/schedules" component={Schedule.Schedule} />
        <Route
          exact
          path="/staff/add-schedule"
          component={Schedule.AddSchedule}
        />
        <Route
          exact
          path="/staff/update-schedule"
          component={Schedule.UpdateSchedule}
        />
        <Route
          exact
          path="/staff/patrol-schedules"
          component={PatrolSchedule.PatrolSchedule}
        />
        <Route
          exact
          path="/staff/add-patrol-schedule"
          component={PatrolSchedule.AddPatrolSchedule}
        />
        <Route
          exact
          path="/staff/update-patrol-schedule"
          component={PatrolSchedule.UpdatePatrolSchedule}
        />
        <Route
          exact
          path="/staff/notifications"
          component={Notification.Notifications}
        />
        <Route
          exact
          path="/staff/add-notification"
          component={Notification.AddNotification}
        />
        <Route
          exact
          path="/staff/update-notification"
          component={Notification.UpdateNotification}
        />
        <Route
          exact
          path="/staff/report/attendance"
          component={Reports.GPS.Attendance}
        />
        <Route
          exact
          path="/staff/report/assign-items-report"
          component={Reports.GPS.AssignItemsReport}
        />
        <Route
          exact
          path="/staff/report/guards-operations"
          component={Reports.GPS.GuardsOperations}
        />
        <Route exact path="/staff/report/logs" component={Reports.GPS.Logs} />
        <Route
          exact
          path="/staff/report/panic-alerts"
          component={Reports.GPS.PanicAlerts}
        />
        <Route
          exact
          path="/staff/report/patrol-activity"
          component={Reports.GPS.PatrolActivity}
        />
        <Route
          exact
          path="/staff/report/schedule-activity"
          component={Reports.GPS.ScheduleActivity}
        />

        <Route
          exact
          path="/staff/report/patrol-schedule-activity"
          component={Reports.GPS.SchedulePatrolActivity}
        />
        <Route
          exact
          path="/staff/report/quick-patrol-schedule-activity"
          component={Reports.GPS.QuickSchedulePatrolActivity}
        />
        <Route exact path="/owner" component={Owner.Owner} />
        <Route exact path="/owner/add" component={Owner.AddOwner} />
        <Route exact path="/owner/update" component={Owner.UpdateOwner} />
        <Route exact path="/resident" component={Member.Member} />
        <Route exact path="/resident/add" component={Member.AddMember} />
        <Route exact path="/resident/update" component={Member.UpdateMember} />
        <Route exact path="/staff/report/shift" component={Reports.GPS.Shift} />
        <Route exact path="/facility/type" component={Facility.FacilityTypes} />
        <Route exact path="/project/unit" component={Unit.Unit} />
        <Route exact path="/project/update-unit" component={Unit.UpdateUnit} />
        <Route exact path="/project/add-unit" component={Unit.AddUnit} />
        <Route exact path="/facility/guide-book" component={Facility.Book} />
        <Route exact path="/facility/conditions" component={Facility.Term} />
        <Route exact path="/race" component={Race} />
        <Route exact path="/religion" component={Religion} />
        <Route exact path="/gate/gate-terminal" component={Gate.GateTerminal} />
        <Route
          exact
          path="/gate/gate-pass-type"
          component={Gate.GatePassType}
        />
        <Route exact path="/gate/gate-pass" component={Gate.GatePass} />
        <Route
          exact
          path="/parking/parking-type"
          component={Parking.Type.ParkingTypes}
        />
        <Route
          exact
          path="/parking/parking-type/add"
          component={Parking.Type.AddParkingType}
        />
        <Route
          exact
          path="/parking/parking-type/update"
          component={Parking.Type.UpdateParkingType}
        />
        <Route
          exact
          path="/parking/parking-level"
          component={Parking.Level.ParkingLevels}
        />
        <Route
          exact
          path="/parking/parking-level/add"
          component={Parking.Level.AddParkingLevel}
        />
        <Route
          exact
          path="/parking/parking-level/update"
          component={Parking.Level.UpdateParkingLevel}
        />
        <Route
          exact
          path="/parking/parking-slot"
          component={Parking.Slot.ParkingSlot}
        />
        <Route
          exact
          path="/parking/parking-slot/add"
          component={Parking.Slot.AddParkingSlot}
        />
        <Route
          exact
          path="/parking/parking-slot/update"
          component={Parking.Slot.UpdateParkingSlot}
        />
        <Route exact path="/move/guide-book" component={Move.MoveBook} />
        <Route exact path="/move/conditions" component={Move.MoveTerms} />
        <Route
          exact
          path="/settings/mo-accounts"
          component={Settings.MOAccounts}
        />
        <Route
          exact
          path="/settings/improvement-types"
          component={Settings.ImprovementTypes}
        />
        <Route
          exact
          path="/member/singup-requests"
          component={Member.SignupRequests}
        />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
