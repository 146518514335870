import React, { Component } from "react";
import { withRouter } from "react-router";
import { Alert } from "react-bootstrap";
import "react-confirm-alert/src/react-confirm-alert.css";
import _ from "lodash";
import "jspdf-autotable";
import DataGrid from "../../../Component/DataGrid";
import axios from "axios";
import { toast } from "react-toastify";
const URL = process.env.REACT_APP_API_URL;
class Member extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reload: false,
      list: ["Sr", "Unit", "Name", "Gender", "Nationality", "Mobile", "DOB"],
      show: ["Sr", "Unit", "Name", "Gender", "Nationality", "Mobile", "DOB"],
      columns: [
        {
          column_name: "Sr",
          field_name: "id",
          width: "5%",
          type: "label",
        },
        {
          column_name: "Unit",
          field_name: "name",
          width: "10%",
          type: "array_in_an_object",
          index: 1,
          object_name: ["resident", "units"],
        },
        {
          column_name: "Name",
          field_name: "name",
          width: "20%",
          type: "object",
          object_name: ["profile"],
        },
        {
          column_name: "Gender",
          field_name: "gender",
          width: "15%",
          type: "object",
          object_name: ["resident"],
        },
        {
          column_name: "Nationality",
          field_name: "nationality",
          width: "15%",
          type: "object",
          object_name: ["resident"],
        },
        {
          column_name: "Mobile",
          field_name: "mobile_no",
          width: "15%",
          type: "object",
          object_name: ["profile"],
        },
        {
          column_name: "DOB",
          field_name: "dob",
          width: "10%",
          type: "object",
          object_name: ["resident"],
        },
      ],
      select: ["none"],
    };
  }

  reject = async (id) => {
    toast.success("Rejecting request...");
    axios.delete(`${URL}/api/staff/owner_request/${id}`).then((res) => {
      toast.success("Request rejected!");
    });
    this.setState({
      reload: true,
    });
  };

  accept = async (id) => {
    toast.success("accepting request...");
    axios.post(`${URL}/api/staff/owner_request`, { owner: id }).then((res) => {
      toast.success("Request accepted!");
    });
    this.setState({
      reload: true,
    });
  };

  render() {
    return (
      <React.Fragment>
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            marginBottom: "20px",
          }}
        >
          <i
            className="flaticon-employees mr-3 mb-3"
            style={{ fontSize: "35px", color: "#3F4254" }}
          ></i>

          <h3 style={{ marginBottom: "2%" }}>
            {/*<span className="flaticon-employees mr-3"  style={{fontSize:"35px" ,fontWeight:"bold"}}></span>*/}
            Owner Requests{" "}
          </h3>
        </div>
        <div className="page-content">
          <div className="row">
            <div className="col-sm-6 col-md-8 col-lg-8"></div>
            <div
              className="col-sm-6 col-md-4 col-lg-4"
              style={{ float: "right" }}
            >
              {this.state.error && this.state.error ? (
                <Alert variant="danger">{this.state.error}</Alert>
              ) : null}
              {this.state.msg && this.state.msg ? (
                <Alert variant="success">{this.state.msg}</Alert>
              ) : null}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <DataGrid
                end_point="owner_request"
                title="Owner Requests"
                file_name="Requests Report"
                new_action="on_different_page"
                new_record_url="/resident/add"
                edit_record_url="/resident/update"
                title_small="resident"
                list={this.state.list}
                columns={this.state.columns}
                permission_label="owner"
                show_add_button={false}
                show_edit_button={false}
                show_delete_button={false}
                show_approve_button={true}
                show_reject_button={true}
                approve_permission="create"
                reject_permission="delete"
                accept={(id) => {
                  this.accept(id);
                }}
                reject={(id) => this.reject(id)}
                reload={this.state.reload}
                setReload={() => {
                  this.setState({ reload: false });
                }}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Member);
