import React, { Component } from "react";
import { withRouter } from "react-router";
import axios from "axios";
import isEmpty from "../../../../Validations/is-empty";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../../_metronic/_partials/controls";
import "react-confirm-alert/src/react-confirm-alert.css";
import "jspdf-autotable";
import CheckPermission from "../../components/CheckPermission";
import DataGrid from "../../../Component/DataGrid";
import { Select, TextInput } from "../../../Component/Form";
import { toast } from "react-toastify";
const URL = process.env.REACT_APP_API_URL;
class GateTerminal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_title: "Add Gate Terminal",
      button_title: "Add",
      action: "Save",
      name: "",
      status: "Active",
      permissionArray: [],
      data: [],
      reload: false,
      id: 0,
      list: ["Sr", "Name", "Status"],
      show: ["Sr", "Name", "Status"],
      columns: [
        {
          column_name: "Sr",
          field_name: "id",
          width: "15%",
          type: "label",
        },
        {
          column_name: "Name",
          field_name: "name",
          width: "40%",
          type: "label",
        },
        {
          column_name: "Status",
          field_name: "status",
          width: "35%",
          type: "label",
        },
      ],
      statuses: [
        {
          value: "Active",
          label: "Active",
        },
        {
          value: "Inactive",
          label: "In Active",
        },
      ],
      select: ["none"],
    };
    this.Save = this.Save.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  CheckKey = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      this.Save();
    }
  };

  UpdateData = (obj) => {
    this.setState({
      id: obj.id,
      name: obj.name,
      status: obj.status,
      modal_title: "Update Gate Terminal",
      button_title: "Update",
      action: "Update",
    });
  };

  AddData = () => {
    this.setState({
      id: 0,
      name: "",
      status: "",
      modal_title: "Add Gate Terminal",
      button_title: "Add",
      action: "Save",
    });
  };

  componentWillMount() {}

  async Save() {
    if (!isEmpty(this.state.name)) {
      const data = { name: this.state.name, status: this.state.status };
      if (this.state.action === "Save") {
        toast.success("Creating gate terminal ...");
        axios
          .post(`${URL}/api/staff/gate_terminal`, data)
          .then(async (res) => {
            this.setState({
              id: "",
              name: "",
              status: "Active",
              action: "Save",
              modal_title: "Add Type",
              button_title: "Add",
              reload: true,
            });
            toast.success("Gate Terminal created successfully!");
          })
          .catch((err) => {
            console.log(err.response);
          });
      } else {
        toast.success("Updating gate terminal...");
        axios
          .put(`${URL}/api/staff/gate_terminal/${this.state.id}`, data)

          .then(async (res) => {
            this.setState({
              id: "",
              name: "",
              status: "Active",
              action: "Save",
              modal_title: "Add Type",
              button_title: "Add",
              reload: true,
            });
            toast.success("Gate Terminal upadted successfully!");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      this.setState({
        msg: "",
        error: "Please enter a terminal name first!",
      });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    return (
      <React.Fragment>
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            marginBottom: "20px",
          }}
        >
          <i
            className="flaticon-log-format  "
            style={{
              fontSize: "35px",
              color: "black",
              fontWeight: "900",
            }}
          ></i>
          <h3 id="modal" style={{ marginBottom: "15px" }}>
            <span style={{ marginBottom: "15px" }}>Gate Terminals</span>
          </h3>
        </div>
        <div className="page-content">
          <div className="row">
            {(CheckPermission("create-gate-terminal") ||
              (CheckPermission("update-gate-terminal") &&
                this.state.action !== "Save")) && (
              <div className="col-sm-12 col-md-6 col-lg-4">
                <Card>
                  <CardHeader title={this.state.modal_title}></CardHeader>
                  <CardBody>
                    <TextInput
                      label="Terminal Name *"
                      name="name"
                      width="col-12"
                      CheckKey={this.CheckKey}
                      type="text"
                      value={this.state.name}
                      icon="flaticon-calendar"
                      onChange={this.onChange}
                      includeIcon={true}
                    />

                    <Select
                      width="col-12"
                      label="Status *"
                      name="status"
                      onChange={this.onChange}
                      value={this.state.status}
                      data={this.state.statuses}
                    />

                    <button
                      className="btn btn-primary"
                      onClick={() => this.Save()}
                      style={{ float: "right" }}
                    >
                      {this.state.button_title}
                    </button>

                    <button
                      className="btn btn-danger"
                      onClick={() => this.AddData()}
                      name="cancel"
                      style={{ float: "left" }}
                    >
                      Cancel
                    </button>
                  </CardBody>
                </Card>
              </div>
            )}

            <div className="col-sm-12 col-md-6 col-lg-8">
              <DataGrid
                end_point="gate_terminal"
                title="Gate Terminal"
                file_name="Gate Terminal Report"
                new_action="on_same_page"
                new_record_url="/owner/add"
                edit_record_url="/owner/update"
                title_small="gate terminal"
                list={this.state.list}
                columns={this.state.columns}
                permission_label="gate-terminal"
                AddData={this.AddData}
                UpdateData={this.UpdateData}
                reload={this.state.reload}
                setReload={() => {
                  this.setState({ reload: false });
                }}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(GateTerminal);
