import React, { Component } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import ReactToPrint from "react-to-print";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Table } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SearchForTable from "../SearchForTable";
import { Avatar, Menu, Button, MenuItem, Checkbox } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ExportCsv from "../../ECommerce/components/Export";
import { Link } from "react-router-dom";
import $ from "jquery";
import _ from "lodash";
import moment from "moment";
import isEmpty from "../../../Validations/is-empty";
import CheckPermission from "../../ECommerce/components/CheckPermission";
import {
  fetchByDotOperator,
  GetNestedArrayString,
  GetNestedObjectString,
} from "../Grid/ObjectFunctions";
import { EditIcon, DeleteIcon, TickIcon, CrossIcon } from "../Icons";
import makeCSVData from "../Grid/MakeCSVData";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
const URL = process.env.REACT_APP_API_URL;
class DataGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pageNo: "1",
      itemsPerPage: "20",
      maxSize: 0,
      image: "",
      qr: "",
      totalPages: "0",
      totalItems: 0,
      sort: false,
      permissionArray: [],
      anchorEl: null,
      anchorEls: null,
      csvData: [],
      search: "",
      list: props.list,
      show: props.list,
      select: ["none"],
      reload: false,
      URL: `${URL}/api/staff/${this.props.end_point}`,
      search_query_param: props.search_query_param
        ? props.search_query_param
        : "search",
    };
    this.PaginateData = this.PaginateData.bind(this);
    this.GetPaginatedData = this.GetPaginatedData.bind(this);
    this.GetPageData = this.GetPageData.bind(this);
    this.PreviousPageData = this.PreviousPageData.bind(this);
    this.NextPageData = this.NextPageData.bind(this);
    this.GetTotalPages = this.GetTotalPages.bind(this);
    this.SetButtons = this.SetButtons.bind(this);
    this.Delete = this.Delete.bind(this);
  }

  PaginateData(PageNo) {
    this.setState({ pageNo: PageNo + "" }, () => {
      axios
        .get(
          `${this.state.URL}?page=${this.state.pageNo}&limit=${this.state.itemsPerPage}&${this.state.search_query_param}=${this.state.search}`
        )
        .then((res) => {
          this.setState(
            {
              data: res.data.data,
              totalPages: Math.ceil(
                res.data.meta.total / res.data.meta.per_page
              ),
              totalItems: res.data.meta.total,
            },
            () => {
              this.makeCSVData(res.data.data, this.props.columns);
              this.SetButtons();
            }
          );
        });
    });
  }

  async GetTotalPages() {
    await axios
      .get(
        `${this.state.URL}?page=1&limit=${this.state.itemsPerPage}&${this.state.search_query_param}=${this.state.search}`
      )
      .then((res) => {
        this.setState(
          {
            data: res.data.data,
            totalPages: Math.ceil(res.data.meta.total / res.data.meta.per_page),
            totalItems: res.data.meta.total,
          },
          () => {
            // this.makeCSVData(res.data.data, this.props.columns);
            this.SetButtons();
            if (this.state.reload) {
              this.props.setReload();
            }
          }
        );
      })
      .catch((err) => {
        console.log(err);
      });
    let array = [];
    if (JSON.parse(localStorage.getItem("permission"))) {
      JSON.parse(localStorage.getItem("permission")).map(async (v) => {
        await array.push(v.name);
      });
    }
    this.setState({
      permissionArray: array,
    });
  }

  GetPaginatedData = () => {
    axios
      .get(
        `${this.state.URL}?page=${this.state.pageNo}&limit=${this.state.itemsPerPage}&${this.state.search_query_param}=${this.state.search}`
      )
      .then((res) => {
        this.setState(
          {
            data: res.data.data,
            totalPages: Math.ceil(res.data.meta.total / res.data.meta.per_page),
            totalItems: res.data.meta.total,
          },
          () => {
            this.makeCSVData(res.data.data, this.props.columns);
            this.SetButtons();
          }
        );
      });
  };

  handleDeleteALert = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>Are you sure?</h1>
            <p>You want to delete this {this.props.title}?</p>
            <button
              className="btn btn-danger"
              onClick={onClose}
              style={{ marginRight: 10 }}
            >
              No
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                this.Delete(id);
                onClose();
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  };

  Delete = (id) => {
    toast.success(`Deleting ${this.props.title_small} ...`);
    axios
      .delete(`${this.state.URL}/${id}`)
      .then(async (res) => {
        toast.success(`${this.props.title} deleted successfully!`);
        this.setState(
          {
            msg: res.data.message,
            error: "",
          },
          () => {
            this.GetTotalPages();
          }
        );
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  GetPageData = (id) => {
    var data = {
      itemsPerPage: this.state.itemsPerPage,
      search: this.state.search,
      pageNo: this.state.pageNo,
    };
    if (id === "1") {
      this.setState({ pageNo: $("#bnt1").text() }, () => {
        data.pageNo = this.state.pageNo;
      });
    } else if (id === "2") {
      this.setState({ pageNo: $("#bnt2").text() }, () => {
        data.pageNo = this.state.pageNo;
      });
    } else if (id === "3") {
      this.setState({ pageNo: $("#bnt3").text() }, () => {
        data.pageNo = this.state.pageNo;
      });
    } else if (id === "4") {
      this.setState({ pageNo: $("#bnt4").text() }, () => {
        data.pageNo = this.state.pageNo;
      });
    } else if (id === "5") {
      this.setState({ pageNo: $("#bnt5").text() }, () => {
        data.pageNo = this.state.pageNo;
      });
    }
    axios
      .get(`${this.state.URL}?page=${id}&limit=${this.state.itemsPerPage}`)
      .then(async (res) => {
        this.setState(
          {
            data: res.data.data,
            totalPages: Math.ceil(res.data.meta.total / res.data.meta.per_page),
            totalItems: res.data.meta.total,
          },
          () => {
            this.makeCSVData(res.data.data, this.props.columns);
            this.SetButtons();
          }
        );
      });
  };

  PreviousPageData = () => {
    if (this.state.pageNo !== 1) {
      this.setState({ pageNo: Number(this.state.pageNo) - 1 + "" }, () => {
        axios
          .get(
            `${this.state.URL}?page=${this.state.pageNo}&limit=${this.state.itemsPerPage}&${this.state.search_query_param}=${this.state.search}`
          )
          .then(async (res) => {
            this.setState(
              {
                data: res.data.data,
                totalPages: Math.ceil(
                  res.data.meta.total / res.data.meta.per_page
                ),
                totalItems: res.data.meta.total,
              },
              () => {
                this.makeCSVData(res.data.data, this.props.columns);
                this.SetButtons();
              }
            );
          });
      });
    }
  };

  NextPageData = () => {
    this.setState({ pageNo: Number(this.state.pageNo) + 1 + "" }, () => {
      axios
        .get(
          `${this.state.URL}?page=${this.state.pageNo}&limit=${this.state.itemsPerPage}&${this.state.search_query_param}=${this.state.search}`
        )
        .then(async (res) => {
          this.setState(
            {
              data: res.data.data,
              totalPages: Math.ceil(
                res.data.meta.total / res.data.meta.per_page
              ),
              totalItems: res.data.meta.total,
            },
            () => {
              this.makeCSVData(res.data.data, this.props.columns);
              this.SetButtons();
            }
          );
        });
    });
  };

  SetButtons = () => {
    if (parseInt(this.state.pageNo) === 1) {
      $("#btnFirst").prop("disabled", true);
      $("#btnPrevious").prop("disabled", true);
      $("#btnNext").prop("disabled", false);
      $("#btnLast").prop("disabled", false);
      $("#bnt1").removeClass("btn-outline-primary");
      $("#bnt1").addClass("btn-primary");
      $("#bnt2").removeClass("btn-primary");
      $("#bnt2").addClass("btn-outline-primary");
      $("#bnt4").removeClass("btn-primary");
      $("#bnt4").addClass("btn-outline-primary");
      $("#bnt3").removeClass("btn-primary");
      $("#bnt3").addClass("btn-outline-primary");
      $("#bnt5").removeClass("btn-primary");
      $("#bnt5").addClass("btn-outline-primary");
      $("#bnt1").html("1");
      $("#bnt2").html("2");
      $("#bnt3").html("3");
      $("#bnt4").html("4");
      $("#bnt5").html("5");
      if (parseInt(this.state.totalPages) === 1) {
        $("#btnNext").prop("disabled", true);
        $("#btnLast").prop("disabled", true);
      }
    } else if (
      parseInt(this.state.pageNo) === parseInt(this.state.totalPages)
    ) {
      $("#btnFirst").prop("disabled", false);
      $("#btnPrevious").prop("disabled", false);
      $("#btnNext").prop("disabled", true);
      $("#btnLast").prop("disabled", true);
      if (parseInt(this.state.totalPages) < 6) {
        $("#bnt" + this.state.totalPages).removeClass("btn-outline-primary");
        $("#bnt" + this.state.totalPages).addClass("btn-primary");
        for (var i = parseInt(this.state.totalPages) - 1; i > 0; i--) {
          $("#bnt" + i).removeClass("btn-primary");
          $("#bnt" + i).addClass("btn-outline-primary");
        }
      } else {
        $("#bnt5").removeClass("btn-outline-primary");
        $("#bnt5").addClass("btn-primary");
        $("#bnt4").removeClass("btn-primary");
        $("#bnt4").addClass("btn-outline-primary");
        $("#bnt3").removeClass("btn-primary");
        $("#bnt3").addClass("btn-outline-primary");
        $("#bnt2").removeClass("btn-primary");
        $("#bnt2").addClass("btn-outline-primary");
        $("#bnt1").removeClass("btn-primary");
        $("#bnt1").addClass("btn-outline-primary");

        $("#bnt1").html("" + (parseInt(this.state.pageNo) - 4));
        $("#bnt2").html("" + (parseInt(this.state.pageNo) - 3));
        $("#bnt3").html("" + (parseInt(this.state.pageNo) - 2));
        $("#bnt4").html("" + (parseInt(this.state.pageNo) - 1));
        $("#bnt5").html("" + parseInt(this.state.pageNo));
      }
    } else {
      $("#btnFirst").prop("disabled", false);
      $("#btnPrevious").prop("disabled", false);
      $("#btnNext").prop("disabled", false);
      $("#btnLast").prop("disabled", false);
      if (parseInt(this.state.totalPages) < 6) {
        for (var j = parseInt(this.state.totalPages); j > 0; j--) {
          $("#bnt" + j).removeClass("btn-primary");
          $("#bnt" + j).addClass("btn-outline-primary");
        }
        $("#bnt" + this.state.pageNo).removeClass("btn-outline-primary");
        $("#bnt" + this.state.pageNo).addClass("btn-primary");
      } else {
        if (parseInt(this.state.pageNo) === 2) {
          $("#bnt5").addClass("btn-outline-primary");
          $("#bnt5").removeClass("btn-primary");
          $("#bnt4").removeClass("btn-primary");
          $("#bnt4").addClass("btn-outline-primary");
          $("#bnt3").removeClass("btn-primary");
          $("#bnt3").addClass("btn-outline-primary");
          $("#bnt2").removeClass("btn-outline-primary");
          $("#bnt2").addClass("btn-primary");
          $("#bnt1").removeClass("btn-primary");
          $("#bnt1").addClass("btn-outline-primary");
        } else if (parseInt(this.state.pageNo) === 3) {
          $("#bnt1").html("1");
          $("#bnt2").html("2");
          $("#bnt3").html("3");
          $("#bnt4").html("4");
          $("#bnt5").html("5");
          $("#bnt5").addClass("btn-outline-primary");
          $("#bnt5").removeClass("btn-primary");
          $("#bnt4").removeClass("btn-primary");
          $("#bnt4").addClass("btn-outline-primary");
          $("#bnt2").removeClass("btn-primary");
          $("#bnt2").addClass("btn-outline-primary");
          $("#bnt3").removeClass("btn-outline-primary");
          $("#bnt3").addClass("btn-primary");
          $("#bnt1").removeClass("btn-primary");
          $("#bnt1").addClass("btn-outline-primary");
        } else {
          if (
            parseInt(this.state.totalPages) - parseInt(this.state.pageNo) >
            2
          ) {
            $("#bnt1").html("" + (parseInt(this.state.pageNo) - 2));
            $("#bnt2").html("" + (parseInt(this.state.pageNo) - 1));
            $("#bnt3").html("" + parseInt(this.state.pageNo));
            $("#bnt4").html("" + (parseInt(this.state.pageNo) + 1));
            $("#bnt5").html("" + (parseInt(this.state.pageNo) + 2));
          } else if (
            parseInt(this.state.totalPages) - parseInt(this.state.pageNo) >
            1
          ) {
            $("#bnt1").html("" + (parseInt(this.state.pageNo) - 2));
            $("#bnt2").html("" + (parseInt(this.state.pageNo) - 1));
            $("#bnt3").html("" + parseInt(this.state.pageNo));
            $("#bnt4").html("" + (parseInt(this.state.pageNo) + 1));
            $("#bnt5").html("" + (parseInt(this.state.pageNo) + 2));
            $("#bnt5").addClass("btn-outline-primary");
            $("#bnt5").removeClass("btn-primary");
            $("#bnt4").removeClass("btn-primary");
            $("#bnt4").addClass("btn-outline-primary");
            $("#bnt2").removeClass("btn-outline-primary");
            $("#bnt2").addClass("btn-outline-primary");
            $("#bnt3").removeClass("btn-outline-primary");
            $("#bnt3").addClass("btn-primary");
            $("#bnt1").removeClass("btn-primary");
            $("#bnt1").addClass("btn-outline-primary");
          } else if (
            parseInt(this.state.totalPages) - parseInt(this.state.pageNo) ===
            1
          ) {
            $("#bnt1").html("" + (parseInt(this.state.pageNo) - 3));
            $("#bnt2").html("" + (parseInt(this.state.pageNo) - 2));
            $("#bnt3").html("" + (parseInt(this.state.pageNo) - 1));
            $("#bnt4").html("" + parseInt(this.state.pageNo));
            $("#bnt5").html("" + (parseInt(this.state.pageNo) + 1));
            $("#bnt5").addClass("btn-outline-primary");
            $("#bnt5").removeClass("btn-primary");
            $("#bnt3").removeClass("btn-primary");
            $("#bnt3").addClass("btn-outline-primary");
            $("#bnt2").removeClass("btn-outline-primary");
            $("#bnt2").addClass("btn-outline-primary");
            $("#bnt4").removeClass("btn-outline-primary");
            $("#bnt4").addClass("btn-primary");
            $("#bnt1").removeClass("btn-primary");
            $("#bnt1").addClass("btn-outline-primary");
          }
        }
      }
    }
  };

  componentWillMount() {
    this.setState({ show: this.props.list });
    if (CheckPermission(`view-${this.props.permission_label}`))
      this.GetTotalPages();
  }

  handleClick = (path, b, type, object_name = "") => {
    const char = this.state.data;
    let ch = [];
    if (type === "object") {
      ch = _.orderBy(
        char,
        (obj) => {
          return fetchByDotOperator(
            obj,
            GetNestedObjectString(object_name) + path
          );
        },
        [this.state.sort ? "asc" : "desc"]
      );
    } else {
      ch = _.orderBy(char, [path], [this.state.sort ? "asc" : "desc"]);
    }
    this.setState({ data: [] });
    this.setState({ data: ch });
    this.setState({ sort: !this.state.sort });
    this.setState({ select: [b] });
  };

  componentDidMount() {
    if (CheckPermission(`view-${this.props.permission_label}`))
      this.GetTotalPages();
  }

  componentWillReceiveProps(nextProps) {
    console.log(nextProps);
    if (nextProps.reload) {
      this.setState({ reload: true }, () => {
        this.GetTotalPages();
      });
    }
  }

  showClick = (v) => {
    let newArray = [];
    if (this.state.show.includes(v)) {
      newArray = this.state.show.filter((e) => e !== v);
      this.setState({ show: newArray });
    } else {
      newArray = this.state.show;
      newArray.push(v);
      this.setState({ show: newArray });
    }
  };

  onSubmitValue = (val) => {
    this.setState({ search: val ? val : "", pageNo: 1 }, () => {
      this.GetTotalPages();
    });
  };

  // makeCSVData = (data, columns) => {
  //   let array = [];
  //   for (var i = 0; i < data.length; i++) {
  //     let obj = {};
  //     for (var j = 0; j < columns.length; j++) {
  //       if (columns[j].column_name === "Sr")
  //         obj = { ...obj, [columns[j].column_name]: i + 1 };
  //       else {
  //         if (columns[j].type === "object") {
  //           obj = {
  //             ...obj,
  //             [columns[j].column_name]: !isEmpty(
  //               fetchByDotOperator(
  //                 data[i],
  //                 GetNestedObjectString(columns[j].object_name) +
  //                   columns[j].field_name
  //               )
  //             )
  //               ? fetchByDotOperator(
  //                   data[i],
  //                   GetNestedObjectString(columns[j].object_name) +
  //                     columns[j].field_name
  //                 )
  //               : "",
  //           };
  //         } else if (columns[j].type === "date") {
  //           obj = {
  //             ...obj,
  //             [columns[j].column_name]:
  //               moment
  //                 .utc(data[i][columns[j].field_name])
  //                 .local()
  //                 .format("MM-DD-YYYY") !== "Invalid date"
  //                 ? moment
  //                     .utc(data[i][columns[j].field_name])
  //                     .local()
  //                     .format("MM-DD-YYYY")
  //                 : "",
  //           };
  //         } else {
  //           obj = {
  //             ...obj,
  //             [columns[j].column_name]: !isEmpty(data[i][columns[j].field_name])
  //               ? data[i][columns[j].field_name]
  //               : "",
  //           };
  //         }
  //       }
  //     }
  //     array.push(obj);
  //   }
  //   this.setState({
  //     csvData: array,
  //   });
  // };

  render() {
    const handleClicks = (event) => {
      this.setState({ anchorEl: event.currentTarget });
    };

    const handleClose = () => {
      this.setState({ anchorEl: null });
    };
    return (
      <Card ref={(el) => (this.componentRef = el)}>
        <CardHeader title={this.props.title} className="notPrint">
          <CardHeaderToolbar className="notPrint">
            {CheckPermission(`view-${this.props.permission_label}`) && (
              <>
                <Button
                  color="secondary"
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleClicks}
                  className="mt-5"
                >
                  <VisibilityIcon />
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={this.state.anchorEl}
                  keepMounted
                  open={Boolean(this.state.anchorEl)}
                  onClose={handleClose}
                >
                  {this.state.list.map((v, k) => (
                    <MenuItem
                      key={k}
                      style={{ textTransform: "capitalize" }}
                      onClick={() => this.showClick(v)}
                    >
                      <Checkbox checked={this.state.show.includes(v)} /> {v}
                    </MenuItem>
                  ))}
                </Menu>
                <ExportCsv
                  csvData={makeCSVData(this.state.data, this.props.columns)}
                  fileName={this.props.file_name}
                />
                <ReactToPrint
                  trigger={() => {
                    return (
                      <Button
                        className="ml-5 mr-5"
                        color="secondary"
                        variant="outlined"
                        onClick={this.print}
                      >
                        Print
                      </Button>
                    );
                  }}
                  content={() => this.componentRef}
                />
              </>
            )}
            {CheckPermission(`create-${this.props.permission_label}`) &&
              (this.props.show_add_button == null ||
                this.props.show_add_button == true) &&
              (this.props.new_action === "on_same_page" ? (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.props.AddData()}
                  name="add_button"
                >
                  Add New {this.props.title}
                </button>
              ) : (
                <Link
                  type="button"
                  className="btn btn-primary"
                  name="add_button"
                  to={this.props.new_record_url}
                >
                  Add New {this.props.title}
                </Link>
              ))}
          </CardHeaderToolbar>
        </CardHeader>
        {CheckPermission(`view-${this.props.permission_label}`) && (
          <>
            <div className="notPrint">
              <SearchForTable onSubmitValue={this.onSubmitValue} />{" "}
            </div>
            <div className="row mt-5 ml-5 mr-5 print d-none ">
              <div className="col-12">
                <h4
                  className="text-center mt-5 mb-5"
                  style={{ marginBottom: "25px" }}
                >
                  {this.props.title}
                </h4>
              </div>
            </div>
            <CardBody>
              <Table responsive>
                <thead>
                  <tr>
                    {this.props.columns.map((column) =>
                      this.state.show.includes(column.column_name) ? (
                        <th
                          width={column.width}
                          onClick={() => {
                            this.handleClick(
                              column.field_name,
                              column.column_name,
                              column.type,
                              column?.object_name
                            );
                          }}
                        >
                          <span style={{ display: "flex" }}>
                            {column.column_name}
                            <div
                              className="d-flex"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <ExpandLessIcon
                                style={{
                                  height: "16px",
                                  color:
                                    this.state.sort === false &&
                                    this.state.select.includes(
                                      column.column_name
                                    )
                                      ? "black"
                                      : "#898f8b",
                                  marginTop: "-3px",
                                }}
                              />
                              <ExpandMoreIcon
                                style={{
                                  height: "16px",
                                  color:
                                    this.state.sort === true &&
                                    this.state.select.includes(
                                      column.column_name
                                    )
                                      ? "black"
                                      : "#898f8b",
                                  marginTop: "-8px",
                                }}
                              />
                            </div>
                          </span>
                        </th>
                      ) : null
                    )}
                    <th
                      width={
                        this.props.new_action === "on_same_page" ? "20%" : "10%"
                      }
                      className="text-center notPrint"
                    >
                      Events
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.data ? (
                    this.state.data.map((obj, k) => (
                      <tr key={k}>
                        {this.props.columns.map((column) =>
                          this.state.show.includes(column.column_name) ? (
                            <td>
                              {column.column_name === "Sr"
                                ? this.state.itemsPerPage *
                                    (this.state.pageNo - 1) +
                                  k +
                                  1
                                : column.type === "object"
                                ? fetchByDotOperator(
                                    obj,
                                    GetNestedObjectString(column.object_name) +
                                      column.field_name
                                  )
                                : column.type === "array_in_an_object"
                                ? obj[column.object_name[0]][
                                    column.object_name[1]
                                  ][0][column.field_name] + ""
                                : obj[column.field_name]}
                            </td>
                          ) : null
                        )}
                        <td className="text-center notPrint">
                          {CheckPermission(
                            `update-${this.props.permission_label}`
                          ) &&
                            (this.props.show_edit_button == null ||
                              this.props.show_edit_button == true) &&
                            (this.props.new_action === "on_same_page" ? (
                              <button
                                onClick={() => this.props.UpdateData(obj)}
                                className="btn btn-xs btn-default btn-text-primary btn-hover-primary btn-icon btn-icon-primary mr-2"
                                title="Edit details"
                              >
                                <span className="svg-icon svg-icon-md">
                                  <EditIcon />
                                </span>
                              </button>
                            ) : (
                              <Link
                                className="btn btn-xs btn-default btn-text-primary btn-hover-primary btn-icon btn-icon-primary mr-2"
                                title="Edit details"
                                to={{
                                  pathname: this.props.edit_record_url,
                                  param1: obj,
                                  param2: "update",
                                }}
                              >
                                <span className="svg-icon svg-icon-md">
                                  <EditIcon />
                                </span>
                              </Link>
                            ))}
                          {CheckPermission(
                            `delete-${this.props.permission_label}`
                          ) &&
                            (this.props.show_edit_button == null ||
                              this.props.show_edit_button == true) && (
                              <button
                                onClick={() => this.handleDeleteALert(obj.id)}
                                className="btn btn-xs btn-default btn-text-primary btn-hover-danger btn-icon btn-icon-danger "
                                title="Delete"
                                ng-click="Delete(role.srNo)"
                              >
                                <span className="svg-icon svg-icon-md">
                                  <DeleteIcon />
                                </span>
                              </button>
                            )}
                          {CheckPermission(
                            `${this.props.approve_permission}-${this.props.permission_label}`
                          ) &&
                            this.props.show_approve_button == true && (
                              <button
                                onClick={() => this.props.accept(obj.id)}
                                className="btn btn-xs btn-default btn-text-primary btn-hover-primary btn-icon btn-icon-primary "
                                title="Reject"
                              >
                                <span className="svg-icon svg-icon-md">
                                  <TickIcon />
                                </span>
                              </button>
                            )}
                          {CheckPermission(
                            `${this.props.reject_permission}-${this.props.permission_label}`
                          ) &&
                            this.props.show_reject_button == true && (
                              <button
                                onClick={() => this.props.reject(obj.id)}
                                className="btn btn-xs btn-default btn-text-primary btn-hover-danger btn-icon btn-icon-danger ml-2"
                                title="Reject"
                              >
                                <span className="svg-icon svg-icon-md">
                                  <CrossIcon />
                                </span>
                              </button>
                            )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <h3>Loading...</h3>
                  )}
                </tbody>
                <tfoot className="notPrint">
                  <tr>
                    <td align="center" colSpan={this.props.columns.length + 1}>
                      <div className="pull-right">
                        <button
                          id="btnFirst"
                          onClick={() => this.PaginateData(1)}
                          className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                        >
                          First
                        </button>
                        <button
                          id="btnPrevious"
                          onClick={() => this.PreviousPageData()}
                          className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                        >
                          Previous
                        </button>
                        {this.state.totalPages !== "0" ? (
                          <button
                            id="bnt1"
                            onClick={() => this.GetPageData("1")}
                            className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                          >
                            1
                          </button>
                        ) : null}
                        {this.state.totalPages > "1" ? (
                          <button
                            id="bnt2"
                            onClick={() => this.GetPageData("2")}
                            className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                          >
                            2
                          </button>
                        ) : null}
                        {this.state.totalPages > "2" ? (
                          <button
                            id="bnt3"
                            onClick={() => this.GetPageData("3")}
                            className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                          >
                            3
                          </button>
                        ) : null}
                        {this.state.totalPages > "3" ? (
                          <button
                            id="bnt4"
                            onClick={() => this.GetPageData("4")}
                            className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                          >
                            4
                          </button>
                        ) : null}
                        {this.state.totalPages > "4" ? (
                          <button
                            id="bnt5"
                            onClick={() => this.GetPageData("5")}
                            className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                          >
                            5
                          </button>
                        ) : null}
                        <button
                          id="btnNext"
                          onClick={() => this.NextPageData()}
                          className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                        >
                          Next
                        </button>
                        <button
                          id="btnLast"
                          onClick={() =>
                            this.PaginateData(this.state.totalPages)
                          }
                          className="btn btn-rounded btn-sm btn-outline-primary"
                        >
                          Last
                        </button>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <label className="btn btn-primary  mt-2 pulll-left">
                            {this.state.pageNo * this.state.itemsPerPage -
                              this.state.itemsPerPage}
                            -
                            {this.state.pageNo === this.state.totalPages
                              ? this.state.totalItems
                              : this.state.pageNo * this.state.itemsPerPage}
                            / {this.state.totalItems} items
                          </label>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-md-4"></div>
                            <div className="col-md-2">
                              <select
                                className="form-control form-control-sm mt-3 pull-right"
                                value={this.state.itemsPerPage}
                                name="itemsPerPage"
                                onChange={this.onChange}
                              >
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="500">500</option>
                              </select>
                            </div>
                            <div className="col-md-6">
                              <button className="btn btn-primary mt-2 pull-right col-8">
                                Page: {this.state.pageNo} /
                                {this.state.totalPages}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tfoot>
              </Table>
            </CardBody>
          </>
        )}
      </Card>
    );
  }
}

export default DataGrid;
