import React, { Component } from "react";
import { withRouter } from "react-router";
import { Alert } from "react-bootstrap";
import "react-confirm-alert/src/react-confirm-alert.css";
import Modal from "react-modal";
import _ from "lodash";
import "jspdf-autotable";
import DataGrid from "../../../Component/DataGrid/index.js";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
const URL = process.env.REACT_APP_API_URL;
class Block extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: "",
      qr: "",
      permissionArray: [],
      list: ["Sr", "Block", "Status"],
      show: ["Sr", "Block", "Status"],
      columns: [
        {
          column_name: "Sr",
          field_name: "id",
          width: "10%",
          type: "label",
        },
        {
          column_name: "Block",
          field_name: "name",
          width: "35%",
          type: "label",
        },
        {
          column_name: "Status",
          field_name: "status",
          width: "35%",
          type: "label",
        },
      ],
      select: ["none"],
    };
  }

  openModel = (url, type) => {
    this.setState({ image: url, modelVisible: true });
  };

  closeModal = () => {
    this.setState({ modelVisible: false, audio: "", image: "" });
  };

  render() {
    return (
      <React.Fragment>
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            marginBottom: "20px",
          }}
        >
          <h3 style={{ marginBottom: "2%" }}>
            {/*<span className="flaticon-employees mr-3"  style={{fontSize:"35px" ,fontWeight:"bold"}}></span>*/}
            Project Blocks
          </h3>
        </div>
        <div className="page-content">
          <div className="row">
            <div className="col-sm-6 col-md-8 col-lg-8"></div>
            <div
              className="col-sm-6 col-md-4 col-lg-4"
              style={{ float: "right" }}
            >
              {this.state.error && this.state.error ? (
                <Alert variant="danger">{this.state.error}</Alert>
              ) : null}
              {this.state.msg && this.state.msg ? (
                <Alert variant="success">{this.state.msg}</Alert>
              ) : null}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <DataGrid
                end_point="block"
                title="Block"
                file_name="Block Report"
                new_action="on_different_page"
                new_record_url="/project/add-block"
                edit_record_url="/project/update-block"
                title_small="block"
                list={this.state.list}
                columns={this.state.columns}
                permission_label="block"
              />
            </div>
          </div>
        </div>
        <Modal
          isOpen={this.state.modelVisible}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <img
            className="no-borderRadiusImportant"
            src={this.state.image}
            width="400px"
            height="400px"
            style={{ borderRadius: "5px!important" }}
            alt=""
          />
        </Modal>
      </React.Fragment>
    );
  }
}

export default withRouter(Block);
