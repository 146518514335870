import React, { Component } from "react";
import { withRouter } from "react-router";
import ReactToPrint from "react-to-print";

import $ from "jquery";
import { Button, CircularProgress } from "@material-ui/core";
import moment from "moment";

import axios from "axios";
import { Form, Table, Alert, Modal } from "react-bootstrap";
import { Card, CardBody } from "../../../../../../_metronic/_partials/controls";
import Select from "react-select";
import { convertLocalToUTC, Constants } from "../../../../../Utils";
import Grid from "../../../../Component/Grid";
import { CalculateTime } from "../../../../Component/Grid/ObjectFunctions";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
const URL = process.env.REACT_APP_API_URL;

class QuickSchedulePatrolActivity extends Component {
  constructor(props) {
    super(props);
    var currentDate = new Date();
    var dd = String(currentDate.getDate()).padStart(2, "0");
    var mm = String(currentDate.getMonth() + 1).padStart(2, "0");
    var yyyy = currentDate.getFullYear();
    currentDate = yyyy + "-" + mm + "-" + dd;
    this.state = {
      scheduleName: "",
      guardsId: "0",
      status: "ALL",
      guards: [],
      guardName: "",
      operationTypeId: "",
      operations: [],
      checkpointName: "",
      loading: true,
      data: [],
      csvData: [],
      startDate: currentDate,
      endDate: currentDate,
      URL: `${URL}/api/staff/quick_schedule_patrol`,
      scheduleId: "0",
      schedules: [],
      details: [],
      obj: {},
      loadingExport: false,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      columns: [
        {
          column_name: "Sr",
          field_name: "id",
          width: "0%",
          type: "label",
        },
        {
          column_name: "Start At",
          field_name: "start_at",
          width: "0%",
          type: "date_time",
        },
        {
          column_name: "Guard",
          field_name: "name",
          width: "0%",
          type: "object",
          object_name: ["user", "profile"],
        },
        {
          column_name: "Schedule",
          field_name: "schedule",
          width: "0%",
          type: "label",
        },
        {
          column_name: "Total",
          field_name: "total",
          width: "0%",
          type: "label",
        },
        {
          column_name: "Visited",
          field_name: "visited",
          width: "0%",
          type: "label",
        },
        {
          column_name: "Missed",
          field_name: "pending",
          width: "0%",
          type: "label",
        },
        // {
        //   column_name: "End At",
        //   field_name: "end_at",
        //   width: "0%",
        //   type: "date_time",
        // },
        {
          column_name: "Status",
          field_name: "status",
          width: "0%",
          type: "label",
        },
      ],
      detailColumns: [
        {
          column_name: "Sr",
          field_name: "id",
          width: "0%",
          type: "label",
        },
        {
          column_name: "Number",
          field_name: "phone_number",
          width: "0%",
          type: "object",
          object_name: ["checkpoint"],
        },
        {
          column_name: "Name",
          field_name: "name",
          width: "0%",
          type: "object",
          object_name: ["checkpoint"],
        },
        {
          column_name: "Date",
          field_name: "created_at",
          width: "0%",
          type: "date_time",
        },
        {
          column_name: "Status",
          field_name: "status",
          width: "0%",
          type: "label",
        },
      ],
    };
    this.GetTotalPages = this.GetTotalPages.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  GetSchedules = () => {
    axios
      .get(`${URL}/api/staff/patrol_schedule?page=1&limit=1000`)
      .then(async (res) => {
        var newCategories = [
          { id: "0", name: "All" },
          { id: "-1", name: "Non Patrol Schedule" },
        ];
        for (var i = 0; i < res.data.data.length; i++) {
          newCategories.push({
            id: res.data.data[i].id + "",
            name: res.data.data[i].name,
          });
        }
        await this.setState({
          schedules: newCategories,
        });
      });
  };

  GetGuards = () => {
    axios
      .get(`${URL}/api/staff/client_staff?role=guard&page=1&limit=1000`)
      .then(async (res) => {
        var newCategories = [{ id: "0", name: "All" }];

        for (var i = 0; i < res.data.data.length; i++) {
          newCategories.push({
            id: res.data.data[i].id + "",
            name: res.data.data[i].profile.name,
          });
        }
        await this.setState({
          guards: newCategories,
        });
      });
  };

  ScheduleChanges = (val) => {
    this.setState({ scheduleId: val.id, scheduleName: val.name });
  };

  OperationChanges = (val) => {
    this.setState({ operationTypeId: val.id, checkpointName: val.name });
  };

  GuardChanges = (val) => {
    this.setState({ guardsId: val.id, guardName: val.name });
  };

  async GetTotalPages() {
    var URL = "";
    let sceduleQeury =
      this.state.scheduleId !== "0"
        ? `&patrolSchedule=${this.state.scheduleId}`
        : "";
    let guardQeury =
      this.state.guardsId !== "0" ? `&guard=${this.state.guardsId}` : "";
    let startDate = convertLocalToUTC(
      `${this.state.startDate} 00:00`,
      Constants.time_format.DATE_TIME_24
    );
    let endDate = convertLocalToUTC(
      `${this.state.endDate} 23:59`,
      Constants.time_format.DATE_TIME_24
    );
    URL =
      this.state.URL +
      `/list?startDate=${startDate}&endDate=${endDate}&status=${this.state.status}${sceduleQeury}${guardQeury}`;

    axios
      .get(URL)
      .then(async (res) => {
        await this.setState({
          loading: false,
        });
        let data = res.data.map((dataItem) => ({
          id: dataItem.id,
          schedule: dataItem?.patrolSchedule?.name,
          status: dataItem.status,
          start_at: dataItem.start_at,
          end_at: dataItem.end_at,
          created_at: dataItem.created_at,
          updated_at: dataItem.updated_at,
          checkpoints: dataItem.checkpoints,
          user: dataItem.user,
          visited: dataItem.visited,
          total: dataItem.checkpoints.length,
          pending: dataItem.checkpoints.length - dataItem.visited,
        }));
        await this.setState({ data });
        let obj = res.data.map((dataItem, v) => ({
          Sr: v + 1,
          Date: dataItem.start_at,
          Schedule: dataItem?.patrolSchedule?.name,
          Guard: dataItem.user.profile.name,
          Total: dataItem.checkpoints.length,
          Visited: dataItem.visited,
          Missing: dataItem.checkpoints.length - dataItem.visited,
          Status: dataItem.status,
        }));
        this.setState({ csvData: obj });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  openModel = (url, type) => {
    if (type == "audio") {
      this.setState({ type: "audio", audio: url, modelVisible: true });
    } else {
      this.setState({ type: "image", image: url, modelVisible: true });
    }
  };

  closeModal = () => {
    this.setState({ modelVisible: false, audio: "", image: "" });
  };

  filter = () => {
    this.setState({ loading: true });
    this.GetTotalPages();
  };

  componentWillMount() {
    this.GetTotalPages();
    this.GetSchedules();
    this.GetGuards();
  }

  ExportDoc = () => {
    this.setState({ loadingExport: true });
    var URL = "";
    let sceduleQeury =
      this.state.scheduleId !== "0"
        ? `&patrolSchedule=${this.state.scheduleId}`
        : "";
    let guardQeury =
      this.state.guardsId !== "0" ? `&guard=${this.state.guardsId}` : "";
    let startDate = convertLocalToUTC(
      `${this.state.startDate} 00:00`,
      Constants.time_format.DATE_TIME_24
    );
    let endDate = convertLocalToUTC(
      `${this.state.endDate} 23:59`,
      Constants.time_format.DATE_TIME_24
    );
    URL =
      this.state.URL +
      `/pdf?startDate=${startDate}&endDate=${endDate}&timezone=${this.state.timeZone}&status=${this.state.status}${sceduleQeury}${guardQeury}`;

    axios({
      url: URL,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      this.setState({ loadingExport: false });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `Quick_Patrol_Activity_${this.state.startDate}_to_${this.state.endDate}.pdf`
      );
      document.body.appendChild(link);
      link.click();
    });
  };

  render() {
    let lgClose = () => this.setState({ lgShow: false });
    const customStylesForFeild = {
      control: (base) => ({
        ...base,
        height: 33,
        minHeight: 33,
        border: "1px solid #E4E6EF",
        borderRadius: "3px",
        fontSize: 10.5,
        fontWeight: 400,
        paddingTop: -25,
      }),
    };
    return (
      <React.Fragment>
        <Modal
          size="lg"
          show={this.state.lgShow}
          onHide={lgClose}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            {this.state.details != null ? (
              <ReactToPrint
                trigger={() => {
                  return (
                    <Button
                      className="ml-5"
                      color="secondary"
                      variant="outlined"
                      onClick={this.print}
                    >
                      Print
                    </Button>
                  );
                }}
                content={() => this.print_detail}
              />
            ) : null}
          </Modal.Header>
          <Modal.Body>
            <div className="row" ref={(el) => (this.print_detail = el)}>
              <div className="col-12 text-center mb-5 mt-5">
                <h2>Quick Patrol Details</h2>
              </div>
              <div className="col-7" style={{ marginTop: "30px" }}>
                <label
                  className="col-3"
                  style={{
                    fontSize: "14px",
                    fontWeight: "normal",
                    textAlign: "right",
                  }}
                >
                  Schedule :
                </label>
                <label
                  className="col-9"
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  {this.state.obj?.patrolSchedule?.name}
                </label>
                <label
                  className="col-3"
                  style={{
                    fontSize: "14px",
                    fontWeight: "normal",
                    textAlign: "right",
                  }}
                >
                  Staff :
                </label>
                <label
                  className="col-9"
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  {this.state.obj?.user?.profile?.name
                    ? this.state.obj?.user?.profile?.name
                    : this.state.obj?.user?.username}
                </label>
                <label
                  className="col-3"
                  style={{
                    fontSize: "14px",
                    fontWeight: "normal",
                    textAlign: "right",
                  }}
                >
                  Total :
                </label>
                <label
                  className="col-9"
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  {this.state.obj?.total}
                </label>
                <label
                  className="col-3"
                  style={{
                    fontSize: "14px",
                    fontWeight: "normal",
                    textAlign: "right",
                  }}
                >
                  Visited :
                </label>
                <label
                  className="col-9"
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  {this.state.obj?.visited}
                </label>
                <label
                  className="col-3"
                  style={{
                    fontSize: "14px",
                    fontWeight: "normal",
                    textAlign: "right",
                  }}
                >
                  Missed :
                </label>
                <label
                  className="col-9"
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  {this.state.obj?.pending}
                </label>
              </div>
              <div className="col-5" style={{ marginTop: "30px" }}>
                <label
                  className="col-4"
                  style={{
                    fontSize: "14px",
                    fontWeight: "normal",
                    textAlign: "right",
                  }}
                >
                  Status :
                </label>
                <label
                  className="col-8"
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  {this.state.obj?.status}
                </label>
                <label
                  className="col-4"
                  style={{
                    fontSize: "14px",
                    fontWeight: "normal",
                    textAlign: "right",
                  }}
                >
                  Start :
                </label>
                <label
                  className="col-8"
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  {moment
                    .utc(this.state.obj?.start_at)
                    .local()
                    .format("DD-MM-YYYY hh:mm A")}
                </label>
                <label
                  className="col-4"
                  style={{
                    fontSize: "14px",
                    fontWeight: "normal",
                    textAlign: "right",
                  }}
                >
                  End :
                </label>
                <label
                  className="col-8"
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  {moment
                    .utc(this.state.obj?.end_at)
                    .local()
                    .format("DD-MM-YYYY hh:mm A")}
                </label>
                <label
                  className="col-4"
                  style={{
                    fontSize: "14px",
                    fontWeight: "normal",
                    textAlign: "right",
                  }}
                >
                  Duration :
                </label>
                <label
                  className="col-8"
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  {this.state.obj?.end_at
                    ? CalculateTime(
                        this.state.obj?.start_at,
                        this.state.obj?.end_at
                      )
                    : "Pending"}
                </label>
              </div>
              <h4 className="mt-3 mb-3">Checkpoints</h4>
              <div className="col-12">
                <Grid
                  columns={this.state.detailColumns}
                  data={this.state.details}
                />
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <div className="page-content">
          <div className="row">
            <span className="svg-icon svg-icon-primary svg-icon-3x mr-2">
              {" "}
              <svg
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
                className="kt-svg-icon"
              >
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <rect id="bound" x="0" y="0" width="24" height="24" />
                  <path
                    d="M3.5,21 L20.5,21 C21.3284271,21 22,20.3284271 22,19.5 L22,8.5 C22,7.67157288 21.3284271,7 20.5,7 L10,7 L7.43933983,4.43933983 C7.15803526,4.15803526 6.77650439,4 6.37867966,4 L3.5,4 C2.67157288,4 2,4.67157288 2,5.5 L2,19.5 C2,20.3284271 2.67157288,21 3.5,21 Z"
                    id="Combined-Shape"
                    fill="#000000"
                    opacity="0.3"
                  />
                  <path
                    d="M12,13 C10.8954305,13 10,12.1045695 10,11 C10,9.8954305 10.8954305,9 12,9 C13.1045695,9 14,9.8954305 14,11 C14,12.1045695 13.1045695,13 12,13 Z"
                    id="Mask"
                    fill="#000000"
                    opacity="0.3"
                  />
                  <path
                    d="M7.00036205,18.4995035 C7.21569918,15.5165724 9.36772908,14 11.9907452,14 C14.6506758,14 16.8360465,15.4332455 16.9988413,18.5 C17.0053266,18.6221713 16.9988413,19 16.5815,19 C14.5228466,19 11.463736,19 7.4041679,19 C7.26484009,19 6.98863236,18.6619875 7.00036205,18.4995035 Z"
                    id="Mask-Copy"
                    fill="#000000"
                    opacity="0.3"
                  />
                </g>
              </svg>
            </span>
            <h3 style={{ marginBottom: "2%" }} className="mt-3">
              Quick Patrol Schedule Activity
            </h3>
          </div>

          <div className="row">
            <div className="col-sm-6 col-md-8 col-lg-8"></div>
            <div
              className="col-sm-6 col-md-4 col-lg-4"
              style={{ float: "right" }}
            >
              {this.state.error && this.state.error ? (
                <Alert variant="danger">{this.state.error}</Alert>
              ) : null}
              {this.state.msg && this.state.msg ? (
                <Alert variant="success">{this.state.msg}</Alert>
              ) : null}
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12">
              <Card ref={(el) => (this.componentRef = el)}>
                <div className="row mt-5 ml-5 mr-5 notPrint">
                  <div className="col-lg-12 col-sm-3 col-md-3">
                    <h4>Filter</h4>
                  </div>
                  <div className="col-lg-2">
                    <Form.Group>
                      Guard
                      <Select
                        styles={customStylesForFeild}
                        name="id"
                        options={this.state.guards}
                        onChange={this.GuardChanges}
                        value={this.state?.guards?.filter(
                          ({ id }) => id === this.state.guardsId
                        )}
                        getOptionLabel={({ name }) => name}
                        getOptionValue={({ id }) => id}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-lg-2">
                    <Form.Group>
                      Schedule:
                      <Select
                        styles={customStylesForFeild}
                        name="scheduleId"
                        options={this.state.schedules}
                        onChange={this.ScheduleChanges}
                        value={this.state.schedules.filter(
                          ({ id }) => id === this.state.scheduleId
                        )}
                        getOptionLabel={({ name }) => name}
                        getOptionValue={({ id }) => id}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-lg-2">
                    <Form.Group>
                      Status:
                      <select
                        className="form-control form-control-sm"
                        name="status"
                        value={this.state.status}
                        onChange={this.onChange}
                      >
                        <option value="ALL">All</option>
                        <option value="PENDING">Pending</option>
                        <option value="COMPLETED">Completed</option>
                      </select>
                    </Form.Group>
                  </div>
                  <div className="col-lg-2">
                    <Form.Group>
                      Start Date:
                      <input
                        min={moment()
                          .subtract(90, "days")
                          .format("YYYY-MM-DD")}
                        max={moment().format("YYYY-MM-DD")}
                        className="form-control form-control-sm"
                        type="date"
                        value={this.state.startDate}
                        name="startDate"
                        onChange={this.onChange}
                      ></input>
                    </Form.Group>
                  </div>
                  <div className="col-lg-2">
                    <Form.Group>
                      End Date:
                      <input
                        className="form-control form-control-sm"
                        type="date"
                        value={this.state.endDate}
                        name="endDate"
                        onChange={this.onChange}
                        min={moment()
                          .subtract(90, "days")
                          .format("YYYY-MM-DD")}
                        max={moment().format("YYYY-MM-DD")}
                      ></input>
                    </Form.Group>
                  </div>

                  <div className="col-lg-1 mt-5">
                    <Button
                      className="ml-5"
                      color="secondary"
                      variant="outlined"
                      onClick={this.ExportDoc}
                      disabled={this.state.loadingExport}
                    >
                      {this.state.loadingExport ? "Exporting..." : "Export"}
                    </Button>
                  </div>
                  <div className=" col-lg-1 mt-5 ">
                    <ReactToPrint
                      trigger={() => {
                        return (
                          <Button
                            className="ml-5"
                            color="secondary"
                            variant="outlined"
                            onClick={this.print}
                          >
                            Print
                          </Button>
                        );
                      }}
                      content={() => this.componentRef}
                    />
                  </div>
                  {this.state.loading ? (
                    <div className="col-lg-1 mt-5">
                      <Button>
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </Button>
                    </div>
                  ) : (
                    <div className=" col-lg-1 mt-5 ">
                      <Button
                        className="ml-5"
                        color="secondary"
                        variant="contained"
                        onClick={() => this.filter()}
                      >
                        Load
                      </Button>
                    </div>
                  )}
                </div>
                <div className="row mt-5 ml-5 mr-5 print d-none ">
                  <div className="col-lg-12 col-sm-3 col-md-3">
                    <div className="col-12">
                      <h4
                        className="text-center mt-5 mb-5"
                        style={{ marginBottom: "25px" }}
                      >
                        Patrol Schedule Activity
                      </h4>
                    </div>
                  </div>
                  <div className="col-3">
                    <Form.Group>
                      Guard:
                      <br />{" "}
                      {this.state.guardName ? this.state.guardName : "All"}
                    </Form.Group>
                  </div>
                  <div className="col-3">
                    <Form.Group>
                      Schedule: <br />
                      {this.state.scheduleName
                        ? this.state.scheduleName
                        : "All"}
                    </Form.Group>
                  </div>
                  <div className="col-3">
                    <Form.Group>
                      Start Date:
                      <br /> {moment(this.state.startDate).format("YYYY-MM-DD")}
                    </Form.Group>
                  </div>
                  <div className="col-3">
                    <Form.Group>
                      End Date:
                      <br /> {moment(this.state.endDate).format("YYYY-MM-DD")}
                    </Form.Group>
                  </div>
                </div>
                <CardBody>
                  <Grid
                    columns={this.state.columns}
                    data={this.state.data}
                    detail={true}
                    GetDetail={(obj) => {
                      this.setState({
                        details: obj.checkpoints.map((item) => ({
                          ...item,
                          status: item.status ? "Visited" : "Missed",
                        })),
                        obj,
                        lgShow: true,
                      });
                    }}
                  />
                  <div
                    style={{
                      height: "20vh",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {this.state.loading ? (
                      <CircularProgress
                        color="secondary"
                        size={100}
                        thickness={4}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(QuickSchedulePatrolActivity);
