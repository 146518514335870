import React, { Component } from "react";
import { withRouter } from "react-router";

import $ from "jquery";
import axios from "axios";
import { Table, Alert } from "react-bootstrap";
import SearchForTable from "../../../Component/SearchForTable";
import StaffCard from "../../components/StaffCard";
import ReactToPrint from "react-to-print";
// import UpdateStaff from "./UpdateStaff";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import PictureAsPdfOutlinedIcon from "@material-ui/icons/PictureAsPdfOutlined";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Avatar, Menu, Button, MenuItem, Checkbox } from "@material-ui/core";
import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Modal from "react-modal";
import _ from "lodash";
import SettingsIcon from "@material-ui/icons/Settings";
import VisibilityIcon from "@material-ui/icons/Visibility";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import CheckPermission from "../../components/CheckPermission";
const URL = process.env.REACT_APP_API_URL;
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
class Staff extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pageNo: "1",
      itemsPerPage: "20",
      maxSize: 0,
      image: "",
      qr: "",
      totalPages: "0",
      totalItems: 0,
      sort: false,
      permissionArray: [],
      anchorEl: null,
      anchorEls: null,
      active: {},
      search: "",

      list: [
        "sr#",
        "name",
        "email",
        "username",
        "code",
        "image",
        "role",
        "events",
      ],
      show: [
        "sr#",
        "name",
        "email",
        "username",
        "code",
        "image",
        "role",
        "events",
      ],
      select: ["none"],
    };
    this.PaginateData = this.PaginateData.bind(this);
    this.GetPaginatedData = this.GetPaginatedData.bind(this);
    this.GetPageData = this.GetPageData.bind(this);
    this.PreviousPageData = this.PreviousPageData.bind(this);
    this.NextPageData = this.NextPageData.bind(this);
    this.GetTotalPages = this.GetTotalPages.bind(this);
    this.SetButtons = this.SetButtons.bind(this);
    this.Delete = this.Delete.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmitValue = this.onSubmitValue.bind(this);
  }

  PaginateData(PageNo) {
    this.setState({ pageNo: PageNo + "" }, () => {
      axios
        .get(
          `${URL}/api/staff/client_staff?page=${this.state.pageNo}&limit=${this.state.itemsPerPage}&search=${this.state.search}`
        )
        .then((res) => {
          this.setState(
            {
              data: res.data.data,
              totalPages: Math.ceil(
                res.data.meta.total / res.data.meta.per_page
              ),
              totalItems: res.data.meta.total,
            },
            () => {
              this.SetButtons();
            }
          );
        });
    });
  }

  async GetTotalPages() {
    await axios
      .get(
        `${URL}/api/staff/client_staff?page=1&limit=${this.state.itemsPerPage}&search=${this.state.search}`
      )
      .then((res) => {
        this.setState(
          {
            data: res.data.data,
            totalPages: Math.ceil(res.data.meta.total / res.data.meta.per_page),
            totalItems: res.data.meta.total,
          },
          () => {
            this.SetButtons();
          }
        );
      })
      .catch((err) => {});
    let array = [];
    if (JSON.parse(localStorage.getItem("permission"))) {
      JSON.parse(localStorage.getItem("permission")).map(async (v) => {
        await array.push(v.name);
      });
    }
    this.setState({
      permissionArray: array,
    });
  }

  GetPaginatedData = () => {
    axios
      .get(
        `${URL}/api/staff/client_staff?page=${this.state.pageNo}&limit=${this.state.itemsPerPage}&search=${this.state.search}`
      )
      .then((res) => {
        this.setState(
          {
            data: res.data.data,
            totalPages: Math.ceil(res.data.meta.total / res.data.meta.per_page),
            totalItems: res.data.meta.total,
          },
          () => {
            this.SetButtons();
          }
        );
      });
  };

  handleDeleteALert = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>Are you sure?</h1>
            <p>You want to delete this?</p>
            <button
              className="btn btn-danger"
              onClick={onClose}
              style={{ marginRight: 10 }}
            >
              No
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                this.Delete(id);
                onClose();
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  };

  Delete = (id) => {
    this.setState({
      msg: "Deleting Staff...",
      error: "",
    });
    axios
      .delete(`${URL}/api/staff/client_staff/${id}`)
      .then(async (res) => {
        this.setState(
          {
            msg: res.data.message,
            error: "",
          },
          async () => {
            setTimeout(() => this.GetTotalPages(), 300);
          }
        );
      })
      .catch((err) => {
        this.setState({
          msg: "",
          error: err.response.data.errors[0].message,
        });
      });
  };

  onSubmitValue = (val) => {
    this.setState({ search: val ? val : "" });
    axios
      .get(
        `${URL}/api/staff/client_staff?page=${this.state.pageNo}&limit=${this.state.itemsPerPage}&search=${val}`
      )
      .then(async (res) => {
        this.setState(
          {
            data: res.data.data,
            totalPages: Math.ceil(res.data.meta.total / res.data.meta.per_page),
            totalItems: res.data.meta.total,
          },
          () => {
            this.SetButtons();
          }
        );
      });
  };

  GetPageData = (id) => {
    var data = {
      itemsPerPage: this.state.itemsPerPage,
      search: this.state.searchText,
      pageNo: this.state.pageNo,
    };
    if (id === "1") {
      this.setState({ pageNo: $("#bnt1").text() }, () => {
        data.pageNo = this.state.pageNo;
      });
    } else if (id === "2") {
      this.setState({ pageNo: $("#bnt2").text() }, () => {
        data.pageNo = this.state.pageNo;
      });
    } else if (id === "3") {
      this.setState({ pageNo: $("#bnt3").text() }, () => {
        data.pageNo = this.state.pageNo;
      });
    } else if (id === "4") {
      this.setState({ pageNo: $("#bnt4").text() }, () => {
        data.pageNo = this.state.pageNo;
      });
    } else if (id === "5") {
      this.setState({ pageNo: $("#bnt5").text() }, () => {
        data.pageNo = this.state.pageNo;
      });
    }
    axios
      .get(
        `${URL}/api/staff/client_staff?page=${id}&limit=${this.state.itemsPerPage}&search=${this.state.search}`
      )
      .then(async (res) => {
        this.setState(
          {
            data: res.data.data,
            totalPages: Math.ceil(res.data.meta.total / res.data.meta.per_page),
            totalItems: res.data.meta.total,
          },
          () => {
            this.SetButtons();
          }
        );
      });
  };

  PreviousPageData = () => {
    if (this.state.pageNo !== 1) {
      this.setState({ pageNo: Number(this.state.pageNo) - 1 + "" }, () => {
        axios
          .get(
            `${URL}/api/staff/client_staff?page=${this.state.pageNo}&limit=${this.state.itemsPerPage}&search=${this.state.search}`
          )
          .then(async (res) => {
            this.setState(
              {
                data: res.data.data,
                totalPages: Math.ceil(
                  res.data.meta.total / res.data.meta.per_page
                ),
                totalItems: res.data.meta.total,
              },
              () => {
                this.SetButtons();
              }
            );
          });
      });
    }
  };

  NextPageData = () => {
    this.setState({ pageNo: Number(this.state.pageNo) + 1 + "" }, () => {
      axios
        .get(
          `${URL}/api/staff/client_staff?page=${this.state.pageNo}&limit=${this.state.itemsPerPage}&search=${this.state.search}`
        )
        .then(async (res) => {
          this.setState(
            {
              data: res.data.data,
              totalPages: Math.ceil(
                res.data.meta.total / res.data.meta.per_page
              ),
              totalItems: res.data.meta.total,
            },
            () => {
              this.SetButtons();
            }
          );
        });
    });
  };

  SetButtons = () => {
    if (parseInt(this.state.pageNo) === 1) {
      $("#btnFirst").prop("disabled", true);
      $("#btnPrevious").prop("disabled", true);
      $("#btnNext").prop("disabled", false);
      $("#btnLast").prop("disabled", false);
      $("#bnt1").removeClass("btn-outline-primary");
      $("#bnt1").addClass("btn-primary");
      $("#bnt2").removeClass("btn-primary");
      $("#bnt2").addClass("btn-outline-primary");
      $("#bnt4").removeClass("btn-primary");
      $("#bnt4").addClass("btn-outline-primary");
      $("#bnt3").removeClass("btn-primary");
      $("#bnt3").addClass("btn-outline-primary");
      $("#bnt5").removeClass("btn-primary");
      $("#bnt5").addClass("btn-outline-primary");
      $("#bnt1").html("1");
      $("#bnt2").html("2");
      $("#bnt3").html("3");
      $("#bnt4").html("4");
      $("#bnt5").html("5");
      if (parseInt(this.state.totalPages) === 1) {
        $("#btnNext").prop("disabled", true);
        $("#btnLast").prop("disabled", true);
      }
    } else if (
      parseInt(this.state.pageNo) === parseInt(this.state.totalPages)
    ) {
      $("#btnFirst").prop("disabled", false);
      $("#btnPrevious").prop("disabled", false);
      $("#btnNext").prop("disabled", true);
      $("#btnLast").prop("disabled", true);
      if (parseInt(this.state.totalPages) < 6) {
        $("#bnt" + this.state.totalPages).removeClass("btn-outline-primary");
        $("#bnt" + this.state.totalPages).addClass("btn-primary");
        for (var i = parseInt(this.state.totalPages) - 1; i > 0; i--) {
          $("#bnt" + i).removeClass("btn-primary");
          $("#bnt" + i).addClass("btn-outline-primary");
        }
      } else {
        $("#bnt5").removeClass("btn-outline-primary");
        $("#bnt5").addClass("btn-primary");
        $("#bnt4").removeClass("btn-primary");
        $("#bnt4").addClass("btn-outline-primary");
        $("#bnt3").removeClass("btn-primary");
        $("#bnt3").addClass("btn-outline-primary");
        $("#bnt2").removeClass("btn-primary");
        $("#bnt2").addClass("btn-outline-primary");
        $("#bnt1").removeClass("btn-primary");
        $("#bnt1").addClass("btn-outline-primary");

        $("#bnt1").html("" + (parseInt(this.state.pageNo) - 4));
        $("#bnt2").html("" + (parseInt(this.state.pageNo) - 3));
        $("#bnt3").html("" + (parseInt(this.state.pageNo) - 2));
        $("#bnt4").html("" + (parseInt(this.state.pageNo) - 1));
        $("#bnt5").html("" + parseInt(this.state.pageNo));
      }
    } else {
      $("#btnFirst").prop("disabled", false);
      $("#btnPrevious").prop("disabled", false);
      $("#btnNext").prop("disabled", false);
      $("#btnLast").prop("disabled", false);
      if (parseInt(this.state.totalPages) < 6) {
        for (var j = parseInt(this.state.totalPages); j > 0; j--) {
          $("#bnt" + j).removeClass("btn-primary");
          $("#bnt" + j).addClass("btn-outline-primary");
        }
        $("#bnt" + this.state.pageNo).removeClass("btn-outline-primary");
        $("#bnt" + this.state.pageNo).addClass("btn-primary");
      } else {
        if (parseInt(this.state.pageNo) === 2) {
          $("#bnt5").addClass("btn-outline-primary");
          $("#bnt5").removeClass("btn-primary");
          $("#bnt4").removeClass("btn-primary");
          $("#bnt4").addClass("btn-outline-primary");
          $("#bnt3").removeClass("btn-primary");
          $("#bnt3").addClass("btn-outline-primary");
          $("#bnt2").removeClass("btn-outline-primary");
          $("#bnt2").addClass("btn-primary");
          $("#bnt1").removeClass("btn-primary");
          $("#bnt1").addClass("btn-outline-primary");
        } else if (parseInt(this.state.pageNo) === 3) {
          $("#bnt1").html("1");
          $("#bnt2").html("2");
          $("#bnt3").html("3");
          $("#bnt4").html("4");
          $("#bnt5").html("5");
          $("#bnt5").addClass("btn-outline-primary");
          $("#bnt5").removeClass("btn-primary");
          $("#bnt4").removeClass("btn-primary");
          $("#bnt4").addClass("btn-outline-primary");
          $("#bnt2").removeClass("btn-primary");
          $("#bnt2").addClass("btn-outline-primary");
          $("#bnt3").removeClass("btn-outline-primary");
          $("#bnt3").addClass("btn-primary");
          $("#bnt1").removeClass("btn-primary");
          $("#bnt1").addClass("btn-outline-primary");
        } else {
          if (
            parseInt(this.state.totalPages) - parseInt(this.state.pageNo) >
            2
          ) {
            $("#bnt1").html("" + (parseInt(this.state.pageNo) - 2));
            $("#bnt2").html("" + (parseInt(this.state.pageNo) - 1));
            $("#bnt3").html("" + parseInt(this.state.pageNo));
            $("#bnt4").html("" + (parseInt(this.state.pageNo) + 1));
            $("#bnt5").html("" + (parseInt(this.state.pageNo) + 2));
          } else if (
            parseInt(this.state.totalPages) - parseInt(this.state.pageNo) >
            1
          ) {
            $("#bnt1").html("" + (parseInt(this.state.pageNo) - 2));
            $("#bnt2").html("" + (parseInt(this.state.pageNo) - 1));
            $("#bnt3").html("" + parseInt(this.state.pageNo));
            $("#bnt4").html("" + (parseInt(this.state.pageNo) + 1));
            $("#bnt5").html("" + (parseInt(this.state.pageNo) + 2));
            $("#bnt5").addClass("btn-outline-primary");
            $("#bnt5").removeClass("btn-primary");
            $("#bnt4").removeClass("btn-primary");
            $("#bnt4").addClass("btn-outline-primary");
            $("#bnt2").removeClass("btn-outline-primary");
            $("#bnt2").addClass("btn-outline-primary");
            $("#bnt3").removeClass("btn-outline-primary");
            $("#bnt3").addClass("btn-primary");
            $("#bnt1").removeClass("btn-primary");
            $("#bnt1").addClass("btn-outline-primary");
          } else if (
            parseInt(this.state.totalPages) - parseInt(this.state.pageNo) ===
            1
          ) {
            $("#bnt1").html("" + (parseInt(this.state.pageNo) - 3));
            $("#bnt2").html("" + (parseInt(this.state.pageNo) - 2));
            $("#bnt3").html("" + (parseInt(this.state.pageNo) - 1));
            $("#bnt4").html("" + parseInt(this.state.pageNo));
            $("#bnt5").html("" + (parseInt(this.state.pageNo) + 1));
            $("#bnt5").addClass("btn-outline-primary");
            $("#bnt5").removeClass("btn-primary");
            $("#bnt3").removeClass("btn-primary");
            $("#bnt3").addClass("btn-outline-primary");
            $("#bnt2").removeClass("btn-outline-primary");
            $("#bnt2").addClass("btn-outline-primary");
            $("#bnt4").removeClass("btn-outline-primary");
            $("#bnt4").addClass("btn-primary");
            $("#bnt1").removeClass("btn-primary");
            $("#bnt1").addClass("btn-outline-primary");
          }
        }
      }
    }
  };

  componentWillMount() {
    if (CheckPermission("view-staff")) this.GetTotalPages();
  }

  onChange(e) {
    var name = e.target.name;
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (name === "itemsPerPage") {
        this.GetTotalPages();
      }
    });
  }

  openModel = (url) => {
    this.setState({ image: url, modelVisible: true });
  };

  closeModal = () => {
    this.setState({ modelVisible: false, audio: "", image: "" });
  };

  handleClick = (path, b) => {
    const char = this.state.data;
    const ch = _.orderBy(char, [path], [this.state.sort ? "asc" : "desc"]);
    this.setState({ data: [] });
    this.setState({ data: ch });
    this.setState({ sort: !this.state.sort });
    this.setState({ select: [b] });
  };

  showClick = (v) => {
    let newArray = [];
    if (this.state.show.includes(v)) {
      newArray = this.state.show.filter((e) => e !== v);
      this.setState({ show: newArray });
    } else {
      newArray = this.state.show;
      newArray.push(v);
      this.setState({ show: newArray });
    }
  };

  exportPDF = () => {
    const unit = "pt";
    const size = "A4";
    const orientation = "portrait";

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Staff";
    const headers = [["sr#", "name", "email", "username", "code", "role"]];

    const data = this.state.data.map((obj, k) => [
      k + 1,
      obj.profile.name,
      obj.profile.email,
      obj.username,
      obj.clientStaff.staff_code,

      obj.role.name,
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("Staff.pdf");
  };

  render() {
    const handleClicks = (event) => {
      this.setState({ anchorEl: event.currentTarget });
    };
    const handleClicks1 = (event) => {
      this.setState({ anchorEls: event.currentTarget });
    };
    const handleClose = () => {
      this.setState({ anchorEl: null });
    };
    const handleCloses = () => {
      this.setState({ anchorEls: null });
    };
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const exportToCSV = (csvData, fileName) => {
      let d = [];
      csvData.map((v, k) =>
        d.push({
          sr: k + 1,
          name: v.profile.name,
          email: v.profile.email,
          userName: v.username,
          code: v.clientStaff.staff_code,
          role: v.role.name,
        })
      );

      const ws = XLSX.utils.json_to_sheet(d);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    };
    return (
      <React.Fragment>
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            marginBottom: "20px",
          }}
        >
          <i
            className="flaticon-employees mr-3 mb-3"
            style={{ fontSize: "35px", color: "#3F4254" }}
          ></i>

          <h3 style={{ marginBottom: "2%" }}>Project's Staff</h3>
        </div>
        <div className="page-content">
          <div className="row">
            <div className="col-sm-6 col-md-8 col-lg-8"></div>
            <div
              className="col-sm-6 col-md-4 col-lg-4"
              style={{ float: "right" }}
            >
              {this.state.error && this.state.error ? (
                <Alert variant="danger">{this.state.error}</Alert>
              ) : null}
              {this.state.msg && this.state.msg ? (
                <Alert variant="success">{this.state.msg}</Alert>
              ) : null}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <Card>
                <CardHeader title="Staff">
                  <CardHeaderToolbar>
                    {CheckPermission("view-astaff") && (
                      <>
                        <Button
                          color="secondary"
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          onClick={handleClicks}
                        >
                          <VisibilityIcon />
                        </Button>
                        <Menu
                          id="simple-menu"
                          anchorEl={this.state.anchorEl}
                          keepMounted
                          open={Boolean(this.state.anchorEl)}
                          onClose={handleClose}
                        >
                          {this.state.list.map((v, k) => (
                            <MenuItem
                              key={k}
                              style={{ textTransform: "capitalize" }}
                              onClick={() => this.showClick(v)}
                            >
                              <Checkbox checked={this.state.show.includes(v)} />{" "}
                              {v}
                            </MenuItem>
                          ))}
                        </Menu>
                        <Button
                          color="secondary"
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          onClick={handleClicks1}
                        >
                          <SettingsIcon />
                        </Button>
                        <Menu
                          id="simple-menu"
                          anchorEl={this.state.anchorEls}
                          keepMounted
                          open={Boolean(this.state.anchorEls)}
                          onClose={handleCloses}
                        >
                          <MenuItem
                            onClick={() =>
                              exportToCSV(this.state.data, "Staff")
                            }
                          >
                            Report To excel{" "}
                            <InsertDriveFileIcon
                              className="ml-2"
                              style={{ color: "#AEAFAD" }}
                            />
                          </MenuItem>
                          <MenuItem onClick={() => this.exportPDF()}>
                            Report To Pdf{" "}
                            <PictureAsPdfOutlinedIcon
                              className="ml-2"
                              style={{ color: "#AEAFAD" }}
                            />
                          </MenuItem>
                        </Menu>
                        <ReactToPrint
                          trigger={() => {
                            return (
                              <button
                                type="button"
                                className="btn btn-outline-primary ml-3 mr-3"
                                onClick={this.print}
                              >
                                Print
                              </button>
                            );
                          }}
                          content={() => this.componentRef1}
                        />
                      </>
                    )}
                    {CheckPermission("create-staff") && (
                      <Link
                        type="button"
                        className="btn btn-primary"
                        name="add_button"
                        to="/staff/add-staff"
                      >
                        Add New Staff Member
                      </Link>
                    )}
                  </CardHeaderToolbar>
                </CardHeader>
                {CheckPermission("view-staff") && (
                  <>
                    <div>
                      <SearchForTable onSubmitValue={this.onSubmitValue} />
                    </div>
                    <CardBody ref={(el) => (this.componentRef1 = el)}>
                      <div className=" row  mb-5 print d-none">
                        <h4 className="text-center col-12">Staff List</h4>
                      </div>
                      <Table responsive style={{ width: "100%" }}>
                        <thead>
                          <tr>
                            {this.state.show.includes("sr#") ? (
                              <th
                                width="7%"
                                onClick={() => this.handleClick("id", "sr#")}
                              >
                                <span style={{ display: "flex" }}>
                                  Sr #
                                  <div
                                    className="d-flex"
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <ExpandLessIcon
                                      style={{
                                        height: "16px",
                                        color:
                                          this.state.sort === false &&
                                          this.state.select.includes("sr#")
                                            ? "black"
                                            : "#898f8b",
                                        marginTop: "-3px",
                                      }}
                                    />
                                    <ExpandMoreIcon
                                      style={{
                                        height: "16px",
                                        color:
                                          this.state.sort === true &&
                                          this.state.select.includes("sr#")
                                            ? "black"
                                            : "#898f8b",
                                        marginTop: "-8px",
                                      }}
                                    />
                                  </div>
                                </span>
                              </th>
                            ) : null}
                            {this.state.show.includes("name") ? (
                              <th
                                width="15%"
                                onClick={() =>
                                  this.handleClick("profile.name", "name")
                                }
                              >
                                <span style={{ display: "flex" }}>
                                  Name
                                  <div
                                    className="d-flex"
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <ExpandLessIcon
                                      style={{
                                        height: "16px",
                                        color:
                                          this.state.sort === false &&
                                          this.state.select.includes("name")
                                            ? "black"
                                            : "#898f8b",
                                        marginTop: "-3px",
                                      }}
                                    />
                                    <ExpandMoreIcon
                                      style={{
                                        height: "16px",
                                        color:
                                          this.state.sort === true &&
                                          this.state.select.includes("name")
                                            ? "black"
                                            : "#898f8b",
                                        marginTop: "-8px",
                                      }}
                                    />
                                  </div>
                                </span>
                              </th>
                            ) : null}

                            {this.state.show.includes("email") ? (
                              <th
                                width="10%"
                                onClick={() =>
                                  this.handleClick("profile.email", "email")
                                }
                              >
                                <span style={{ display: "flex" }}>
                                  Email
                                  <div
                                    className="d-flex"
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <ExpandLessIcon
                                      style={{
                                        height: "16px",
                                        color:
                                          this.state.sort === false &&
                                          this.state.select.includes("email")
                                            ? "black"
                                            : "#898f8b",
                                        marginTop: "-3px",
                                      }}
                                    />
                                    <ExpandMoreIcon
                                      style={{
                                        height: "16px",
                                        color:
                                          this.state.sort === true &&
                                          this.state.select.includes("email")
                                            ? "black"
                                            : "#898f8b",
                                        marginTop: "-8px",
                                      }}
                                    />
                                  </div>
                                </span>
                              </th>
                            ) : null}
                            {this.state.show.includes("username") ? (
                              <th
                                width="10%"
                                onClick={() =>
                                  this.handleClick("username", "username")
                                }
                              >
                                <span style={{ display: "flex" }}>
                                  User Name
                                  <div
                                    className="d-flex"
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <ExpandLessIcon
                                      style={{
                                        height: "16px",
                                        color:
                                          this.state.sort === false &&
                                          this.state.select.includes("username")
                                            ? "black"
                                            : "#898f8b",
                                        marginTop: "-3px",
                                      }}
                                    />
                                    <ExpandMoreIcon
                                      style={{
                                        height: "16px",
                                        color:
                                          this.state.sort === true &&
                                          this.state.select.includes("username")
                                            ? "black"
                                            : "#898f8b",
                                        marginTop: "-8px",
                                      }}
                                    />
                                  </div>
                                </span>
                              </th>
                            ) : null}
                            {this.state.show.includes("code") ? (
                              <th
                                width="7%"
                                onClick={() =>
                                  this.handleClick(
                                    "clientStaff.staff_code",
                                    "code"
                                  )
                                }
                              >
                                <span style={{ display: "flex" }}>
                                  Code
                                  <div
                                    className="d-flex"
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <ExpandLessIcon
                                      style={{
                                        height: "16px",
                                        color:
                                          this.state.sort === false &&
                                          this.state.select.includes("code")
                                            ? "black"
                                            : "#898f8b",
                                        marginTop: "-3px",
                                      }}
                                    />
                                    <ExpandMoreIcon
                                      style={{
                                        height: "16px",
                                        color:
                                          this.state.sort === true &&
                                          this.state.select.includes("code")
                                            ? "black"
                                            : "#898f8b",
                                        marginTop: "-8px",
                                      }}
                                    />
                                  </div>
                                </span>
                              </th>
                            ) : null}
                            {this.state.show.includes("image") ? (
                              <th
                                width="7%"
                                onClick={() => this.handleClick("image")}
                              >
                                Image
                              </th>
                            ) : null}
                            {this.state.show.includes("role") ? (
                              <th
                                width="10%"
                                onClick={() =>
                                  this.handleClick("role.name", "role")
                                }
                              >
                                <span style={{ display: "flex" }}>
                                  Role
                                  <div
                                    className="d-flex"
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <ExpandLessIcon
                                      style={{
                                        height: "16px",
                                        color:
                                          this.state.sort === false &&
                                          this.state.select.includes("role")
                                            ? "black"
                                            : "#898f8b",
                                        marginTop: "-3px",
                                      }}
                                    />
                                    <ExpandMoreIcon
                                      style={{
                                        height: "16px",
                                        color:
                                          this.state.sort === true &&
                                          this.state.select.includes("role")
                                            ? "black"
                                            : "#898f8b",
                                        marginTop: "-8px",
                                      }}
                                    />
                                  </div>
                                </span>
                              </th>
                            ) : null}
                            {this.state.show.includes("events") ? (
                              <th width="11%" className="text-center notPrint">
                                Events
                              </th>
                            ) : null}
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.data ? (
                            this.state.data.map((obj, k) => (
                              <tr key={k}>
                                {this.state.show.includes("sr#") ? (
                                  <td>
                                    {this.state.itemsPerPage *
                                      (this.state.pageNo - 1) +
                                      k +
                                      1}
                                  </td>
                                ) : null}
                                {this.state.show.includes("name") ? (
                                  <td>{obj.profile.name}</td>
                                ) : null}
                                {this.state.show.includes("email") ? (
                                  <td>{obj.profile.email}</td>
                                ) : null}
                                {this.state.show.includes("username") ? (
                                  <td>{obj.username}</td>
                                ) : null}
                                {this.state.show.includes("code") ? (
                                  <td>{obj.clientStaff.staff_code}</td>
                                ) : null}
                                {this.state.show.includes("image") ? (
                                  <td>
                                    <Avatar
                                      alt="Remy Sharp"
                                      src={obj.image}
                                      onClick={() => this.openModel(obj.image)}
                                    />

                                    {/* <img
                              
                                alt=""
                                width="25px"
                                height="25px"
                               
                                style={{ borderRadius: "5px !important" }}
                              /> */}
                                  </td>
                                ) : null}
                                {this.state.show.includes("role") ? (
                                  <td>{obj.role.name}</td>
                                ) : null}
                                {this.state.show.includes("events") ? (
                                  <td className="text-center d-flex notPrint">
                                    <button
                                      onClick={() =>
                                        this.openModel(obj.clientStaff.qrImage)
                                      }
                                      className="btn btn-xs btn-default btn-text-primary btn-hover-primary btn-icon btn-icon-primary"
                                      style={{ marginRight: 5 }}
                                    >
                                      <span className="svg-icon svg-icon-primary svg-icon-2x">
                                        <svg viewBox="0 0 24 24" version="1.1">
                                          <g
                                            stroke="none"
                                            strokeWidth="1"
                                            fill="none"
                                            fillRule="evenodd"
                                          >
                                            <rect
                                              x="0"
                                              y="0"
                                              width="24"
                                              height="24"
                                            />
                                            <polygon
                                              fill="#000000"
                                              opacity="0.3"
                                              points="5 7 5 15 19 15 19 7"
                                            />
                                            <path
                                              d="M11,19 L11,16 C11,15.4477153 11.4477153,15 12,15 C12.5522847,15 13,15.4477153 13,16 L13,19 L14.5,19 C14.7761424,19 15,19.2238576 15,19.5 C15,19.7761424 14.7761424,20 14.5,20 L9.5,20 C9.22385763,20 9,19.7761424 9,19.5 C9,19.2238576 9.22385763,19 9.5,19 L11,19 Z"
                                              fill="#000000"
                                              opacity="0.3"
                                            />
                                            <path
                                              d="M5,7 L5,15 L19,15 L19,7 L5,7 Z M5.25,5 L18.75,5 C19.9926407,5 21,5.8954305 21,7 L21,15 C21,16.1045695 19.9926407,17 18.75,17 L5.25,17 C4.00735931,17 3,16.1045695 3,15 L3,7 C3,5.8954305 4.00735931,5 5.25,5 Z"
                                              fill="#000000"
                                              fillRule="nonzero"
                                            />
                                          </g>
                                        </svg>
                                      </span>
                                    </button>
                                    {CheckPermission("update-staff") && (
                                      <Link
                                        className="btn btn-xs btn-default btn-text-primary btn-hover-primary btn-icon btn-icon-primary mr-2"
                                        title="Edit details"
                                        ng-click="UpdateData(role)"
                                        to={{
                                          pathname: "/staff/update-staff",
                                          state: { obj: obj },
                                        }}
                                      >
                                        <span className="svg-icon svg-icon-md">
                                          <svg
                                            viewBox="0 0 24 24"
                                            version="1.1"
                                          >
                                            <g
                                              stroke="none"
                                              strokeWidth="1"
                                              fill="none"
                                              fillRule="evenodd"
                                            >
                                              <rect
                                                x="0"
                                                y="0"
                                                width="24"
                                                height="24"
                                              ></rect>
                                              <path
                                                d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z"
                                                fill="#000000"
                                                fillRule="nonzero"
                                                transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953) "
                                              ></path>
                                              <path
                                                d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z"
                                                fill="#000000"
                                                fillRule="nonzero"
                                                opacity="0.3"
                                              ></path>
                                            </g>
                                          </svg>
                                        </span>
                                      </Link>
                                    )}
                                    {CheckPermission("delete-staff") && (
                                      <button
                                        onClick={() =>
                                          this.handleDeleteALert(obj.id)
                                        }
                                        className="btn btn-xs btn-default btn-text-primary btn-hover-danger btn-icon btn-icon-danger "
                                        title="Delete"
                                        ng-click="Delete(role.srNo)"
                                      >
                                        <span className="svg-icon svg-icon-md">
                                          <svg
                                            viewBox="0 0 24 24"
                                            version="1.1"
                                          >
                                            <g
                                              stroke="none"
                                              strokeWidth="1"
                                              fill="none"
                                              fillRule="evenodd"
                                            >
                                              <rect
                                                x="0"
                                                y="0"
                                                width="24"
                                                height="24"
                                              ></rect>
                                              <path
                                                d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                                fill="#000000"
                                                fillRule="nonzero"
                                              ></path>
                                              <path
                                                d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                                fill="#000000"
                                                opacity="0.3"
                                              ></path>
                                            </g>
                                          </svg>
                                        </span>
                                      </button>
                                    )}

                                    <div
                                      onClick={async () =>
                                        await this.setState({ active: obj })
                                      }
                                    >
                                      <ReactToPrint
                                        onBeforeGetContent={() =>
                                          this.setState((prevstate) => ({
                                            ...prevstate,
                                          }))
                                        }
                                        trigger={() => {
                                          return (
                                            <button className="btn btn-xs btn-default btn-text-primary btn-hover-primary btn-icon btn-icon-primary ">
                                              <span className="svg-icon svg-icon-md ">
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                                  width="24px"
                                                  height="24px"
                                                  viewBox="0 0 24 24"
                                                  version="1.1"
                                                >
                                                  <g
                                                    stroke="none"
                                                    strokeWidth="1"
                                                    fill="none"
                                                    fillRule="evenodd"
                                                  >
                                                    <rect
                                                      x="0"
                                                      y="0"
                                                      width="24"
                                                      height="24"
                                                    />
                                                    <path
                                                      d="M16,17 L16,21 C16,21.5522847 15.5522847,22 15,22 L9,22 C8.44771525,22 8,21.5522847 8,21 L8,17 L5,17 C3.8954305,17 3,16.1045695 3,15 L3,8 C3,6.8954305 3.8954305,6 5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,15 C21,16.1045695 20.1045695,17 19,17 L16,17 Z M17.5,11 C18.3284271,11 19,10.3284271 19,9.5 C19,8.67157288 18.3284271,8 17.5,8 C16.6715729,8 16,8.67157288 16,9.5 C16,10.3284271 16.6715729,11 17.5,11 Z M10,14 L10,20 L14,20 L14,14 L10,14 Z"
                                                      fill="#000000"
                                                    />
                                                    <rect
                                                      fill="#000000"
                                                      opacity="0.3"
                                                      x="8"
                                                      y="2"
                                                      width="8"
                                                      height="2"
                                                      rx="1"
                                                    />
                                                  </g>
                                                </svg>
                                              </span>
                                            </button>
                                          );
                                        }}
                                        content={() => this.componentRef}
                                      />
                                    </div>
                                  </td>
                                ) : null}
                              </tr>
                            ))
                          ) : (
                            <h3>Loading...</h3>
                          )}
                          {console.log(
                            this.state.active?.clientStaff?.staff_code,
                            "herrree"
                          )}
                          <div style={{ display: "none" }}>
                            <StaffCard
                              name={this.state.active?.profile?.name}
                              des={this.state.active?.role?.name}
                              id={this.state.active?.clientStaff?.staff_code}
                              gender={this.state.active?.profile?.gender}
                              img={this.state.active?.image}
                              qr={this.state.active?.clientStaff?.qrImage}
                              ref={(el) => (this.componentRef = el)}
                            />
                          </div>
                        </tbody>
                        <tfoot className="notPrint">
                          <tr>
                            <td align="center" colSpan="7">
                              <div className="pull-right">
                                <button
                                  id="btnFirst"
                                  onClick={() => this.PaginateData(1)}
                                  className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                                >
                                  First
                                </button>
                                <button
                                  id="btnPrevious"
                                  onClick={() => this.PreviousPageData()}
                                  className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                                >
                                  Previous
                                </button>
                                {this.state.totalPages !== "0" ? (
                                  <button
                                    id="bnt1"
                                    onClick={() => this.GetPageData("1")}
                                    className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                                  >
                                    1
                                  </button>
                                ) : null}
                                {this.state.totalPages > "1" ? (
                                  <button
                                    id="bnt2"
                                    onClick={() => this.GetPageData("2")}
                                    className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                                  >
                                    2
                                  </button>
                                ) : null}
                                {this.state.totalPages > "2" ? (
                                  <button
                                    id="bnt3"
                                    onClick={() => this.GetPageData("3")}
                                    className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                                  >
                                    3
                                  </button>
                                ) : null}
                                {this.state.totalPages > "3" ? (
                                  <button
                                    id="bnt4"
                                    onClick={() => this.GetPageData("4")}
                                    className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                                  >
                                    4
                                  </button>
                                ) : null}
                                {this.state.totalPages > "4" ? (
                                  <button
                                    id="bnt5"
                                    onClick={() => this.GetPageData("5")}
                                    className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                                  >
                                    5
                                  </button>
                                ) : null}
                                <button
                                  id="btnNext"
                                  onClick={() => this.NextPageData()}
                                  className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                                >
                                  Next
                                </button>
                                <button
                                  id="btnLast"
                                  onClick={() =>
                                    this.PaginateData(this.state.totalPages)
                                  }
                                  className="btn btn-rounded btn-sm btn-outline-primary"
                                >
                                  Last
                                </button>
                              </div>
                              <div className="row">
                                <div className="col-6">
                                  <label className="btn btn-primary  mt-2 pulll-left">
                                    {this.state.pageNo *
                                      this.state.itemsPerPage -
                                      this.state.itemsPerPage}
                                    -
                                    {this.state.pageNo === this.state.totalPages
                                      ? this.state.totalItems
                                      : this.state.pageNo *
                                        this.state.itemsPerPage}
                                    / {this.state.totalItems} items
                                  </label>
                                </div>
                                <div className="col-6">
                                  <div className="row">
                                    <div className="col-3"></div>
                                    <div className="col-3">
                                      <select
                                        className="form-control form-control-sm mt-3 pull-right"
                                        value={this.state.itemsPerPage}
                                        name="itemsPerPage"
                                        onChange={this.onChange}
                                      >
                                        <option value="20">20</option>

                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="500">500</option>
                                      </select>
                                    </div>
                                    <div className="col-6">
                                      <button className="btn btn-primary mt-2 pull-right col-8">
                                        Page: {this.state.pageNo} /
                                        {this.state.totalPages}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tfoot>
                      </Table>
                    </CardBody>
                  </>
                )}
              </Card>
            </div>
          </div>
        </div>
        <Modal
          isOpen={this.state.modelVisible}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          {" "}
          <a
            href={this.state.image}
            target="_blank"
            style={{ height: "400px", width: "400px" }}
          >
            <img
              className="no-borderRadiusImportant"
              src={this.state.image}
              alt="P"
              width="400px"
              height="400px"
              style={{
                borderRadius: "5px!important",
                height: "400px",
                width: "400px",
              }}
            />
          </a>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withRouter(Staff);
