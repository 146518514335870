import { Form } from "react-bootstrap";
import React from "react";
const CheckInput = (props) => {
  return (
    <>
      <Form.Group className={props.width}>
        <label className="checkbox">
          <input
            type="checkbox"
            name={props.name}
            checked={props.checked}
            onChange={props.onChange}
          />
          <span></span>
          <label className="ml-2 mt-2">{props.label}</label>
        </label>
      </Form.Group>
    </>
  );
};
export default CheckInput;
