import React, { Component } from "react";
import { withRouter } from "react-router";
import ReactToPrint from "react-to-print";

import $ from "jquery";
import { Button, CircularProgress } from "@material-ui/core";
import moment from "moment";

import axios from "axios";
import { Form, Table, Alert, Modal } from "react-bootstrap";
import { Card, CardBody } from "../../../../../../_metronic/_partials/controls";
import Select from "react-select";
import ExportCsv from "../../../components/Export";
import Grid from "../../../../Component/Grid";

const URL = process.env.REACT_APP_API_URL;

class Shift extends Component {
  constructor(props) {
    super(props);
    var currentDate = new Date();
    var dd = String(currentDate.getDate()).padStart(2, "0");
    var mm = String(currentDate.getMonth() + 1).padStart(2, "0");
    var yyyy = currentDate.getFullYear();
    currentDate = yyyy + "-" + mm + "-" + dd;
    this.state = {
      guardsToId: "0",
      guardToName: "All",
      guardsFromId: "0",
      guardFromName: "All",
      guards: [],
      loading: true,
      data: [],
      startDate: currentDate,
      endDate: currentDate,
      URL: `${URL}/api/staff/shift/report`,
      details: null,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      from: "",
      to: "",
      columns: [
        {
          column_name: "Sr",
          field_name: "id",
          width: "0%",
          type: "label",
        },
        {
          column_name: "Date",
          field_name: "created_at",
          width: "0%",
          type: "date_time",
        },
        {
          column_name: "From",
          field_name: "name",
          width: "0%",
          type: "object",
          object_name: ["from", "profile"],
        },
        {
          column_name: "To",
          field_name: "name",
          width: "0%",
          type: "object",
          object_name: ["to", "profile"],
        },
      ],
    };
    this.GetTotalPages = this.GetTotalPages.bind(this);
    this.onChange = this.onChange.bind(this);
    this.GetDetail.bind(this);
  }

  componentWillMount() {
    this.GetTotalPages();
    this.GetGuards();
  }

  GetGuards = () => {
    axios
      .get(`${URL}/api/staff/client_staff?role=guard&page=1&limit=1000`)
      .then(async (res) => {
        var newCategories = [{ id: "0", name: "All" }];

        for (var i = 0; i < res.data.data.length; i++) {
          newCategories.push({
            id: res.data.data[i].id + "",
            name: res.data.data[i].profile.name,
          });
        }
        await this.setState({
          guards: newCategories,
        });
      });
  };

  GuardToChanges = (val) => {
    this.setState({ guardsToId: val.id, guardToName: val.name });
  };

  GuardFromChanges = (val) => {
    this.setState({ guardsFromId: val.id, guardFromName: val.name });
  };

  async GetTotalPages() {
    var URL = "";
    if (this.state.guardsFromId !== "0") {
      if (this.state.guardsToId !== "0") {
        URL =
          this.state.URL +
          `?startDate=${moment
            .utc(this.state.startDate)
            .format("YYYY-MM-DD")}&endDate=${moment
            .utc(this.state.endDate)
            .format("YYYY-MM-DD")}&to=${this.state.guardsToId}&from=${
            this.state.guardsFromId
          }`;
      } else {
        URL =
          this.state.URL +
          `?startDate=${moment
            .utc(this.state.startDate)
            .format("YYYY-MM-DD")}&endDate=${moment
            .utc(this.state.endDate)
            .format("YYYY-MM-DD")}&from=${this.state.guardsFromId}`;
      }
    } else {
      if (this.state.guardsToId !== "0") {
        URL =
          this.state.URL +
          `?startDate=${moment
            .utc(this.state.startDate)
            .format("YYYY-MM-DD")}&endDate=${moment
            .utc(this.state.endDate)
            .format("YYYY-MM-DD")}&to=${this.state.guardsToId}`;
      } else {
        URL =
          this.state.URL +
          `?startDate=${moment
            .utc(this.state.startDate)
            .format("YYYY-MM-DD")}&endDate=${moment
            .utc(this.state.endDate)
            .format("YYYY-MM-DD")}`;
      }
    }
    axios
      .get(URL)
      .then(async (res) => {
        console.log(res);
        await this.setState({
          data: res.data,
          loading: false,
        });
        let obj = res.data.map((obj, v) => ({
          Sr: v + 1,
          Date: moment
            .utc(obj.created_at)
            .local()
            .format("DD-MM-YYYY hh:mm A"),
          From: obj.from.profile.name,
          To: obj.to.profile.name,
        }));
        this.setState({ csvData: obj });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  filter = () => {
    this.setState({ loading: true });
    this.GetTotalPages();
  };

  GetDetail = (id, date, from, to) => {
    axios
      .get(
        this.state.URL +
          `/${id}?startDate=${date}&endDate=${date}&page=1&limit=1000`
      )
      .then((res) => {
        this.setState({
          details: res.data,
          lgShow: true,
          from,
          to,
        });
      });
  };

  render() {
    let lgClose = () => this.setState({ lgShow: false });
    const customStylesForFeild = {
      control: (base) => ({
        ...base,
        height: 33,
        minHeight: 33,
        border: "1px solid #E4E6EF",
        borderRadius: "3px",
        fontSize: 10.5,
        fontWeight: 400,
        paddingTop: -25,
      }),
    };
    return (
      <React.Fragment>
        <Modal
          size="lg"
          show={this.state.lgShow}
          onHide={lgClose}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            {this.state.details != null ? (
              <ReactToPrint
                trigger={() => {
                  return (
                    <Button
                      className="ml-5"
                      color="secondary"
                      variant="outlined"
                      onClick={this.print}
                    >
                      Print
                    </Button>
                  );
                }}
                content={() => this.print_detail}
              />
            ) : null}
          </Modal.Header>
          <Modal.Body>
            {this.state.details != null ? (
              <div className="row" ref={(el) => (this.print_detail = el)}>
                <div className="col-12 text-center mb-5 mt-5">
                  <h2>Shift Details</h2>
                </div>
                <div className="col-6"></div>
                <div className="col-6" style={{ marginTop: "30px" }}>
                  <label
                    className="col-3"
                    style={{
                      fontSize: "14px",
                      fontWeight: "normal",
                      textAlign: "right",
                    }}
                  >
                    Date :
                  </label>
                  <label
                    className="col-9"
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    {moment
                      .utc(this.state.details?.created_at)
                      .local()
                      .format("DD-MM-YYYY hh:mm A")}
                  </label>
                </div>
                <div className="col-6">
                  <label
                    className="col-3"
                    style={{
                      fontSize: "14px",
                      fontWeight: "normal",
                      textAlign: "right",
                    }}
                  >
                    From :
                  </label>
                  <label
                    className="col-9"
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    {this.state.from}
                  </label>
                </div>
                <div className="col-6">
                  <label
                    className="col-3"
                    style={{
                      fontSize: "14px",
                      fontWeight: "normal",
                      textAlign: "right",
                    }}
                  >
                    To :
                  </label>
                  <label
                    className="col-9"
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    {this.state.to}
                  </label>
                </div>
                {this.state.details?.note ? (
                  <div className="col-12">
                    <label
                      style={{
                        fontSize: "17px",
                        fontWeight: "bold",
                      }}
                    >
                      Note :
                    </label>{" "}
                    <br />
                    <label
                      style={{
                        fontSize: "17px",
                        fontWeight: "normal",
                      }}
                    >
                      {this.state.details?.note}
                    </label>
                  </div>
                ) : null}
                {this.state.details?.assignItems ? (
                  <div className="col-12 mt-5">
                    <h6>Assigned Items</h6>

                    <Table className="col-12" responsive>
                      <thead>
                        <tr>
                          <th width="10%">Sr #</th>
                          <th width="90%">Item</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.details?.assignItems?.map((obj, k) => (
                          <tr>
                            <td>{k + 1}</td>
                            <td>{obj?.item?.name}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                ) : null}
                {this.state.details?.notAssignItems ? (
                  <div className="col-12 mt-5">
                    <h6>Unassigned Items</h6>
                    <Table className="col-12" responsive>
                      <thead>
                        <tr>
                          <th width="10%">Sr #</th>
                          <th width="90%">Item</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.details?.notAssignItems ? (
                          this.state.details?.notAssignItems?.map((obj, k) => (
                            <tr>
                              <td>{k + 1}</td>
                              <td>{obj?.item?.name}</td>
                            </tr>
                          ))
                        ) : (
                          <h3>Loading...</h3>
                        )}
                      </tbody>
                    </Table>
                  </div>
                ) : null}
              </div>
            ) : (
              <h6>Loading...</h6>
            )}
          </Modal.Body>
        </Modal>
        <div className="page-content">
          <div className="row">
            <span className="svg-icon svg-icon-primary svg-icon-3x mr-2">
              {" "}
              <svg
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
                className="kt-svg-icon"
              >
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <rect id="bound" x="0" y="0" width="24" height="24" />
                  <path
                    d="M3.5,21 L20.5,21 C21.3284271,21 22,20.3284271 22,19.5 L22,8.5 C22,7.67157288 21.3284271,7 20.5,7 L10,7 L7.43933983,4.43933983 C7.15803526,4.15803526 6.77650439,4 6.37867966,4 L3.5,4 C2.67157288,4 2,4.67157288 2,5.5 L2,19.5 C2,20.3284271 2.67157288,21 3.5,21 Z"
                    id="Combined-Shape"
                    fill="#000000"
                    opacity="0.3"
                  />
                  <path
                    d="M12,13 C10.8954305,13 10,12.1045695 10,11 C10,9.8954305 10.8954305,9 12,9 C13.1045695,9 14,9.8954305 14,11 C14,12.1045695 13.1045695,13 12,13 Z"
                    id="Mask"
                    fill="#000000"
                    opacity="0.3"
                  />
                  <path
                    d="M7.00036205,18.4995035 C7.21569918,15.5165724 9.36772908,14 11.9907452,14 C14.6506758,14 16.8360465,15.4332455 16.9988413,18.5 C17.0053266,18.6221713 16.9988413,19 16.5815,19 C14.5228466,19 11.463736,19 7.4041679,19 C7.26484009,19 6.98863236,18.6619875 7.00036205,18.4995035 Z"
                    id="Mask-Copy"
                    fill="#000000"
                    opacity="0.3"
                  />
                </g>
              </svg>
            </span>
            <h3 style={{ marginBottom: "2%" }} className="mt-3">
              Shift
            </h3>
          </div>

          <div className="row">
            <div className="col-sm-6 col-md-8 col-lg-8"></div>
            <div
              className="col-sm-6 col-md-4 col-lg-4"
              style={{ float: "right" }}
            >
              {this.state.error && this.state.error ? (
                <Alert variant="danger">{this.state.error}</Alert>
              ) : null}
              {this.state.msg && this.state.msg ? (
                <Alert variant="success">{this.state.msg}</Alert>
              ) : null}
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12">
              <Card ref={(el) => (this.componentRef = el)}>
                <div className="row mt-5 ml-5 mr-5 notPrint">
                  <div className="col-lg-12 col-sm-3 col-md-3">
                    <h4>Filter</h4>
                  </div>
                  <div className="col-lg-3">
                    <Form.Group>
                      From
                      <Select
                        styles={customStylesForFeild}
                        name="id"
                        options={this.state.guards}
                        onChange={this.GuardFromChanges}
                        value={this.state?.guards?.filter(
                          ({ id }) => id === this.state.guardsFromId
                        )}
                        getOptionLabel={({ name }) => name}
                        getOptionValue={({ id }) => id}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-lg-3">
                    <Form.Group>
                      To
                      <Select
                        styles={customStylesForFeild}
                        name="id"
                        options={this.state.guards}
                        onChange={this.GuardToChanges}
                        value={this.state?.guards?.filter(
                          ({ id }) => id === this.state.guardsToId
                        )}
                        getOptionLabel={({ name }) => name}
                        getOptionValue={({ id }) => id}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-lg-2">
                    <Form.Group>
                      Start Date:
                      <input
                        min={moment()
                          .subtract(90, "days")
                          .format("YYYY-MM-DD")}
                        max={moment().format("YYYY-MM-DD")}
                        className="form-control form-control-sm"
                        type="date"
                        value={this.state.startDate}
                        name="startDate"
                        onChange={this.onChange}
                      ></input>
                    </Form.Group>
                  </div>
                  <div className="col-lg-2">
                    <Form.Group>
                      End Date:
                      <input
                        className="form-control form-control-sm"
                        type="date"
                        value={this.state.endDate}
                        name="endDate"
                        onChange={this.onChange}
                        min={moment()
                          .subtract(90, "days")
                          .format("YYYY-MM-DD")}
                        max={moment().format("YYYY-MM-DD")}
                      ></input>
                    </Form.Group>
                  </div>

                  <div className="col-lg-1">
                    <ExportCsv
                      csvData={this.state.csvData}
                      fileName={`Shift_Report_${this.state.startDate}_to_${this.state.endDate}`}
                    />
                  </div>
                  <div className=" col-lg-1 mt-5 ">
                    <ReactToPrint
                      trigger={() => {
                        return (
                          <Button
                            className="ml-5"
                            color="secondary"
                            variant="outlined"
                            onClick={this.print}
                          >
                            Print
                          </Button>
                        );
                      }}
                      content={() => this.componentRef}
                    />
                  </div>
                  {this.state.loading ? (
                    <div className="col-lg-1 mt-5">
                      <Button>
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </Button>
                    </div>
                  ) : (
                    <div className=" col-lg-1 mt-5 ">
                      <Button
                        className="ml-5"
                        color="secondary"
                        variant="contained"
                        onClick={() => this.filter()}
                      >
                        Load
                      </Button>
                    </div>
                  )}
                </div>
                <div className="row mt-5 ml-5 mr-5 print d-none ">
                  <div className="col-lg-12 col-sm-3 col-md-3">
                    <div className="col-12">
                      <h4
                        className="text-center mt-5 mb-5"
                        style={{ marginBottom: "25px" }}
                      >
                        Shift
                      </h4>
                    </div>
                  </div>

                  <div className="col-3">
                    <Form.Group>
                      From:
                      <br /> {this.state.guardFromName}
                    </Form.Group>
                  </div>
                  <div className="col-3">
                    <Form.Group>
                      To:
                      <br /> {this.state.guardToName}
                    </Form.Group>
                  </div>
                  <div className="col-3">
                    <Form.Group>
                      Start Date:
                      <br /> {moment(this.state.startDate).format("YYYY-MM-DD")}
                    </Form.Group>
                  </div>
                  <div className="col-3">
                    <Form.Group>
                      End Date:
                      <br /> {moment(this.state.endDate).format("YYYY-MM-DD")}
                    </Form.Group>
                  </div>
                </div>
                <CardBody>
                  <Grid
                    columns={this.state.columns}
                    data={this.state.data}
                    detail={true}
                    dataUpdated={(data) => {
                      this.setState({ data });
                    }}
                    GetDetail={(obj) => {
                      this.GetDetail(
                        obj.id,
                        moment(obj.created_at).format("YYYY-MM-YYYY"),
                        obj.from.profile.name,
                        obj.to.profile.name
                      );
                    }}
                  />
                  {/* <Table responsive>
                    <thead>
                      <tr>
                        <th>Sr #</th>
                        <th>Date</th>
                        <th>From</th>
                        <th>To</th>
                        <th>Detail</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!this.state.loading && this.state.data
                        ? this.state.data.map((obj, i) => (
                            <tr>
                              {console.log(obj, "heree i canned")}
                              <td>{i + 1}</td>
                              <td>
                                {moment(obj.created_at).format("DD-MM-YYYY")}
                              </td>
                              <td>{obj.from.profile.name}</td>
                              <td>{obj.to.profile.name}</td>
                              <td>
                                <button
                                  onClick={() =>
                                    this.GetDetail(
                                      obj.id,
                                      moment(obj.created_at).format(
                                        "YYYY-MM-YYYY"
                                      ),
                                      obj.from.profile.name,
                                      obj.to.profile.name
                                    )
                                  }
                                  className="btn btn-xs btn-default btn-text-primary btn-hover-primary btn-icon btn-icon-primary "
                                  title="View Details"
                                >
                                  <span className="svg-icon svg-icon-md">
                                    <svg
                                      viewBox="0 0 24 24"
                                      version="1.1"
                                      className="kt-svg-icon"
                                    >
                                      <g
                                        stroke="none"
                                        strokeWidth="1"
                                        fill="none"
                                        fillRule="evenodd"
                                      >
                                        <polygon
                                          id="Shape"
                                          points="0 0 24 0 24 24 0 24"
                                        />
                                        <path
                                          d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z"
                                          id="Combined-Shape"
                                          fill="#000000"
                                          fillRule="nonzero"
                                          opacity="0.3"
                                        />
                                        <rect
                                          id="Rectangle"
                                          fill="#000000"
                                          x="6"
                                          y="11"
                                          width="9"
                                          height="2"
                                          rx="1"
                                        />
                                        <rect
                                          id="Rectangle-Copy"
                                          fill="#000000"
                                          x="6"
                                          y="15"
                                          width="5"
                                          height="2"
                                          rx="1"
                                        />
                                      </g>
                                    </svg>
                                  </span>
                                </button>
                              </td>
                            </tr>
                          ))
                        : ""}
                    </tbody>
                  </Table> */}
                  <div
                    style={{
                      height: "20vh",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {this.state.loading ? (
                      <CircularProgress
                        color="secondary"
                        size={100}
                        thickness={4}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Shift);
