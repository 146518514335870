import * as React from "react";

const DeleteIcon = (props) => (
  <svg viewBox="0 0 24 24" {...props}>
    <g fill="none">
      <path d="M0 0h24v24H0z" />
      <path
        d="M6 8v12.5A1.5 1.5 0 0 0 7.5 22h9a1.5 1.5 0 0 0 1.5-1.5V8H6Z"
        fill="#000"
      />
      <path
        d="M14 4.5V4a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v.5H5.5A.5.5 0 0 0 5 5v.5a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5V5a.5.5 0 0 0-.5-.5H14Z"
        fill="#000"
        opacity={0.3}
      />
    </g>
  </svg>
);

export default DeleteIcon;
