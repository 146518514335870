import React, { Component } from "react";
import { withRouter } from "react-router";
import ReactToPrint from "react-to-print";

import axios from "axios";
import { Form, Alert } from "react-bootstrap";
import { Card, CardBody } from "../../../../../../_metronic/_partials/controls";
import Select from "react-select";
import moment from "moment";
import { Button, CircularProgress } from "@material-ui/core";
// import _ from "lodash";
import { toast } from "react-toastify";
import makeCSVData from "../../../../Component/Grid/MakeCSVData";
import ExportCsv from "../../../components/Export";
import Grid from "../../../../Component/Grid";
const URL = process.env.REACT_APP_API_URL;
class Facility extends Component {
  constructor(props) {
    super(props);
    var currentDate = new Date();
    var dd = String(currentDate.getDate()).padStart(2, "0");
    var mm = String(currentDate.getMonth() + 1).padStart(2, "0");
    var yyyy = currentDate.getFullYear();
    currentDate = yyyy + "-" + mm + "-" + dd;
    this.state = {
      logName: "",
      statusName: "",
      searchStatus: "",
      allStatus: [],
      allLog: [],
      loading: true,
      data: [],
      startDate: currentDate,
      endDate: currentDate,
      URL: `${URL}/api/staff/log_book`,
      operationTypeId: "0",
      operations: [],
      guardName: "All",
      guardsId: "0",
      active: {},
      columns: [
        {
          column_name: "Sr",
          field_name: "id",
          width: "0%",
          type: "label",
        },
        {
          column_name: "Guard",
          field_name: "name",
          width: "0%",
          type: "object",
          object_name: ["user", "profile"],
        },
        {
          column_name: "Date",
          field_name: "created_at",
          width: "0%",
          type: "date_time",
        },
        {
          column_name: "Type",
          field_name: "name",
          width: "0%",
          type: "object",
          object_name: ["logType"],
        },
        {
          column_name: "Status",
          field_name: "status",
          width: "0%",
          type: "static_dropdown",
          staticOptions: ["status", "status_one", "status_two"],
          staticOptionsObject: ["logType"],
          value: "status",
        },
        {
          column_name: "Message",
          field_name: "log",
          width: "0%",
          type: "label",
        },
        {
          column_name: "Image",
          field_name: "image",
          width: "0%",
          type: "image",
        },
        {
          column_name: "Audio",
          field_name: "audio",
          width: "0%",
          type: "audio",
        },
      ],
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    this.GetTotalPages = this.GetTotalPages.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  GuardChanges = (val) => {
    this.setState({ guardsId: val.id, guardName: val.name });
  };

  GetGuards = () => {
    axios
      .get(`${URL}/api/staff/client_staff?role=guard&page=1&limit=1000`)
      .then(async (res) => {
        var newCategories = [{ id: "0", name: "All" }];

        for (var i = 0; i < res.data.data.length; i++) {
          newCategories.push({
            id: res.data.data[i].id + "",
            name: res.data.data[i].profile.name,
          });
        }
        await this.setState({
          guards: newCategories,
        });
      });
  };

  GetOperations = () => {
    axios
      .get(`${URL}/api/staff/log_type?page=1&limit=1000`)
      .then(async (res) => {
        var newCategories = [{ id: "0", name: "All" }];
        for (var i = 0; i < res.data.data.length; i++) {
          newCategories.push({
            id: res.data.data[i].id + "",
            name: res.data.data[i].name,
          });
        }
        await this.setState({
          allLog: res.data.data,

          operations: newCategories,
        });
      });
  };

  OperationChanges = (val) => {
    let st = ["All"];

    let obj = this.state.allLog.filter((v) =>
      parseInt(v.id) === parseInt(val.id)
        ? st.push(v.status, v.status_one, v.status_two)
        : ""
    );
    console.log(obj);
    this.setState({
      operationTypeId: val.id,
      allStatus: st,
      searchStatus: "",
      logName: val.name,
    });
  };

  async GetTotalPages() {
    var URL = "";
    if (this.state.operationTypeId !== "0") {
      if (this.state.guardsId !== "0") {
        URL =
          this.state.URL +
          `?page=${this.state.pageNo}&limit=${
            this.state.itemsPerPage
          }&startDate=${this.state.startDate} 00:00&endDate=${
            this.state.endDate
          } 23:59&timezone=${this.state.timeZone}&logTypeId=${
            this.state.operationTypeId
          }&search=${
            this.state.searchStatus === "All" ? "" : this.state.searchStatus
          }&guard=${this.state.guardsId}`;
      } else {
        URL =
          this.state.URL +
          `?page=${this.state.pageNo}&limit=${
            this.state.itemsPerPage
          }&startDate=${this.state.startDate} 00:00&endDate=${
            this.state.endDate
          } 23:59&timezone=${this.state.timeZone}&logTypeId=${
            this.state.operationTypeId
          }&search=${
            this.state.searchStatus === "All" ? "" : this.state.searchStatus
          }`;
      }
    } else {
      if (this.state.guardsId !== "0") {
        URL =
          this.state.URL +
          `?page=${this.state.pageNo}&limit=${
            this.state.itemsPerPage
          }&startDate=${this.state.startDate} 00:00&endDate=${
            this.state.endDate
          } 23:59&timezone=${this.state.timeZone}&search=${
            this.state.searchStatus === "All" ? "" : this.state.searchStatus
          }&guard=${this.state.guardsId}`;
      } else {
        URL =
          this.state.URL +
          `?page=${this.state.pageNo}&limit=${
            this.state.itemsPerPage
          }&startDate=${this.state.startDate} 00:00&endDate=${
            this.state.endDate
          } 23:59&timezone=${this.state.timeZone}&search=${
            this.state.searchStatus === "All" ? "" : this.state.searchStatus
          }`;
      }
    }
    axios
      .get(URL)
      .then((res) => {
        // console.log(res.data);
        // res.data.data.map((item,i)=>{
        //         var newDate=new Date(item.created_at)
        //         res.data.data[i].created_at=newDate.toString()
        // })
        this.setState({
          loading: false,
          data: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleClose2 = () => {
    this.setState({ anchorEl2: null });
  };

  PostDataById = (id, status) => {
    axios.put(`${this.state.URL}/${id}`, { status: status }).then((res) => {
      toast.success("Status is Updated Succesfully.");

      this.handleClose2();
      setTimeout(() => {
        this.GetPageData();
      }, 300);
    });
  };

  filter = () => {
    this.setState({ loading: true });
    this.GetTotalPages();
  };

  componentWillMount() {
    this.GetTotalPages();
    this.GetOperations();
    this.GetGuards();
  }

  render() {
    const customStylesForFeild = {
      control: (base) => ({
        ...base,
        height: 33,
        minHeight: 33,
        border: "1px solid #E4E6EF",
        borderRadius: "3px",
        fontSize: 10.5,
        fontWeight: 400,
        paddingTop: -25,
      }),
    };

    return (
      <React.Fragment>
        <div className="page-content">
          <div className="row">
            <span className="svg-icon svg-icon-primary svg-icon-3x mr-2">
              {" "}
              <svg
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
                className="kt-svg-icon"
              >
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <rect id="bound" x="0" y="0" width="24" height="24" />
                  <path
                    d="M3.5,21 L20.5,21 C21.3284271,21 22,20.3284271 22,19.5 L22,8.5 C22,7.67157288 21.3284271,7 20.5,7 L10,7 L7.43933983,4.43933983 C7.15803526,4.15803526 6.77650439,4 6.37867966,4 L3.5,4 C2.67157288,4 2,4.67157288 2,5.5 L2,19.5 C2,20.3284271 2.67157288,21 3.5,21 Z"
                    id="Combined-Shape"
                    fill="#000000"
                    opacity="0.3"
                  />
                  <path
                    d="M12,13 C10.8954305,13 10,12.1045695 10,11 C10,9.8954305 10.8954305,9 12,9 C13.1045695,9 14,9.8954305 14,11 C14,12.1045695 13.1045695,13 12,13 Z"
                    id="Mask"
                    fill="#000000"
                    opacity="0.3"
                  />
                  <path
                    d="M7.00036205,18.4995035 C7.21569918,15.5165724 9.36772908,14 11.9907452,14 C14.6506758,14 16.8360465,15.4332455 16.9988413,18.5 C17.0053266,18.6221713 16.9988413,19 16.5815,19 C14.5228466,19 11.463736,19 7.4041679,19 C7.26484009,19 6.98863236,18.6619875 7.00036205,18.4995035 Z"
                    id="Mask-Copy"
                    fill="#000000"
                    opacity="0.3"
                  />
                </g>
              </svg>
            </span>
            <h3 style={{ marginBottom: "2%" }} className="mt-3">
              Log Book
            </h3>
          </div>

          <div className="row">
            <div className="col-sm-6 col-md-8 col-lg-8"></div>
            <div
              className="col-sm-6 col-md-4 col-lg-4"
              style={{ float: "right" }}
            >
              {this.state.error && this.state.error ? (
                <Alert variant="danger">{this.state.error}</Alert>
              ) : null}
              {this.state.msg && this.state.msg ? (
                <Alert variant="success">{this.state.msg}</Alert>
              ) : null}
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12">
              <Card ref={(el) => (this.componentRef = el)}>
                <div className="row mt-5 ml-5 mr-5 notPrint">
                  <div className="col-lg-12 col-sm-12 col-md-12">
                    <h4>Filter</h4>
                  </div>
                  <div className="col-lg-3">
                    <Form.Group>
                      Guard
                      <Select
                        styles={customStylesForFeild}
                        name="id"
                        options={this.state.guards}
                        onChange={this.GuardChanges}
                        value={this.state?.guards?.filter(
                          ({ id }) => id === this.state.guardsId
                        )}
                        getOptionLabel={({ name }) => name}
                        getOptionValue={({ id }) => id}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-lg-2 col-sm-6 col-md-2">
                    <Form.Group>
                      Log Type:
                      <Select
                        styles={customStylesForFeild}
                        name="operationTypeId"
                        options={this.state.operations}
                        onChange={this.OperationChanges}
                        value={this.state.operations.filter(
                          ({ id }) => id === this.state.operationTypeId
                        )}
                        getOptionLabel={({ name }) => name}
                        getOptionValue={({ id }) => id}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-2">
                    <Form.Group>
                      Status
                      <Select
                        styles={customStylesForFeild}
                        name="operationTypeId"
                        options={this.state.allStatus}
                        onChange={(val) => {
                          console.log(val, "hhbbh");
                          this.setState({ searchStatus: val });
                        }}
                        value={this.state.allStatus.filter(
                          (v) => v === this.state.searchStatus
                        )}
                        getOptionLabel={(name) => name}
                        getOptionValue={(name) => name}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-lg-2 col-sm-6 col-md-2">
                    <Form.Group>
                      Start Date:
                      <input
                        min={moment()
                          .subtract(90, "days")
                          .format("YYYY-MM-DD")}
                        max={moment().format("YYYY-MM-DD")}
                        className="form-control form-control-sm"
                        type="date"
                        value={this.state.startDate}
                        name="startDate"
                        onChange={this.onChange}
                      ></input>
                    </Form.Group>
                  </div>
                  <div className="col-lg-2 col-sm-6 col-md-2">
                    <Form.Group>
                      End Date:
                      <input
                        min={moment()
                          .subtract(90, "days")
                          .format("YYYY-MM-DD")}
                        max={moment().format("YYYY-MM-DD")}
                        className="form-control form-control-sm"
                        type="date"
                        value={this.state.endDate}
                        name="endDate"
                        onChange={this.onChange}
                      ></input>
                    </Form.Group>
                  </div>
                  <ExportCsv
                    csvData={makeCSVData(this.state.data, this.state.columns)}
                    fileName={`Log_Book_${this.state.startDate}_to_${this.state.endDate}`}
                  />
                  <div className=" col-lg-1 mt-5 ">
                    <ReactToPrint
                      trigger={() => {
                        return (
                          <Button
                            className="ml-5"
                            color="secondary"
                            variant="outlined"
                            onClick={this.print}
                          >
                            Print
                          </Button>
                        );
                      }}
                      content={() => this.componentRef}
                    />
                  </div>
                  {this.state.loading ? (
                    <div className="col-lg-1 mt-5">
                      <Button>
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </Button>
                    </div>
                  ) : (
                    <div className=" col-lg-1 mt-5 ">
                      <Button
                        className="ml-5"
                        color="secondary"
                        variant="contained"
                        onClick={this.filter}
                      >
                        Load
                      </Button>
                    </div>
                  )}
                </div>
                <div className="row mt-5 ml-5 mr-5 print d-none ">
                  <div className="col-lg-12 col-sm-3 col-md-3">
                    <div className="col-12">
                      <h4
                        className="text-center mt-5 mb-5"
                        style={{ marginBottom: "25px" }}
                      >
                        Log Book
                      </h4>
                    </div>
                  </div>
                  <div className="col-3">
                    <Form.Group>
                      Guard: <br />
                      {this.state.guardName}
                    </Form.Group>
                  </div>
                  <div className="col-3">
                    <Form.Group>
                      LOG type: <br />{" "}
                      {this?.state?.logname ? this?.state?.logname : "All"}
                    </Form.Group>
                  </div>
                  <div className="col-2">
                    <Form.Group>
                      Status: <br />{" "}
                      {this.state.searchStatus
                        ? this.state.searchStatus
                        : "All"}
                    </Form.Group>
                  </div>
                  <div className="col-2">
                    <Form.Group>
                      Start Date: <br />{" "}
                      {moment(this.state.startDate).format("YYYY-MM-DD")}
                    </Form.Group>
                  </div>
                  <div className="col-2">
                    <Form.Group>
                      End Date: <br />{" "}
                      {moment(this.state.endDate).format("YYYY-MM-DD")}
                    </Form.Group>
                  </div>
                </div>
                <CardBody>
                  <Grid
                    columns={this.state.columns}
                    data={this.state.data}
                    print={true}
                    printRef={this.print_operation}
                    setPrintData={(active) => {
                      this.setState({ active }, () => {
                        // this.printDiv();
                      });
                    }}
                    dataUpdated={(data) => {
                      this.setState({ data });
                    }}
                    updateDropdownObject={(obj, status) => {
                      this.PostDataById(obj.id, status);
                    }}
                  />
                  <div
                    style={{
                      height: "20vh",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {this.state.loading ? (
                      <CircularProgress
                        color="secondary"
                        size={100}
                        thickness={4}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
        <div style={{ display: "none" }}>
          <div ref={(el) => (this.print_operation = el)}>
            <div className="row" style={{ padding: "50px" }}>
              <div className="col-12 text-center mb-5 mt-5">
                <h2>Log Book</h2>
              </div>
              <div className="col-6" style={{ marginTop: "30px" }}>
                <label
                  className="col-3"
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    textAlign: "right",
                  }}
                >
                  Log Type :
                </label>
                <label
                  className="col-9"
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                >
                  {this.state.active?.logType?.name}
                </label>
              </div>
              <div className="col-6 text-right" style={{ marginTop: "30px" }}>
                <label
                  className="col-4"
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  Status :
                </label>
                <label
                  className="col-8"
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  {this.state.active?.status}
                </label>
              </div>
              <div className="col-6">
                <label
                  className="col-3"
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    textAlign: "right",
                  }}
                >
                  Guard :
                </label>
                <label
                  className="col-9"
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                >
                  {this.state.active?.user?.profile?.name}
                </label>
              </div>
              <div className="col-6 text-right">
                <label
                  className="col-4"
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  Date {`&`} Time :
                </label>
                <label
                  className="col-8"
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  {moment
                    .utc(this.state.active?.created_at)
                    .local()
                    .format("DD-MM-YYYY hh:mm A")}
                </label>
              </div>
              <div className="col-6 "></div>
              <div className="col-6 ">
                <label
                  className="col-4"
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    textAlign: "right",
                  }}
                >
                  Audio :
                </label>
                <label
                  className="col-8"
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  {this.state.active?.audio !== null ? "YES" : "NO"}
                </label>
              </div>

              <div className="col-12">
                <label
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  Message :
                </label>{" "}
                <br />
                <label
                  style={{
                    fontSize: "20px",
                    fontWeight: "normal",
                  }}
                >
                  {this.state.active?.log}
                </label>
              </div>
              {this.state.active?.image !== null ? (
                <div className="col-12 text-center mt-5">
                  <img
                    src={this.state.active?.image}
                    width="325px"
                    height="325px"
                    alt=""
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Facility);
