import React, { Component } from "react";
import { withRouter } from "react-router";
import axios from "axios";
import { Form, Alert } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../../_metronic/_partials/controls";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Select2, Select, CheckInput } from "../../../../Component/Form";
import isEmpty from "../../../../../Validations/is-empty";
const URL = process.env.REACT_APP_API_URL;

class UpdateParkingSlot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slotNumber: "",
      slotName: "",
      status: "Active",
      block: "0",
      type: "0",
      level: "0",
      blockName: "",
      levelName: "",
      allowBlockFromSlotNumber: false,
      AllBlock: [],
      levels: [],
      types: [],
      slots: [],
      from: [150],
      sendFrom: "",
      sendTo: parseInt(this?.state?.sendFrom) + 1,
      checkedList: ["single"],
      statuses: [
        {
          value: "Active",
          label: "Active",
        },
        {
          value: "In Active",
          label: "In Active",
        },
      ],
    };
    this.Save = this.Save.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  CheckKey = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      this.Save();
    }
  };

  GetBlock = () => {
    axios.get(`${URL}/api/staff/block?page=1&limit=1000`).then(async (res) => {
      var newCategories = [{ id: "0", name: "--Select Block--" }];
      for (var i = 0; i < res.data.data.length; i++) {
        if (res.data.data[i].status === "Active")
          newCategories.push({
            id: res.data.data[i].id + "",
            name: res.data.data[i].name,
          });
      }
      await this.setState({
        AllBlock: newCategories,
      });
    });
  };

  AddData = () => {
    this.setState({
      block: "",
      level: "",
      status: "",
      msg: "",
      error: "",
    });
  };

  componentWillMount() {
    if (this.props?.location?.param1) {
      this.GetBlock();
      this.GetTypes();
      var obj = this.props?.location?.param1;
      console.log(obj);
      this.setState(
        {
          block: obj.parkingLevel.block_id + "",
          level: obj.parking_level_id + "",
          status: obj.status,
          type: obj.parking_type_id + "",
          slotNumber: Number(obj.name),
          slotName: obj.custom_slot_name,
        },
        () => this.GetLevels()
      );
    } else {
      this.props.history.push("/parking/parking-slot");
    }
  }

  ValidateInputs = () => {
    if (this.state.block === "0") {
      toast.error("Please select a block first!");
      return false;
    } else if (this.state.level === "0") {
      toast.error("Please select a level first!");
      return false;
    } else if (this.state.type === "0") {
      toast.error("Please select a type first!");
      return false;
    } else if (this.state.status === "") {
      toast.error("Please select a status first!");
      return false;
    } else if (this.state.slotNumber === "") {
      toast.error("Please enter slot number!");
      return false;
    } else {
      return true;
    }
  };

  async Save() {
    if (this.ValidateInputs()) {
      let obj = {
        parkingLevelId: this.state.level,
        status: this.state.status,
        parkingTypeId: this.state.type,
        allowBlockFromSlotNumber: this.state.allowBlockFromSlotNumber,
        name: this.state.slotNumber + "",
        customSlotName: isEmpty(this.state.slotName)
          ? this.state.blockName +
            "-" +
            this.state.levelName +
            "-" +
            this.state.slotNumber
          : this.state.slotName,
      };
      toast.success("Updating slot ...");
      axios
        .put(
          `${URL}/api/staff/parking_slot/${this.props.location.param1.id}`,
          obj
        )
        .then(async (res) => {
          toast.success("Slot updated successfully!");
          this.props.history.push("/parking/parking-slot");
        })
        .catch((err) => {
          console.log(err);
          if (err.status === 404) {
            toast.error("Block Not Found!");
          }
        });
    }
  }

  onChange(e) {
    var name = e.target.name;
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (name === "slotNumber") this.ChangeName();
    });
  }

  BlockChanges = (val) => {
    this.setState({ block: val.id, blockName: val.name }, async () => {
      await this.GetLevels();
      this.ChangeName();
    });
  };

  ChangeName = () => {
    var block = this.state.blockName;
    var level = this.state.levelName;
    var number = this.state.slotNumber;
    this.setState({
      slotName: block + "-" + level + "-" + number,
    });
  };
  GetLevels = () => {
    axios
      .get(
        `${URL}/api/staff/parking_level?page=1&limit=1000&blockId=${this.state.block}`
      )
      .then(async (res) => {
        var newCategories = [{ id: "0", name: "--Select Level--" }];
        for (var i = 0; i < res.data.data.length; i++) {
          if (res.data.data[i].status === "Active")
            newCategories.push({
              id: res.data.data[i].id + "",
              name: res.data.data[i].name,
            });
        }
        await this.setState({
          levels: newCategories,
        });
      });
  };

  LevelChanges = (val) => {
    console.log(val);
    this.setState({ level: val.id, levelName: val.name }, () => {
      this.ChangeName();
    });
  };

  GetTypes = () => {
    axios
      .get(`${URL}/api/staff/parking_type?page=1&limit=1000`)
      .then(async (res) => {
        var newCategories = [{ id: "0", name: "--Select Type--" }];
        for (var i = 0; i < res.data.data.length; i++) {
          if (res.data.data[i].status === "Active")
            newCategories.push({
              id: res.data.data[i].id + "",
              name: res.data.data[i].custom_parking_type,
            });
        }
        await this.setState({
          types: newCategories,
        });
      });
  };

  TypeChanges = (val) => {
    this.setState({ type: val.id });
  };

  handleAllowBlockFromSlotNumber = () => {
    var val = this.state.allowBlockFromSlotNumber;
    this.setState({ allowBlockFromSlotNumber: !val });
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <div
            className="row"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="col-sm-12 col-md-9 col-lg-9 ">
              <Card>
                <CardHeader title="Add Slot">
                  <CardHeaderToolbar>
                    <Link
                      type="button"
                      className="btn btn-primary"
                      name="add_button"
                      to="/parking/parking-slot"
                    >
                      <span className="svg-icon menu-icon mr-2">
                        <svg
                          viewBox="0 0 24 24"
                          version="1.1"
                          className="kt-svg-icon"
                        >
                          <g
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <rect
                              id="bound"
                              x="0"
                              y="0"
                              width="24"
                              height="24"
                            />
                            <path
                              d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
                              id="Combined-Shape"
                              fill="#000000"
                              opacity="0.3"
                            />
                            <path
                              d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
                              id="Combined-Shape"
                              fill="#000000"
                            />
                            <rect
                              id="Rectangle-152"
                              fill="#000000"
                              opacity="0.3"
                              x="10"
                              y="9"
                              width="7"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-152-Copy-2"
                              fill="#000000"
                              opacity="0.3"
                              x="7"
                              y="9"
                              width="2"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-152-Copy-3"
                              fill="#000000"
                              opacity="0.3"
                              x="7"
                              y="13"
                              width="2"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-152-Copy"
                              fill="#000000"
                              opacity="0.3"
                              x="10"
                              y="13"
                              width="7"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-152-Copy-5"
                              fill="#000000"
                              opacity="0.3"
                              x="7"
                              y="17"
                              width="2"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-152-Copy-4"
                              fill="#000000"
                              opacity="0.3"
                              x="10"
                              y="17"
                              width="7"
                              height="2"
                              rx="1"
                            />
                          </g>
                        </svg>
                      </span>
                      List
                    </Link>
                  </CardHeaderToolbar>
                </CardHeader>
                <CardBody className="row">
                  <h4 className="col-12 mt-2 mb-4">Slot Detail</h4>

                  <div className="col-12">
                    <div className="row">
                      <Select2
                        label="Select Block *"
                        name="block"
                        data={this.state.AllBlock}
                        SelectionChanged={this.BlockChanges}
                        value={this.state.block}
                        width="col-12"
                      />
                      <CheckInput
                        label="Allow Block From Slot Number"
                        name="allowBlockFromSlotNumber"
                        width="col-12"
                        checked={this.state.allowBlockFromSlotNumber}
                        onChange={this.handleAllowBlockFromSlotNumber}
                      />
                      <Select2
                        label="Select Level *"
                        name="level"
                        data={this.state.levels}
                        SelectionChanged={this.LevelChanges}
                        value={this.state.level}
                        width="col-12"
                      />
                      <Select2
                        label="Select Type *"
                        name="type"
                        data={this.state.types}
                        SelectionChanged={this.TypeChanges}
                        value={this.state.type}
                        width="col-12"
                      />
                      <Select
                        width="col-12"
                        label="Status *"
                        name="status"
                        onChange={this.onChange}
                        defaultValue={this.state.status}
                        data={this.state.statuses}
                      />
                      <Form.Group className="col-12">
                        <Form.Label>Slot Number</Form.Label>
                        <div className="input-group ">
                          <input
                            onKeyPress={this.CheckKey}
                            className="form-control form-control-sm"
                            type="number"
                            placeholder="Slot Number"
                            value={this.state.slotNumber}
                            name="slotNumber"
                            onChange={this.onChange}
                          ></input>
                        </div>
                      </Form.Group>
                      <Form.Group className="col-12">
                        <Form.Label>Custom Slot Name</Form.Label>
                        <div className="input-group ">
                          <input
                            onKeyPress={this.CheckKey}
                            className="form-control form-control-sm"
                            type="text"
                            placeholder="Slot Name"
                            value={this.state.slotName}
                            name="slotName"
                            onChange={this.onChange}
                          ></input>
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                </CardBody>
                <div>
                  <button
                    className="btn btn-primary  mr-4 mb-2"
                    onClick={() => this.Save()}
                    style={{ float: "right" }}
                  >
                    <span className="svg-icon menu-icon mr-2">
                      <svg
                        viewBox="0 0 24 24"
                        version="1.1"
                        className="kt-svg-icon"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <polygon id="Shape" points="0 0 24 0 24 24 0 24" />
                          <path
                            d="M17,4 L6,4 C4.79111111,4 4,4.7 4,6 L4,18 C4,19.3 4.79111111,20 6,20 L18,20 C19.2,20 20,19.3 20,18 L20,7.20710678 C20,7.07449854 19.9473216,6.94732158 19.8535534,6.85355339 L17,4 Z M17,11 L7,11 L7,4 L17,4 L17,11 Z"
                            id="Shape"
                            fill="#000000"
                            fillRule="nonzero"
                          />
                          <rect
                            id="Rectangle-16"
                            fill="#000000"
                            opacity="0.3"
                            x="12"
                            y="4"
                            width="3"
                            height="5"
                            rx="0.5"
                          />
                        </g>
                      </svg>
                    </span>
                    Update
                  </button>

                  <button
                    className="btn btn-danger mr-3 mb-2"
                    onClick={() => this.AddData()}
                    name="cancel"
                    style={{ float: "right" }}
                  >
                    <span className="svg-icon menu-icon mr-2">
                      <svg
                        viewBox="0 0 24 24"
                        version="1.1"
                        className="kt-svg-icon"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <g
                            id="Group"
                            transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)"
                            fill="#000000"
                          >
                            <rect
                              id="Rectangle-185"
                              x="0"
                              y="7"
                              width="16"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-185-Copy"
                              opacity="0.3"
                              transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) "
                              x="0"
                              y="7"
                              width="16"
                              height="2"
                              rx="1"
                            />
                          </g>
                        </g>
                      </svg>
                    </span>
                    Cancel
                  </button>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(UpdateParkingSlot);
