import { UtilsPage } from "../modules/GoogleMaterialExamples/utils/UtilsPage";
import isEmpty from "../Validations/is-empty";
import { Htoh } from "../Utils";

const EnterFormElement = (name, type, value, formData) => {
  if (type === "image") {
    console.log(value);
    console.log(type);
  }
  if (!isEmpty(value)) {
    if (type === "image") {
      console.log(value);
      console.log(type);
    }
    if (name === "state") {
      formData.append(name, Number(value));
    } else if (type === "image") {
      formData.append(name, value);
      console.log(formData);
    } else if (type === "int" || type === "float") {
      formData.append(name, Number(value));
    } else if (type === "bool") {
      formData.append(name, Boolean(value));
    } else if (type === "time24HC") {
      formData.append(name, Htoh(value));
    } else {
      formData.append(name, value + "");
    }
  }
  return formData;
};

export default EnterFormElement;
