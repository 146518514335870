import React, { Component } from "react";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import axios from "axios";
import { Form, Alert, Tab, Tabs } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import { LinkButton } from "../../../Component/BaseComponents";
import { CrossIcon, ListIcon } from "../../../../../assets/Icons";
const URL = process.env.REACT_APP_API_URL;

class UpdateCheckpoint extends Component {
  constructor(props) {
    super(props);
    var currentDate = new Date();
    var dd = String(currentDate.getDate()).padStart(2, "0");
    var mm = String(currentDate.getMonth() + 1).padStart(2, "0");
    var yyyy = currentDate.getFullYear();
    currentDate = yyyy + "-" + mm + "-" + dd;
    this.state = {
      name: "",
      code: "",
      status: "ACTIVE",
      nfcCode: "",
      phoneNumber: "",
      note: "",
      latitude: "",
      longitude: "",
      geofenceRadius: "",
      geocode: "",
      notificationAction: "",
      hour: "",
      minute: "",
      reminderDatetime: currentDate,
      subject: "",
      notification: "",
      msg: "",
      error: "",
      data: this.props?.location?.state?.obj,
    };
    this.Update = this.Update.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  CheckKey = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      this.Update();
    }
  };

  AddData = () => {
    this.setState({
      name: "",
      code: "",
      status: "",
      nfcCode: "",
      phoneNumber: "",
      note: "",
      latitude: "",
      longitude: "",
      geofenceRadius: "",
      geocode: "",
      notificationAction: "",
      hour: "",
      minute: "",
      reminderDatetime: "",
      subject: "",
      notification: "",
      msg: "",
      error: "",
    });
  };

  componentWillMount() {
    if (this.props?.location?.state?.obj) {
      console.log(this.state.data);
      var currentDate = new Date(this.state.data.reminder_datetime);
      var dd = String(currentDate.getDate()).padStart(2, "0");
      var mm = String(currentDate.getMonth() + 1).padStart(2, "0");
      var yyyy = currentDate.getFullYear();
      currentDate = yyyy + "-" + mm + "-" + dd;
      this.setState({
        name: this.state.data.name,
        code: this.state.data.code,
        status: this.state.data.status,
        nfcCode: this.state.data.nfc_code,
        phoneNumber: this.state.data.phone_number,
        note: this.state.data.note,
        latitude: this.state.data.latitude,
        longitude: this.state.data.longitude,
        geofenceRadius: this.state.data.geofence_radius,
        geocode: this.state.data.geocode,
        notificationAction: this.state.data.notification_action,
        hour: this.state.data.hour,
        minute: this.state.data.minute,
        reminderDatetime: currentDate,
        subject: this.state.data.subject,
        notification: this.state.data.notification,
        id: this.state.data.id,
      });
    } else {
      this.props.history.push("/staff/checkpoint");
    }
  }

  async Update() {
    if (this.state.name === "") {
      toast.error("Please enter checkpoint name!");
    } else if (this.state.phoneNumber === "") {
      toast.error("Please enter checkpoint number!");
    } else if (this.state.nfcCode === "") {
      toast.error("Please enter NFC Code!");
    } else {
      toast.success("Updating checkpoint ...");
      var currentDate = new Date(this.state.reminderDatetime);
      var dd = String(currentDate.getDate()).padStart(2, "0");
      var mm = String(currentDate.getMonth() + 1).padStart(2, "0");
      var yyyy = currentDate.getFullYear();
      currentDate = yyyy + "-" + mm + "-" + dd;
      var formData = new FormData();
      formData.append("name", this.state.name);
      formData.append("code", this.state.code);
      formData.append("status", this.state.status);
      formData.append("nfcCode", this.state.nfcCode);
      formData.append("phoneNumber", this.state.phoneNumber);
      if (!isEmpty(this.state.note)) formData.append("note", this.state.note);
      if (!isEmpty(this.state.latitude))
        formData.append("latitude", this.state.latitude);
      if (!isEmpty(this.state.longitude))
        formData.append("longitude", this.state.longitude);
      if (!isEmpty(this.state.geofenceRadius))
        formData.append("geofenceRadius", this.state.geofenceRadius);
      if (!isEmpty(this.state.geocode))
        formData.append("geocode", this.state.geocode);
      if (!isEmpty(this.state.notificationAction))
        formData.append("notificationAction", this.state.notificationAction);
      if (!isEmpty(this.state.addditional_information))
        formData.append(
          "addditional_information",
          this.state.addditional_information
        );
      if (!isEmpty(this.state.hour)) formData.append("hour", this.state.hour);
      if (!isEmpty(this.state.minute))
        formData.append("minute", this.state.minute);
      if (!isEmpty(this.state.currentDate))
        formData.append("reminderDatetime", currentDate);
      if (!isEmpty(this.state.subject))
        formData.append("subject", this.state.subject);
      if (!isEmpty(this.state.notification))
        formData.append("notification", this.state.notification);
      axios
        .put(`${URL}/api/staff/checkpoint/${this.state.id}`, formData)
        .then(async (res) => {
          toast.success("Checkpoint Updated Succesfully");

          this.setState(
            {
              msg: "Checkpoint Updated Successfully!",
              error: "",
              name: "",
              code: "",
              status: "",
              nfcCode: "",
              phoneNumber: "",
              note: "",
              latitude: "",
              longitude: "",
              geofenceRadius: "",
              geocode: "",
              notificationAction: "",
              hour: "",
              minute: "",
              reminderDatetime: "",
              subject: "",
              notification: "",
            },
            () => {
              this.props.history.push("/staff/checkpoint");
            }
          );
        })
        .catch((err) => {
          console.log(err.response);
          if (Array.isArray(err?.response?.data?.errors)) {
            if (err?.response?.data?.errors[0]?.field === "phoneNumber") {
              toast.error(
                " Checkpoint number must be " +
                  err?.response?.data?.errors[0]?.rule +
                  " !"
              );
            } else {
              toast.error(
                err?.response?.data?.errors[0]?.field +
                  " should be " +
                  err?.response?.data?.errors[0]?.rule +
                  " !"
              );
            }
          } else {
            toast.error(err?.response?.data?.message);
          }
        });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <div className="row">
            <div className="col-sm-6 col-md-8 col-lg-8"></div>
            <div
              className="col-sm-6 col-md-4 col-lg-4"
              style={{ float: "right" }}
            >
              {this.state.error && this.state.error ? (
                <Alert variant="danger">{this.state.error}</Alert>
              ) : null}
              {this.state.msg && this.state.msg ? (
                <Alert variant="success">{this.state.msg}</Alert>
              ) : null}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <Card>
                <CardHeader title="Update Checkpoint">
                  <CardHeaderToolbar>
                    <LinkButton
                      className="btn-primary"
                      to="/staff/checkpoint"
                      label="List"
                      name="list"
                      icon={<ListIcon />}
                    ></LinkButton>
                  </CardHeaderToolbar>
                </CardHeader>
                <CardBody className="row">
                  <div className="col-12">
                    <div className="row">
                      <h4 className="col-md-9 col-lg-10 col-sm-6">
                        Checkpoint Information
                      </h4>
                      <Form.Group
                        style={{ float: "right" }}
                        className="col-md-3 col-lg-2 col-sm-6 -mt-5"
                      >
                        <Form.Label>Status</Form.Label>
                        <select
                          className="form-control form-control-sm"
                          name="status"
                          value={this.state.status}
                          onChange={this.onChange}
                        >
                          <option value="ACTIVE">ACTIVE</option>
                          <option value="DEACTIVE">De-ACTIVE</option>
                          <option value="SUSPENDED">SUSPENDED</option>
                          <option value="APPROVE ">APPROVED</option>
                        </select>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-12 col-sm-12">
                    <div className="row">
                      <Form.Group className="col-md-6 col-lg-4 col-sm-12">
                        <Form.Label>Name</Form.Label>
                        <div className="input-group ">
                          <input
                            onKeyPress={this.CheckKey}
                            className="form-control form-control-sm"
                            type="text"
                            placeholder="Name"
                            value={this.state.name}
                            name="name"
                            onChange={this.onChange}
                          ></input>
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i
                                className="flaticon-user"
                                style={{ fontSize: "13px" }}
                              ></i>
                            </span>
                          </div>
                        </div>
                      </Form.Group>
                      <Form.Group className="col-md-6 col-lg-4 col-sm-12">
                        <Form.Label>Number</Form.Label>
                        <div className="input-group ">
                          <input
                            onKeyPress={this.CheckKey}
                            className="form-control form-control-sm"
                            type="text"
                            placeholder="Number"
                            value={this.state.phoneNumber}
                            name="phoneNumber"
                            onChange={this.onChange}
                          ></input>
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text"
                              id="basic-addon1"
                            >
                              <i
                                className="flaticon-smartphone"
                                style={{ fontSize: "13px" }}
                              ></i>
                            </span>
                          </div>
                        </div>
                      </Form.Group>
                      <Form.Group className="col-md-6 col-lg-4 col-sm-12">
                        <Form.Label>NFC-Code</Form.Label>
                        <div className="input-group ">
                          <input
                            onKeyPress={this.CheckKey}
                            className="form-control form-control-sm"
                            type="text"
                            placeholder="NFC-Code"
                            value={this.state.nfcCode}
                            name="nfcCode"
                            onChange={this.onChange}
                          ></input>
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text"
                              id="basic-addon1"
                            >
                              <i
                                className="flaticon-qr-code"
                                style={{ fontSize: "13px" }}
                              ></i>
                            </span>
                          </div>
                        </div>
                      </Form.Group>

                      <Form.Group className="col-md-12 col-lg-12 col-sm-12">
                        <Form.Label>Notes</Form.Label>
                        <textarea
                          onKeyPress={this.CheckKey}
                          rows="5"
                          className="form-control form-control-sm"
                          type="text"
                          placeholder="Notes"
                          value={this.state.note}
                          name="note"
                          onChange={this.onChange}
                        ></textarea>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="col-12">
                    <Tabs
                      defaultActiveKey="location"
                      transition={false}
                      id="noanim-tab-example"
                    >
                      <Tab eventKey="location" title="Location">
                        <div className="row mt-3">
                          <Form.Group className="col-md-6 col-lg-6 col-sm-12">
                            <Form.Label>Longitude</Form.Label>
                            <div className="input-group ">
                              <input
                                onKeyPress={this.CheckKey}
                                className="form-control form-control-sm"
                                type="text"
                                placeholder="Longitude"
                                value={this.state.longitude}
                                name="longitude"
                                onChange={this.onChange}
                              ></input>
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text"
                                  id="basic-addon1"
                                >
                                  <i
                                    className="flaticon-placeholder"
                                    style={{ fontSize: "13px" }}
                                  ></i>
                                </span>
                              </div>
                            </div>
                          </Form.Group>
                          <Form.Group className="col-md-6 col-lg-6 col-sm-12">
                            <Form.Label>Latitude</Form.Label>
                            <div className="input-group ">
                              <input
                                onKeyPress={this.CheckKey}
                                className="form-control form-control-sm"
                                type="text"
                                placeholder="Latitude"
                                value={this.state.latitude}
                                name="latitude"
                                onChange={this.onChange}
                              ></input>
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text"
                                  id="basic-addon1"
                                >
                                  <i
                                    className="flaticon-placeholder"
                                    style={{ fontSize: "13px" }}
                                  ></i>
                                </span>
                              </div>
                            </div>
                          </Form.Group>
                          <Form.Group className="col-md-6 col-lg-6 col-sm-12">
                            <Form.Label>Geofence Radius</Form.Label>
                            <div className="input-group ">
                              <input
                                onKeyPress={this.CheckKey}
                                className="form-control form-control-sm"
                                type="number"
                                placeholder="Geofence Radius"
                                value={this.state.geofenceRadius}
                                name="geofenceRadius"
                                onChange={this.onChange}
                              ></input>
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text"
                                  id="basic-addon1"
                                >
                                  <i
                                    className="flaticon-placeholder"
                                    style={{ fontSize: "13px" }}
                                  ></i>
                                </span>
                              </div>
                            </div>
                          </Form.Group>
                          <Form.Group className="col-md-6 col-lg-6 col-sm-12">
                            <Form.Label>Geocode</Form.Label>
                            <div className="input-group ">
                              <input
                                onKeyPress={this.CheckKey}
                                className="form-control form-control-sm"
                                type="text"
                                placeholder="Geocode"
                                value={this.state.geocode}
                                name="geocode"
                                onChange={this.onChange}
                              ></input>
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text"
                                  id="basic-addon1"
                                >
                                  <i
                                    className="flaticon-qr-code"
                                    style={{ fontSize: "13px" }}
                                  ></i>
                                </span>
                              </div>
                            </div>
                          </Form.Group>
                        </div>
                      </Tab>
                      <Tab eventKey="notification" title="Notification">
                        <div className="row mt-3">
                          <Form.Group className="col-md-6 col-lg-3 col-sm-12">
                            <Form.Label>Action</Form.Label>
                            <select
                              className="form-control form-control-sm"
                              name="notificationAction"
                              value={this.state.notificationAction}
                              onChange={this.onChange}
                            >
                              <option value="On Time">On Time</option>
                              <option value="Before Time">Before Time</option>
                              <option value="Before Custom Time">
                                Before Custom Time
                              </option>
                              <option value="After Custom Time">
                                After Custom Time
                              </option>
                            </select>
                          </Form.Group>
                          <Form.Group className="col-md-6 col-lg-3 col-sm-12">
                            <Form.Label>Reminder Date</Form.Label>
                            <input
                              onKeyPress={this.CheckKey}
                              className="form-control form-control-sm"
                              type="date"
                              placeholder="Reminder Date"
                              value={this.state.reminderDatetime}
                              name="reminderDatetime"
                              onChange={this.onChange}
                            ></input>
                          </Form.Group>
                          <Form.Group className="col-md-6 col-lg-3 col-sm-12">
                            <Form.Label>Hour</Form.Label>
                            <div className="input-group ">
                              <input
                                onKeyPress={this.CheckKey}
                                className="form-control form-control-sm"
                                type="number"
                                placeholder="Hour"
                                value={this.state.hour}
                                name="hour"
                                onChange={this.onChange}
                              ></input>
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text"
                                  id="basic-addon1"
                                >
                                  <i
                                    className="flaticon-24-hour-service"
                                    style={{ fontSize: "13px" }}
                                  ></i>
                                </span>
                              </div>
                            </div>
                          </Form.Group>
                          <Form.Group className="col-md-6 col-lg-3 col-sm-12">
                            <Form.Label>Minute</Form.Label>
                            <div className="input-group ">
                              <input
                                onKeyPress={this.CheckKey}
                                className="form-control form-control-sm"
                                type="number"
                                placeholder="Minute"
                                value={this.state.minute}
                                name="minute"
                                onChange={this.onChange}
                              ></input>
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text"
                                  id="basic-addon1"
                                >
                                  <i
                                    className="flaticon-60-minutes"
                                    style={{ fontSize: "13px" }}
                                  ></i>
                                </span>
                              </div>
                            </div>
                          </Form.Group>
                          <Form.Group className="col-md-12 col-lg-12 col-sm-12">
                            <Form.Label>Subject</Form.Label>
                            <div className="input-group ">
                              <input
                                onKeyPress={this.CheckKey}
                                className="form-control form-control-sm"
                                type="text"
                                placeholder="Subject"
                                value={this.state.subject}
                                name="subject"
                                onChange={this.onChange}
                              ></input>
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text"
                                  id="basic-addon1"
                                >
                                  <i
                                    className="flaticon-description"
                                    style={{ fontSize: "13px" }}
                                  ></i>
                                </span>
                              </div>
                            </div>
                          </Form.Group>
                          <Form.Group className="col-md-12 col-lg-12 col-sm-12">
                            <Form.Label>Notification</Form.Label>
                            <textarea
                              onKeyPress={this.CheckKey}
                              rows="10"
                              className="form-control form-control-sm"
                              type="text"
                              placeholder="Notification"
                              value={this.state.notification}
                              name="notification"
                              onChange={this.onChange}
                            ></textarea>
                          </Form.Group>
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </CardBody>
                <div>
                  <button
                    className="btn btn-primary   mr-4 mb-2"
                    onClick={() => this.Update()}
                    style={{ float: "right" }}
                  >
                    <span className="svg-icon menu-icon mr-2">
                      <svg
                        viewBox="0 0 24 24"
                        version="1.1"
                        className="kt-svg-icon"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <polygon id="Shape" points="0 0 24 0 24 24 0 24" />
                          <path
                            d="M17,4 L6,4 C4.79111111,4 4,4.7 4,6 L4,18 C4,19.3 4.79111111,20 6,20 L18,20 C19.2,20 20,19.3 20,18 L20,7.20710678 C20,7.07449854 19.9473216,6.94732158 19.8535534,6.85355339 L17,4 Z M17,11 L7,11 L7,4 L17,4 L17,11 Z"
                            id="Shape"
                            fill="#000000"
                            fillRule="nonzero"
                          />
                          <rect
                            id="Rectangle-16"
                            fill="#000000"
                            opacity="0.3"
                            x="12"
                            y="4"
                            width="3"
                            height="5"
                            rx="0.5"
                          />
                        </g>
                      </svg>
                    </span>
                    Update
                  </button>

                  <LinkButton
                    className="btn-danger mr-3 mb-2"
                    to="/staff/checkpoint"
                    label="Cancel"
                    name="cancel"
                    icon={<CrossIcon color="#fff" />}
                    style={{ float: "right" }}
                  ></LinkButton>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(UpdateCheckpoint);
