import React, { Component } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Dropdown, Modal, Table } from "react-bootstrap";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import _ from "lodash";
import moment from "moment";
import {
  GetNestedObjectString,
  fetchByDotOperator,
  CalculateTime,
} from "./ObjectFunctions";
import { Avatar } from "@material-ui/core";
import ReactAudioPlayer from "react-audio-player";
import ReactToPrint from "react-to-print";
import { Constants,convertUTCToLocal } from "../../../Utils";
class Grid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sort: false,
      select: ["none"],
      data: props.data,
      url: "",
      type: "",
      modelVisible: false,
    };
    console.log(props);
  }

  componentWillMount() {
    this.setState({ data: this.props.data });
  }

  componentDidMount() {
    this.setState({ data: this.props.data });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.state.data) {
      this.setState({ data: nextProps.data });
    }
  }

  componentWillUnmount() {}

  handleClick = (path, b, type, object_name = "") => {
    const char = this.state.data;
    let ch = [];
    if (type === "object") {
      ch = _.orderBy(
        char,
        (obj) => {
          return fetchByDotOperator(
            obj,
            GetNestedObjectString(object_name) + path
          );
        },
        [this.state.sort ? "asc" : "desc"]
      );
    } else {
      ch = _.orderBy(char, [path], [this.state.sort ? "asc" : "desc"]);
    }
    this.setState({ data: [] });
    this.setState({ data: ch });
    this.setState({ sort: !this.state.sort });
    this.setState({ select: [b] });
    if (this.props.dataUpdated) {
      this.props.dataUpdated(ch);
    }
  };

  openModel = (url, type = "") => {
    if (type === "audio") {
      this.setState({ type: "audio", url: url, modelVisible: true });
    } else {
      this.setState({ type: "image", url: url, modelVisible: true }, () =>
        console.log(this.state)
      );
    }
  };

  closeModal = () => {
    this.setState({ modelVisible: false, audio: "", image: "" });
  };

  render() {
    const customStyles = {
      control: (base) => ({
        ...base,
        height: 33,
        minHeight: 33,
        border: "1px solid #E4E6EF",
        borderRadius: "3px",
        fontSize: 10.5,
        fontWeight: 400,
        paddingTop: -25,
      }),
    };

    return (
      <>
        <Table responsive>
          <thead>
            <tr>
              {this.props.columns.map((column) => (
                <th
                  width={column.width}
                  onClick={() => {
                    if (column.type !== "audio" && column.type !== "image")
                      this.handleClick(
                        column.field_name,
                        column.column_name,
                        column.type,
                        column?.object_name
                      );
                  }}
                >
                  <span style={{ display: "flex" }}>
                    {column.column_name}
                    <div
                      className="d-flex"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {column.type !== "audio" && column.type !== "image" && (
                        <>
                          <ExpandLessIcon
                            style={{
                              height: "16px",
                              color:
                                this.state.sort === false &&
                                this.state.select.includes(column.column_name)
                                  ? "black"
                                  : "#898f8b",
                              marginTop: "-3px",
                            }}
                          />
                          <ExpandMoreIcon
                            style={{
                              height: "16px",
                              color:
                                this.state.sort === true &&
                                this.state.select.includes(column.column_name)
                                  ? "black"
                                  : "#898f8b",
                              marginTop: "-8px",
                            }}
                          />
                        </>
                      )}
                    </div>
                  </span>
                </th>
              ))}
              {(this.props.print || this.props.detail) && (
                <th className="notPrint">Actions</th>
              )}
            </tr>
          </thead>
          <tbody>
            {this.state?.data &&
              this.state?.data?.map((obj, k) => (
                <tr key={k}>
                  {this.props.columns.map((column) => (
                    <td>
                      {column.column_name === "Sr" ? (
                        k + 1
                      ) : column.type === "object" ? (
                        fetchByDotOperator(
                          obj,
                          GetNestedObjectString(column.object_name) +
                            column.field_name
                        )
                      ) : column.type === "date_time" ? (
                        convertUTCToLocal(
                          obj[column.field_name],
                          Constants.time_format.DATE_TIME_12
                        ) !== "Invalid date" ? (
                          convertUTCToLocal(
                            obj[column.field_name],
                            Constants.time_format.DATE_TIME_12
                          )
                        ) : (
                          ""
                        )
                      ) : column.type === "hour" ? (
                        convertUTCToLocal(
                          obj[column.field_name],
                          Constants.time_format.TIME_12
                        ) !== "Invalid Time" ? (
                          convertUTCToLocal(
                            obj[column.field_name],
                            Constants.time_format.TIME_12
                          )
                        ) : (
                          ""
                        )
                      ) : column.type === "image" ? (
                        <Avatar
                          src={obj[column.field_name]}
                          width="25px"
                          height="25px"
                          onClick={() => this.openModel(obj[column.field_name])}
                        ></Avatar>
                      ) : column.type === "audio" ? (
                        obj[column.field_name] ? (
                          <img
                            src={require("../../../../assets/play.png")}
                            onClick={() =>
                              this.openModel(obj[column.field_name], "audio")
                            }
                            width="25px"
                            height="25px"
                            alt=""
                          />
                        ) : (
                          ""
                        )
                      ) : column.type === "static_dropdown" ? (
                        <Dropdown>
                          <Dropdown.Toggle
                            id="dropdown-basic"
                            size="sm"
                            variant="secondary"
                          >
                            {obj.status}{" "}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {column.staticOptionsObject.length > 0
                              ? column.staticOptions.map((option) => (
                                  <Dropdown.Item
                                    onClick={() =>
                                      this.props.updateDropdownObject(
                                        obj,
                                        fetchByDotOperator(
                                          obj,
                                          GetNestedObjectString(
                                            column.staticOptionsObject
                                          ) + option
                                        )
                                      )
                                    }
                                  >
                                    {fetchByDotOperator(
                                      obj,
                                      GetNestedObjectString(
                                        column.staticOptionsObject
                                      ) + option
                                    )}
                                  </Dropdown.Item>
                                ))
                              : null}
                          </Dropdown.Menu>
                        </Dropdown>
                      ) : column.type === "time_difference" ? (
                        obj[column.field_name1] ? (
                          CalculateTime(
                            obj[column.field_name],
                            obj[column.field_name1]
                          )
                        ) : (
                          "On Duty"
                        )
                      ) : (
                        obj[column.field_name]
                      )}
                    </td>
                  ))}
                  {(this.props.print || this.props.detail) && (
                    <td className="notPrint">
                      {this.props.print && (
                        <ReactToPrint
                          onBeforeGetContent={() => {
                            this.props.setPrintData(obj);
                          }}
                          trigger={() => {
                            return (
                              <button
                                title="Print"
                                className="btn btn-xs btn-default btn-text-primary btn-hover-primary btn-icon btn-icon-primary "
                              >
                                <span className="svg-icon svg-icon-md ">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                  >
                                    <g
                                      stroke="none"
                                      strokeWidth="1"
                                      fill="none"
                                      fillRule="evenodd"
                                    >
                                      <rect
                                        x="0"
                                        y="0"
                                        width="24"
                                        height="24"
                                      />
                                      <path
                                        d="M16,17 L16,21 C16,21.5522847 15.5522847,22 15,22 L9,22 C8.44771525,22 8,21.5522847 8,21 L8,17 L5,17 C3.8954305,17 3,16.1045695 3,15 L3,8 C3,6.8954305 3.8954305,6 5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,15 C21,16.1045695 20.1045695,17 19,17 L16,17 Z M17.5,11 C18.3284271,11 19,10.3284271 19,9.5 C19,8.67157288 18.3284271,8 17.5,8 C16.6715729,8 16,8.67157288 16,9.5 C16,10.3284271 16.6715729,11 17.5,11 Z M10,14 L10,20 L14,20 L14,14 L10,14 Z"
                                        fill="#000000"
                                      />
                                      <rect
                                        fill="#000000"
                                        opacity="0.3"
                                        x="8"
                                        y="2"
                                        width="8"
                                        height="2"
                                        rx="1"
                                      />
                                    </g>
                                  </svg>
                                </span>
                              </button>
                            );
                          }}
                          content={() => this.props.printRef}
                        />
                      )}

                      {this.props.detail && (
                        <button
                          onClick={() => this.props.GetDetail(obj)}
                          className="btn btn-xs btn-default btn-text-primary btn-hover-primary btn-icon btn-icon-primary "
                          title="View Details"
                        >
                          <span className="svg-icon svg-icon-md">
                            <svg
                              viewBox="0 0 24 24"
                              version="1.1"
                              className="kt-svg-icon"
                            >
                              <g
                                stroke="none"
                                strokeWidth="1"
                                fill="none"
                                fillRule="evenodd"
                              >
                                <polygon
                                  id="Shape"
                                  points="0 0 24 0 24 24 0 24"
                                />
                                <path
                                  d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z"
                                  id="Combined-Shape"
                                  fill="#000000"
                                  fillRule="nonzero"
                                  opacity="0.3"
                                />
                                <rect
                                  id="Rectangle"
                                  fill="#000000"
                                  x="6"
                                  y="11"
                                  width="9"
                                  height="2"
                                  rx="1"
                                />
                                <rect
                                  id="Rectangle-Copy"
                                  fill="#000000"
                                  x="6"
                                  y="15"
                                  width="5"
                                  height="2"
                                  rx="1"
                                />
                              </g>
                            </svg>
                          </span>
                        </button>
                      )}
                    </td>
                  )}
                </tr>
              ))}
          </tbody>
        </Table>

        <Modal
          show={this.state.modelVisible}
          onHide={this.closeModal}
          style={customStyles}
          contentLabel="Media Popup"
        >
          {this.state.type === "audio" ? (
            <ReactAudioPlayer src={this.state.url} controls />
          ) : (
            <a
              href={this.state.url}
              target="_blank"
              style={{ height: "400px", width: "400px", alignSelf: "center" }}
            >
              <img
                className="no-borderRadiusImportant"
                src={this.state.url}
                alt="P"
                width="400px"
                height="400px"
                style={{
                  borderRadius: "5px!important",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              />
            </a>
          )}
        </Modal>
      </>
    );
  }
}

export default Grid;
