import { Form } from "react-bootstrap";
import React from "react";
const TextInput = (props) => {
  return (
    <>
      <Form.Group className={props.width}>
        <Form.Label>{props.label}</Form.Label>
        <div className="input-group ">
          <input
            onKeyPress={props.CheckKey}
            className="form-control form-control-sm"
            type={props.type}
            placeholder={props.placeholder}
            value={props.value}
            name={props.name}
            onChange={props.onChange}
          ></input>
          {props.includeIcon && (
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                <i className={props.icon} style={{ fontSize: "12.5px" }}></i>
              </span>
            </div>
          )}
        </div>
      </Form.Group>
    </>
  );
};
export default TextInput;
