import React, { Component } from "react";
import { withRouter } from "react-router";
import $ from "jquery";
import { toast } from "react-toastify";
import axios from "axios";
import isEmpty from "../../../../Validations/is-empty";
import { Form, Table, Alert } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import Select from "react-select";
const URL = process.env.REACT_APP_API_URL;

class AssignItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      guards: [],
      id: "0",
      items: [],
      itemsList: [],
      reqObj: {},
    };
    this.Save.bind(this);
    this.GetGuards.bind(this);
    this.GuardChanges.bind(this);
    this.GetUnAssignedItems.bind(this);
    this.ItemsChanges.bind(this);
  }

  GetGuards = () => {
    axios
      .get(`${URL}/api/staff/client_staff?role=guard&page=1&limit=1000`)
      .then(async (res) => {
        var newCategories = [];
        for (var i = 0; i < res.data.data.length; i++) {
          newCategories.push({
            id: res.data.data[i].id + "",
            name: res.data.data[i].profile.name,
            items: res.data.data[i].items,
          });
        }
        await this.setState({
          guards: newCategories,
        });
      });
  };

  GetUnAssignedItems = () => {
    axios.get(`${URL}/api/staff/item/?page=1&limit=1000`).then(async (res) => {
      var newCategories = [];
      for (var i = 0; i < res.data.data.length; i++) {
        newCategories.push({
          value: res.data.data[i].id + "",
          label: res.data.data[i].name,
        });
      }
      console.log(newCategories, "allItemss");
      await this.setState({
        items: newCategories,
      });
    });
  };

  GuardChanges = (val) => {
    if (val.id !== "0") {
      if (!isEmpty(val.items)) {
        let selectedItems = [];
        val.items.map((v) => { 
          selectedItems.push({
            value: v.id.toString(),
            label: v.name.toString(),
          });
        }); 
        this.setState({
          itemsList: [...selectedItems],
          data: val.items,
        });
      } else {
        this.setState({ data: [] });
      }
    }
    this.setState({ id: val.id });
  };

  componentWillMount() {
    this.GetGuards();
    this.GetUnAssignedItems();
  }

  async Save() {
    if (this.state.id !== "0") {
      if (!isEmpty(this.state.itemsList)) {
        this.setState({
          msg: "Asigning items...",
          error: "",
        });
        var ids = [];
        for (var i = 0; i < this.state.itemsList.length; i++) {
          if (i == 0) {
            ids = [Number(this.state.itemsList[i].value)];
          } else {
            ids.push(Number(this.state.itemsList[i].value));
          }
        }
        // for (i = 0; i < this.state.data.length; i++) {
        //   console.log("I am here data");
        //   if (i == 0) {
        //     ids = [Number(this.state.data[i].id)];
        //   } else {
        //     console.log("I am here data 1");
        //     ids.push(Number(this.state.data[i].id));
        //   }
        // }
        await this.setState(
          {
            reqObj: {
              guardId: Number(this.state.id),
              items: ids,
            },
          },
          () => {
            console.log(this.state.reqObj);
          }
        );
        await axios
          .post(`${URL}/api/staff/item/assign`, this.state.reqObj)
          .then(async (res) => {
            toast.success("Items Assigned Successfully!");
            this.setState(
              {
                msg: "Items Assigned Successfully!",
                error: "",
                data: [],
                id: "0",
                itemsList: [],
              },
              () => {
                console.log(res);
                this.GetGuards();
                this.GetUnAssignedItems();
              }
            );
          })
          .catch((err) => {
            console.log(err.response);
            this.setState({
              msg: "",
              error: err.response.data.errors[0].message,
            });
          });
      } else {
        toast.error("Please select an item first!");
        this.setState({
          msg: "",
          error: "Please select an item first!",
        });
      }
    } else {
      toast.error("Please select a guard first!");

      this.setState({
        msg: "",
        error: "Please select a guard first!",
      });
    }
  }

  ItemsChanges = (selectedMulti) => {
    console.log(selectedMulti, "sai");
    this.setState({ itemsList: selectedMulti });
  };

  render() {
    return (
      <React.Fragment>
        <h3 style={{ marginBottom: "2%" }}>Assign Items To Guard</h3>
        <div className="page-content">
          <div className="row">
            <div className="col-sm-6 col-md-8 col-lg-8"></div>
            <div
              className="col-sm-6 col-md-4 col-lg-4"
              style={{ float: "right" }}
            >
              {/* {this.state.error && this.state.error ? (
                <Alert variant="danger">{this.state.error}</Alert>
              ) : null}
              {this.state.msg && this.state.msg ? (
                <Alert variant="success">{this.state.msg}</Alert>
              ) : null} */}
            </div>
          </div>
          <div className="row">
            <Form.Group className="col-md-4 col-lg-3 col-sm-12">
              <Form.Label>Select Guard</Form.Label>
              <Select
                placeholder="Select a Gurd"
                name="id"
                options={this.state.guards}
                onChange={this.GuardChanges}
                value={this.state.guards.filter(
                  ({ id }) => id === this.state.id
                )}
                getOptionLabel={({ name, id }) => name + " | " + id}
                getOptionValue={({ id }) => id}
              />
            </Form.Group>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6">
              <Card>
                <CardHeader title="Guard Items"></CardHeader>
                <CardBody>
                  <Table responsive className="col-12">
                    <thead>
                      <tr>
                        <th width="12%">Sr #</th>
                        <th width="38%">Name</th>
                        <th width="50%">Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.data ? (
                        this.state.data.map((obj, key) => (
                          <tr>
                            <td>{key + 1}</td>
                            <td>{obj.name}</td>
                            <td>{obj.description}</td>
                          </tr>
                        ))
                      ) : (
                        <h3>No Data Available.</h3>
                      )}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </div>

            <div className="col-sm-12 col-md-6 col-lg-6">
              <Card>
                <CardHeader title="Assign Items"></CardHeader>
                <CardBody>
                  <Form.Group className="col-md-12">
                    <Form.Label>Select Items</Form.Label>
                    <Select
                      placeholder="Select Items"
                      value={this.state.itemsList}
                      isMulti={true}
                      onChange={this.ItemsChanges}
                      options={this.state.items}
                      classNamePrefix="select2-selection"
                    />
                  </Form.Group>
                  <button
                    className="btn btn-primary mr-4 mb-2"
                    onClick={() => this.Save()}
                    style={{ float: "right" }}
                  >
                    <span className="svg-icon menu-icon mr-2">
                      <svg
                        viewBox="0 0 24 24"
                        version="1.1"
                        className="kt-svg-icon"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <polygon id="Shape" points="0 0 24 0 24 24 0 24" />
                          <path
                            d="M17,4 L6,4 C4.79111111,4 4,4.7 4,6 L4,18 C4,19.3 4.79111111,20 6,20 L18,20 C19.2,20 20,19.3 20,18 L20,7.20710678 C20,7.07449854 19.9473216,6.94732158 19.8535534,6.85355339 L17,4 Z M17,11 L7,11 L7,4 L17,4 L17,11 Z"
                            id="Shape"
                            fill="#000000"
                            fillRule="nonzero"
                          />
                          <rect
                            id="Rectangle-16"
                            fill="#000000"
                            opacity="0.3"
                            x="12"
                            y="4"
                            width="3"
                            height="5"
                            rx="0.5"
                          />
                        </g>
                      </svg>
                    </span>
                    Assign
                  </button>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(AssignItem);
