import React, { Component } from "react";
import { withRouter } from "react-router";
import axios from "axios";
import { Form, Alert } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../../_metronic/_partials/controls";
import { Link } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
const URL = process.env.REACT_APP_API_URL;
class UpdateParkingLevel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      level: "",
      status: "Active",
      block: "0",
      AllBlock: [],
      msg: "",
      error: "",
      from: [150],
      sendFrom: "",
      sendTo: parseInt(this?.state?.sendFrom) + 1,

      checkedList: ["single"],
    };
    this.Save = this.Save.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  CheckKey = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      this.Save();
    }
  };

  GetBlock = () => {
    axios.get(`${URL}/api/staff/block?page=1&limit=1000`).then(async (res) => {
      var newCategories = [{ id: "0", name: "--Select One--" }];
      for (var i = 0; i < res.data.data.length; i++) {
        newCategories.push({
          id: res.data.data[i].id + "",
          name: res.data.data[i].name,
        });
      }
      await this.setState({
        AllBlock: newCategories,
      });
    });
  };

  BlockChanges = (val) => {
    this.setState({ block: val.id });
  };

  AddData = () => {
    this.setState({
      block: "",
      level: "",
      status: "",

      msg: "",
      error: "",
    });
  };

  componentWillMount() {
    if (this.props?.location?.param1) {
      this.GetBlock();
      this.setState({
        block: this.props.location.param1.block_id + "",
        status: this.props.location.param1.status,
        level: this.props.location.param1.name,
      });
    } else {
      this.props.history.push("/parking/parking-level");
    }
  }

  ValidateInputs = () => {
    if (this.state.block === "0") {
      toast.error("Please select a block first!");
      return false;
    } else if (this.state.status === "") {
      toast.error("Please select a status first!");
      return false;
    } else if (
      this.state.checkedList.includes("single") &&
      this.state.level === ""
    ) {
      toast.error("Please enter level name!");
      return false;
    } else if (
      this.state.checkedList.includes("multiple") &&
      this.state.sendFrom === ""
    ) {
      toast.error("Please select level from !");
      return false;
    } else if (
      this.state.checkedList.includes("multiple") &&
      this.state.sendTo === ""
    ) {
      toast.error("Please select level to!");
      return false;
    } else {
      return true;
    }
  };

  async Save() {
    if (this.ValidateInputs()) {
      toast.success("Updating level ...");
      let obj = {
        blockId: this.state.block,
        status: this.state.status,
        name: this.state.level,
      };
      if (this.state.checkedList.includes("single")) {
        obj.levels = [this.state.level];
      } else if (this.state.checkedList.includes("multiple")) {
        let arr = [];
        for (let i = this.state.sendFrom; i <= this.state.sendTo; i++) {
          arr.push(i.toString());
        }
        obj.levels = arr;
      }
      axios
        .put(
          `${URL}/api/staff/parking_level/${this.props.location.param1.id}`,
          obj
        )
        .then(async (res) => {
          toast.success("Level updated successfully!");
          this.setState(
            {
              block: "",
              level: "",
              status: "",
            },
            () => {
              this.props.history.push("/parking/parking-level");
            }
          );
        })
        .catch((err) => {
          console.log(err.response);
          if (err.status === 404) {
            toast.error("Block Not Found!");
          }
        });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    console.log(this.state);
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <div className="row">
            <div className="col-sm-6 col-md-8 col-lg-8"></div>
            <div
              className="col-sm-6 col-md-4 col-lg-4"
              style={{ float: "right" }}
            >
              {this.state.error && this.state.error ? (
                <Alert variant="danger">{this.state.error}</Alert>
              ) : null}
              {this.state.msg && this.state.msg ? (
                <Alert variant="success">{this.state.msg}</Alert>
              ) : null}
            </div>
          </div>
          <div
            className="row"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="col-sm-12 col-md-9 col-lg-9 ">
              <Card>
                <CardHeader
                  title="Update Level" 
                >
                  <CardHeaderToolbar>
                    <Link
                      type="button"
                      className="btn btn-primary"
                      name="add_button"
                      to="/project/level"
                    >
                      <span className="svg-icon menu-icon mr-2">
                        <svg
                          viewBox="0 0 24 24"
                          version="1.1"
                          className="kt-svg-icon"
                        >
                          <g
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <rect
                              id="bound"
                              x="0"
                              y="0"
                              width="24"
                              height="24"
                            />
                            <path
                              d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
                              id="Combined-Shape"
                              fill="#000000"
                              opacity="0.3"
                            />
                            <path
                              d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
                              id="Combined-Shape"
                              fill="#000000"
                            />
                            <rect
                              id="Rectangle-152"
                              fill="#000000"
                              opacity="0.3"
                              x="10"
                              y="9"
                              width="7"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-152-Copy-2"
                              fill="#000000"
                              opacity="0.3"
                              x="7"
                              y="9"
                              width="2"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-152-Copy-3"
                              fill="#000000"
                              opacity="0.3"
                              x="7"
                              y="13"
                              width="2"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-152-Copy"
                              fill="#000000"
                              opacity="0.3"
                              x="10"
                              y="13"
                              width="7"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-152-Copy-5"
                              fill="#000000"
                              opacity="0.3"
                              x="7"
                              y="17"
                              width="2"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-152-Copy-4"
                              fill="#000000"
                              opacity="0.3"
                              x="10"
                              y="17"
                              width="7"
                              height="2"
                              rx="1"
                            />
                          </g>
                        </svg>
                      </span>
                      List
                    </Link>
                  </CardHeaderToolbar>
                </CardHeader>
                <CardBody className="row">
                  <h4 className="col-12 mt-2 mb-4">Level Detail</h4>

                  <div className="col-12">
                    <div className="row">
                      <Form.Group
                        className="col-12 "
                        style={{ marginTop: "-10px" }}
                      >
                        {/* <Form.Label>Image</Form.Label> */}
                      </Form.Group>

                      <Form.Group className="col-12">
                        <Form.Label>Block Number/Name</Form.Label>
                        <Select
                          name="block"
                          options={this.state.AllBlock}
                          onChange={this.BlockChanges}
                          value={this.state.AllBlock.find(
                            (v) => v.id === this.state.block
                          )}
                          getOptionLabel={({ name }) => name}
                          getOptionValue={({ id }) => id}
                        />
                      </Form.Group>

                      <Form.Group className="col-12">
                        <Form.Label>Status</Form.Label>
                        <select
                          name="status"
                          onChange={this.onChange}
                          value={this.state.status}
                          className="form-control form-control-sm"
                        >
                          <option value="Active">Active</option>
                          <option value="Inactive">De Active</option>
                        </select>
                      </Form.Group>

                      <div className="col-12 mt-5">
                        {this.state.checkedList.includes("single") ? (
                          <Form.Group className="col-12">
                            <Form.Label>Level Name</Form.Label>
                            <div className="input-group ">
                              <input
                                onKeyPress={this.CheckKey}
                                className="form-control form-control-sm"
                                type="text"
                                placeholder="Single Level"
                                value={this.state.level}
                                name="level"
                                onChange={this.onChange}
                              ></input>
                              {/* <div className="input-group-prepend">
                              <span className="input-group-text" id="basic-addon1">
                                <i
                                  className="flaticon-qr-code"
                                  style={{ fontSize: "12.5px" }}
                                ></i>
                              </span>
                            </div> */}
                            </div>
                          </Form.Group>
                        ) : null}
                        {this.state.checkedList.includes("multiple") ? (
                          <div className="row">
                            <Form.Group className="col-6">
                              <Form.Label>From</Form.Label>
                              <select
                                name="sendFrom"
                                onChange={this.onChange}
                                value={this.state.sendFrom}
                                className="form-select form-control form-control-sm"
                              >
                                <option value="" disabled selected hidden>
                                  Select a Level From
                                </option>

                                {new Array(150).fill(150).map((v, k) => (
                                  <option value={k + 1}>{k + 1}</option>
                                ))}
                              </select>
                            </Form.Group>
                            <Form.Group className="col-6">
                              <Form.Label>To</Form.Label>
                              <select
                                name="sendTo"
                                onChange={this.onChange}
                                value={this.state.sendTo}
                                className="form-select form-control form-control-sm"
                              >
                                {new Array(150).fill(150).map((v, k) => {
                                  if (k >= parseInt(this.state.sendFrom)) {
                                    return (
                                      <option value={k + 1}>{k + 1}</option>
                                    );
                                  }
                                })}
                              </select>
                            </Form.Group>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </CardBody>
                <div>
                  <button
                    className="btn btn-primary  mr-4 mb-2"
                    onClick={() => this.Save()}
                    style={{ float: "right" }}
                  >
                    <span className="svg-icon menu-icon mr-2">
                      <svg
                        viewBox="0 0 24 24"
                        version="1.1"
                        className="kt-svg-icon"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <polygon id="Shape" points="0 0 24 0 24 24 0 24" />
                          <path
                            d="M17,4 L6,4 C4.79111111,4 4,4.7 4,6 L4,18 C4,19.3 4.79111111,20 6,20 L18,20 C19.2,20 20,19.3 20,18 L20,7.20710678 C20,7.07449854 19.9473216,6.94732158 19.8535534,6.85355339 L17,4 Z M17,11 L7,11 L7,4 L17,4 L17,11 Z"
                            id="Shape"
                            fill="#000000"
                            fillRule="nonzero"
                          />
                          <rect
                            id="Rectangle-16"
                            fill="#000000"
                            opacity="0.3"
                            x="12"
                            y="4"
                            width="3"
                            height="5"
                            rx="0.5"
                          />
                        </g>
                      </svg>
                    </span>
                    Create
                  </button>

                  <button
                    className="btn btn-danger mr-3 mb-2"
                    onClick={() => this.AddData()}
                    name="cancel"
                    style={{ float: "right" }}
                  >
                    <span className="svg-icon menu-icon mr-2">
                      <svg
                        viewBox="0 0 24 24"
                        version="1.1"
                        className="kt-svg-icon"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <g
                            id="Group"
                            transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)"
                            fill="#000000"
                          >
                            <rect
                              id="Rectangle-185"
                              x="0"
                              y="7"
                              width="16"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-185-Copy"
                              opacity="0.3"
                              transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) "
                              x="0"
                              y="7"
                              width="16"
                              height="2"
                              rx="1"
                            />
                          </g>
                        </g>
                      </svg>
                    </span>
                    Cancel
                  </button>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(UpdateParkingLevel);
