import moment from "moment";

const Htoh = (time24h) => {
  var time12 = moment(time24h, ["hh:mm A"]).format("HH:mm A");
  console.log(time12);
  var timeUTC = moment(time12, ["HH:mm A"])
    .utc()
    .format("hh:mm A");
  console.log(timeUTC);
  return timeUTC;
};
const htoH = (time12h) => {
  var timelocal = moment
    .utc(time12h, "hh:mm A")
    .local()
    .format("hh:mm A");
  var tim24h = moment(timelocal, ["hh:mm A"]).format("HH:mm");
  return tim24h;
};
export { Htoh, htoH };
