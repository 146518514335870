import React, { Component } from "react";
import { withRouter } from "react-router";
import { Alert } from "react-bootstrap";
import "react-confirm-alert/src/react-confirm-alert.css";
import Modal from "react-modal";
import _ from "lodash";
import "jspdf-autotable";
import DataGrid from "../../../../Component/DataGrid";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
class ParkingLevels extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pageNo: "1",
      itemsPerPage: "20",
      maxSize: 0,
      image: "",
      qr: "",
      totalPages: "0",
      totalItems: 0,
      sort: false,
      permissionArray: [],
      anchorEl: null,
      anchorEls: null,
      select: ["none"],
      search: "",
      csvData: [],
      list: ["Sr", "Block", "Level", "Status"],
      show: ["Sr", "Block", "Level", "Status"],
      columns: [
        {
          column_name: "Sr",
          field_name: "id",
          width: "7%",
          type: "label",
        },
        {
          column_name: "Block",
          field_name: "name",
          object_name: ["block"],
          width: "30%",
          type: "object",
        },
        {
          column_name: "Level",
          field_name: "name",
          width: "30%",
          type: "label",
        },
        {
          column_name: "Status",
          field_name: "status",
          width: "13%",
          type: "label",
        },
      ],
    };
  }

  componentWillMount() {}

  render() {
    return (
      <React.Fragment>
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            marginBottom: "20px",
          }}
        >
          <h3 style={{ marginBottom: "2%" }}>Parking Levels</h3>
        </div>
        <div className="page-content">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <DataGrid
                end_point="parking_level"
                title="Parking Level"
                file_name="Parking Level Report"
                new_action="on_different_page"
                new_record_url="/parking/parking-level/add"
                edit_record_url="/parking/parking-level/update"
                title_small="parking level"
                list={this.state.list}
                columns={this.state.columns}
                permission_label="parking-level"
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(ParkingLevels);
