import React, { Component } from "react";
import { withRouter } from "react-router";
import axios from "axios";
import isEmpty from "../../../../Validations/is-empty";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../../_metronic/_partials/controls";
import "react-confirm-alert/src/react-confirm-alert.css";
// import _ from "lodash";
import "jspdf-autotable";
import CheckPermission from "../../components/CheckPermission";
import DataGrid from "../../../Component/DataGrid";
import {
  CheckInput,
  Select,
  Select2,
  TextInput,
} from "../../../Component/Form";
import { toast } from "react-toastify";
import EnterFormElement from "../../../../Utils/CreateForm";
const URL = process.env.REACT_APP_API_URL;
var permission_name = "gate-pass";
var formData = new FormData();
class GatePass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_title: "Add Pass",
      button_title: "Add",
      action: "Save",
      passNumber: "",
      gatePassTypeId: "0",
      status: "Active",
      permissionArray: [],
      types: [],
      reload: false,
      id: 0,
      list: ["Sr", "Type", "Number", "Status"],
      show: ["Sr", "Type", "Number", "Status"],
      columns: [
        {
          column_name: "Sr",
          field_name: "id",
          width: "10%",
          type: "label",
        },
        {
          column_name: "Type",
          field_name: "custom_gate_pass_type",
          width: "25%",
          object_name: ["gatePassType"],
          type: "object",
        },
        {
          column_name: "Number",
          field_name: "pass_number",
          width: "25%",
          type: "label",
        },
        {
          column_name: "Status",
          field_name: "status",
          width: "20%",
          type: "label",
        },
      ],
      statuses: [
        {
          value: "Active",
          label: "Active",
        },
        {
          value: "Inactive",
          label: "In Active",
        },
      ],
      select: ["none"],
    };
    this.Save = this.Save.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  CheckKey = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      this.Save();
    }
  };

  UpdateData = (obj) => {
    this.setState({
      id: obj.id,
      passNumber: obj.pass_number,
      gatePassTypeId: obj.gate_pass_type_id,
      status: obj.status,
      modal_title: "Update Pass",
      button_title: "Update",
      action: "Update",
    });
  };

  AddData = () => {
    this.setState({
      id: 0,
      passNumber: "",
      gatePassTypeId: "0",
      status: "Active",
      modal_title: "Add Pass",
      button_title: "Add",
      action: "Save",
      reload: true,
    });
  };

  SendDataToForm = (name, type) => {
    formData = EnterFormElement(name, type, this.state[name], formData);
  };

  CreateForm = () => {
    this.SendDataToForm("passNumber", "string");
    this.SendDataToForm("gatePassTypeId", "int");
    this.SendDataToForm("status", "string");
  };

  Validation = () => {
    if (this.state.gatePassTypeId === "0") {
      toast.error("Please select pass type!");
      return false;
    } else if (isEmpty(this.state.passNumber)) {
      toast.error("Please enter pass number!");
      return false;
    } else {
      return true;
    }
  };

  componentWillMount() {
    this.GetTypes();
  }

  async Save() {
    var isValid = await this.Validation();
    if (isValid) {
      this.CreateForm();
      if (this.state.action === "Save") {
        toast.success("Creating gate pass ...");
        axios
          .post(`${URL}/api/staff/gate_pass`, formData)
          .then(async (res) => {
            this.AddData();
            toast.success("Gate pass created successfully!");
          })
          .catch((err) => {
            console.log(err.response);
          });
      } else {
        toast.success("Updating gate pass ...");
        axios
          .put(`${URL}/api/staff/gate_pass/${this.state.id}`, formData)

          .then(async (res) => {
            this.AddData();
            toast.success("Gate pass upadted successfully!");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  GetTypes = () => {
    axios
      .get(`${URL}/api/staff/gate_pass_type?page=1&limit=1000`)
      .then(async (res) => {
        var newCategories = [{ id: "0", name: "-- Select Pass Type --" }];
        for (var i = 0; i < res.data.data.length; i++) {
          if (res.data.data[i].status === "Active")
            newCategories.push({
              id: res.data.data[i].id + "",
              name: res.data.data[i].custom_gate_pass_type,
            });
        }
        await this.setState({
          types: newCategories,
        });
      });
  };

  TypeChange = async (val) => {
    this.setState({ gatePassTypeId: val.id });
  };

  render() {
    return (
      <React.Fragment>
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            marginBottom: "20px",
          }}
        >
          <span class="svg-icon svg-icon-primary svg-icon-3x">
            <svg viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <polygon points="0 0 24 0 24 24 0 24" />
                <path
                  d="M4.85714286,1 L11.7364114,1 C12.0910962,1 12.4343066,1.12568431 12.7051108,1.35473959 L17.4686994,5.3839416 C17.8056532,5.66894833 18,6.08787823 18,6.52920201 L18,19.0833333 C18,20.8738751 17.9795521,21 16.1428571,21 L4.85714286,21 C3.02044787,21 3,20.8738751 3,19.0833333 L3,2.91666667 C3,1.12612489 3.02044787,1 4.85714286,1 Z M8,12 C7.44771525,12 7,12.4477153 7,13 C7,13.5522847 7.44771525,14 8,14 L15,14 C15.5522847,14 16,13.5522847 16,13 C16,12.4477153 15.5522847,12 15,12 L8,12 Z M8,16 C7.44771525,16 7,16.4477153 7,17 C7,17.5522847 7.44771525,18 8,18 L11,18 C11.5522847,18 12,17.5522847 12,17 C12,16.4477153 11.5522847,16 11,16 L8,16 Z"
                  fill="#000000"
                  fill-rule="nonzero"
                  opacity="0.3"
                />
                <path
                  d="M6.85714286,3 L14.7364114,3 C15.0910962,3 15.4343066,3.12568431 15.7051108,3.35473959 L20.4686994,7.3839416 C20.8056532,7.66894833 21,8.08787823 21,8.52920201 L21,21.0833333 C21,22.8738751 20.9795521,23 19.1428571,23 L6.85714286,23 C5.02044787,23 5,22.8738751 5,21.0833333 L5,4.91666667 C5,3.12612489 5.02044787,3 6.85714286,3 Z M8,12 C7.44771525,12 7,12.4477153 7,13 C7,13.5522847 7.44771525,14 8,14 L15,14 C15.5522847,14 16,13.5522847 16,13 C16,12.4477153 15.5522847,12 15,12 L8,12 Z M8,16 C7.44771525,16 7,16.4477153 7,17 C7,17.5522847 7.44771525,18 8,18 L11,18 C11.5522847,18 12,17.5522847 12,17 C12,16.4477153 11.5522847,16 11,16 L8,16 Z"
                  fill="#000000"
                  fill-rule="nonzero"
                />
              </g>
            </svg>
          </span>
          <h3 id="modal" className="mt-3 ml-2">
            <span>Gate Passes</span>
          </h3>
        </div>
        <div className="page-content">
          <div className="row">
            {(CheckPermission(`create-${permission_name}`) ||
              (CheckPermission(`update-${permission_name}`) &&
                this.state.action !== "Save")) && (
              <div className="col-sm-12 col-md-6 col-lg-4">
                <Card>
                  <CardHeader title={this.state.modal_title}></CardHeader>
                  <CardBody>
                    <Select2
                      label="Pass Type *"
                      name="passType"
                      data={this.state.types}
                      SelectionChanged={this.TypeChange}
                      value={this.state.gatePassTypeId}
                      width="col-12"
                    />

                    <TextInput
                      label="Pass Number *"
                      name="passNumber"
                      width="col-12"
                      CheckKey={this.CheckKey}
                      type="text"
                      value={this.state.passNumber}
                      icon="flaticon-calendar"
                      onChange={this.onChange}
                      includeIcon={true}
                    />

                    <Select
                      width="col-12"
                      label="Status *"
                      name="status"
                      onChange={this.onChange}
                      value={this.state.status}
                      data={this.state.statuses}
                    />

                    <button
                      className="btn btn-primary"
                      onClick={() => this.Save()}
                      style={{ float: "right" }}
                    >
                      {this.state.button_title}
                    </button>

                    <button
                      className="btn btn-danger"
                      onClick={() => this.AddData()}
                      name="cancel"
                      style={{ float: "left" }}
                    >
                      Cancel
                    </button>
                  </CardBody>
                </Card>
              </div>
            )}

            <div className="col-sm-12 col-md-6 col-lg-8">
              <DataGrid
                end_point="gate_pass"
                title="Gate Pass"
                file_name="Gate Pass Report"
                new_action="on_same_page"
                title_small="gate pass"
                list={this.state.list}
                columns={this.state.columns}
                permission_label={permission_name}
                AddData={this.AddData}
                UpdateData={this.UpdateData}
                reload={this.state.reload}
                setReload={() => {
                  this.setState({ reload: false });
                }}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(GatePass);
