import React, { Component } from "react";
import { Avatar } from "@material-ui/core";
class ImageInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imagePreview: props.imagePreview,
      image: null,
    };
  }

  UploadFiles(e) {
    var image = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        this.setState({ imagePreview: reader.result });
      }
    };
    reader.readAsDataURL(image);
    this.setState({ image });
    this.props.getFile(image);
  }

  componentWillReceiveProps(nextProps) {}

  render() {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            position: "relative",
            width: "100%",
          }}
        >
          {this.state.imagePreview ? (
            <p
              style={{
                position: "absolute",
                top: "0%",
                fontSize: "14px",
                left: "65%",
                color: "red",
              }}
            >
              <button
                onClick={() => {
                  this.setState({
                    imagePreview: null,
                    avatar: null,
                  });
                  this.props.getFile("");
                }}
                className="btn btn-xs btn-default btn-text-primary btn-hover-danger btn-icon btn-icon-danger "
                title="Delete"
              >
                <span className="svg-icon svg-icon-md">
                  <svg viewBox="0 0 24 24" version="1.1">
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24"></rect>
                      <path
                        d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                        fill="#000000"
                        fillRule="nonzero"
                      ></path>
                      <path
                        d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                        fill="#000000"
                        opacity="0.3"
                      ></path>
                    </g>
                  </svg>
                </span>
              </button>
            </p>
          ) : (
            ""
          )}

          <Avatar
            alt="res"
            id="target"
            style={{ width: "100px", height: "100px" }}
            src={this.state.imagePreview}
            onClick={() => {
              this.imageFileInput.click();
            }}
          />
        </div>
        <p
          onClick={() => this.imageFileInput.click()}
          className="text-center mt-5"
          style={{ cursor: "pointer" }}
        >
          Select photo of {this.props.name}
        </p>

        <input
          onKeyPress={this.CheckKey}
          className="form-control form-control-sm d-none"
          onChange={(e) => this.UploadFiles(e)}
          type="file"
          ref={(fileInput) => (this.imageFileInput = fileInput)}
        ></input>
      </>
    );
  }
}
export default ImageInput;
