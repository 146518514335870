import React, { Component } from "react";
import { withRouter } from "react-router";
import { Alert } from "react-bootstrap";
import "react-confirm-alert/src/react-confirm-alert.css";
import _ from "lodash";
import "jspdf-autotable";
import DataGrid from "../../../Component/DataGrid";
class Member extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: "",
      qr: "",
      permissionArray: [],
      list: [
        "Sr",
        "Name",
        "Gender",
        "Passport",
        "Nationality",
        "Mobile",
        "DOB",
        "Status",
      ],
      show: [
        "Sr",
        "Name",
        "Gender",
        "Passport",
        "Nationality",
        "Mobile",
        "DOB",
        "Status",
      ],
      columns: [
        {
          column_name: "Sr",
          field_name: "id",
          width: "10%",
          type: "label",
        },
        {
          column_name: "Name",
          field_name: "name",
          width: "35%",
          type: "object",
          object_name: ["profile"],
        },
        {
          column_name: "Gender",
          field_name: "gender",
          width: "35%",
          type: "object",
          object_name: ["resident"],
        },
        {
          column_name: "Passport",
          field_name: "passport",
          width: "35%",
          type: "object",
          object_name: ["resident"],
        },
        {
          column_name: "Nationality",
          field_name: "nationality",
          width: "35%",
          type: "object",
          object_name: ["resident"],
        },
        {
          column_name: "Mobile",
          field_name: "mobile_no",
          width: "35%",
          type: "object",
          object_name:[ "profile"],
        },
        {
          column_name: "DOB",
          field_name: "dob",
          width: "35%",
          type: "object",
          object_name: ["resident"],
        },
        {
          column_name: "Status",
          field_name: "status",
          width: "35%",
          type: "object",
          object_name: ["resident"],
        },
      ],
      select: ["none"],
    };
  }

  render() {
    return (
      <React.Fragment>
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            marginBottom: "20px",
          }}
        >
          <i
            className="flaticon-employees mr-3 mb-3"
            style={{ fontSize: "35px", color: "#3F4254" }}
          ></i>

          <h3 style={{ marginBottom: "2%" }}>
            {/*<span className="flaticon-employees mr-3"  style={{fontSize:"35px" ,fontWeight:"bold"}}></span>*/}
            Residents{" "}
          </h3>
        </div>
        <div className="page-content">
          <div className="row">
            <div className="col-sm-6 col-md-8 col-lg-8"></div>
            <div
              className="col-sm-6 col-md-4 col-lg-4"
              style={{ float: "right" }}
            >
              {this.state.error && this.state.error ? (
                <Alert variant="danger">{this.state.error}</Alert>
              ) : null}
              {this.state.msg && this.state.msg ? (
                <Alert variant="success">{this.state.msg}</Alert>
              ) : null}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <DataGrid
                end_point="resident"
                title="Resident"
                file_name="Resident Report"
                new_action="on_different_page"
                new_record_url="/resident/add"
                edit_record_url="/resident/update"
                title_small="resident"
                list={this.state.list}
                columns={this.state.columns}
                permission_label="resident"
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Member);
