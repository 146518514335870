import * as React from "react";

const EditIcon = (props) => (
  <svg viewBox="0 0 24 24" {...props}>
    <g fill="none">
      <path d="M0 0h24v24H0z" />
      <path
        d="m21.748 7.099-8.85 9.215a1.5 1.5 0 0 1-1.05.46l-3.723.079.079-3.738a1.5 1.5 0 0 1 .442-1.033l9.05-8.999a1.5 1.5 0 0 1 2.118.003L21.727 5a1.5 1.5 0 0 1 .021 2.1Z"
        fill="#000"
      />
      <path
        d="M12.9 2a1 1 0 0 1 0 2H6a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-5a1 1 0 0 1 2 0v5a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4V6a4 4 0 0 1 4-4h6.9Z"
        fill="#000"
        opacity={0.3}
      />
    </g>
  </svg>
);

export default EditIcon;
