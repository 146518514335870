import React, { Component } from "react";
import { withRouter } from "react-router"; 
import "react-confirm-alert/src/react-confirm-alert.css"; 
import "jspdf-autotable"; 
import DataGrid from "../../../../Component/DataGrid";  
const URL = process.env.REACT_APP_API_URL;
class ParkingTypes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permissionArray: [],
      data: [],
      list: ["Sr", "Name", "Status"],
      show: ["Sr", "Name", "Status"],
      columns: [
        {
          column_name: "Sr",
          field_name: "id",
          width: "15%",
          type: "label",
        },
        {
          column_name: "Name",
          field_name: "custom_parking_type",
          width: "40%",
          type: "label",
        },
        {
          column_name: "Status",
          field_name: "status",
          width: "35%",
          type: "label",
        },
      ],
      select: ["none"],
    };
    this.onChange = this.onChange.bind(this);
  }

  componentWillMount() {}

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    return (
      <React.Fragment>
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            marginBottom: "20px",
          }}
        >
          <span class="svg-icon svg-icon-primary svg-icon-3x">
            <svg 
              viewBox="0 0 24 24"
              version="1.1"
              className="kt-svg-icon"
            >
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect x="0" y="0" width="24" height="24" />
                <rect fill="#000000" x="2" y="4" width="19" height="4" rx="1" />
                <path
                  d="M3,10 L6,10 C6.55228475,10 7,10.4477153 7,11 L7,19 C7,19.5522847 6.55228475,20 6,20 L3,20 C2.44771525,20 2,19.5522847 2,19 L2,11 C2,10.4477153 2.44771525,10 3,10 Z M10,10 L13,10 C13.5522847,10 14,10.4477153 14,11 L14,19 C14,19.5522847 13.5522847,20 13,20 L10,20 C9.44771525,20 9,19.5522847 9,19 L9,11 C9,10.4477153 9.44771525,10 10,10 Z M17,10 L20,10 C20.5522847,10 21,10.4477153 21,11 L21,19 C21,19.5522847 20.5522847,20 20,20 L17,20 C16.4477153,20 16,19.5522847 16,19 L16,11 C16,10.4477153 16.4477153,10 17,10 Z"
                  fill="#000000"
                  opacity="0.3"
                />
              </g>
            </svg>
          </span>
          <h3 id="modal"  className="mt-3 ml-2">
            <span >Parking Types</span>
          </h3>
        </div>
        <div className="page-content">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <DataGrid
                end_point="parking_type"
                title="Parking Types"
                file_name="Parking Types Report"
                new_action="on_different_page"
                new_record_url="/parking/parking-type/add"
                edit_record_url="/parking/parking-type/update"
                title_small="parking type"
                list={this.state.list}
                columns={this.state.columns}
                permission_label="parking-type"
                search_query_param="name"
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(ParkingTypes);
