import { Form } from "react-bootstrap";
import React from "react";
import Select from "react-select";
const customStyles = {
  control: (base) => ({
    ...base,
    height: 33,
    minHeight: 33,
    border: "1px solid #E4E6EF",
    borderRadius: "3px",
    fontSize: 10.5,
    fontWeight: 400,
    paddingTop: -25,
  }),
};
 const Select2 = (props) => {
  return (
    <>
      <Form.Group className={props.width}>
        <Form.Label>{props.label}</Form.Label>
        <Select
          styles={customStyles}
          name={props.name}
          options={props.data}
          onChange={props.SelectionChanged}
          value={props.data.filter(({ id }) => id === props.value.toString())}
          getOptionLabel={({ name }) => name}
          getOptionValue={({ id }) => id}
        />
      </Form.Group>
    </>
  );
};
export default Select2;