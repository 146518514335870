import React, { Component } from "react";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import axios from "axios";
import { Form, Alert, Table, Tabs, Dropdown } from "react-bootstrap";
import ReactToPrint from "react-to-print";

import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { Link } from "react-router-dom";
import Select from "react-select";
import isEmpty from "../../../../Validations/is-empty";
import moment from "moment";
const URL = process.env.REACT_APP_API_URL;

class UpdateSchedule extends Component {
  constructor(props) {
    super(props);
    var currentDate = new Date();
    var dd = String(currentDate.getDate()).padStart(2, "0");
    var mm = String(currentDate.getMonth() + 1).padStart(2, "0");
    var yyyy = currentDate.getFullYear();
    currentDate = yyyy + "-" + mm + "-" + dd;
    this.state = {
      name: "",
      description: "",
      status: "ACTIVE",
      checkpoints: [],
      data: this?.props?.location?.state?.obj,
      msg: "",
      error: "",
    };
    this.Save = this.Save.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  CheckKey = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      this.Save();
    }
  };

  AddData = () => {
    this.setState({
      name: "",
      description: "",
      status: "ACTIVE",
      routines: [
        {
          checkDate: null,
          startTime: "",
          endTime: "",
          lockTime: false,
          checkpointId: "0",
          repeat: "",
          mon: true,
          tue: true,
          wed: true,
          thu: true,
          fri: true,
          sat: true,
          sun: true,
        },
      ],
      msg: "",
      error: "",
    });
  };

  GetCheckpoints = () => {
    axios
      .get(`${URL}/api/staff/checkpoint?page=1&limit=1000`)
      .then(async (res) => {
        var newCategories = [{ id: "0", name: "-- Select Checkpoint --" }];
        for (var i = 0; i < res.data.data.length; i++) {
          newCategories.push({
            id: res.data.data[i].id + "",
            name:
              res.data.data[i].name +
              " (" +
              res.data.data[i].phone_number +
              ")",
          });
        }
        await this.setState({
          checkpoints: newCategories,
        });
      });
  };

  tConvert = (time12h) => {
    var timelocal = moment
      .utc(time12h, "hh:mm A")
      .local()
      .format("hh:mm A");
    var tim24h = moment(timelocal, ["hh:mm A"]).format("HH:mm");
    return tim24h;
  };

  tConvert1 = (time12h) => {
    var time24 = moment(time12h, ["hh:mm A"]).format("HH:mm A");
    var timeUTC = moment(time24, ["HH:mm A"])
      .utc()
      .format("hh:mm A");
    return timeUTC;
  };

  componentWillMount() {
    if (this?.props?.location?.state?.obj) {
      this.GetCheckpoints();
      var routines = [];
      for (var i = 0; i < this.state.data.routines.length; i++) {
        if (i === 0) {
          routines = [
            {
              checkDate: this.state.data.routines[i].check_date,
              startTime: this.tConvert(this.state.data.routines[i].start_time),
              endTime: this.tConvert(this.state.data.routines[i].end_time),
              lockTime: this.state.data.routines[i].lock_time,
              checkpointId: this.state.data.routines[i].checkpoint_id + "",
              repeat: this.state.data.routines[i].repeat,
              sat: this.state.data.routines[i].saturday,
              sun: this.state.data.routines[i].sunday,
              mon: this.state.data.routines[i].monday,
              tue: this.state.data.routines[i].tuesday,
              wed: this.state.data.routines[i].wednesday,
              thu: this.state.data.routines[i].thursday,
              fri: this.state.data.routines[i].friday,
              checkpoint_data: this.state.data.routines[i].checkpoint,
            },
          ];
        } else {
          routines.push({
            checkDate: this.state.data.routines[i].check_date,
            startTime: this.tConvert(this.state.data.routines[i].start_time),
            endTime: this.tConvert(this.state.data.routines[i].end_time),
            lockTime: this.state.data.routines[i].lock_time,
            checkpointId: this.state.data.routines[i].checkpoint_id + "",
            repeat: this.state.data.routines[i].repeat,
            sat: this.state.data.routines[i].saturday,
            sun: this.state.data.routines[i].sunday,
            mon: this.state.data.routines[i].monday,
            tue: this.state.data.routines[i].tuesday,
            wed: this.state.data.routines[i].wednesday,
            thu: this.state.data.routines[i].thursday,
            fri: this.state.data.routines[i].friday,
            checkpoint_data: this.state.data.routines[i].checkpoint,
          });
        }
      }
      this.setState(
        {
          name: this.state.data.name,
          description: this.state.data.description,
          status: this.state.data.status,
          routines: routines,
        },
        () => {
          console.log(this.state.routines);
        }
      );
    } else {
      this.props.history.push("/staff/schedules");
    }
  }
  Validate = () => {
    if (isEmpty(this.state.name)) {
      toast.error("Please enter name of schedule before proceeding!");
      return false;
    } else if (isEmpty(this.state.routines)) {
      toast.error("Please select at least one checkoint!");
      return false;
    } else {
      let index = this.state.routines.findIndex(
        (checkpoint) => checkpoint.checkpointId === "0"
      );
      if (index !== -1) {
        toast.error("Please select checkpoints in all routines!");
        return false;
      } else {
        return true;
      }
    }
  };
  async Save() {
    if (this.Validate()) {
    
      toast.success("Updating Schedule...");
      this.setState({
        msg: "Updating Schedule...",
        error: "",
      });

      var routines = [];
      for (var i = 0; i < this.state.routines.length; i++) {
        if (i === 0) {
          routines = [
            {
              checkDate: this.state.routines[i].checkDate,
              startTime: this.tConvert1(this.state.routines[i].startTime),
              endTime: this.tConvert1(this.state.routines[i].endTime),
              lockTime: this.state.routines[i].lockTime,
              checkpointId: this.state.routines[i].checkpointId,
              repeat: this.state.routines[i].repeat,
              saturday: this.state.routines[i].sat,
              sunday: this.state.routines[i].sun,
              monday: this.state.routines[i].mon,
              tuesday: this.state.routines[i].tue,
              wednesday: this.state.routines[i].wed,
              thursday: this.state.routines[i].thu,
              friday: this.state.routines[i].fri,
            },
          ];
        } else {
          routines.push({
            checkDate: this.state.routines[i].checkDate,
            startTime: this.tConvert1(this.state.routines[i].startTime),
            endTime: this.tConvert1(this.state.routines[i].endTime),
            lockTime: this.state.routines[i].lockTime,
            checkpointId: this.state.routines[i].checkpointId,
            repeat: this.state.routines[i].repeat,
            saturday: this.state.routines[i].sat,
            sunday: this.state.routines[i].sun,
            monday: this.state.routines[i].mon,
            tuesday: this.state.routines[i].tue,
            wednesday: this.state.routines[i].wed,
            thursday: this.state.routines[i].thu,
            friday: this.state.routines[i].fri,
          });
        }
      }
    
      var data = {
        name: this.state.name,
        status: this.state.status,
        description: this.state.description,
        routines: routines,
      };
      axios
        .put(`${URL}/api/staff/schedule/${this.state.data.id}`, data)
        .then(async (res) => {
          toast.success("Schedule Updated Succesfully");
          this.setState(
            {
              msg: "Schedule Updated Successfully!",
              error: "",
            },
            () => {
              this.props.history.push("/staff/schedules");
            }
          );
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  }
  
  CheckpointChanges = (val, event) => {
    console.log(val, "heree");
    const targetName = event.name + "";
    var routines = this.state.routines;
    var index = Number(targetName.substring(0, targetName.indexOf("-")));
    routines[index].checkpointId = val.id;
    this.setState({ routines }, () => {
      console.log(this.state.routines);
    });
  };

  async onChange(e) {
    console.log(e.target.name + ":" + e.target.value);
    const targetName = e.target.name + "";
    var routines = this.state.routines;
    if (e.target.name.includes("checkDate")) {
      var currentDate = new Date(e.target.value);
      var dd = String(currentDate.getDate()).padStart(2, "0");
      var mm = String(currentDate.getMonth() + 1).padStart(2, "0");
      var yyyy = currentDate.getFullYear();
      currentDate = yyyy + "-" + mm + "-" + dd;
      var index = Number(targetName.substring(0, targetName.indexOf("-")));
      routines[index].checkDate = currentDate;
      this.setState({ routines });
    } else if (e.target.name.includes("startTime")) {
      var index = Number(targetName.substring(0, targetName.indexOf("-")));
      routines[index].startTime = e.target.value;
      this.setState({ routines });
    } else if (e.target.name.includes("endTime")) {
      var index = Number(targetName.substring(0, targetName.indexOf("-")));
      routines[index].endTime = e.target.value;
      this.setState({ routines });
    } else if (e.target.name.includes("lockTime")) {
      var index = Number(targetName.substring(0, targetName.indexOf("-")));
      routines[index].lockTime = e.target.checked;
      this.setState({ routines });
    } else if (e.target.name.includes("repeat")) {
      var index = Number(targetName.substring(0, targetName.indexOf("-")));
      routines[index].repeat = e.target.value;
      routines[index].checkDate = "";
      if (e.target.value !== "Daily") {
        routines[index].sat = false;
        routines[index].sun = false;
        routines[index].mon = false;
        routines[index].tue = false;
        routines[index].wed = false;
        routines[index].thu = false;
        routines[index].fri = false;
      } else if (e.target.value === "Daily") {
        console.log("callles");
        routines[index].sat = true;
        routines[index].sun = true;
        routines[index].mon = true;
        routines[index].tue = true;
        routines[index].wed = true;
        routines[index].thu = true;
        routines[index].fri = true;
        routines[index].checkDate = null;
      } else {
        routines[index].checkDate = null;
      }
      await this.setState({ routines });
    } else if (e.target.name.includes("mon")) {
      var index = Number(targetName.substring(0, targetName.indexOf("-")));
      routines[index].mon = e.target.checked;
      this.setState({ routines });
    } else if (e.target.name.includes("tue")) {
      var index = Number(targetName.substring(0, targetName.indexOf("-")));
      routines[index].tue = e.target.checked;
      this.setState({ routines });
    } else if (e.target.name.includes("wed")) {
      var index = Number(targetName.substring(0, targetName.indexOf("-")));
      routines[index].wed = e.target.checked;
      this.setState({ routines });
    } else if (e.target.name.includes("thu")) {
      var index = Number(targetName.substring(0, targetName.indexOf("-")));
      routines[index].thu = e.target.checked;
      this.setState({ routines });
    } else if (e.target.name.includes("fri")) {
      var index = Number(targetName.substring(0, targetName.indexOf("-")));
      routines[index].fri = e.target.checked;
      this.setState({ routines });
    } else if (e.target.name.includes("sat")) {
      var index = Number(targetName.substring(0, targetName.indexOf("-")));
      routines[index].sat = e.target.checked;
      this.setState({ routines });
    } else if (e.target.name.includes("sun")) {
      var index = Number(targetName.substring(0, targetName.indexOf("-")));
      routines[index].sun = e.target.checked;
      this.setState({ routines });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  }

  DeleteObject(key) {
    var routines = this.state.routines;
    routines.splice(key, 1);
    this.setState({
      routines,
    });
  }

  AddRecord() {
    if (isEmpty(this.state.routines)) {
      var routines = [
        {
          checkDate: null,
          startTime: "12:00",
          endTime: "13:00",
          lockTime: false,
          checkpointId: "0",
          repeat: "Daily",
          mon: true,
          tue: true,
          wed: true,
          thu: true,
          fri: true,
          sat: true,
          sun: true,
        },
      ];
      this.setState({ routines });
    } else {
      var routines = this.state.routines;
      routines.push({
        checkDate: null,
        startTime: "12:00",
        endTime: "13:00",
        lockTime: false,
        checkpointId: "0",
        repeat: "Daily",
        mon: true,
        tue: true,
        wed: true,
        thu: true,
        fri: true,
        sat: true,
        sun: true,
      });
      this.setState({ routines });
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <div className="row">
            <div className=" col-8"></div>
            <div className=" col-4" style={{ float: "right" }}>
              {this.state.error && this.state.error ? (
                <Alert variant="danger">{this.state.error}</Alert>
              ) : null}
              {this.state.msg && this.state.msg ? (
                <Alert variant="success">{this.state.msg}</Alert>
              ) : null}
            </div>
          </div>
          <div className="row">
            <div className=" col-12">
              <Card>
                <CardHeader title="Update Schedule">
                  <CardHeaderToolbar>
                    <Link
                      type="button"
                      className="btn btn-primary"
                      name="add_button"
                      to="/staff/schedules"
                    >
                      <span className="svg-icon menu-icon mr-2">
                        <svg
                          viewBox="0 0 24 24"
                          version="1.1"
                          className="kt-svg-icon"
                        >
                          <g
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <rect
                              id="bound"
                              x="0"
                              y="0"
                              width="24"
                              height="24"
                            />
                            <path
                              d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
                              id="Combined-Shape"
                              fill="#000000"
                              opacity="0.3"
                            />
                            <path
                              d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
                              id="Combined-Shape"
                              fill="#000000"
                            />
                            <rect
                              id="Rectangle-152"
                              fill="#000000"
                              opacity="0.3"
                              x="10"
                              y="9"
                              width="7"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-152-Copy-2"
                              fill="#000000"
                              opacity="0.3"
                              x="7"
                              y="9"
                              width="2"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-152-Copy-3"
                              fill="#000000"
                              opacity="0.3"
                              x="7"
                              y="13"
                              width="2"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-152-Copy"
                              fill="#000000"
                              opacity="0.3"
                              x="10"
                              y="13"
                              width="7"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-152-Copy-5"
                              fill="#000000"
                              opacity="0.3"
                              x="7"
                              y="17"
                              width="2"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-152-Copy-4"
                              fill="#000000"
                              opacity="0.3"
                              x="10"
                              y="17"
                              width="7"
                              height="2"
                              rx="1"
                            />
                          </g>
                        </svg>
                      </span>
                      List
                    </Link>
                    <ReactToPrint
                      trigger={() => {
                        return (
                          <a className="btn btn-primary ml-5">Print Schecule</a>
                        );
                      }}
                      content={() => this.print}
                    />
                  </CardHeaderToolbar>
                </CardHeader>
                <CardBody
                  className="row schedule "
                  ref={(el) => (this.componentRef = el)}
                >
                  <div className="col-12 ">
                    <div className="row">
                      <div className="col-12">
                        <div className="row">
                          <h4 className="col-8 ">Schedule Information</h4>
                          <Form.Group
                            style={{ float: "right" }}
                            className="col-4"
                          >
                            <Form.Label className="d-none printInline">
                              Status :
                              <span className="pl-4">{this.state.status}</span>
                            </Form.Label>
                            <select
                              className="form-control form-control-sm select"
                              name="status"
                              value={this.state.status}
                              onChange={this.onChange}
                            >
                              <option value="ACTIVE">ACTIVE</option>
                              <option value="DEACTIVE">DE-ACTIVE</option>
                              <option value="SUSPENDED">SUSPENDED</option>
                              <option value="APPROVE ">APPROVED</option>
                            </select>
                          </Form.Group>
                        </div>
                      </div>
                      <Form.Group className="col-4">
                        <Form.Label className="printInline">
                          Name :{" "}
                          <span className="pl-4  d-none printInline ">
                            {this.state.name}
                          </span>
                        </Form.Label>{" "}
                        <input
                          onKeyPress={this.CheckKey}
                          className="form-control form-control-sm input"
                          type="text"
                          placeholder="Name"
                          value={this.state.name}
                          name="name"
                          onChange={this.onChange}
                        ></input>
                      </Form.Group>
                      <Form.Group className="col-8">
                        <Form.Label className="printInline">
                          Description :
                          <span className="pl-4  d-none printInline ">
                            {this.state.description}
                          </span>
                        </Form.Label>{" "}
                        <textarea
                          onKeyPress={this.CheckKey}
                          rows="1"
                          className="form-control form-control-sm textarea"
                          type="text"
                          placeholder="Description"
                          value={this.state.description}
                          name="description"
                          onChange={this.onChange}
                        ></textarea>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="col-12">
                    <h4 className="col-12 mt-2 mb-1 checkpoints">
                      Checkpoints
                    </h4>

                    <Table responsive width="100%">
                      <thead>
                        <tr>
                          <th width="18%">
                            <small>Checkpoint</small>
                          </th>
                          <th width="10%">
                            <small>Repeat</small>
                          </th>
                          <th width="5%">
                            <small>Check Date</small>
                          </th>
                          <th width="7%">
                            <small>Select Days</small>
                          </th>

                          <th width="5%">
                            <small>Start Time</small>
                          </th>
                          <th width="5%">
                            <small>End Time</small>
                          </th>
                          <th width="3%">
                            <small>Lock</small>
                          </th>
                          <th width="5%" className="text-center notPrint">
                            <button
                              onClick={() => this.AddRecord()}
                              className="btn btn-xs btn-default btn-text-primary btn-hover-primary btn-icon btn-icon-primary "
                              title="Delete"
                              ng-click="Delete(role.srNo)"
                            >
                              <span className="svg-icon svg-icon-md">
                                <svg
                                  viewBox="0 0 24 24"
                                  version="1.1"
                                  className="kt-svg-icon"
                                >
                                  <g
                                    stroke="none"
                                    strokeWidth="1"
                                    fill="none"
                                    fillRule="evenodd"
                                  >
                                    <rect
                                      id="Rectangle-185"
                                      fill="#000000"
                                      x="4"
                                      y="11"
                                      width="16"
                                      height="2"
                                      rx="1"
                                    />
                                    <rect
                                      id="Rectangle-185-Copy"
                                      fill="#000000"
                                      opacity="0.3"
                                      transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) "
                                      x="4"
                                      y="11"
                                      width="16"
                                      height="2"
                                      rx="1"
                                    />
                                  </g>
                                </svg>
                              </span>
                            </button>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.routines ? (
                          this.state.routines.map((obj, key) => (
                            <tr>
                              <td>
                                <Select
                                  name={key + "-checkpointId"}
                                  options={this.state.checkpoints}
                                  onChange={this.CheckpointChanges}
                                  value={this.state.checkpoints.filter(
                                    ({ id }) => id === obj.checkpointId
                                  )}
                                  getOptionLabel={({ name }) => name}
                                  getOptionValue={({ id }) => id}
                                />
                              </td>
                              <td>
                                <select
                                  className="form-control form-control-sm"
                                  value={obj.repeat}
                                  onChange={this.onChange}
                                  name={key + "-repeat"}
                                >
                                  <option value="Daily">Daily</option>
                                  <option value="Monthly">Monthly</option>
                                  <option value="Yearly">Yearly</option>
                                </select>
                              </td>
                              <td>
                                <input
                                  className="form-control form-control-sm"
                                  type="date"
                                  placeholder="Check Date"
                                  value={obj.checkDate}
                                  name={key + "-checkDate"}
                                  onChange={this.onChange}
                                  disabled={obj.repeat === "Daily"}
                                ></input>
                              </td>
                              <td className="d-none print labelofDaily">
                                <div>
                                  <p>sat</p>

                                  <Form.Check
                                    type="checkbox"
                                    checked={obj.sat}
                                    value={obj.sat}
                                    name="sat"
                                    onChange={this.onChange}
                                    disabled={obj.repeat !== "Daily"}
                                  />
                                </div>

                                <div>
                                  <p>Sun</p>
                                  <Form.Check
                                    type="checkbox"
                                    checked={obj.sun}
                                    value={obj.sun}
                                    name="sun"
                                    onChange={this.onChange}
                                    disabled={obj.repeat !== "Daily"}
                                  />
                                </div>
                                <div>
                                  <p>Mon</p>

                                  <Form.Check
                                    type="checkbox"
                                    checked={obj.mon}
                                    value={obj.mon}
                                    name="mon"
                                    onChange={this.onChange}
                                    disabled={obj.repeat !== "Daily"}
                                  />
                                </div>
                                <div>
                                  <p>Tue</p>

                                  <Form.Check
                                    type="checkbox"
                                    checked={obj.tue}
                                    value={obj.tue}
                                    name="tue"
                                    onChange={this.onChange}
                                    disabled={obj.repeat !== "Daily"}
                                  />
                                </div>
                                <div>
                                  <p>wed</p>

                                  <Form.Check
                                    type="checkbox"
                                    checked={obj.wed}
                                    value={obj.wed}
                                    name="wed"
                                    onChange={this.onChange}
                                    disabled={obj.repeat !== "Daily"}
                                  />
                                </div>
                                <div>
                                  <p>Thu</p>

                                  <Form.Check
                                    type="checkbox"
                                    checked={obj.thu}
                                    value={obj.thu}
                                    name="thu"
                                    onChange={this.onChange}
                                    disabled={obj.repeat !== "Daily"}
                                  />
                                </div>
                                <div>
                                  <p>Fri</p>

                                  <Form.Check
                                    type="checkbox"
                                    checked={obj.fri}
                                    value={obj.fri}
                                    name="fri"
                                    onChange={this.onChange}
                                    disabled={obj.repeat !== "Daily"}
                                  />
                                </div>
                              </td>
                              <td className="notPrint">
                                <Dropdown>
                                  <Dropdown.Toggle
                                    id="dropdown-basic"
                                    size="sm"
                                    variant="secondary"
                                  >
                                    Days
                                  </Dropdown.Toggle>
                                  {obj.repeat === "Daily" ? (
                                    <Dropdown.Menu className="p-4">
                                      <Form.Check
                                        className="mt-2"
                                        label="Saturday"
                                        type="checkbox"
                                        checked={obj.sat}
                                        value={obj.sat}
                                        name={key + "-sat"}
                                        onChange={this.onChange}
                                        disabled={obj.repeat !== "Daily"}
                                      />
                                      <Form.Check
                                        className="mt-2"
                                        label="Sunday"
                                        type="checkbox"
                                        checked={obj.sun}
                                        value={obj.sun}
                                        name={key + "-sun"}
                                        onChange={this.onChange}
                                        disabled={obj.repeat !== "Daily"}
                                      />
                                      <Form.Check
                                        className="mt-2"
                                        label="Monday"
                                        type="checkbox"
                                        checked={obj.mon}
                                        value={obj.mon}
                                        name={key + "-mon"}
                                        onChange={this.onChange}
                                        disabled={obj.repeat !== "Daily"}
                                      />
                                      <Form.Check
                                        className="mt-2"
                                        label="Tuesday"
                                        type="checkbox"
                                        checked={obj.tue}
                                        value={obj.tue}
                                        name={key + "-tue"}
                                        onChange={this.onChange}
                                        disabled={obj.repeat !== "Daily"}
                                      />
                                      <Form.Check
                                        className="mt-2"
                                        label="Wednesday"
                                        type="checkbox"
                                        checked={obj.wed}
                                        value={obj.wed}
                                        name={key + "-wed"}
                                        onChange={this.onChange}
                                        disabled={obj.repeat !== "Daily"}
                                      />
                                      <Form.Check
                                        className="mt-2"
                                        label="Thursday"
                                        type="checkbox"
                                        checked={obj.thu}
                                        value={obj.thu}
                                        name={key + "-thu"}
                                        onChange={this.onChange}
                                        disabled={obj.repeat !== "Daily"}
                                      />
                                      <Form.Check
                                        className="mt-2"
                                        label="Friday"
                                        type="checkbox"
                                        checked={obj.fri}
                                        value={obj.fri}
                                        name={key + "-fri"}
                                        onChange={this.onChange}
                                        disabled={obj.repeat !== "Daily"}
                                      />
                                    </Dropdown.Menu>
                                  ) : (
                                    ""
                                  )}
                                </Dropdown>
                              </td>
                              {/* <td>
                                <Form.Check
                                  type="checkbox"
                                  checked={obj.sat}
                                  value={obj.sat}
                                  name={key + "-sat"}
                                  onChange={this.onChange}
                                  disabled={obj.repeat !== "Daily"}
                                />
                              </td>
                              <td>
                                <Form.Check
                                  type="checkbox"
                                  checked={obj.sun}
                                  value={obj.sun}
                                  name={key + "-sun"}
                                  onChange={this.onChange}
                                  disabled={obj.repeat !== "Daily"}
                                />
                              </td>
                              <td>
                                <Form.Check
                                  type="checkbox"
                                  checked={obj.mon}
                                  value={obj.mon}
                                  name={key + "-mon"}
                                  onChange={this.onChange}
                                  disabled={obj.repeat !== "Daily"}
                                />
                              </td>
                              <td>
                                <Form.Check
                                  type="checkbox"
                                  checked={obj.tue}
                                  value={obj.tue}
                                  name={key + "-tue"}
                                  onChange={this.onChange}
                                  disabled={obj.repeat !== "Daily"}
                                />
                              </td>
                              <td>
                                <Form.Check
                                  type="checkbox"
                                  checked={obj.wed}
                                  value={obj.wed}
                                  name={key + "-wed"}
                                  onChange={this.onChange}
                                  disabled={obj.repeat !== "Daily"}
                                />
                              </td>
                              <td>
                                <Form.Check
                                  type="checkbox"
                                  checked={obj.thu}
                                  value={obj.thu}
                                  name={key + "-thu"}
                                  onChange={this.onChange}
                                  disabled={obj.repeat !== "Daily"}
                                />
                              </td>
                              <td>
                                <Form.Check
                                  type="checkbox"
                                  checked={obj.fri}
                                  value={obj.fri}
                                  name={key + "-fri"}
                                  onChange={this.onChange}
                                  disabled={obj.repeat !== "Daily"}
                                />
                              </td> */}
                              <td>
                                <input
                                  className="form-control form-control-sm"
                                  type="time"
                                  placeholder="Start Time"
                                  value={obj.startTime}
                                  name={key + "-startTime"}
                                  onChange={this.onChange}
                                ></input>
                              </td>
                              <td>
                                <input
                                  className="form-control form-control-sm"
                                  type="time"
                                  placeholder="End Time"
                                  value={obj.endTime}
                                  name={key + "-endTime"}
                                  onChange={this.onChange}
                                ></input>
                              </td>
                              <td>
                                <input
                                  type="checkbox"
                                  value={obj.lockTime}
                                  checked={obj.lockTime}
                                  name={key + "-lockTime"}
                                  onChange={this.onChange}
                                ></input>
                              </td>
                              <td className="text-center notPrint">
                                <button
                                  onClick={() => this.DeleteObject(key)}
                                  className="btn btn-xs btn-default btn-text-primary btn-hover-danger btn-icon btn-icon-danger "
                                  title="Delete"
                                  ng-click="Delete(role.srNo)"
                                >
                                  <span className="svg-icon svg-icon-md">
                                    <svg viewBox="0 0 24 24" version="1.1">
                                      <g
                                        stroke="none"
                                        strokeWidth="1"
                                        fill="none"
                                        fillRule="evenodd"
                                      >
                                        <rect
                                          x="0"
                                          y="0"
                                          width="24"
                                          height="24"
                                        ></rect>
                                        <path
                                          d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                          fill="#000000"
                                          fillRule="nonzero"
                                        ></path>
                                        <path
                                          d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                          fill="#000000"
                                          opacity="0.3"
                                        ></path>
                                      </g>
                                    </svg>
                                  </span>
                                </button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <h3>No Data Available.</h3>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
                <CardBody
                  className="row schedule schedulePrint d-none print"
                  ref={(el) => (this.print = el)}
                >
                  <div className="col-12 ">
                    <div className="row">
                      <div className="col-12">
                        <div className="row">
                          <h2 className="col-12 text-center">Schedule</h2>
                        </div>
                      </div>
                      <Form.Group className="col-8">
                        <Form.Label className="d-none printInline">
                          Name :<h3>{this.state.name}</h3>
                        </Form.Label>
                      </Form.Group>
                      <Form.Group style={{ float: "right" }} className="col-4">
                        <Form.Label className="d-none printInline">
                          Status :<h3>{this.state.status}</h3>
                        </Form.Label>
                      </Form.Group>
                      <Form.Group className="col-12">
                        <Form.Label className="d-none printInline">
                          Description :<h5>{this.state.description}</h5>
                        </Form.Label>{" "}
                      </Form.Group>
                    </div>
                  </div>
                  <div className="col-12">
                    <h4 className="col-12 mb-1 checkpoints">Checkpoints</h4>

                    <Table responsive width="100%">
                      <thead>
                        <tr>
                          <th width="43%">
                            <small>Checkpoint</small>
                          </th>
                          <th width="10%">
                            <small>Repeat</small>
                          </th>
                          <th width="10%">
                            <small>Check Date</small>
                          </th>
                          <th width="17%">
                            <small> Days</small>
                          </th>

                          <th width="8%">
                            <small>Start Time</small>
                          </th>
                          <th width="8%">
                            <small>End Time</small>
                          </th>
                          <th width="5%">
                            <small>Lock</small>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.routines ? (
                          this.state.routines.map((obj, key) => (
                            <tr>
                              <td>
                                {obj?.checkpoint_data?.name}
                                <small>
                                  <b>
                                    <label className="pl-1"> ( </label>
                                    {obj?.checkpoint_data?.phone_number}
                                    <label> )</label>
                                  </b>
                                </small>
                              </td>
                              <td>{obj.repeat}</td>
                              <td>
                                {obj.repeat !== "Daily"
                                  ? obj.checkDate
                                  : "No date"}
                              </td>
                              <td className="d-none print labelofDaily">
                                <div>
                                  <p>sat</p>

                                  <Form.Check
                                    type="checkbox"
                                    checked={obj.sat}
                                    value={obj.sat}
                                    name="sat"
                                    onChange={this.onChange}
                                    disabled={obj.repeat !== "Daily"}
                                  />
                                </div>

                                <div>
                                  <p>Sun</p>
                                  <Form.Check
                                    type="checkbox"
                                    checked={obj.sun}
                                    value={obj.sun}
                                    name="sun"
                                    onChange={this.onChange}
                                    disabled={obj.repeat !== "Daily"}
                                  />
                                </div>
                                <div>
                                  <p>Mon</p>

                                  <Form.Check
                                    type="checkbox"
                                    checked={obj.mon}
                                    value={obj.mon}
                                    name="mon"
                                    onChange={this.onChange}
                                    disabled={obj.repeat !== "Daily"}
                                  />
                                </div>
                                <div>
                                  <p>Tue</p>

                                  <Form.Check
                                    type="checkbox"
                                    checked={obj.tue}
                                    value={obj.tue}
                                    name="tue"
                                    onChange={this.onChange}
                                    disabled={obj.repeat !== "Daily"}
                                  />
                                </div>
                                <div>
                                  <p>wed</p>

                                  <Form.Check
                                    type="checkbox"
                                    checked={obj.wed}
                                    value={obj.wed}
                                    name="wed"
                                    onChange={this.onChange}
                                    disabled={obj.repeat !== "Daily"}
                                  />
                                </div>
                                <div>
                                  <p>Thu</p>

                                  <Form.Check
                                    type="checkbox"
                                    checked={obj.thu}
                                    value={obj.thu}
                                    name="thu"
                                    onChange={this.onChange}
                                    disabled={obj.repeat !== "Daily"}
                                  />
                                </div>
                                <div>
                                  <p>Fri</p>

                                  <Form.Check
                                    type="checkbox"
                                    checked={obj.fri}
                                    value={obj.fri}
                                    name="fri"
                                    onChange={this.onChange}
                                    disabled={obj.repeat !== "Daily"}
                                  />
                                </div>
                              </td>
                              <td className="notPrint">
                                <Dropdown>
                                  <Dropdown.Toggle
                                    id="dropdown-basic"
                                    size="sm"
                                    variant="secondary"
                                  >
                                    Days
                                  </Dropdown.Toggle>
                                  {obj.repeat === "Daily" ? (
                                    <Dropdown.Menu className="p-4">
                                      <Form.Check
                                        className="mt-2"
                                        label="Saturday"
                                        type="checkbox"
                                        checked={obj.sat}
                                        value={obj.sat}
                                        name={key + "-sat"}
                                        onChange={this.onChange}
                                        disabled={obj.repeat !== "Daily"}
                                      />
                                      <Form.Check
                                        className="mt-2"
                                        label="Sunday"
                                        type="checkbox"
                                        checked={obj.sun}
                                        value={obj.sun}
                                        name={key + "-sun"}
                                        onChange={this.onChange}
                                        disabled={obj.repeat !== "Daily"}
                                      />
                                      <Form.Check
                                        className="mt-2"
                                        label="Monday"
                                        type="checkbox"
                                        checked={obj.mon}
                                        value={obj.mon}
                                        name={key + "-mon"}
                                        onChange={this.onChange}
                                        disabled={obj.repeat !== "Daily"}
                                      />
                                      <Form.Check
                                        className="mt-2"
                                        label="Tuesday"
                                        type="checkbox"
                                        checked={obj.tue}
                                        value={obj.tue}
                                        name={key + "-tue"}
                                        onChange={this.onChange}
                                        disabled={obj.repeat !== "Daily"}
                                      />
                                      <Form.Check
                                        className="mt-2"
                                        label="Wednesday"
                                        type="checkbox"
                                        checked={obj.wed}
                                        value={obj.wed}
                                        name={key + "-wed"}
                                        onChange={this.onChange}
                                        disabled={obj.repeat !== "Daily"}
                                      />
                                      <Form.Check
                                        className="mt-2"
                                        label="Thursday"
                                        type="checkbox"
                                        checked={obj.thu}
                                        value={obj.thu}
                                        name={key + "-thu"}
                                        onChange={this.onChange}
                                        disabled={obj.repeat !== "Daily"}
                                      />
                                      <Form.Check
                                        className="mt-2"
                                        label="Friday"
                                        type="checkbox"
                                        checked={obj.fri}
                                        value={obj.fri}
                                        name={key + "-fri"}
                                        onChange={this.onChange}
                                        disabled={obj.repeat !== "Daily"}
                                      />
                                    </Dropdown.Menu>
                                  ) : (
                                    ""
                                  )}
                                </Dropdown>
                              </td>

                              <td>
                                <input
                                  className="form-control form-control-sm"
                                  type="time"
                                  placeholder="Start Time"
                                  value={obj.startTime}
                                  name={key + "-startTime"}
                                  onChange={this.onChange}
                                ></input>
                              </td>
                              <td>
                                <input
                                  className="form-control form-control-sm"
                                  type="time"
                                  placeholder="End Time"
                                  value={obj.endTime}
                                  name={key + "-endTime"}
                                  onChange={this.onChange}
                                ></input>
                              </td>
                              <td>
                                <input
                                  type="checkbox"
                                  value={obj.lockTime}
                                  checked={obj.lockTime}
                                  name={key + "-lockTime"}
                                  onChange={this.onChange}
                                ></input>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <h3>No Data Available.</h3>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
                <div>
                  <button
                    className="btn btn-primary   mr-4 mb-2"
                    onClick={() => this.Save()}
                    style={{ float: "right" }}
                  >
                    <span className="svg-icon menu-icon mr-2">
                      <svg
                        viewBox="0 0 24 24"
                        version="1.1"
                        className="kt-svg-icon"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <polygon id="Shape" points="0 0 24 0 24 24 0 24" />
                          <path
                            d="M17,4 L6,4 C4.79111111,4 4,4.7 4,6 L4,18 C4,19.3 4.79111111,20 6,20 L18,20 C19.2,20 20,19.3 20,18 L20,7.20710678 C20,7.07449854 19.9473216,6.94732158 19.8535534,6.85355339 L17,4 Z M17,11 L7,11 L7,4 L17,4 L17,11 Z"
                            id="Shape"
                            fill="#000000"
                            fillRule="nonzero"
                          />
                          <rect
                            id="Rectangle-16"
                            fill="#000000"
                            opacity="0.3"
                            x="12"
                            y="4"
                            width="3"
                            height="5"
                            rx="0.5"
                          />
                        </g>
                      </svg>
                    </span>
                    Update
                  </button>

                  <Link
                    className="btn btn-danger mr-3 mb-2"
                    to="/staff/schedules"
                    name="cancel"
                    style={{ float: "right" }}
                  >
                    <span className="svg-icon menu-icon mr-2">
                      <svg
                        viewBox="0 0 24 24"
                        version="1.1"
                        className="kt-svg-icon"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <g
                            id="Group"
                            transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)"
                            fill="#000000"
                          >
                            <rect
                              id="Rectangle-185"
                              x="0"
                              y="7"
                              width="16"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-185-Copy"
                              opacity="0.3"
                              transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) "
                              x="0"
                              y="7"
                              width="16"
                              height="2"
                              rx="1"
                            />
                          </g>
                        </g>
                      </svg>
                    </span>
                    Cancel
                  </Link>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(UpdateSchedule);
