import React, { Component } from "react";
import { withRouter } from "react-router";
import axios from "axios";
import isEmpty from "../../../../Validations/is-empty";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../../_metronic/_partials/controls";
import "react-confirm-alert/src/react-confirm-alert.css";
// import _ from "lodash";
import "jspdf-autotable";
import CheckPermission from "../../components/CheckPermission";
import DataGrid from "../../../Component/DataGrid";
import { CheckInput, Select, TextInput } from "../../../Component/Form";
import { toast } from "react-toastify";
import EnterFormElement from "../../../../Utils/CreateForm";
const URL = process.env.REACT_APP_API_URL;
var permission_name = "gate-pass-type";
var formData = new FormData();
class GatePassType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_title: "Add Type",
      button_title: "Add",
      action: "Save",
      customGatePassType: "",
      personalGatePassAvailableInCheckin: false,
      vehicleGatePassAvailableInCheckin: false,
      status: "Active",
      permissionArray: [],
      data: [],
      reload: false,
      id: 0,
      list: ["Sr", "Name", "Status"],
      show: ["Sr", "Name", "Status"],
      columns: [
        {
          column_name: "Sr",
          field_name: "id",
          width: "15%",
          type: "label",
        },
        {
          column_name: "Name",
          field_name: "custom_gate_pass_type",
          width: "40%",
          type: "label",
        },
        {
          column_name: "Status",
          field_name: "status",
          width: "35%",
          type: "label",
        },
      ],
      statuses: [
        {
          value: "Active",
          label: "Active",
        },
        {
          value: "Inactive",
          label: "In Active",
        },
      ],
      select: ["none"],
    };
    this.Save = this.Save.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  CheckKey = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      this.Save();
    }
  };

  UpdateData = (obj) => {
    this.setState({
      id: obj.id,
      customGatePassType: obj.custom_gate_pass_type,
      personalGatePassAvailableInCheckin:
        obj.personal_gate_pass_available_in_checkin,
      vehicleGatePassAvailableInCheckin:
        obj.vehicle_gate_pass_available_in_checkin,
      status: obj.status,
      modal_title: "Update Type",
      button_title: "Update",
      action: "Update",
    });
  };

  AddData = () => {
    this.setState({
      id: 0,
      customGatePassType: "",
      personalGatePassAvailableInCheckin: false,
      vehicleGatePassAvailableInCheckin: false,
      status: "Active",
      status: "",
      modal_title: "Add Type",
      button_title: "Add",
      action: "Save",
      reload: true,
    });
  };

  SendDataToForm = (name, type) => {
    formData = EnterFormElement(name, type, this.state[name], formData);
  };

  CreateForm = () => {
    formData = new FormData();
    this.SendDataToForm("customGatePassType", "string");
    this.SendDataToForm("vehicleGatePassAvailableInCheckin", "bool");
    this.SendDataToForm("personalGatePassAvailableInCheckin", "bool");
    this.SendDataToForm("status", "string");
  };

  Validation = () => {
    if (isEmpty(this.state.customGatePassType)) {
      toast.error("Please enter name!");
      return false;
    } else {
      return true;
    }
  };

  componentWillMount() {}

  async Save() {
    var isValid = await this.Validation();
    if (isValid) {
      this.CreateForm();
      if (this.state.action === "Save") {
        toast.success("Creating gate pass type ...");
        axios
          .post(`${URL}/api/staff/gate_pass_type`, formData)
          .then(async (res) => {
            this.AddData();
            toast.success("Gate pass type created successfully!");
          })
          .catch((err) => {
            console.log(err.response);
          });
      } else {
        toast.success("Updating gate pass type...");
        axios
          .put(`${URL}/api/staff/gate_pass_type/${this.state.id}`, formData)

          .then(async (res) => {
            this.AddData();
            toast.success("Gate pass type upadted successfully!");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handlePersonalCheck = () => {
    var val = this.state.personalGatePassAvailableInCheckin;
    this.setState({ personalGatePassAvailableInCheckin: !val });
  };

  handleVehicleCheck = () => {
    var val = this.state.vehicleGatePassAvailableInCheckin;
    this.setState({ vehicleGatePassAvailableInCheckin: !val });
  };

  render() {
    return (
      <React.Fragment>
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            marginBottom: "20px",
          }}
        >
          <span class="svg-icon svg-icon-primary svg-icon-3x">
            <svg viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect x="0" y="0" width="24" height="24" />
                <path
                  d="M13,5 L15,5 L15,20 L13,20 L13,5 Z M5,5 L5,20 L3,20 C2.44771525,20 2,19.5522847 2,19 L2,6 C2,5.44771525 2.44771525,5 3,5 L5,5 Z M16,5 L18,5 L18,20 L16,20 L16,5 Z M20,5 L21,5 C21.5522847,5 22,5.44771525 22,6 L22,19 C22,19.5522847 21.5522847,20 21,20 L20,20 L20,5 Z"
                  fill="#000000"
                />
                <polygon
                  fill="#000000"
                  opacity="0.3"
                  points="9 5 9 20 7 20 7 5"
                />
              </g>
            </svg>
          </span>
          <h3 id="modal" className="mt-3 ml-2">
            <span>Gate Pass Types</span>
          </h3>
        </div>
        <div className="page-content">
          <div className="row">
            {(CheckPermission(`create-${permission_name}`) ||
              (CheckPermission(`update-${permission_name}`) &&
                this.state.action !== "Save")) && (
              <div className="col-sm-12 col-md-6 col-lg-4">
                <Card>
                  <CardHeader title={this.state.modal_title}></CardHeader>
                  <CardBody>
                    <TextInput
                      label="Type Name *"
                      name="customGatePassType"
                      width="col-12"
                      CheckKey={this.CheckKey}
                      type="text"
                      value={this.state.customGatePassType}
                      icon="flaticon-calendar"
                      onChange={this.onChange}
                      includeIcon={true}
                    />

                    <Select
                      width="col-12"
                      label="Status *"
                      name="status"
                      onChange={this.onChange}
                      value={this.state.status}
                      data={this.state.statuses}
                    />

                    <CheckInput
                      label="Personal Gate Pass Available in Checkin"
                      name="personalGatePassAvailableInCheckin"
                      width="col-12"
                      checked={this.state.personalGatePassAvailableInCheckin}
                      onChange={this.handlePersonalCheck}
                    />

                    <CheckInput
                      label="Vehical Gate Pass Available in Checkin"
                      name="vehicleGatePassAvailableInCheckin"
                      width="col-12"
                      checked={this.state.vehicleGatePassAvailableInCheckin}
                      onChange={this.handleVehicleCheck}
                    />

                    <button
                      className="btn btn-primary"
                      onClick={() => this.Save()}
                      style={{ float: "right" }}
                    >
                      {this.state.button_title}
                    </button>

                    <button
                      className="btn btn-danger"
                      onClick={() => this.AddData()}
                      name="cancel"
                      style={{ float: "left" }}
                    >
                      Cancel
                    </button>
                  </CardBody>
                </Card>
              </div>
            )}

            <div className="col-sm-12 col-md-6 col-lg-8">
              <DataGrid
                end_point="gate_pass_type"
                title="Gate Pass Type"
                file_name="Gate Pass Type Report"
                new_action="on_same_page"
                title_small="gate pass type"
                list={this.state.list}
                columns={this.state.columns}
                permission_label={permission_name}
                AddData={this.AddData}
                UpdateData={this.UpdateData}
                reload={this.state.reload}
                setReload={() => {
                  this.setState({ reload: false });
                }}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(GatePassType);
