import React, { Component } from "react";
import { withRouter } from "react-router";
import { Alert } from "react-bootstrap";
import "react-confirm-alert/src/react-confirm-alert.css";
import Modal from "react-modal";
import _ from "lodash";
import "jspdf-autotable";
import DataGrid from "../../../../Component/DataGrid";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
class ParkingLevels extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: ["Sr", "Block", "Level", "Type", "Slot", "Name", "Status"],
      columns: [
        {
          column_name: "Sr",
          field_name: "id",
          width: "7%",
          type: "label",
        },
        {
          column_name: "Block",
          field_name: "name",
          object_name: ["parkingLevel", "block"],
          width: "15%",
          type: "object",
        },
        {
          column_name: "Level",
          field_name: "name",
          object_name: ["parkingLevel"],
          width: "10%",
          type: "object",
        },
        {
          column_name: "Type",
          field_name: "custom_parking_type",
          width: "15%",
          type: "object",
          object_name: ["parkingType"],
        },
        {
          column_name: "Slot",
          field_name: "name",
          width: "10%",
          type: "label",
        },
        {
          column_name: "Name",
          field_name: "custom_slot_name",
          width: "15%",
          type: "label",
        },
        {
          column_name: "Status",
          field_name: "status",
          width: "13%",
          type: "label",
        },
      ],
    };
  }

  componentWillMount() {}

  render() {
    return (
      <React.Fragment>
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            marginBottom: "20px",
          }}
        >
          <h3 style={{ marginBottom: "2%" }}>Parking Slots</h3>
        </div>
        <div className="page-content">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <DataGrid
                end_point="parking_slot"
                title="Parking Slot"
                file_name="Parking Slot Report"
                new_action="on_different_page"
                new_record_url="/parking/parking-slot/add"
                edit_record_url="/parking/parking-slot/update"
                title_small="parking slot"
                list={this.state.list}
                columns={this.state.columns}
                permission_label="parking-slot"
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(ParkingLevels);
