import React from "react";
import { Link } from "react-router-dom";

export default function LinkButton(props) {
  return (
    <Link
      type="button"
      className={`btn ${props.className}`}
      name={props.name}
      to={props.to}
      style={props.style}
    >
      <span className="svg-icon menu-icon mr-2">{props.icon}</span>
      {props.label}
    </Link>
  );
}
