import React, { Component } from "react";
import { withRouter } from "react-router";
import RTEScreen from "../../components/RTEScreen";
const URL = process.env.REACT_APP_API_URL;
class MoveTerms extends Component {
  render() {
    return (
      <React.Fragment>
        <RTEScreen
          end_point="move_term_condition"
          title="Move In / Out  Terms & Conditions"
          short_title="move in / out  terms & conditions"
        />
      </React.Fragment>
    );
  }
}

export default withRouter(MoveTerms);
