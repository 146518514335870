import React from "react";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import QRCode from "qrcode.react";
import { Avatar } from "@material-ui/core";

class StaffCard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      qr: "",
    };
  }
  componentWillMount() {
    console.log("here", this.props.qr);
    this.setState({ qr: this.props.qr });
  }
  render() {
    console.log(this.props.img);
    return (
      <div className="row">
        <div className="col-12 text-center mb-5 mt-5">
          <img
            alt=""
            src={toAbsoluteUrl("/media/favicon2.PNG")}
            style={{ height: "13vh", width: "15%" }}
          />
        </div>

        <div className="col-6 text-center mt-5 mb-5">
          {this.props.qr ? (
            <img
              src={this.props.qr}
              style={{ height: "30vh", width: "90%" }}
              alt=""
            />
          ) : null}
        </div>
        <div className="col-6 text-center mt-5 mb-5">
          {this.props.img ? (
            <img
              src={this.props.img}
              style={{ height: "30vh", width: "90%" }}
              alt=""
            />
          ) : (
            <Avatar
              alt="res"
              id="target"
              style={{ height: "30vh", width: "90%" }}
              onClick={() => this.fileInput1.click()}
            />
          )}
        </div>
        <div className="col-3 mt-15 ">
          <p className="text-right font-weight-normal staffCard ">Name:</p>
          <p className="text-right font-weight-normal staffCard">
            Designation:
          </p>
          <p className="text-right font-weight-normal staffCard">ID:</p>
          <p className="text-right font-weight-normal staffCard">Gender:</p>
        </div>
        <div className="col-9  mt-15">
          <p className="staffCard">{this.props.name}</p>
          <p className="staffCard">{this.props.des}</p>
          <p className="staffCard">{this.props.id}</p>
          <p className="staffCard">{this.props.gender}</p>
        </div>
        <div className="col-4 mt-4 ml-5">
          <img src={toAbsoluteUrl("/media/full_logo.PNG")} />
        </div>
        <div
          className="col-7 staffCard mt-4 mr-5"
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "end",
          }}
        >
          <div> Subscript Logo and company More</div>
        </div>
      </div>
    );
  }
}

export default StaffCard;
