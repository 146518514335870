import { Form } from "react-bootstrap";
import React from "react";
const customStyles = {
  control: (base) => ({
    ...base,
    height: 33,
    minHeight: 33,
    border: "1px solid #E4E6EF",
    borderRadius: "3px",
    fontSize: 10.5,
    fontWeight: 400,
    paddingTop: -25,
  }),
};
const Select = (props) => {
  return (
    <>
      <Form.Group className={props.width}>
        <Form.Label>{props.label}</Form.Label>
        <select
          name={props.name}
          defaultValue={props.value}
          onChange={props.onChange}
          value={props.value}
          className=" form-control form-control-sm"
        >
          {props.data.map((obj, i) => (
            <option key={i} value={obj.value}>
              {obj.label} 
            </option>
          ))}
        </select>
      </Form.Group>
    </>
  );
};
export default Select;
