import React, { Component } from "react";
import { toast } from "react-toastify";
import ReactToPrint from "react-to-print";
import { withRouter } from "react-router";
import axios from "axios";
import { Form, Alert, Table } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { Link } from "react-router-dom";
import Select from "react-select";
import isEmpty from "../../../../Validations/is-empty";
import moment from "moment";

const URL = process.env.REACT_APP_API_URL;
class UpdatePatrolSchedule extends Component {
  constructor(props) {
    super(props);
    var currentDate = new Date();
    var dd = String(currentDate.getDate()).padStart(2, "0");
    var mm = String(currentDate.getMonth() + 1).padStart(2, "0");
    var yyyy = currentDate.getFullYear();
    currentDate = yyyy + "-" + mm + "-" + dd;
    this.state = {
      name: "",
      description: "",
      status: "ACTIVE",
      checkDate: "",
      startTime: "12:00",
      endTime: "12:00",
      lockTime: false,
      repeat: "Daily",
      mon: false,
      tue: false,
      wed: false,
      thu: false,
      fri: false,
      sat: false,
      sun: false,
      checkpoints: [{ priority: 0, estimated_time: "5", checkpoint: "0" }],
      checkpointsList: [],
      msg: "",
      error: "",
      obj: this.props?.location?.state?.obj,
      total_mins: "0",
    };
    this.Save = this.Save.bind(this);
    this.onChange = this.onChange.bind(this);
    this.AddRecord = this.AddRecord.bind(this);
    this.DeleteObject = this.DeleteObject.bind(this);
  }

  CheckKey = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      this.Save();
    }
  };

  AddData = () => {
    this.setState({
      name: "",
      description: "",
      status: "ACTIVE",
      checkDate: "",
      startTime: "12:00",
      endTime: "12:00",
      lockTime: false,
      repeat: "Daily",
      mon: false,
      tue: false,
      wed: false,
      thu: false,
      fri: false,
      sat: false,
      sun: false,
      checkpoints: [{ priority: 0, estimated_time: "5", checkpoint: "0" }],
      msg: "",
      error: "",
      total_mins: "0",
    });
    this.props.history.push("/staff/patrol-schedules");
  };

  GetCheckpoints = () => {
    axios
      .get(`${URL}/api/staff/checkpoint?page=1&limit=1000`)
      .then(async (res) => {
        var newCategories = [{ id: "0", name: "-- Select Checkpoint --" }];
        for (var i = 0; i < res.data.data.length; i++) {
          newCategories.push({
            id: res.data.data[i].id + "",
            name:
              res.data.data[i].name +
              " (" +
              res.data.data[i].phone_number +
              ")",
          });
        }
        await this.setState({
          checkpointsList: newCategories,
        });
      });
  };

  componentWillMount = async () => {
    if (this.props?.location?.state?.obj) {
      this.GetCheckpoints();
      var checkpoints = [];
      for (var i = 0; i < this.state.obj.checkpoints.length; i++) {
       
        if (i === 0) {
          checkpoints = [
            {
              priority: this.state.obj.checkpoints[i].pivot_priority,
              estimated_time: this.state.obj.checkpoints[i]
                .pivot_estimated_time,
              checkpoint: this.state.obj.checkpoints[i].id + "",
              checkpoint_data: this.state.obj.checkpoints[i],
            },
          ];
        } else {
          checkpoints.push({
            priority: this.state.obj.checkpoints[i].pivot_priority,
            estimated_time: this.state.obj.checkpoints[i].pivot_estimated_time,
            checkpoint: this.state.obj.checkpoints[i].id + "",
            checkpoint_data: this.state.obj.checkpoints[i],
          });
        }
        if (i === this.state.obj.checkpoints.length - 1) {
          this.setState({ checkpoints }, () => this.CalculateTotalMin());
        }
      }
      await this.setState(
        {
          name: this.state.obj.name,
          description: this.state.obj.description,
          status: this.state.obj.status,
          checkDate: this.state.obj?.routine?.check_date,
          startTime: this.tConvert(this.state.obj.routine.start_time),
          endTime: this.tConvert(this.state.obj.routine.end_time),
          lockTime: this.state.obj.routine.lock_time,
          repeat: this.state.obj.routine.repeat,
          mon: this.state.obj.routine.monday,
          tue: this.state.obj.routine.tuesday,
          wed: this.state.obj.routine.wednesday,
          thu: this.state.obj.routine.thursday,
          fri: this.state.obj.routine.friday,
          sat: this.state.obj.routine.saturday,
          sun: this.state.obj.routine.sunday,
        },
        () => {
          console.log(this.state);
        }
      );
    } else {
      this.props.history.push("/staff/patrol-schedules");
    }
  };

  tConvert = (time12h) => {
    var timelocal = moment
      .utc(time12h, "hh:mm A")
      .local()
      .format("hh:mm A");
    var tim24h = moment(timelocal, ["hh:mm A"]).format("HH:mm");
    return tim24h;
  };

  tConvert1 = (time12h) => {
    var time24 = moment(time12h, ["hh:mm A"]).format("HH:mm A");
    var timeUTC = moment(time24, ["HH:mm A"])
      .utc()
      .format("hh:mm A");
    return timeUTC;
  };

  async Save() {
    this.setState({
      msg: "Updating patrol schedule...",
      error: "",
    });
    if (this.state.name === "") {
      toast.error("Please enter name!");
    } else if (this.state.description === "") {
      toast.error("Please enter descriptoin!");
    } else if (this.state.checkDate === "" && this.state.repeat !== "Daily") {
      toast.error("Please select a check date!");
    } else if (this.state.checkpoints.length < 1) {
      toast.error("Please select a checkpoint!");
    } else {
      toast.success("Updating Patrol Schedule...");
      var data = {
        name: this.state.name,
        status: this.state.status,
        description: this.state.description,
        checkpoints: this.state.checkpoints,

        routine: {
          checkDate: this.state.checkDate,
          startTime: this.tConvert1(this.state.startTime),
          endTime: this.tConvert1(this.state.endTime),
          lockTime: this.state.lockTime,
          repeat: this.state.repeat,
          saturday: this.state.sat,
          sunday: this.state.sun,
          monday: this.state.mon,
          tuesday: this.state.tue,
          wednesday: this.state.wed,
          thursday: this.state.thu,
          friday: this.state.fri,
        },
      };
      console.log(data, "update");
      axios
        .put(`${URL}/api/staff/patrol_schedule/${this.state.obj.id}`, data)
        .then(async (res) => {
          this.setState(
            {
              msg: "Patrol Schedule Updated Successfully!",
              error: "",
            },
            () => {
              this.props.history.push("/staff/patrol-schedules");
            }
          );
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  }

  CheckpointChanges = (val, event) => {
    const targetName = event.name + "";
    var checkpoints = this.state.checkpoints;
    if (checkpoints.some((obj) => obj.checkpoint == val.id)) {
      toast.error("This checkpoint is already scheduled in this schedule");
    } else {
      var index = Number(targetName.substring(0, targetName.indexOf("-")));
      checkpoints[index].checkpoint = val.id;
      this.setState({ checkpoints });
    }
  };

  async onChange(e) {
    const targetName = e.target.name + "";
    if (e.target.name.includes("priority")) {
      var checkpoints = this.state.checkpoints;
      var index = Number(targetName.substring(0, targetName.indexOf("-")));
      checkpoints[index].priority = e.target.value;
      this.setState({ checkpoints });
    } else if (e.target.name.includes("repeat")) {
      if (e.target.value === "Daily") {
        this.setState({
          [e.target.name]: e.target.value,
          checkDate: null,
          sat: true,
          sun: true,
          mon: true,
          tues: true,
          wed: true,
          thu: true,
          fri: true,
        });
      } else {
        this.setState({
          [e.target.name]: e.target.value,
        });
      }
    } else if (e.target.name.includes("estimated_time")) {
      var checkpoints = this.state.checkpoints;
      var index = Number(targetName.substring(0, targetName.indexOf("-")));
      checkpoints[index].estimated_time = e.target.value;
      this.setState({ checkpoints });
      this.CalculateTotalMin();
    } else if (e.target.name.includes("mon")) {
      this.setState({ [e.target.name]: e.target.checked });
    } else if (e.target.name.includes("lockTime")) {
      this.setState({ [e.target.name]: e.target.checked });
    } else if (e.target.name.includes("tue")) {
      this.setState({ [e.target.name]: e.target.checked });
    } else if (e.target.name.includes("wed")) {
      this.setState({ [e.target.name]: e.target.checked });
    } else if (e.target.name.includes("thu")) {
      this.setState({ [e.target.name]: e.target.checked });
    } else if (e.target.name.includes("fri")) {
      this.setState({ [e.target.name]: e.target.checked });
    } else if (e.target.name.includes("sat")) {
      this.setState({ [e.target.name]: e.target.checked });
    } else if (e.target.name.includes("sun")) {
      this.setState({ [e.target.name]: e.target.checked });
    } else {
      console.log(e.target.value);
      this.setState({ [e.target.name]: e.target.value });
    }
  }

  DeleteObject(key) {
    var checkpoints = this.state.checkpoints;
    checkpoints.splice(key, 1);
    this.setState({ checkpoints }, () => this.CalculateTotalMin());
  }

  AddRecord() {
    if (isEmpty(this.state.checkpoints)) {
      var checkpoints = [{ checkpoint: "0", estimated_time: "5", priority: 0 }];
      this.setState({ checkpoints }, () => this.CalculateTotalMin());
    } else {
      var checkpoints = this.state.checkpoints;
      checkpoints.push({ checkpoint: "0", estimated_time: "5", priority: 0 });
      this.setState({ checkpoints }, () => this.CalculateTotalMin());
    }
  }

  CalculateTotalMin = () => {
    var checkpoints = this.state.checkpoints;
    if (checkpoints.length > 0) {
      const total_mins = checkpoints.reduce((sum, object) => {
        return sum + Number(object.estimated_time);
      }, 0);
      this.setState({ total_mins });
    } else {
      this.setState({ total_mins: 0 });
    }
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <div className="row">
            <div className="col-sm-6 col-md-8 col-lg-8"></div>
            <div
              className="col-sm-6 col-md-4 col-lg-4"
              style={{ float: "right" }}
            >
              {this.state.error && this.state.error ? (
                <Alert variant="danger">{this.state.error}</Alert>
              ) : null}
              {this.state.msg && this.state.msg ? (
                <Alert variant="success">{this.state.msg}</Alert>
              ) : null}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <Card>
                <CardHeader title="Update Patrol Schedule">
                  <CardHeaderToolbar>
                    <Link
                      type="button"
                      className="btn btn-primary"
                      name="add_button"
                      to="/staff/patrol-schedules"
                    >
                      <span className="svg-icon menu-icon mr-2">
                        <svg
                          viewBox="0 0 24 24"
                          version="1.1"
                          className="kt-svg-icon"
                        >
                          <g
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <rect
                              id="bound"
                              x="0"
                              y="0"
                              width="24"
                              height="24"
                            />
                            <path
                              d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
                              id="Combined-Shape"
                              fill="#000000"
                              opacity="0.3"
                            />
                            <path
                              d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
                              id="Combined-Shape"
                              fill="#000000"
                            />
                            <rect
                              id="Rectangle-152"
                              fill="#000000"
                              opacity="0.3"
                              x="10"
                              y="9"
                              width="7"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-152-Copy-2"
                              fill="#000000"
                              opacity="0.3"
                              x="7"
                              y="9"
                              width="2"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-152-Copy-3"
                              fill="#000000"
                              opacity="0.3"
                              x="7"
                              y="13"
                              width="2"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-152-Copy"
                              fill="#000000"
                              opacity="0.3"
                              x="10"
                              y="13"
                              width="7"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-152-Copy-5"
                              fill="#000000"
                              opacity="0.3"
                              x="7"
                              y="17"
                              width="2"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-152-Copy-4"
                              fill="#000000"
                              opacity="0.3"
                              x="10"
                              y="17"
                              width="7"
                              height="2"
                              rx="1"
                            />
                          </g>
                        </svg>
                      </span>
                      List
                    </Link>
                    <ReactToPrint
                      trigger={() => {
                        return (
                          <a className="btn btn-primary ml-5">
                            Print Patrol Schecule
                          </a>
                        );
                      }}
                      content={() => this.print}
                    />
                  </CardHeaderToolbar>
                </CardHeader>
                <CardBody
                  className="row patrol"
                  ref={(el) => (this.componentRef = el)}
                >
                  <div className="col-12">
                    <div className="row">
                      <div className="col-12">
                        <div className="row">
                          <h4 className=" col-10 ">Schedule Information</h4>
                          <Form.Group
                            style={{ float: "right" }}
                            className="col-2"
                          >
                            <Form.Label className="d-none print">
                              Status :
                              <span className="pl-4">{this.state.status}</span>
                            </Form.Label>

                            <select
                              className="form-control form-control-sm select"
                              name="status"
                              value={this.state.status}
                              onChange={this.onChange}
                            >
                              <option value="ACTIVE">ACTIVE</option>
                              <option value="DEACTIVE">DE-ACTIVE</option>
                              <option value="SUSPENDED">SUSPENDED</option>
                              <option value="APPROVE ">APPROVED</option>
                            </select>
                          </Form.Group>
                        </div>
                      </div>
                      <Form.Group className="col-4 ">
                        <Form.Label className="print">
                          Name :{" "}
                          <span className="pl-4  d-none print ">
                            {this.state.name}
                          </span>
                        </Form.Label>
                        <input
                          onKeyPress={this.CheckKey}
                          className="form-control form-control-sm input"
                          type="text"
                          placeholder="Name"
                          value={this.state.name}
                          name="name"
                          onChange={this.onChange}
                        ></input>
                      </Form.Group>
                      <Form.Group className="col-8  ">
                        <Form.Label className="print">
                          Description :
                          <span className="pl-4  d-none print ">
                            {this.state.description}
                          </span>
                        </Form.Label>
                        <textarea
                          onKeyPress={this.CheckKey}
                          rows="1"
                          className="form-control form-control-sm textarea"
                          type="text"
                          placeholder="Description"
                          value={this.state.description}
                          name="description"
                          onChange={this.onChange}
                        ></textarea>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="col-12">
                    <h4 className=" mt-2 mb-5 routine">Routine</h4>
                    <Table responsive width="100%">
                      <thead>
                        <tr>
                          <th width="20%">
                            <small>Repeat</small>
                          </th>
                          <th width="20%">
                            <small>Check Date</small>
                          </th>
                          <th width="5%">
                            <small>Sat</small>
                          </th>
                          <th width="5%">
                            <small>Sun</small>
                          </th>
                          <th width="5%">
                            <small>Mon</small>
                          </th>
                          <th width="5%">
                            <small>Tue</small>
                          </th>
                          <th width="5%">
                            <small>Wed</small>
                          </th>
                          <th width="5%">
                            <small>Thu</small>
                          </th>
                          <th width="5%">
                            <small>Fri</small>
                          </th>
                          <th width="10%">
                            <small>Start Time</small>
                          </th>
                          <th width="10%">
                            <small>End Time</small>
                          </th>
                          <th width="5%">
                            <small>Lock</small>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <select
                              className="form-control form-control-sm"
                              value={this.state.repeat}
                              onChange={(e) => {
                                this.onChange(e);
                              }}
                              name="repeat"
                            >
                              <option value="Daily">Daily</option>
                              <option value="Monthly">Monthly</option>
                              <option value="Yearly">Yearly</option>
                            </select>
                          </td>
                          <td>
                            <input
                              className="form-control form-control-sm"
                              type="date"
                              placeholder="Check Date"
                              value={this.state.checkDate}
                              name="checkDate"
                              onChange={this.onChange}
                              disabled={this.state.repeat === "Daily"}
                            ></input>
                          </td>
                          <td>
                            <Form.Check
                              type="checkbox"
                              checked={this.state.sat}
                              value={this.state.sat}
                              name="sat"
                              onChange={this.onChange}
                              disabled={this.state.repeat !== "Daily"}
                            />
                          </td>
                          <td>
                            <Form.Check
                              type="checkbox"
                              checked={this.state.sun}
                              value={this.state.sun}
                              name="sun"
                              onChange={this.onChange}
                              disabled={this.state.repeat !== "Daily"}
                            />
                          </td>
                          <td>
                            <Form.Check
                              type="checkbox"
                              checked={this.state.mon}
                              value={this.state.mon}
                              name="mon"
                              onChange={this.onChange}
                              disabled={this.state.repeat !== "Daily"}
                            />
                          </td>
                          <td>
                            <Form.Check
                              type="checkbox"
                              checked={this.state.tue}
                              value={this.state.tue}
                              name="tue"
                              onChange={this.onChange}
                              disabled={this.state.repeat !== "Daily"}
                            />
                          </td>
                          <td>
                            <Form.Check
                              type="checkbox"
                              checked={this.state.wed}
                              value={this.state.wed}
                              name="wed"
                              onChange={this.onChange}
                              disabled={this.state.repeat !== "Daily"}
                            />
                          </td>
                          <td>
                            <Form.Check
                              type="checkbox"
                              checked={this.state.thu}
                              value={this.state.thu}
                              name="thu"
                              onChange={this.onChange}
                              disabled={this.state.repeat !== "Daily"}
                            />
                          </td>
                          <td>
                            <Form.Check
                              type="checkbox"
                              checked={this.state.fri}
                              value={this.state.fri}
                              name="fri"
                              onChange={this.onChange}
                              disabled={this.state.repeat !== "Daily"}
                            />
                          </td>
                          <td>
                            <input
                              className="form-control form-control-sm"
                              type="time"
                              placeholder="Start Time"
                              value={this.state.startTime}
                              name="startTime"
                              onChange={this.onChange}
                            ></input>
                          </td>
                          <td>
                            <input
                              className="form-control form-control-sm"
                              type="time"
                              placeholder="End Time"
                              value={this.state.endTime}
                              name="endTime"
                              onChange={this.onChange}
                            ></input>
                          </td>
                          <td>
                            <input
                              type="checkbox"
                              value={this.state.lockTime}
                              checked={this.state.lockTime}
                              name="lockTime"
                              onChange={this.onChange}
                            ></input>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>

                  <div className="col-12">
                    <h4 className=" routineCheckpoint  mt-2 mb-5">
                      Checkpoints
                    </h4>

                    <Table responsive width="100%">
                      <thead>
                        <tr>
                          <th width="10%">
                            <small>Priority #</small>
                          </th>
                          <th width="65%">
                            <small>Checkpoint</small>
                          </th>
                          <th width="15%">
                            <small>
                              Estimated Time{" "}
                              <small>({this.state.total_mins} Min.)</small>
                            </small>
                          </th>
                          <th width="5%" className="text-center notPrint">
                            <button
                              onClick={() => this.AddRecord()}
                              className="btn btn-xs btn-default btn-text-primary btn-hover-primary btn-icon btn-icon-primary "
                              title="Delete"
                              ng-click="Delete(role.srNo)"
                            >
                              <span className="svg-icon svg-icon-md">
                                <svg
                                  viewBox="0 0 24 24"
                                  version="1.1"
                                  className="kt-svg-icon"
                                >
                                  <g
                                    stroke="none"
                                    strokeWidth="1"
                                    fill="none"
                                    fillRule="evenodd"
                                  >
                                    <rect
                                      id="Rectangle-185"
                                      fill="#000000"
                                      x="4"
                                      y="11"
                                      width="16"
                                      height="2"
                                      rx="1"
                                    />
                                    <rect
                                      id="Rectangle-185-Copy"
                                      fill="#000000"
                                      opacity="0.3"
                                      transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) "
                                      x="4"
                                      y="11"
                                      width="16"
                                      height="2"
                                      rx="1"
                                    />
                                  </g>
                                </svg>
                              </span>
                            </button>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.checkpoints ? (
                          this.state.checkpoints.map((obj, key) => (
                            <tr>
                              <td>
                                <input
                                  className="form-control form-control-sm"
                                  type="number"
                                  placeholder="Priority #"
                                  value={obj.priority}
                                  name={key + "-priority"}
                                  onChange={this.onChange}
                                ></input>
                              </td>
                              <td>
                                <Select
                                  name={key + "-checkpointId"}
                                  options={this.state.checkpointsList}
                                  onChange={this.CheckpointChanges}
                                  value={this.state.checkpointsList.find(
                                    (v) => v.id === obj.checkpoint
                                  )}
                                  getOptionLabel={({ name }) => name}
                                  getOptionValue={({ id }) => id}
                                />
                              </td>
                              <td>
                                <input
                                  className="form-control form-control-sm"
                                  type="text"
                                  placeholder="Estimated Time"
                                  value={obj.estimated_time}
                                  name={key + "-estimated_time"}
                                  onChange={this.onChange}
                                ></input>
                              </td>
                              <td className="text-center notPrint">
                                <button
                                  onClick={() => this.DeleteObject(key)}
                                  className="btn btn-xs btn-default btn-text-primary btn-hover-danger btn-icon btn-icon-danger "
                                  title="Delete"
                                  ng-click="Delete(role.srNo)"
                                >
                                  <span className="svg-icon svg-icon-md">
                                    <svg viewBox="0 0 24 24" version="1.1">
                                      <g
                                        stroke="none"
                                        strokeWidth="1"
                                        fill="none"
                                        fillRule="evenodd"
                                      >
                                        <rect
                                          x="0"
                                          y="0"
                                          width="24"
                                          height="24"
                                        ></rect>
                                        <path
                                          d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                          fill="#000000"
                                          fillRule="nonzero"
                                        ></path>
                                        <path
                                          d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                          fill="#000000"
                                          opacity="0.3"
                                        ></path>
                                      </g>
                                    </svg>
                                  </span>
                                </button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <h3>No Data Available.</h3>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
                <CardBody
                  className="row patrol d-none print"
                  ref={(el) => (this.print = el)}
                >
                  <div className="col-12">
                    <div className="row">
                      <div className="col-12">
                        <div className="row">
                          <h2 className="col-12 text-center">
                            Patrol Schedule
                          </h2>
                        </div>
                      </div>
                      <Form.Group className="col-8">
                        <Form.Label>
                          Name :<h3>{this.state.name}</h3>
                        </Form.Label>
                      </Form.Group>
                      <Form.Group style={{ float: "right" }} className="col-4">
                        <Form.Label>
                          Status :<h3>{this.state.status}</h3>
                        </Form.Label>
                      </Form.Group>
                      <Form.Group className="col-12">
                        <Form.Label>
                          Description :<h5>{this.state.description}</h5>
                        </Form.Label>{" "}
                      </Form.Group>
                    </div>
                  </div>
                  <div className="col-12">
                    <h4 className=" mt-2 mb-5 routine">Routine</h4>
                    <Table responsive width="100%">
                      <thead>
                        <tr>
                          <th width="20%">
                            <small>Repeat</small>
                          </th>
                          <th width="20%">
                            <small>Check Date</small>
                          </th>
                          <th width="5%">
                            <small>Sat</small>
                          </th>
                          <th width="5%">
                            <small>Sun</small>
                          </th>
                          <th width="5%">
                            <small>Mon</small>
                          </th>
                          <th width="5%">
                            <small>Tue</small>
                          </th>
                          <th width="5%">
                            <small>Wed</small>
                          </th>
                          <th width="5%">
                            <small>Thu</small>
                          </th>
                          <th width="5%">
                            <small>Fri</small>
                          </th>
                          <th width="10%">
                            <small>Start Time</small>
                          </th>
                          <th width="10%">
                            <small>End Time</small>
                          </th>
                          <th width="5%">
                            <small>Lock</small>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{this.state.repeat}</td>
                          <td>
                            {this.state.repeat !== "Daily"
                              ? this.state.checkDate
                              : "No date"}
                          </td>
                          <td>
                            <Form.Check
                              type="checkbox"
                              checked={this.state.sat}
                              value={this.state.sat}
                              name="sat"
                              onChange={this.onChange}
                              disabled={this.state.repeat !== "Daily"}
                            />
                          </td>
                          <td>
                            <Form.Check
                              type="checkbox"
                              checked={this.state.sun}
                              value={this.state.sun}
                              name="sun"
                              onChange={this.onChange}
                              disabled={this.state.repeat !== "Daily"}
                            />
                          </td>
                          <td>
                            <Form.Check
                              type="checkbox"
                              checked={this.state.mon}
                              value={this.state.mon}
                              name="mon"
                              onChange={this.onChange}
                              disabled={this.state.repeat !== "Daily"}
                            />
                          </td>
                          <td>
                            <Form.Check
                              type="checkbox"
                              checked={this.state.tue}
                              value={this.state.tue}
                              name="tue"
                              onChange={this.onChange}
                              disabled={this.state.repeat !== "Daily"}
                            />
                          </td>
                          <td>
                            <Form.Check
                              type="checkbox"
                              checked={this.state.wed}
                              value={this.state.wed}
                              name="wed"
                              onChange={this.onChange}
                              disabled={this.state.repeat !== "Daily"}
                            />
                          </td>
                          <td>
                            <Form.Check
                              type="checkbox"
                              checked={this.state.thu}
                              value={this.state.thu}
                              name="thu"
                              onChange={this.onChange}
                              disabled={this.state.repeat !== "Daily"}
                            />
                          </td>
                          <td>
                            <Form.Check
                              type="checkbox"
                              checked={this.state.fri}
                              value={this.state.fri}
                              name="fri"
                              onChange={this.onChange}
                              disabled={this.state.repeat !== "Daily"}
                            />
                          </td>
                          <td>
                            <input
                              className="form-control form-control-sm"
                              type="time"
                              placeholder="Start Time"
                              value={this.state.startTime}
                              name="startTime"
                              onChange={this.onChange}
                            ></input>
                          </td>
                          <td>
                            <input
                              className="form-control form-control-sm"
                              type="time"
                              placeholder="End Time"
                              value={this.state.endTime}
                              name="endTime"
                              onChange={this.onChange}
                            ></input>
                          </td>
                          <td>
                            <input
                              type="checkbox"
                              value={this.state.lockTime}
                              checked={this.state.lockTime}
                              name="lockTime"
                              onChange={this.onChange}
                            ></input>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>

                  <div className="col-12">
                    <h4 className=" routineCheckpoint  mt-2 mb-5">
                      Checkpoints
                    </h4>

                    <Table responsive width="100%">
                      <thead>
                        <tr>
                          <th width="10%">
                            <small>Priority #</small>
                          </th>
                          <th width="65%">
                            <small>Checkpoint</small>
                          </th>
                          <th width="15%">
                            <small>
                              Estimated Time
                              <small>({this.state.total_mins} Min.)</small>
                            </small>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.checkpoints ? (
                          this.state.checkpoints.map((obj, key) => (
                            <tr>
                              <td>
                                <input
                                  className="form-control form-control-sm"
                                  type="number"
                                  placeholder="Priority #"
                                  value={obj.priority}
                                  name={key + "-priority"}
                                  onChange={this.onChange}
                                ></input>
                              </td>
                              <td>
                                <Select
                                  styles={{
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 0,
                                    }),
                                  }}
                                  name={key + "-checkpointId"}
                                  options={this.state.checkpointsList}
                                  onChange={this.CheckpointChanges}
                                  value={this.state.checkpointsList.filter(
                                    ({ id }) => id === obj.checkpoint
                                  )}
                                  getOptionLabel={({ name }) => name}
                                  getOptionValue={({ id }) => id}
                                />
                              </td>
                              <td>
                                <input
                                  className="form-control form-control-sm"
                                  type="text"
                                  placeholder="Estimated Time"
                                  value={obj.estimated_time}
                                  name={key + "-estimated_time"}
                                  onChange={this.onChange}
                                ></input>
                              </td>
                              <td className="text-center">
                                <button
                                  onClick={() => this.DeleteObject(key)}
                                  className="btn btn-xs btn-default btn-text-primary btn-hover-danger btn-icon btn-icon-danger "
                                  title="Delete"
                                  ng-click="Delete(role.srNo)"
                                >
                                  <span className="svg-icon svg-icon-md">
                                    <svg viewBox="0 0 24 24" version="1.1">
                                      <g
                                        stroke="none"
                                        strokeWidth="1"
                                        fill="none"
                                        fillRule="evenodd"
                                      >
                                        <rect
                                          x="0"
                                          y="0"
                                          width="24"
                                          height="24"
                                        ></rect>
                                        <path
                                          d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                          fill="#000000"
                                          fillRule="nonzero"
                                        ></path>
                                        <path
                                          d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                          fill="#000000"
                                          opacity="0.3"
                                        ></path>
                                      </g>
                                    </svg>
                                  </span>
                                </button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <h3>No Data Available.</h3>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
                <div>
                  <button
                    className="btn btn-primary   mr-4 mb-2"
                    onClick={() => this.Save()}
                    style={{ float: "right" }}
                  >
                    <span className="svg-icon menu-icon mr-2">
                      <svg
                        viewBox="0 0 24 24"
                        version="1.1"
                        className="kt-svg-icon"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <polygon id="Shape" points="0 0 24 0 24 24 0 24" />
                          <path
                            d="M17,4 L6,4 C4.79111111,4 4,4.7 4,6 L4,18 C4,19.3 4.79111111,20 6,20 L18,20 C19.2,20 20,19.3 20,18 L20,7.20710678 C20,7.07449854 19.9473216,6.94732158 19.8535534,6.85355339 L17,4 Z M17,11 L7,11 L7,4 L17,4 L17,11 Z"
                            id="Shape"
                            fill="#000000"
                            fillRule="nonzero"
                          />
                          <rect
                            id="Rectangle-16"
                            fill="#000000"
                            opacity="0.3"
                            x="12"
                            y="4"
                            width="3"
                            height="5"
                            rx="0.5"
                          />
                        </g>
                      </svg>
                    </span>
                    Update
                  </button>

                  <Link
                    className="btn btn-danger mr-3 mb-2"
                    to="/staff/patrol-schedules"
                    onClick={() => this.AddData()}
                    name="cancel"
                    style={{ float: "right" }}
                  >
                    <span className="svg-icon menu-icon mr-2">
                      <svg
                        viewBox="0 0 24 24"
                        version="1.1"
                        className="kt-svg-icon"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <g
                            id="Group"
                            transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)"
                            fill="#000000"
                          >
                            <rect
                              id="Rectangle-185"
                              x="0"
                              y="7"
                              width="16"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-185-Copy"
                              opacity="0.3"
                              transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) "
                              x="0"
                              y="7"
                              width="16"
                              height="2"
                              rx="1"
                            />
                          </g>
                        </g>
                      </svg>
                    </span>
                    Cancel
                  </Link>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(UpdatePatrolSchedule);
