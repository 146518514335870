import React, { Component } from "react";
import { withRouter } from "react-router";
import axios from "axios";
import isEmpty from "../../../../Validations/is-empty";
import { Form } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { Link } from "react-router-dom";
import {
  ImageInput,
  Select2,
  TextInput,
  Select,
} from "../../../Component/Form";
import { toast } from "react-toastify";
import EnterFormElement from "../../../../Utils/CreateForm";
const URL = process.env.REACT_APP_API_URL;

var currentDate = new Date();
var dd = String(currentDate.getDate()).padStart(2, "0");
var mm = String(currentDate.getMonth() + 1).padStart(2, "0");
var yyyy = currentDate.getFullYear();
currentDate = yyyy + "-" + mm + "-" + dd;
var formData = new FormData();
class AddMember extends Component {
  componentWillMount() {
    this.GetCountry();
    this.GetUnit();
    this.GetRace();
    this.GetReligion();
  }
  constructor(props) {
    super(props);
    this.state = {
      status: "0",
      name: "",
      email: "",
      mobileNo: "",
      phone: "",
      registration_document: "0",
      registration_no: "",
      dob: currentDate,
      country: "",
      nationality: "",
      gender: "0",
      religion: "0",
      race: "0",
      note: null,
      password: "",
      password_confirmation: "",
      hasCompany: "false",
      companyName: "",
      companyPhone: "",
      companyMobile: "",
      companyEmail: "",
      image: null,
      idCard: null,
      sign: null,
      passport: "",
      unitId: "0",
      type: "resident",
      allCountry: [],
      allUnit: [],
      genders: [
        {
          value: "0",
          label: "-- Select Gender --",
        },
        {
          value: "male",
          label: "Male",
        },
        {
          value: "female",
          label: "Female",
        },
      ],
      religions: [],
      races: [],
      statuses: [
        {
          value: "Active",
          label: "Active",
        },
        {
          value: "In Active",
          label: "In Active",
        },
      ],
      document_types: [
        {
          value: "0",
          label: "-- Select Document Type --",
        },
        {
          value: "passport",
          label: "Passport",
        },
        {
          value: "ic",
          label: "ID Card",
        },
        {
          value: "driving_licence",
          label: "Driving Licence",
        },
        {
          value: "other",
          label: "Other",
        },
      ],
      resident_types: [
        {
          value: "resident",
          label: "Resident",
        },
        {
          value: "tenant",
          label: "Tenant",
        },
      ],
    };
    this.Save = this.Save.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  GetCountry = () => {
    axios.get(`${URL}/api/countries?page=1&limit=1000`).then(async (res) => {
      var newCategories = [{ id: "0", name: "-- Select Country --" }];
      for (var i = 0; i < res.data.data.length; i++) {
        newCategories.push({
          id: res.data.data[i].id + "",
          name: res.data.data[i].name,
        });
      }
      await this.setState({
        allCountry: newCategories,
      });
    });
  };
  GetRace = () => {
    axios.get(`${URL}/api/staff/race?page=1&limit=1000`).then(async (res) => {
      var newCategories = [{ id: "0", name: "-- Select Race --" }];
      for (var i = 0; i < res.data.data.length; i++) {
        if (res.data.data[i].status === "Active")
          newCategories.push({
            id: res.data.data[i].id + "",
            name: res.data.data[i].race,
          });
      }
      await this.setState({
        races: newCategories,
      });
    });
  };

  GetReligion = () => {
    axios
      .get(`${URL}/api/staff/religion?page=1&limit=1000`)
      .then(async (res) => {
        var newCategories = [{ id: "0", name: "-- Select Religion --" }];
        for (var i = 0; i < res.data.data.length; i++) {
          if (res.data.data[i].status === "Active")
            newCategories.push({
              id: res.data.data[i].id + "",
              name: res.data.data[i].religion,
            });
        }
        await this.setState({
          religions: newCategories,
        });
      });
  };

  CountryChange = async (val) => {
    this.setState({ country: val.id, nationality: val.name });
  };

  RaceChange = async (val) => {
    this.setState({ race: val.id });
  };

  ReligionChange = async (val) => {
    this.setState({ religion: val.id });
  };

  GetUnit = () => {
    axios.get(`${URL}/api/staff/unit?page=1&limit=1000`).then(async (res) => {
      var newCategories = [{ id: "0", name: "-- Select Unit --" }];
      for (var i = 0; i < res.data.data.length; i++) {
        newCategories.push({
          id: res.data.data[i].id + "",
          name: res.data.data[i].name,
        });
      }
      await this.setState({
        allUnit: newCategories,
      });
    });
  };

  UnitChange = async (val) => {
    this.setState({ unitId: val.id });
  };

  CheckKey = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      this.Save();
    }
  };

  AddData = () => {
    this.setState({
      status: "0",
      name: "",
      email: "",
      mobileNo: "",
      phone: "",
      registration_document: "0",
      registration_no: "",
      dob: currentDate,
      country: "",
      nationality: "",
      gender: "0",
      religion: "0",
      race: "0",
      note: null,
      password: "",
      password_confirmation: "",
      has_company: false,
      companyName: "",
      companyPhone: "",
      companyMobile: "",
      companyEmail: "",
      image: null,
      idCard: null,
      sign: null,
      unitId: "0",
    });
  };

  Validation = () => {
    if (this.state.unitId === "0") {
      toast.error("Please select a unit first!");
      return false;
    } else if (isEmpty(this.state.name)) {
      toast.error("Please enter name!");
      return false;
    } else if (isEmpty(this.state.email)) {
      toast.error("Please enter email!");
      return false;
    } else if (isEmpty(this.state.mobileNo)) {
      toast.error("Please enter mobile number!");
      return false;
    } else if (this.state.registration_document === "0") {
      toast.error("Please select document type!");
      return false;
    } else if (isEmpty(this.state.registration_no)) {
      toast.error("Please enter registration document number!");
      return false;
    } else if (this.state.dob === currentDate) {
      toast.error("Please select date of birth!");
      return false;
    } else if (this.state.country === "0") {
      toast.error("Please select a country!");
      return false;
    } else if (this.state.gender === "0") {
      toast.error("Please select a gender!");
      return false;
    } else if (this.state.religion === "0") {
      toast.error("Please select a religion!");
      return false;
    } else if (this.state.race === "0") {
      toast.error("Please select a race!");
      return false;
    } else if (isEmpty(this.state.password)) {
      toast.error("Please enter password!");
      return false;
    } else if (isEmpty(this.state.password_confirmation)) {
      toast.error("Please re enter password for confirmation!");
      return false;
    } else if (this.state.password !== this.state.password_confirmation) {
      toast.error("Passwords are not same!");
      return false;
    } else {
      return true;
    }
  };

  SendDataToForm = (name, type) => {
    formData = EnterFormElement(name, type, this.state[name], formData);
  };

  CreateForm = () => {
    this.SendDataToForm("name", "string");
    this.SendDataToForm("mobileNo", "string");
    this.SendDataToForm("phone", "string");
    this.SendDataToForm("email", "string");
    this.SendDataToForm("password", "string");
    this.SendDataToForm("password_confirmation", "string");
    this.SendDataToForm("registration_document", "string");
    this.SendDataToForm("registration_no", "string");
    this.SendDataToForm("passport", "string");
    this.SendDataToForm("dob", "string");
    this.SendDataToForm("country", "int");
    this.SendDataToForm("nationality", "string");
    this.SendDataToForm("gender", "string");
    this.SendDataToForm("religion", "string");
    this.SendDataToForm("race", "string");
    this.SendDataToForm("note", "string");
    this.SendDataToForm("hasCompany", "string");
    this.SendDataToForm("companyName", "string");
    this.SendDataToForm("companyPhone", "string");
    this.SendDataToForm("companyMobile", "string");
    this.SendDataToForm("companyEmail", "string");
    this.SendDataToForm("type", "string");
    this.SendDataToForm("unitId", "string");
    if (this.state.image) formData.append("image", this.state.image);
    if (this.state.sign) formData.append("sign", this.state.sign);
    if (this.state.idCard) formData.append("idCard", this.state.idCard);
  };

  async Save() {
    var isValid = await this.Validation();
    if (isValid) {
      toast.success("Creating resident...");
      formData = new FormData();
      this.CreateForm();
      axios
        .post(`${URL}/api/staff/resident`, formData)
        .then(async (res) => {
          toast.success("Resident Saved Successfully!");
          this.props.history.push("/resident");
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  }

  onChange(e) {
    if (e.target.name === "registration_no") {
      this.setState({ passport: e.target.value });
    }
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <Card>
                <CardHeader
                  title="Add Resident"
                  icon={
                    <i
                      className="flaticon-employees mr-3 mb-3"
                      style={{ fontSize: "35px", color: "#3F4254" }}
                    ></i>
                  }
                >
                  <CardHeaderToolbar>
                    <Link
                      type="button"
                      className="btn btn-primary"
                      name="add_button"
                      to="/resident"
                    >
                      <span className="svg-icon menu-icon mr-2">
                        <svg
                          viewBox="0 0 24 24"
                          version="1.1"
                          className="kt-svg-icon"
                        >
                          <g
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <rect
                              id="bound"
                              x="0"
                              y="0"
                              width="24"
                              height="24"
                            />
                            <path
                              d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
                              id="Combined-Shape"
                              fill="#000000"
                              opacity="0.3"
                            />
                            <path
                              d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
                              id="Combined-Shape"
                              fill="#000000"
                            />
                            <rect
                              id="Rectangle-152"
                              fill="#000000"
                              opacity="0.3"
                              x="10"
                              y="9"
                              width="7"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-152-Copy-2"
                              fill="#000000"
                              opacity="0.3"
                              x="7"
                              y="9"
                              width="2"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-152-Copy-3"
                              fill="#000000"
                              opacity="0.3"
                              x="7"
                              y="13"
                              width="2"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-152-Copy"
                              fill="#000000"
                              opacity="0.3"
                              x="10"
                              y="13"
                              width="7"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-152-Copy-5"
                              fill="#000000"
                              opacity="0.3"
                              x="7"
                              y="17"
                              width="2"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-152-Copy-4"
                              fill="#000000"
                              opacity="0.3"
                              x="10"
                              y="17"
                              width="7"
                              height="2"
                              rx="1"
                            />
                          </g>
                        </svg>
                      </span>
                      List
                    </Link>
                  </CardHeaderToolbar>
                </CardHeader>
                <CardBody className="row">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-md-8 col-lg-9 col-xl-10 col-sm-12">
                        <h4>Resident Detail</h4>
                      </div>
                      <Select
                        width="col-md-4 col-12 col-lg-3 col-xl-2 "
                        label="Status"
                        name="status"
                        onChange={this.onChange}
                        defaultValue={this.state.status}
                        data={this.state.statuses}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 ">
                    <div className="row">
                      <div className="col-9">
                        <div className="row">
                          <Select2
                            label="Unit *"
                            name="unit"
                            data={this.state.allUnit}
                            SelectionChanged={this.UnitChange}
                            value={this.state.unitId}
                            width="col-md-6 col-sm-12"
                          />
                          <Select
                            width="col-md-6 col-12 "
                            label="Resident Type *"
                            name="type"
                            onChange={this.onChange}
                            defaultValue={this.state.type}
                            data={this.state.resident_types}
                          />
                          <TextInput
                            label="Name *"
                            name="name"
                            width="col-md-6 col-sm-12"
                            CheckKey={this.CheckKey}
                            type="text"
                            placeholder="Enter resident name"
                            value={this.state.name}
                            icon="flaticon-user"
                            onChange={this.onChange}
                            includeIcon={true}
                          />
                          <TextInput
                            label="Email *"
                            name="email"
                            width="col-md-6 col-sm-12"
                            CheckKey={this.CheckKey}
                            type="text"
                            placeholder="Enter resident email"
                            value={this.state.email}
                            icon="flaticon-email"
                            onChange={this.onChange}
                            includeIcon={true}
                          />

                          <TextInput
                            label="Mobile No. *"
                            name="mobileNo"
                            width="col-md-6 col-sm-12"
                            CheckKey={this.CheckKey}
                            type="text"
                            placeholder="Enter resident mobile number"
                            value={this.state.mobileNo}
                            icon="flaticon-smartphone"
                            onChange={this.onChange}
                            includeIcon={true}
                          />
                          <TextInput
                            label="Phone No."
                            name="phone"
                            width="col-md-6 col-sm-12"
                            CheckKey={this.CheckKey}
                            type="text"
                            placeholder="Enter resident phone number"
                            value={this.state.phone}
                            icon="flaticon-support"
                            onChange={this.onChange}
                            includeIcon={true}
                          />
                          <Select
                            width="col-md-6 col-sm-12"
                            label="Document Type *"
                            name="registration_document"
                            onChange={this.onChange}
                            defaultValue={this.state.registration_document}
                            data={this.state.document_types}
                          />

                          <TextInput
                            label="Document No. *"
                            name="registration_no"
                            width="col-md-6 col-sm-12"
                            CheckKey={this.CheckKey}
                            type="text"
                            placeholder="Enter resident document number"
                            value={this.state.registration_no}
                            icon="flaticon-file"
                            onChange={this.onChange}
                            includeIcon={true}
                          />
                          <Select2
                            label="Nationality *"
                            name="country"
                            data={this.state.allCountry}
                            SelectionChanged={this.CountryChange}
                            value={this.state.country}
                            width="col-md-6 col-sm-12"
                          />
                          <TextInput
                            label="Date of Birth *"
                            name="dob"
                            width="col-md-6 col-sm-12"
                            CheckKey={this.CheckKey}
                            type="date"
                            value={this.state.dob}
                            icon="flaticon-calendar"
                            onChange={this.onChange}
                            includeIcon={false}
                          />

                          <Select
                            width="col-md-6 col-sm-12"
                            label="Gender *"
                            name="gender"
                            onChange={this.onChange}
                            defaultValue={this.state.gender}
                            data={this.state.genders}
                          />

                          <Select2
                            label="Religion *"
                            name="religion"
                            data={this.state.religions}
                            SelectionChanged={this.ReligionChange}
                            value={this.state.religion}
                            width="col-md-6 col-sm-12"
                          />

                          <Select2
                            label="Race *"
                            name="race"
                            data={this.state.races}
                            SelectionChanged={this.RaceChange}
                            value={this.state.race}
                            width="col-md-6 col-sm-12"
                          />

                          <TextInput
                            label="Notes"
                            name="note"
                            width="col-md-6 col-sm-12"
                            CheckKey={this.CheckKey}
                            type="text"
                            value={this.state.note}
                            icon="flaticon-file"
                            onChange={this.onChange}
                            includeIcon={true}
                          />
                          <TextInput
                            label="Password *"
                            name="password"
                            width="col-md-6 col-sm-12"
                            CheckKey={this.CheckKey}
                            type="password"
                            value={this.state.password}
                            icon="flaticon-key"
                            onChange={this.onChange}
                            includeIcon={true}
                          />
                          <TextInput
                            label="Confirm Password *"
                            name="password_confirmation"
                            width="col-md-6 col-sm-12"
                            CheckKey={this.CheckKey}
                            type="password"
                            value={this.state.password_confirmation}
                            icon="flaticon-key"
                            onChange={this.onChange}
                            includeIcon={true}
                          />
                          <Form.Group className="col-md-6 col-sm-12">
                            <Form.Label>Has Company</Form.Label>
                            <select
                              name="has_company"
                              defaultValue={this.state.has_company}
                              onChange={this.onChange}
                              className=" form-control form-control-sm"
                            >
                              <option value="" disabled>
                                select the option
                              </option>

                              <option value={"true"}>Yes</option>
                              <option value={"false"}>No</option>
                            </select>
                          </Form.Group>
                          <div className="col-md-6 col-md-12"> </div>
                          {this.state.has_company === "true" && (
                            <>
                              <TextInput
                                label="Company Name"
                                name="companyName"
                                width="col-md-6 col-sm-12"
                                CheckKey={this.CheckKey}
                                type="text"
                                placeholder="Enter company name"
                                value={this.state.companyName}
                                icon="flaticon-user"
                                onChange={this.onChange}
                                includeIcon={true}
                              />
                              <TextInput
                                label="Company Email"
                                name="companyEmail"
                                width="col-md-6 col-sm-12"
                                CheckKey={this.CheckKey}
                                type="text"
                                placeholder="Enter company email"
                                value={this.state.companyEmail}
                                icon="flaticon-email"
                                onChange={this.onChange}
                                includeIcon={true}
                              />

                              <TextInput
                                label="Company Mobile No."
                                name="companyMobile"
                                width="col-md-6 col-sm-12"
                                CheckKey={this.CheckKey}
                                type="text"
                                placeholder="Enter company mobile number"
                                value={this.state.companyMobile}
                                icon="flaticon-smartphone"
                                onChange={this.onChange}
                                includeIcon={true}
                              />
                              <TextInput
                                label="Company Phone No."
                                name="companyPhone"
                                width="col-md-6 col-sm-12"
                                CheckKey={this.CheckKey}
                                type="text"
                                placeholder="Enter company phone number"
                                value={this.state.companyPhone}
                                icon="flaticon-support"
                                onChange={this.onChange}
                                includeIcon={true}
                              />
                            </>
                          )}
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="row">
                          <Form.Group className="col-12">
                            <ImageInput
                              name="Person"
                              getFile={(file) => {
                                this.setState({ image: file });
                              }}
                            />
                          </Form.Group>
                          <Form.Group className="col-12">
                            <ImageInput
                              name="Document"
                              getFile={(file) => {
                                this.setState({ idCard: file });
                              }}
                            />
                          </Form.Group>
                          <Form.Group className="col-12">
                            <ImageInput
                              name="Signature"
                              getFile={(file) => {
                                this.setState({ sign: file });
                              }}
                            />
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
                <div>
                  <button
                    className="btn btn-primary   mr-4 mb-2"
                    onClick={() => this.Save()}
                    style={{ float: "right" }}
                  >
                    <span className="svg-icon menu-icon mr-2">
                      <svg
                        viewBox="0 0 24 24"
                        version="1.1"
                        className="kt-svg-icon"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <polygon id="Shape" points="0 0 24 0 24 24 0 24" />
                          <path
                            d="M17,4 L6,4 C4.79111111,4 4,4.7 4,6 L4,18 C4,19.3 4.79111111,20 6,20 L18,20 C19.2,20 20,19.3 20,18 L20,7.20710678 C20,7.07449854 19.9473216,6.94732158 19.8535534,6.85355339 L17,4 Z M17,11 L7,11 L7,4 L17,4 L17,11 Z"
                            id="Shape"
                            fill="#000000"
                            fillRule="nonzero"
                          />
                          <rect
                            id="Rectangle-16"
                            fill="#000000"
                            opacity="0.3"
                            x="12"
                            y="4"
                            width="3"
                            height="5"
                            rx="0.5"
                          />
                        </g>
                      </svg>
                    </span>
                    Create
                  </button>

                  <button
                    className="btn btn-danger mr-3 mb-2"
                    onClick={() => this.AddData()}
                    name="cancel"
                    style={{ float: "right" }}
                  >
                    <span className="svg-icon menu-icon mr-2">
                      <svg
                        viewBox="0 0 24 24"
                        version="1.1"
                        className="kt-svg-icon"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <g
                            id="Group"
                            transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)"
                            fill="#000000"
                          >
                            <rect
                              id="Rectangle-185"
                              x="0"
                              y="7"
                              width="16"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-185-Copy"
                              opacity="0.3"
                              transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) "
                              x="0"
                              y="7"
                              width="16"
                              height="2"
                              rx="1"
                            />
                          </g>
                        </g>
                      </svg>
                    </span>
                    Cancel
                  </button>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(AddMember);
