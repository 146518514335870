import isEmpty from "../../../Validations/is-empty";
import moment from "moment";
import {
  GetNestedObjectString,
  fetchByDotOperator,
  CalculateTime,
} from "./ObjectFunctions";
import { el } from "date-fns/locale";
export default function makeCSVData(data, columns) {
  let array = [];
  for (var i = 0; i < data.length; i++) {
    let obj = {};
    for (var j = 0; j < columns.length; j++) {
      if (columns[j].column_name === "Sr")
        obj = { ...obj, [columns[j].column_name]: i + 1 };
      else {
        if (columns[j].type === "object") {
          obj = {
            ...obj,
            [columns[j].column_name]: !isEmpty(
              fetchByDotOperator(
                data[i],
                GetNestedObjectString(columns[j].object_name) +
                  columns[j].field_name
              )
            )
              ? fetchByDotOperator(
                  data[i],
                  GetNestedObjectString(columns[j].object_name) +
                    columns[j].field_name
                )
              : "",
          };
        } else if (columns[j].type === "date") {
          obj = {
            ...obj,
            [columns[j].column_name]:
              moment
                .utc(data[i][columns[j].field_name])
                .local()
                .format("MM-DD-YYYY") !== "Invalid date"
                ? moment
                    .utc(data[i][columns[j].field_name])
                    .local()
                    .format("MM-DD-YYYY")
                : "",
          };
        } else if (columns[j].type === "date_time") {
          obj = {
            ...obj,
            [columns[j].column_name]:
              moment
                .utc(data[i][columns[j].field_name])
                .local()
                .format("MM-DD-YYYY hh:mm A") !== "Invalid date"
                ? moment
                    .utc(data[i][columns[j].field_name])
                    .local()
                    .format("MM-DD-YYYY hh:mm A")
                : "",
          };
        } else if (columns[j].type === "hour") {
          obj = {
            ...obj,
            [columns[j].column_name]:
              moment
                .utc(data[i][columns[j].field_name])
                .local()
                .format("hh:mm A") !== "Invalid time"
                ? moment
                    .utc(data[i][columns[j].field_name])
                    .local()
                    .format("hh:mm A")
                : "",
          };
        } else if (columns[j].type === "image") {
          obj = {
            ...obj,
            [columns[j].column_name]: !isEmpty(data[i][columns[j].field_name])
              ? "Have image"
              : "No image",
          };
        } else if (columns[j].type === "audio") {
          obj = {
            ...obj,
            [columns[j].column_name]: !isEmpty(data[i][columns[j].field_name])
              ? "Have audio"
              : "No audio",
          };
        } else if (columns[j].type === "time_difference") {
          obj = {
            ...obj,
            [columns[j].column_name]: !isEmpty(data[i][columns[j].field_name1])
              ? CalculateTime(
                  data[i][columns[j].field_name],
                  data[i][columns[j].field_name1]
                )
              : "On Duty",
          };
        } else if (columns[j].type === "array_in_an_object") {
          obj = {
            ...obj,
            [columns[j].column_name]:
              data[i][columns[j].object_name[0]] 
              [columns[j].object_name[1]][0][columns[j].field_name] 
          };
        } else {
          obj = {
            ...obj,
            [columns[j].column_name]: !isEmpty(data[i][columns[j].field_name])
              ? data[i][columns[j].field_name]
              : "",
          };
        }
      }
    }
    array.push(obj);
  }
  return array;
}
