import React, { useEffect, useState } from "react";

import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { Table, Form } from "react-bootstrap";
import Select from "react-select";
import { toast } from "react-toastify";

import axios from "axios";
import { Checkbox } from "@material-ui/core";

function Role() {
  let url = process.env.REACT_APP_API_URL;
  const [allPermission, setAllPermission] = useState([]);
  const [originalPremissions, setOriginalPremissions] = useState([]);
  const [roles, setRoles] = useState([]);
  const [currentRole, setCurrentRole] = useState();
  const [currentRoleId, setCurrentRoleId] = useState();
  const [permissions, setPermission] = useState([]);
  const [permissionArray, setpermissionArray] = useState([]);
  const [ReportPermissions, setReportPermissionArray] = useState([]);

  useEffect(() => {
    let array = [];
    if (JSON.parse(localStorage.getItem("permission"))) {
      JSON.parse(localStorage.getItem("permission")).map(async (v) => {
        await array.push(v.name);
      });
    }
    setpermissionArray(array);
  }, []);

  useEffect(() => {
    axios.get(`${url}/api/staff/permissions`).then(async (res) => {
      setOriginalPremissions(res.data)
      const val = res.data
        .sort((a, b) =>
          a.name.split()[0] > b.name.split()[0]
            ? 1
            : b.name.split()[0] > a.name.split()[0]
            ? -1
            : 0
        )
        ?.map((e) => {
          let name = "";
          e.name.split(" ").map((v, k) => (k !== 0 ? (name += " " + v) : " "));
          return {
            key: name,
            ...e,
          };
        });
      const other_permissions = [];
      const report_permissions = [];
      for (var i = 0; i < val.length; i++) {
        if (val[i].slug.match("report")) {
          report_permissions.push(val[i]);
        } else {
          other_permissions.push(val[i]);
        }
      }
      setReportPermissionArray(report_permissions);

      const group = [];

      other_permissions.forEach((e) => {
        const index = group.findIndex((v) => v.key === e.key);
        if (index < 0) {
          group.push({
            key: e.key,
            data: [e],
          });
        } else {
          group[index].data.push(e);
        }
      });
      setAllPermission(group);
    });
  }, []);

  useEffect(() => {
    axios.get(`${url}/api/staff/role`).then(async (res) => {
      var newCategories = [];
      for (var i = 0; i < res.data.data.length; i++) {
        newCategories.push({
          id: res.data.data[i].id + "",
          name: res.data.data[i].name,
          user_id: res.data.data[i].user_id,
        });
      }
      var final = newCategories.filter((v) => v.user_id !== null);

      await setRoles(final);
    });
  }, []);

  const dataByid = async (id) => {
    try {
      await axios
        .get(`${url}/api/staff/role/${id}`)
        .then((res) => setPermission(res.data.permissions));
    } catch (error) {
      console.log(error);
    }
  };

  const putData = async (id, array) => {
    var newArray = [];
    array.map((val) => newArray.push(val.id));

    var obj = { permissions: newArray };

    try {
      await axios
        .put(`${url}/api/staff/role/${id}`, obj)
        .then((res) => {
          toast.success("Permission Updated Succesfully");
        })
        .catch((err) => {
          if (Array.isArray(err.response.data.errors)) {
          } else {
            toast.error(err.response.data.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  const RoleChanges = (val) => {
    setCurrentRole(val.name);
    setCurrentRoleId(val.id);
    dataByid(val.id);
  };

  const onChangeHandler = (val) => {
    if (currentRole) {
      delete val.key;
      var index = permissions.findIndex((i) => i.id === val.id);
      if (index === -1) {
        var newArray1 = [...permissions, val];
        setPermission(newArray1);
      } else {
        var newArray = permissions.filter((k) => k.id !== val.id);

        setPermission(newArray);
      }
    }
  };

  const onReportChangeHandler = (val) => {
    if (currentRole) {
      var index = permissions.findIndex((i) => i.id === val.id);
      if (index === -1) {
        var newArray1 = [...permissions, val];
        setPermission(newArray1);
      } else {
        var newArray = permissions.filter((k) => k.id !== val.id);

        setPermission(newArray);
      }
    }
  };

  const updateHandler = () => {
    putData(currentRoleId, permissions);
  };

  return (
    <>
      <h3>Roles</h3>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <Card>
            <CardHeader title="Permissions">
              <CardHeaderToolbar></CardHeaderToolbar>
            </CardHeader>

            <CardBody>
              <div className="row flex flex-row justify-content-between">
                <Form.Group className="col-5">
                  <Form.Label>Select Role</Form.Label>
                  <Select
                    name="role_id"
                    options={roles}
                    onChange={RoleChanges}
                    // value={roles.filter(({ id }) => id === this.state.recipient_id)}
                    getOptionLabel={({ name }) => name}
                    getOptionValue={({ id }) => id}
                  />
                </Form.Group>
                {/* <div>
                  <button
                    className="btn  btn-primary"
                    disabled={!currentRole}
                    onClick={updateHandler}
                  >
                    Update
                  </button>
                </div> */}
              </div>
              <Table responsive>
                <thead className="permission">
                  <tr>
                    <th width="60%">Module Name</th>
                    <th
                      width="10%"
                      className="text-center text-white font-weight-normal "
                      style={{ backgroundColor: "#5B9BD5" }}
                    >
                      Create
                    </th>
                    <th
                      width="10%"
                      className="text-center text-white font-weight-normal  "
                      style={{ backgroundColor: "#4E7C31" }}
                    >
                      Delete
                    </th>
                    <th
                      width="10%"
                      className="text-center text-white font-weight-normal "
                      style={{ backgroundColor: "#4E7C31" }}
                    >
                      Update
                    </th>
                    <th
                      width="10%"
                      className="text-center text-white font-weight-normal "
                      style={{ backgroundColor: "#FF0404" }}
                    >
                      View
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {allPermission?.map((v, k) => {
                    var name = v.key;
                    return (
                      <tr
                        key={k}
                        className="permissionTable"
                        style={{ width: "100%" }}
                      >
                        <td width="30%">{name}</td>
                        <td key={k + "1"}>
                          {v.data.find((a) => a.slug.match("create")) !==
                          undefined ? (
                            <Checkbox
                              disabled={true}
                              checked={permissions.some(
                                (vi) =>
                                  vi.id ===
                                  v.data.find((a) => a.slug.match("create")).id
                              )}
                              onChange={() =>
                                onChangeHandler(
                                  v.data.find((a) => a.slug.match("create"))
                                )
                              }
                            />
                          ) : null}
                        </td>
                        <td key={k + "2"}>
                          {v.data.find((a) => a.slug.match("delete")) !==
                          undefined ? (
                            <Checkbox
                              disabled={true}
                              checked={permissions.some(
                                (vi) =>
                                  vi.id ===
                                  v.data.find((a) => a.slug.match("delete")).id
                              )}
                              onChange={() =>
                                onChangeHandler(
                                  v.data.find((a) => a.slug.match("delete"))
                                )
                              }
                            />
                          ) : null}
                        </td>
                        <td key={k + "3"}>
                          {v.data.find((a) => a.slug.match("update")) !==
                          undefined ? (
                            <Checkbox
                              disabled={true}
                              checked={permissions.some(
                                (vi) =>
                                  vi.id ===
                                  v.data.find((a) => a.slug.match("update")).id
                              )}
                              onChange={() =>
                                onChangeHandler(
                                  v.data.find((a) => a.slug.match("update"))
                                )
                              }
                            />
                          ) : null}
                        </td>
                        <td key={k + "4"}>
                          {v.data.find((a) => a.slug.match("view")) !==
                          undefined ? (
                            <Checkbox
                              disabled={true}
                              checked={permissions.some(
                                (vi) =>
                                  vi.id ===
                                  v.data.find((a) => a.slug.match("view")).id
                              )}
                              onChange={() =>
                                onChangeHandler(
                                  v.data.find((a) => a.slug.match("view"))
                                )
                              }
                            />
                          ) : null}
                        </td>
                      </tr>
                    );
                  })}
                  {ReportPermissions.length > 0 && <h4>Reports</h4>}
                  {ReportPermissions?.map((v, k) => {
                    return (
                      <tr
                        key={k + "5"}
                        className="permissionTable"
                        style={{ width: "100%" }}
                      >
                        <td>{v.name}</td>
                        <td key={k + "11"}></td>
                        <td key={k + "21"}></td>
                        <td key={k + "31"}></td>
                        <td key={k + "41"}>
                          {v.slug.match("report") !== undefined ? (
                            <Checkbox
                              disabled={true}
                              checked={permissions.some((vi) => vi.id === v.id)}
                              onChange={() => onReportChangeHandler(v)}
                            />
                          ) : null}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}
export default Role;
