import moment from "moment";
export const convertLocalToUTC = (date, format) => {
  return moment(date)
    .utc()
    .format(format);
};
export const convertUTCToLocal = (date, format) => {
  console.log(date);
  return moment
    .utc(date)
    .local()
    .format(format);
};
