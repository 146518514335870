import React, { Component } from "react";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import axios from "axios";
import { Form, Alert, Tab, Tabs } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { Link } from "react-router-dom";
const URL = process.env.REACT_APP_API_URL;

class AddCheckpoint extends Component {
  constructor(props) {
    super(props);
    var currentDate = new Date();
    var dd = String(currentDate.getDate()).padStart(2, "0");
    var mm = String(currentDate.getMonth() + 1).padStart(2, "0");
    var yyyy = currentDate.getFullYear();
    currentDate = yyyy + "-" + mm + "-" + dd;
    this.state = {
      name: "",
      code: "",
      status: "ACTIVE",
      nfcCode: "",
      phoneNumber: "",
      note: "",
      latitude: "",
      longitude: "",
      geofenceRadius: "",
      geocode: "",
      notificationAction: "On Time",
      hour: "",
      minute: "",
      reminderDatetime: currentDate,
      subject: "",
      notification: "",
      msg: "",
      error: "",
    };
    this.Save = this.Save.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  CheckKey = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      this.Save();
    }
  };

  AddData = () => {
    this.setState({
      name: "",
      code: "",
      status: "",
      nfcCode: "",
      phoneNumber: "",
      note: "",
      latitude: "",
      longitude: "",
      geofenceRadius: "",
      geocode: "",
      notificationAction: "On Time",
      hour: "",
      minute: "",
      reminderDatetime: "",
      subject: "",
      notification: "",
      msg: "",
      error: "",
    });
    this.props.history.push("/staff/checkpoint");
  };

  componentWillMount() {}

  async Save() {
    if (this.state.name === "") {
      toast.error("Please enter name!");
    } else if (this.state.phoneNumber === "") {
      toast.error("Please enter checkpoint number!");
    } else if (this.state.nfcCode === "") {
      toast.error("Please enter NFC code!");
    } else {
      toast.success("Creating checkpoint ...");
      var currentDate = new Date(this.state.reminderDatetime);
      var dd = String(currentDate.getDate()).padStart(2, "0");
      var mm = String(currentDate.getMonth() + 1).padStart(2, "0");
      var yyyy = currentDate.getFullYear();
      // currentDate = dd + '-' + mm + '-' + yyyy;
      currentDate = yyyy + "-" + mm + "-" + dd;
      var formData = new FormData();
      formData.append("name", this.state.name);
      formData.append("code", this.state.code);
      formData.append("status", this.state.status);
      formData.append("nfcCode", this.state.nfcCode);
      formData.append("phoneNumber", this.state.phoneNumber);
      formData.append("note", this.state.note);
      formData.append("latitude", this.state.latitude);
      formData.append("longitude", this.state.longitude);
      formData.append("geofenceRadius", this.state.geofenceRadius);
      formData.append("geocode", this.state.geocode);
      formData.append("notificationAction", this.state.notificationAction);
      formData.append(
        "addditional_information",
        this.state.addditional_information
      );
      formData.append("hour", this.state.hour);
      formData.append("minute", this.state.minute);
      formData.append("reminderDatetime", currentDate);
      formData.append("subject", this.state.subject);
      formData.append("notification", this.state.notification);
      axios
        .post(`${URL}/api/staff/checkpoint`, formData)
        .then(async (res) => {
          toast.success("Checkpoint Added Succesfully");

          this.setState(
            {
              msg: "Checkpoint Saved Successfully!",
              error: "",
              name: "",
              code: "",
              status: "",
              nfcCode: "",
              phoneNumber: "",
              note: "",
              latitude: "",
              longitude: "",
              geofenceRadius: "",
              geocode: "",
              notificationAction: "",
              hour: "",
              minute: "",
              reminderDatetime: "",
              subject: "",
              notification: "",
            },
            () => {
              this.props.history.push("/staff/checkpoint");
            }
          );
        })
        .catch((err) => {
          console.log(err.response);
          if (
            err?.response?.data?.errors.some((e) => e?.field === "phoneNumber")
          ) {
            toast.error("Number Should Be Unique");
          } else if (Array.isArray(err?.response?.data?.errors)) {
            toast.error(
              err?.response?.data?.errors[0]?.field +
                " should be " +
                err?.response?.data?.errors[0]?.rule
            );
          } else {
            toast.error(err?.response?.data?.message);
          }
        });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <div className="row">
            <div className="col-sm-6 col-md-8 col-lg-8"></div>
            <div
              className="col-sm-6 col-md-4 col-lg-4"
              style={{ float: "right" }}
            >
              {this.state.error && this.state.error ? (
                <Alert variant="danger">{this.state.error}</Alert>
              ) : null}
              {this.state.msg && this.state.msg ? (
                <Alert variant="success">{this.state.msg}</Alert>
              ) : null}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <Card>
                <CardHeader
                  title="Add Checkpoint"
                  icon={
                    <i
                      className="flaticon-checkpoint mr-3"
                      style={{ fontSize: "25px", color: "#3F4254" }}
                    ></i>
                  }
                >
                  <CardHeaderToolbar>
                    <Link
                      type="button"
                      className="btn btn-primary"
                      name="add_button"
                      to="/staff/checkpoint"
                    >
                      <span className="svg-icon menu-icon mr-2">
                        <svg
                          viewBox="0 0 24 24"
                          version="1.1"
                          className="kt-svg-icon"
                        >
                          <g
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <rect
                              id="bound"
                              x="0"
                              y="0"
                              width="24"
                              height="24"
                            />
                            <path
                              d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
                              id="Combined-Shape"
                              fill="#000000"
                              opacity="0.3"
                            />
                            <path
                              d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
                              id="Combined-Shape"
                              fill="#000000"
                            />
                            <rect
                              id="Rectangle-152"
                              fill="#000000"
                              opacity="0.3"
                              x="10"
                              y="9"
                              width="7"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-152-Copy-2"
                              fill="#000000"
                              opacity="0.3"
                              x="7"
                              y="9"
                              width="2"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-152-Copy-3"
                              fill="#000000"
                              opacity="0.3"
                              x="7"
                              y="13"
                              width="2"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-152-Copy"
                              fill="#000000"
                              opacity="0.3"
                              x="10"
                              y="13"
                              width="7"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-152-Copy-5"
                              fill="#000000"
                              opacity="0.3"
                              x="7"
                              y="17"
                              width="2"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-152-Copy-4"
                              fill="#000000"
                              opacity="0.3"
                              x="10"
                              y="17"
                              width="7"
                              height="2"
                              rx="1"
                            />
                          </g>
                        </svg>
                      </span>
                      List
                    </Link>
                  </CardHeaderToolbar>
                </CardHeader>

                <CardBody className="row">
                  {/* <div className="row">     */}
                  <div className="col-12">
                    <div className="row">
                      <h4 className="col-md-9 col-lg-10 col-sm-6">
                        Checkpoint Information
                      </h4>
                      <Form.Group
                        style={{ float: "right" }}
                        className="col-md-3 col-lg-2 col-sm-6 -mt-5"
                      >
                        <Form.Label>Status</Form.Label>
                        <select
                          className="form-control form-control-sm"
                          name="status"
                          value={this.state.status}
                          onChange={this.onChange}
                        >
                          <option value="ACTIVE">ACTIVE</option>
                          <option value="DEACTIVE">De-ACTIVE</option>
                          <option value="SUSPENDED">SUSPENDED</option>
                          <option value="APPROVE ">APPROVED</option>
                        </select>
                      </Form.Group>
                    </div>
                  </div>
                  {/* </div> */}
                  <div className="col-md-12 col-lg-12 col-sm-12">
                    <div className="row">
                      <Form.Group className="col-md-6 col-lg-4 col-sm-12">
                        <Form.Label>Name</Form.Label>
                        <div className="input-group ">
                          <input
                            onKeyPress={this.CheckKey}
                            className="form-control form-control-sm"
                            type="text"
                            placeholder="Name"
                            value={this.state.name}
                            name="name"
                            onChange={this.onChange}
                          ></input>
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i
                                className="flaticon-checkpoint"
                                style={{ fontSize: "13px" }}
                              ></i>
                            </span>
                          </div>
                        </div>
                      </Form.Group>
                      <Form.Group className="col-md-6 col-lg-4 col-sm-12">
                        <Form.Label>Number</Form.Label>
                        <div className="input-group ">
                          <input
                            onKeyPress={this.CheckKey}
                            className="form-control form-control-sm"
                            type="text"
                            placeholder="Number"
                            value={this.state.phoneNumber}
                            name="phoneNumber"
                            onChange={this.onChange}
                          ></input>
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text"
                              id="basic-addon1"
                            >
                              <i
                                className="flaticon-smartphone"
                                style={{ fontSize: "13px" }}
                              ></i>
                            </span>
                          </div>
                        </div>
                      </Form.Group>
                      <Form.Group className="col-md-6 col-lg-4 col-sm-12">
                        <Form.Label>NFC-Code</Form.Label>
                        <div className="input-group ">
                          <input
                            onKeyPress={this.CheckKey}
                            className="form-control form-control-sm"
                            type="text"
                            placeholder="NFC-Code"
                            value={this.state.nfcCode}
                            name="nfcCode"
                            onChange={this.onChange}
                          ></input>
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text"
                              id="basic-addon1"
                            >
                              <i
                                className="flaticon-qr-code"
                                style={{ fontSize: "13px" }}
                              ></i>
                            </span>
                          </div>
                        </div>
                      </Form.Group>

                      <Form.Group className="col-md-12 col-lg-12 col-sm-12">
                        <Form.Label>Notes</Form.Label>
                        <div className="input-group ">
                          <textarea
                            onKeyPress={this.CheckKey}
                            rows="5"
                            className="form-control form-control-sm"
                            type="text"
                            placeholder="Notes"
                            value={this.state.note}
                            name="note"
                            onChange={this.onChange}
                          ></textarea>
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="col-12">
                    <Tabs
                      defaultActiveKey="location"
                      transition={false}
                      id="noanim-tab-example"
                    >
                      <Tab eventKey="location" title="Location">
                        <div className="row mt-3">
                          <Form.Group className="col-md-6 col-lg-6 col-sm-12">
                            <Form.Label>Longitude</Form.Label>
                            <div className="input-group ">
                              <input
                                onKeyPress={this.CheckKey}
                                className="form-control form-control-sm"
                                type="text"
                                placeholder="Longitude"
                                value={this.state.longitude}
                                name="longitude"
                                onChange={this.onChange}
                              ></input>
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text"
                                  id="basic-addon1"
                                >
                                  <i
                                    className="flaticon-placeholder"
                                    style={{ fontSize: "13px" }}
                                  ></i>
                                </span>
                              </div>
                            </div>
                          </Form.Group>
                          <Form.Group className="col-md-6 col-lg-6 col-sm-12">
                            <Form.Label>Latitude</Form.Label>
                            <div className="input-group ">
                              <input
                                onKeyPress={this.CheckKey}
                                className="form-control form-control-sm"
                                type="text"
                                placeholder="Latitude"
                                value={this.state.latitude}
                                name="latitude"
                                onChange={this.onChange}
                              ></input>
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text"
                                  id="basic-addon1"
                                >
                                  <i
                                    className="flaticon-placeholder"
                                    style={{ fontSize: "13px" }}
                                  ></i>
                                </span>
                              </div>
                            </div>
                          </Form.Group>
                          <Form.Group className="col-md-6 col-lg-6 col-sm-12">
                            <Form.Label>Geofence Radius</Form.Label>
                            <div className="input-group ">
                              <input
                                onKeyPress={this.CheckKey}
                                className="form-control form-control-sm"
                                type="number"
                                placeholder="Geofence Radius"
                                value={this.state.geofenceRadius}
                                name="geofenceRadius"
                                onChange={this.onChange}
                              ></input>
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text"
                                  id="basic-addon1"
                                >
                                  <i
                                    className="flaticon-placeholder"
                                    style={{ fontSize: "13px" }}
                                  ></i>
                                </span>
                              </div>
                            </div>
                          </Form.Group>
                          <Form.Group className="col-md-6 col-lg-6 col-sm-12">
                            <Form.Label>Geocode</Form.Label>
                            <div className="input-group ">
                              <input
                                onKeyPress={this.CheckKey}
                                className="form-control form-control-sm"
                                type="text"
                                placeholder="Geocode"
                                value={this.state.geocode}
                                name="geocode"
                                onChange={this.onChange}
                              ></input>
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text"
                                  id="basic-addon1"
                                >
                                  <i
                                    className="flaticon-qr-code"
                                    style={{ fontSize: "13px" }}
                                  ></i>
                                </span>
                              </div>
                            </div>
                          </Form.Group>
                        </div>
                      </Tab>
                      <Tab eventKey="notification" title="Notification">
                        <div className="row mt-3">
                          <Form.Group className="col-md-6 col-lg-3 col-sm-12">
                            <Form.Label>Action</Form.Label>

                            <div className="input-group ">
                              <select
                                className="form-control form-control-sm"
                                name="notificationAction"
                                value={this.state.notificationAction}
                                onChange={this.onChange}
                              >
                                <option value="On Time">On Time</option>
                                <option value="Before Time">Before Time</option>
                                <option value="Before Custom Time">
                                  Before Custom Time
                                </option>
                                <option value="After Custom Time">
                                  After Custom Time
                                </option>
                              </select>
                            </div>
                          </Form.Group>
                          <Form.Group className="col-md-6 col-lg-3 col-sm-12">
                            <Form.Label>Reminder Date</Form.Label>
                            <input
                              onKeyPress={this.CheckKey}
                              className="form-control form-control-sm"
                              type="date"
                              placeholder="Reminder Date"
                              value={this.state.reminderDatetime}
                              name="reminderDatetime"
                              onChange={this.onChange}
                            ></input>
                          </Form.Group>
                          <Form.Group className="col-md-6 col-lg-3 col-sm-12">
                            <Form.Label>Hour</Form.Label>
                            <div className="input-group ">
                              <input
                                onKeyPress={this.CheckKey}
                                className="form-control form-control-sm"
                                type="number"
                                placeholder="Hour"
                                value={this.state.hour}
                                name="hour"
                                onChange={this.onChange}
                              ></input>
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text"
                                  id="basic-addon1"
                                >
                                  <i
                                    className="flaticon-24-hour-service"
                                    style={{ fontSize: "13px" }}
                                  ></i>
                                </span>
                              </div>
                            </div>
                          </Form.Group>
                          <Form.Group className="col-md-6 col-lg-3 col-sm-12">
                            <Form.Label>Minute</Form.Label>
                            <div className="input-group ">
                              <input
                                onKeyPress={this.CheckKey}
                                className="form-control form-control-sm"
                                type="number"
                                placeholder="Minute"
                                value={this.state.minute}
                                name="minute"
                                onChange={this.onChange}
                              ></input>
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text"
                                  id="basic-addon1"
                                >
                                  <i
                                    className="flaticon-60-minutes"
                                    style={{ fontSize: "13px" }}
                                  ></i>
                                </span>
                              </div>
                            </div>
                          </Form.Group>
                          <Form.Group className="col-md-12 col-lg-12 col-sm-12">
                            <Form.Label>Subject</Form.Label>
                            <div className="input-group ">
                              <input
                                onKeyPress={this.CheckKey}
                                className="form-control form-control-sm"
                                type="text"
                                placeholder="Subject"
                                value={this.state.subject}
                                name="subject"
                                onChange={this.onChange}
                              ></input>
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text"
                                  id="basic-addon1"
                                >
                                  <i
                                    className="flaticon-description"
                                    style={{ fontSize: "13px" }}
                                  ></i>
                                </span>
                              </div>
                            </div>
                          </Form.Group>
                          <Form.Group className="col-md-12 col-lg-12 col-sm-12">
                            <Form.Label>Notification</Form.Label>
                            <textarea
                              onKeyPress={this.CheckKey}
                              rows="10"
                              className="form-control form-control-sm"
                              type="text"
                              placeholder="Notification"
                              value={this.state.notification}
                              name="notification"
                              onChange={this.onChange}
                            ></textarea>
                          </Form.Group>
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </CardBody>
                <div>
                  <button
                    className="btn btn-primary   mr-4 mb-2"
                    onClick={() => this.Save()}
                    style={{ float: "right" }}
                  >
                    <span className="svg-icon menu-icon mr-2">
                      <svg
                        viewBox="0 0 24 24"
                        version="1.1"
                        className="kt-svg-icon"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <polygon id="Shape" points="0 0 24 0 24 24 0 24" />
                          <path
                            d="M17,4 L6,4 C4.79111111,4 4,4.7 4,6 L4,18 C4,19.3 4.79111111,20 6,20 L18,20 C19.2,20 20,19.3 20,18 L20,7.20710678 C20,7.07449854 19.9473216,6.94732158 19.8535534,6.85355339 L17,4 Z M17,11 L7,11 L7,4 L17,4 L17,11 Z"
                            id="Shape"
                            fill="#000000"
                            fillRule="nonzero"
                          />
                          <rect
                            id="Rectangle-16"
                            fill="#000000"
                            opacity="0.3"
                            x="12"
                            y="4"
                            width="3"
                            height="5"
                            rx="0.5"
                          />
                        </g>
                      </svg>
                    </span>
                    Create
                  </button>

                  <button
                    className="btn btn-danger mr-3 mb-2"
                    onClick={() => this.AddData()}
                    name="cancel"
                    style={{ float: "right" }}
                  >
                    <span className="svg-icon menu-icon mr-2">
                      <svg
                        viewBox="0 0 24 24"
                        version="1.1"
                        className="kt-svg-icon"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <g
                            id="Group"
                            transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)"
                            fill="#000000"
                          >
                            <rect
                              id="Rectangle-185"
                              x="0"
                              y="7"
                              width="16"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-185-Copy"
                              opacity="0.3"
                              transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) "
                              x="0"
                              y="7"
                              width="16"
                              height="2"
                              rx="1"
                            />
                          </g>
                        </g>
                      </svg>
                    </span>
                    Cancel
                  </button>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(AddCheckpoint);
