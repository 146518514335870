import React, { Component } from "react";
import ReactToPrint from "react-to-print";
import SearchForTable from "../../../Component/SearchForTable";

import Modal from "react-modal";

import Compressor from "compressorjs";
import { withRouter } from "react-router";
import $ from "jquery";
import axios from "axios";
import isEmpty from "../../../../Validations/is-empty";
import { Form, Table } from "react-bootstrap";
import { Avatar } from "@material-ui/core";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";

import SettingsIcon from "@material-ui/icons/Settings";
import VisibilityIcon from "@material-ui/icons/Visibility";
import jsPDF from "jspdf";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import PictureAsPdfOutlinedIcon from "@material-ui/icons/PictureAsPdfOutlined";
import { Menu, Button, MenuItem, Checkbox } from "@material-ui/core";
import { toast } from "react-toastify";
import _ from "lodash";

import "jspdf-autotable";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import CheckPermission from "../../components/CheckPermission";
const URL = process.env.REACT_APP_API_URL;
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
class Items extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_title: "Add Item",
      button_title: "Add",
      action: "Save",
      name: "",
      description: "",
      image: null,
      data: [],
      id: 0,
      pageNo: "1",
      itemsPerPage: "20",
      maxSize: 0,
      totalPages: "0",
      searchText: "",
      totalItems: 0,
      btn1Text: 1,
      btn2Text: 2,
      btn3Text: 3,
      btn4Text: 4,
      btn5Text: 5,
      search: "",
      showBtn1: false,
      showBtn2: false,
      showBtn3: false,
      showBtn4: false,
      showBtn5: false,
      btnFirstDisable: true,
      btnPreviousDisable: true,
      btnNextDisable: true,
      btnLastDisable: true,
      imagePreview: null,
      modelVisible: false,
      list: ["sr#", "name", "description", "image", "events"],
      show: ["sr#", "name", "description", "image", "events"],
    };
    this.Save = this.Save.bind(this);
    this.onChange = this.onChange.bind(this);
    this.PaginateData = this.PaginateData.bind(this);
    this.GetTotalPages = this.GetTotalPages.bind(this);
    this.GetPaginatedData = this.GetPaginatedData.bind(this);
    this.GetPageData = this.GetPageData.bind(this);
    this.PreviousPageData = this.PreviousPageData.bind(this);
    this.NextPageData = this.NextPageData.bind(this);
    this.SetButtons = this.SetButtons.bind(this);
    this.Search = this.Search.bind(this);
    this.CheckKey.bind(this);
    this.UploadFile.bind(this);
    this.removeImage = this.removeImage.bind(this);
  }

  PaginateData(PageNo) {
    this.setState({ pageNo: PageNo + "" }, () => {
      axios
        .get(
          `${URL}/api/staff/item?page=${this.state.pageNo}&limit=${this.state.itemsPerPage}`
        )
        .then((res) => {
          this.setState(
            {
              data: res.data.data,
              totalPages: Math.ceil(
                res.data.meta.total / res.data.meta.per_page
              ),
              totalItems: res.data.meta.total,
            },
            () => {
              this.SetButtons();
            }
          );
        });
    });
  }
  onSubmitValue = (val) => {
    console.log(val);
    this.setState({ search: val ? val : "" });
    axios
      .get(
        `${URL}/api/staff/item?page=${this.state.pageNo}&limit=${this.state.itemsPerPage}&search=${val}`
      )
      .then(async (res) => {
        console.log(res.data);
        this.setState(
          {
            data: res.data.data,
            totalPages: Math.ceil(res.data.meta.total / res.data.meta.per_page),
            totalItems: res.data.meta.total,
          },
          () => {
            this.SetButtons();
          }
        );
      });
  };

  async GetTotalPages() {
    await axios
      .get(`${URL}/api/staff/item?page=1&limit=${this.state.itemsPerPage}`)
      .then((res) => {
        console.log(res.data);
        this.setState(
          {
            data: res.data.data,
            totalPages: Math.ceil(res.data.meta.total / res.data.meta.per_page),
            totalItems: res.data.meta.total,
          },
          () => {
            this.SetButtons();
          }
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }

  GetPaginatedData = () => {
    axios
      .get(
        `${URL}/api/staff/item?page=${this.state.pageNo}&limit=${this.state.itemsPerPage}`
      )
      .then((res) => {
        this.setState(
          {
            data: res.data.data,
            totalPages: Math.ceil(res.data.meta.total / res.data.meta.per_page),
            totalItems: res.data.meta.total,
          },
          () => {
            this.SetButtons();
          }
        );
      });
  };

  removeImage = () => {
    if (this.state.modal_title === "Update Item") {
      axios
        .put(`${URL}/api/staff/item/${this.state.id}`, {
          remove_image: true,
        })
        .then((res) => {
          this.setState(
            {
              imagePreview: "",
              image: "",
            },
            () => {
              this.GetTotalPages();
            }
          );
        });
    } else {
      this.setState({
        imagePreview: "",
        image: "",
      });
    }
  };
  GetPageData = (id) => {
    var data = {
      itemsPerPage: this.state.itemsPerPage,
      search: this.state.searchText,
      pageNo: this.state.pageNo,
    };
    if (id === "1") {
      this.setState({ pageNo: $("#bnt1").text() }, () => {
        data.pageNo = this.state.pageNo;
      });
    } else if (id === "2") {
      this.setState({ pageNo: $("#bnt2").text() }, () => {
        data.pageNo = this.state.pageNo;
      });
    } else if (id === "3") {
      this.setState({ pageNo: $("#bnt3").text() }, () => {
        data.pageNo = this.state.pageNo;
      });
    } else if (id === "4") {
      this.setState({ pageNo: $("#bnt4").text() }, () => {
        data.pageNo = this.state.pageNo;
      });
    } else if (id === "5") {
      this.setState({ pageNo: $("#bnt5").text() }, () => {
        data.pageNo = this.state.pageNo;
      });
    }
    console.log(data);
    axios
      .get(`${URL}/api/staff/item?page=${id}&limit=${this.state.itemsPerPage}`)
      .then(async (res) => {
        console.log(res.data);
        this.setState(
          {
            data: res.data.data,
            totalPages: Math.ceil(res.data.meta.total / res.data.meta.per_page),
            totalItems: res.data.meta.total,
          },
          () => {
            this.SetButtons();
          }
        );
      });
  };

  PreviousPageData = () => {
    if (this.state.pageNo !== 1) {
      this.setState({ pageNo: Number(this.state.pageNo) - 1 + "" }, () => {
        axios
          .get(
            `${URL}/api/staff/item?page=${this.state.pageNo}&limit=${this.state.itemsPerPage}`
          )
          .then(async (res) => {
            this.setState(
              {
                data: res.data.data,
                totalPages: Math.ceil(
                  res.data.meta.total / res.data.meta.per_page
                ),
                totalItems: res.data.meta.total,
              },
              () => {
                this.SetButtons();
              }
            );
          });
      });
    }
  };

  NextPageData = () => {
    this.setState({ pageNo: Number(this.state.pageNo) + 1 + "" }, () => {
      axios
        .get(
          `${URL}/api/staff/item?page=${this.state.pageNo}&limit=${this.state.itemsPerPage}`
        )
        .then(async (res) => {
          this.setState(
            {
              data: res.data.data,
              totalPages: Math.ceil(
                res.data.meta.total / res.data.meta.per_page
              ),
              totalItems: res.data.meta.total,
            },
            () => {
              this.SetButtons();
            }
          );
        });
    });
  };

  SetButtons = () => {
    if (parseInt(this.state.pageNo) === 1) {
      $("#btnFirst").prop("disabled", true);
      $("#btnPrevious").prop("disabled", true);
      $("#btnNext").prop("disabled", false);
      $("#btnLast").prop("disabled", false);
      $("#bnt1").removeClass("btn-outline-primary");
      $("#bnt1").addClass("btn-primary");
      $("#bnt2").removeClass("btn-primary");
      $("#bnt2").addClass("btn-outline-primary");
      $("#bnt4").removeClass("btn-primary");
      $("#bnt4").addClass("btn-outline-primary");
      $("#bnt3").removeClass("btn-primary");
      $("#bnt3").addClass("btn-outline-primary");
      $("#bnt5").removeClass("btn-primary");
      $("#bnt5").addClass("btn-outline-primary");
      $("#bnt1").html("1");
      $("#bnt2").html("2");
      $("#bnt3").html("3");
      $("#bnt4").html("4");
      $("#bnt5").html("5");
      if (parseInt(this.state.totalPages) === 1) {
        $("#btnNext").prop("disabled", true);
        $("#btnLast").prop("disabled", true);
      }
    } else if (
      parseInt(this.state.pageNo) === parseInt(this.state.totalPages)
    ) {
      $("#btnFirst").prop("disabled", false);
      $("#btnPrevious").prop("disabled", false);
      $("#btnNext").prop("disabled", true);
      $("#btnLast").prop("disabled", true);
      if (parseInt(this.state.totalPages) < 6) {
        $("#bnt" + this.state.totalPages).removeClass("btn-outline-primary");
        $("#bnt" + this.state.totalPages).addClass("btn-primary");
        for (var i = parseInt(this.state.totalPages) - 1; i > 0; i--) {
          $("#bnt" + i).removeClass("btn-primary");
          $("#bnt" + i).addClass("btn-outline-primary");
        }
      } else {
        $("#bnt5").removeClass("btn-outline-primary");
        $("#bnt5").addClass("btn-primary");
        $("#bnt4").removeClass("btn-primary");
        $("#bnt4").addClass("btn-outline-primary");
        $("#bnt3").removeClass("btn-primary");
        $("#bnt3").addClass("btn-outline-primary");
        $("#bnt2").removeClass("btn-primary");
        $("#bnt2").addClass("btn-outline-primary");
        $("#bnt1").removeClass("btn-primary");
        $("#bnt1").addClass("btn-outline-primary");

        $("#bnt1").html("" + (parseInt(this.state.pageNo) - 4));
        $("#bnt2").html("" + (parseInt(this.state.pageNo) - 3));
        $("#bnt3").html("" + (parseInt(this.state.pageNo) - 2));
        $("#bnt4").html("" + (parseInt(this.state.pageNo) - 1));
        $("#bnt5").html("" + parseInt(this.state.pageNo));
      }
    } else {
      $("#btnFirst").prop("disabled", false);
      $("#btnPrevious").prop("disabled", false);
      $("#btnNext").prop("disabled", false);
      $("#btnLast").prop("disabled", false);
      if (parseInt(this.state.totalPages) < 6) {
        for (var j = parseInt(this.state.totalPages); j > 0; j--) {
          $("#bnt" + j).removeClass("btn-primary");
          $("#bnt" + j).addClass("btn-outline-primary");
        }
        $("#bnt" + this.state.pageNo).removeClass("btn-outline-primary");
        $("#bnt" + this.state.pageNo).addClass("btn-primary");
      } else {
        if (parseInt(this.state.pageNo) === 2) {
          $("#bnt5").addClass("btn-outline-primary");
          $("#bnt5").removeClass("btn-primary");
          $("#bnt4").removeClass("btn-primary");
          $("#bnt4").addClass("btn-outline-primary");
          $("#bnt3").removeClass("btn-primary");
          $("#bnt3").addClass("btn-outline-primary");
          $("#bnt2").removeClass("btn-outline-primary");
          $("#bnt2").addClass("btn-primary");
          $("#bnt1").removeClass("btn-primary");
          $("#bnt1").addClass("btn-outline-primary");
        } else if (parseInt(this.state.pageNo) === 3) {
          $("#bnt1").html("1");
          $("#bnt2").html("2");
          $("#bnt3").html("3");
          $("#bnt4").html("4");
          $("#bnt5").html("5");
          $("#bnt5").addClass("btn-outline-primary");
          $("#bnt5").removeClass("btn-primary");
          $("#bnt4").removeClass("btn-primary");
          $("#bnt4").addClass("btn-outline-primary");
          $("#bnt2").removeClass("btn-primary");
          $("#bnt2").addClass("btn-outline-primary");
          $("#bnt3").removeClass("btn-outline-primary");
          $("#bnt3").addClass("btn-primary");
          $("#bnt1").removeClass("btn-primary");
          $("#bnt1").addClass("btn-outline-primary");
        } else {
          if (
            parseInt(this.state.totalPages) - parseInt(this.state.pageNo) >
            2
          ) {
            $("#bnt1").html("" + (parseInt(this.state.pageNo) - 2));
            $("#bnt2").html("" + (parseInt(this.state.pageNo) - 1));
            $("#bnt3").html("" + parseInt(this.state.pageNo));
            $("#bnt4").html("" + (parseInt(this.state.pageNo) + 1));
            $("#bnt5").html("" + (parseInt(this.state.pageNo) + 2));
          } else if (
            parseInt(this.state.totalPages) - parseInt(this.state.pageNo) >
            1
          ) {
            $("#bnt1").html("" + (parseInt(this.state.pageNo) - 2));
            $("#bnt2").html("" + (parseInt(this.state.pageNo) - 1));
            $("#bnt3").html("" + parseInt(this.state.pageNo));
            $("#bnt4").html("" + (parseInt(this.state.pageNo) + 1));
            $("#bnt5").html("" + (parseInt(this.state.pageNo) + 2));
            $("#bnt5").addClass("btn-outline-primary");
            $("#bnt5").removeClass("btn-primary");
            $("#bnt4").removeClass("btn-primary");
            $("#bnt4").addClass("btn-outline-primary");
            $("#bnt2").removeClass("btn-outline-primary");
            $("#bnt2").addClass("btn-outline-primary");
            $("#bnt3").removeClass("btn-outline-primary");
            $("#bnt3").addClass("btn-primary");
            $("#bnt1").removeClass("btn-primary");
            $("#bnt1").addClass("btn-outline-primary");
          } else if (
            parseInt(this.state.totalPages) - parseInt(this.state.pageNo) ===
            1
          ) {
            $("#bnt1").html("" + (parseInt(this.state.pageNo) - 3));
            $("#bnt2").html("" + (parseInt(this.state.pageNo) - 2));
            $("#bnt3").html("" + (parseInt(this.state.pageNo) - 1));
            $("#bnt4").html("" + parseInt(this.state.pageNo));
            $("#bnt5").html("" + (parseInt(this.state.pageNo) + 1));
            $("#bnt5").addClass("btn-outline-primary");
            $("#bnt5").removeClass("btn-primary");
            $("#bnt3").removeClass("btn-primary");
            $("#bnt3").addClass("btn-outline-primary");
            $("#bnt2").removeClass("btn-outline-primary");
            $("#bnt2").addClass("btn-outline-primary");
            $("#bnt4").removeClass("btn-outline-primary");
            $("#bnt4").addClass("btn-primary");
            $("#bnt1").removeClass("btn-primary");
            $("#bnt1").addClass("btn-outline-primary");
          }
        }
      }
    }
  };

  Search = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      this.GetTotalPages();
    }
  };

  CheckKey = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      this.Save();
    }
  };
  handleDeleteALert = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>Are you sure?</h1>
            <p>You want to delete this?</p>
            <button
              className="btn btn-danger"
              onClick={onClose}
              style={{ marginRight: 10 }}
            >
              No
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                this.Delete(id);
                onClose();
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  };
  Delete = (id) => {
    this.setState({
      msg: "Deleting item...",
      error: "",
    });
    axios
      .delete(`${URL}/api/staff/item/${id}`)
      .then(async (res) => {
        toast.success("Items Deleted Succesfully!");
        this.setState(
          {
            msg: res.data.message,
            error: "",
            id: "",
            name: "",
            description: "",
            image: null,
            action: "Save",
            modal_title: "Add Item",
            button_title: "Add",
          },
          () => {
            this.GetTotalPages();
          }
        );
      })
      .catch((err) => {
        console.log(err.response);
        toast.error(err.response.data.errors[0].message);
        this.setState({
          msg: "",
          error: err.response.data.errors[0].message,
        });
      });
  };

  UpdateData = (obj) => {
    this.setState({
      id: obj.id,
      name: obj.name,
      description: obj.description,
      modal_title: "Update Item",
      button_title: "Update",
      action: "Update",
    });
    this.setState({ imagePreview: obj.image });
  };

  AddData = () => {
    this.setState({
      id: 0,
      name: "",
      description: "",
      modal_title: "Add Item",
      button_title: "Add",
      action: "Save",
    });
    this.setState({ imagePreview: null });
  };

  componentWillMount() {
    if (CheckPermission("view-guard-item"));
    this.GetTotalPages();
  }

  async Save() {
    if (!isEmpty(this.state.name)) {
      if (!isEmpty(this.state.description)) {
        if (this.state.action === "Save") {
          this.setState({
            msg: "Adding item...",
            error: "",
          });
          var formData = new FormData();
          formData.append("name", this.state.name);
          formData.append("description", this.state.description);
          if (this.state.image !== null)
            formData.append("image", this.state.image);
          axios
            .post(`${URL}/api/staff/item`, formData)
            .then(async (res) => {
              toast.success("Item Saved Successfully!");
              this.setState(
                {
                  msg: "Item Saved Successfully!",
                  error: "",
                  id: "",
                  name: "",
                  description: "",
                  image: null,
                  action: "Save",
                  modal_title: "Add Item",
                  button_title: "Add",
                  imagePreview: "", 
                },
                () => {
                  this.GetTotalPages();
                }
              );
              this.setState({ imagePreview: null });
            })
            .catch((err) => {
              console.log(err.response);
              toast.error(err.response.data.errors[0].message);
              this.setState({
                msg: "",
                error: err.response.data.errors[0].message,
              });
            });
        } else {
          this.setState({
            msg: "Updating item...",
            error: "",
          });
          formData = new FormData();
          formData.append("name", this.state.name);
          formData.append("description", this.state.description);
          if (this.state.image !== null)
            formData.append("image", this.state.image);
          axios
            .put(`${URL}/api/staff/item/${this.state.id}`, formData)
            .then(async (res) => {
              toast.success("Item Updated Successfully!");
              this.setState(
                {
                  msg: "Item Updated Successfully!",
                  error: "",
                  id: "",
                  name: "",
                  description: "",
                  image: null,
                  action: "Save",
                  modal_title: "Add Item",
                  button_title: "Add",
                  imagePreview: "",
                },
                () => {
                  this.GetTotalPages();
                }
              );
            })
            .catch((err) => {
              toast.error(err.response.data.errors[0].message);
              this.setState({
                msg: "",
                error: err.response.data.errors[0].message,
              });
            });
        }
      } else {
        toast.error("Please enter description of item!");
        this.setState({
          msg: "",
          error: "Please enter description of item!",
        });
      }
    } else {
      toast.error("Please enter a name first!");

      this.setState({
        msg: "",
        error: "Please enter a name first!",
      });
    }
  }
  openModel = (url, type) => {
    this.setState({ image: url, modelVisible: true });
  };
  closeModal = () => {
    this.setState({ modelVisible: false, audio: "", image: "" });
  };
  onChange(e) {
    var name = e.target.name;
    console.log(e.target.value);
    this.setState({ [e.target.name]: e.target.value }, () => {
      console.log(this.state.itemsPerPage);
      if (name === "itemsPerPage") {
        this.GetTotalPages();
      }
    });
  }

  UploadFile(e) {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        this.setState({ imagePreview: reader.result });
      }
    };
    reader.readAsDataURL(e.target.files[0]);
    const image = e.target.files[0];
    new Compressor(image, {
      quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
      success: (compressedResult) => {
        // compressedResult has the compressed file.
        // Use the compressed file to upload the images to your server.
        this.setState({ image: compressedResult });
      },
    });
  }
  handleClick = (path) => {
    const char = this.state.data;
    const ch = _.orderBy(char, [path], [this.state.sort ? "asc" : "desc"]);
    this.setState({ data: [] });
    this.setState({ data: ch });
    this.setState({ sort: !this.state.sort });
  };
  exportPDF = () => {
    const unit = "pt";
    const size = "A4";
    const orientation = "portrait";

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "My Awesome Report";
    const headers = [["sr#", "name"]];

    const data = this.state.data.map((obj, k) => [
      k + 1,
      obj.name,
      obj.description,
    ]);
    console.log(data, "data from export");

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("report.pdf");
  };
  showClick = (v) => {
    let newArray = [];
    if (this.state.show.includes(v)) {
      newArray = this.state.show.filter((e) => e !== v);
      this.setState({ show: newArray });
    } else {
      newArray = this.state.show;
      newArray.push(v);
      this.setState({ show: newArray });
    }
  };

  render() {
    const handleClicks = (event) => {
      this.setState({ anchorEl: event.currentTarget });
    };
    const handleClicks1 = (event) => {
      this.setState({ anchorEls: event.currentTarget });
    };
    const handleClose = () => {
      this.setState({ anchorEl: null });
    };
    const handleCloses = () => {
      this.setState({ anchorEls: null });
    };
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const exportToCSV = (csvData, fileName) => {
      let d = [];
      csvData.map((v, k) =>
        d.push({
          sr: k + 1,
          name: v.name,
          descriptions: v.description,
        })
      );

      const ws = XLSX.utils.json_to_sheet(d);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    };
    console.log(this.state.data, "dattta");
    return (
      <React.Fragment>
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            marginBottom: "20px",
          }}
        >
          <i
            className="flaticon-guard mr-3"
            style={{
              fontSize: "35px",
              color: "#3F4254",
              fontWeight: "900",
            }}
          ></i>
          <h3 style={{ marginBottom: "1.1%" }}>Guard Items</h3>
        </div>
        <div className="page-content">
          <div className="row">
            {(CheckPermission("create-guard-item") ||
              (CheckPermission("update-guard-item") &&
                this.state.action !== "Save")) && (
              <div className="col-sm-12 col-md-6 col-lg-4">
                <Card>
                  <CardHeader title={this.state.modal_title}></CardHeader>
                  <CardBody>
                    <Form.Group>
                      <Form.Label>Name</Form.Label>
                      <div className="input-group ">
                        <input
                          onKeyPress={this.CheckKey}
                          className="form-control form-control-sm"
                          type="text"
                          placeholder="Enter item name"
                          value={this.state.name}
                          name="name"
                          onChange={this.onChange}
                        ></input>
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i
                              className="flaticon-user"
                              style={{ fontSize: "13px" }}
                            ></i>
                          </span>
                        </div>
                      </div>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Description</Form.Label>
                      <div className="input-group ">
                        <input
                          onKeyPress={this.CheckKey}
                          className="form-control form-control-sm"
                          type="text"
                          placeholder="Enter item description"
                          value={this.state.description}
                          name="description"
                          onChange={this.onChange}
                        ></input>
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i
                              className="flaticon-job-description"
                              style={{ fontSize: "13px" }}
                            ></i>
                          </span>
                        </div>
                      </div>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Image</Form.Label>
                      <input
                        style={{ display: "none" }}
                        className="form-control form-control-sm"
                        type="file"
                        placeholder="Select image item"
                        name="image"
                        ref={(file) => (this.file1 = file)}
                        onChange={(e) => this.UploadFile(e)}
                      ></input>
                      <div
                        className="mb-3"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "center",
                          position: "relative",
                          width: "100%",
                        }}
                      >
                        {this.state.imagePreview ? (
                          <p
                            style={{
                              position: "absolute",
                              top: "0%",
                              fontSize: "14px",
                              left: "65%",
                              color: "red",
                            }}
                          >
                            <button
                              onClick={() => this.removeImage()}
                              className="notPrint btn btn-xs btn-default btn-text-primary btn-hover-danger btn-icon btn-icon-danger "
                              title="Delete"
                              ng-click="Delete(role.srNo)"
                            >
                              <span className="svg-icon svg-icon-md">
                                <svg viewBox="0 0 24 24" version="1.1">
                                  <g
                                    stroke="none"
                                    strokeWidth="1"
                                    fill="none"
                                    fillRule="evenodd"
                                  >
                                    <rect
                                      x="0"
                                      y="0"
                                      width="24"
                                      height="24"
                                    ></rect>
                                    <path
                                      d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                      fill="#000000"
                                      fillRule="nonzero"
                                    ></path>
                                    <path
                                      d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                      fill="#000000"
                                      opacity="0.3"
                                    ></path>
                                  </g>
                                </svg>
                              </span>
                            </button>
                          </p>
                        ) : (
                          ""
                        )}
                        <Avatar
                          alt="res"
                          id="target"
                          style={{ width: "80px", height: "80px" }}
                          src={this.state.imagePreview}
                          onClick={() => this.file1.click()}
                        />
                        <p className="mt-3">
                          Select Image with dimensions{" "}
                          <small> 256*256 px</small>
                        </p>
                      </div>
                    </Form.Group>
                    <button
                      className="btn btn-primary"
                      onClick={() => this.Save()}
                      style={{ float: "right" }}
                    >
                      {this.state.button_title}
                    </button>

                    <button
                      className="btn btn-danger"
                      onClick={() => this.AddData()}
                      name="cancel"
                      style={{ float: "left" }}
                    >
                      Cancel
                    </button>
                  </CardBody>
                </Card>
              </div>
            )}

            <div className="col-sm-12 col-md-6 col-lg-8">
              <Card>
                <CardHeader title="Items">
                  <CardHeaderToolbar>
                    {CheckPermission("view-guard-item") && (
                      <>
                        <Button
                          color="secondary"
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          onClick={handleClicks}
                        >
                          <VisibilityIcon />
                        </Button>
                        <Menu
                          id="simple-menu"
                          anchorEl={this.state.anchorEl}
                          keepMounted
                          open={Boolean(this.state.anchorEl)}
                          onClose={handleClose}
                        >
                          {this.state.list.map((v, k) => (
                            <MenuItem
                              key={k}
                              style={{ textTransform: "capitalize" }}
                              onClick={() => this.showClick(v)}
                            >
                              <Checkbox checked={this.state.show.includes(v)} />{" "}
                              {v}
                            </MenuItem>
                          ))}
                        </Menu>
                        <Button
                          color="secondary"
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          onClick={handleClicks1}
                        >
                          <SettingsIcon />
                        </Button>
                        <Menu
                          id="simple-menu"
                          anchorEl={this.state.anchorEls}
                          keepMounted
                          open={Boolean(this.state.anchorEls)}
                          onClose={handleCloses}
                        >
                          <MenuItem
                            onClick={() =>
                              exportToCSV(this.state.data, "Report in Excel ")
                            }
                          >
                            Generate To excel
                            <InsertDriveFileIcon
                              className="ml-2"
                              style={{ color: "#AEAFAD" }}
                            />
                          </MenuItem>
                          <MenuItem onClick={() => this.exportPDF()}>
                            Report To Pdf
                            <PictureAsPdfOutlinedIcon
                              className="ml-2"
                              style={{ color: "#AEAFAD" }}
                            />
                          </MenuItem>
                        </Menu>
                        <ReactToPrint
                          trigger={() => {
                            return (
                              <a className="btn btn-outline-primary mr-5" href="">
                                Print
                              </a>
                            );
                          }}
                          content={() => this.componentRef}
                        />
                      </>
                    )}
                    {CheckPermission("create-guard-item") && (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => this.AddData()}
                        name="add_button"
                      >
                        Add New Item
                      </button>
                    )}
                  </CardHeaderToolbar>
                </CardHeader>
                {CheckPermission("view-guard-item") && (
                  <>
                    <div>
                      <SearchForTable onSubmitValue={this.onSubmitValue} />{" "}
                    </div>
                    <CardBody ref={(el) => (this.componentRef = el)}>
                      <div className="row d-none print">
                        <div className="col-sm-12">
                          <span>
                            <h2 className="text-center">Items</h2>
                          </span>
                        </div>
                      </div>
                      <Table responsive>
                        <thead>
                          <tr>
                            {this.state.show.includes("sr#") ? (
                              <th
                                width="10%"
                                onClick={() => this.handleClick("id")}
                              >
                                <span style={{ display: "flex" }}>
                                  Sr#
                                  <UnfoldMoreIcon
                                    style={{ height: "16px", marginTop: "2px" }}
                                  />
                                </span>
                              </th>
                            ) : null}
                            {this.state.show.includes("name") ? (
                              <th
                                width="420%"
                                onClick={() => this.handleClick("name")}
                              >
                                <span style={{ display: "flex" }}>
                                  Name
                                  <UnfoldMoreIcon
                                    style={{ height: "16px", marginTop: "2px" }}
                                  />
                                </span>
                              </th>
                            ) : null}
                            {this.state.show.includes("description") ? (
                              <th width="40%" className="text-center">
                                <span style={{ display: "flex" }}>
                                  Description
                                </span>
                              </th>
                            ) : null}
                            {this.state.show.includes("image") ? (
                              <th width="15%" className="text-center">
                                <span style={{ display: "flex" }}> Image </span>
                              </th>
                            ) : null}
                            {this.state.show.includes("events") ? (
                              <th width="15%" className="text-center  notPrint">
                                <span style={{ display: "flex" }}>
                                  {" "}
                                  Events{" "}
                                </span>
                              </th>
                            ) : null}
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.data ? (
                            this.state.data.map((obj, k) => (
                              <tr>
                                {this.state.show.includes("sr#") ? (
                                  <td>
                                    {this.state.itemsPerPage *
                                      (this.state.pageNo - 1) +
                                      k +
                                      1}
                                  </td>
                                ) : null}
                                {this.state.show.includes("name") ? (
                                  <td>{obj.name}</td>
                                ) : null}
                                {this.state.show.includes("description") ? (
                                  <td>{obj.description}</td>
                                ) : null}
                                {this.state.show.includes("image") ? (
                                  <td>
                                    <Avatar
                                      alt="Remy Sharp"
                                      src={obj.image}
                                      onClick={() => this.openModel(obj.image)}
                                    />
                                    {/* <img src={obj.image} /> */}
                                  </td>
                                ) : null}
                                {this.state.show.includes("events") ? (
                                  <td className="text-center">
                                    <div className="notPrint">
                                      {CheckPermission("update-log-type") && (
                                        <button
                                          onClick={() => this.UpdateData(obj)}
                                          className="btn btn-xs btn-default btn-text-primary btn-hover-primary btn-icon btn-icon-primary mr-2"
                                          title="Edit details"
                                          ng-click="UpdateData(role)"
                                        >
                                          <span className="svg-icon svg-icon-md">
                                            <svg
                                              viewBox="0 0 24 24"
                                              version="1.1"
                                            >
                                              <g
                                                stroke="none"
                                                strokeWidth="1"
                                                fill="none"
                                                fillRule="evenodd"
                                              >
                                                <rect
                                                  x="0"
                                                  y="0"
                                                  width="24"
                                                  height="24"
                                                ></rect>
                                                <path
                                                  d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z"
                                                  fill="#000000"
                                                  fillRule="nonzero"
                                                  transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953) "
                                                ></path>
                                                <path
                                                  d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z"
                                                  fill="#000000"
                                                  fillRule="nonzero"
                                                  opacity="0.3"
                                                ></path>
                                              </g>
                                            </svg>
                                          </span>
                                        </button>
                                      )}
                                      {CheckPermission("delete-log-type") && (
                                        <button
                                          onClick={() =>
                                            this.handleDeleteALert(obj.id)
                                          }
                                          className="btn btn-xs btn-default btn-text-primary btn-hover-danger btn-icon btn-icon-danger "
                                          title="Delete"
                                          ng-click="Delete(role.srNo)"
                                        >
                                          <span className="svg-icon svg-icon-md">
                                            <svg
                                              viewBox="0 0 24 24"
                                              version="1.1"
                                            >
                                              <g
                                                stroke="none"
                                                strokeWidth="1"
                                                fill="none"
                                                fillRule="evenodd"
                                              >
                                                <rect
                                                  x="0"
                                                  y="0"
                                                  width="24"
                                                  height="24"
                                                ></rect>
                                                <path
                                                  d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                                  fill="#000000"
                                                  fillRule="nonzero"
                                                ></path>
                                                <path
                                                  d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                                  fill="#000000"
                                                  opacity="0.3"
                                                ></path>
                                              </g>
                                            </svg>
                                          </span>
                                        </button>
                                      )}
                                    </div>
                                  </td>
                                ) : null}
                              </tr>
                            ))
                          ) : (
                            <h3>Loading...</h3>
                          )}
                        </tbody>
                        <tfoot className="notPrint">
                          <tr>
                            <td align="center" colSpan="5">
                              <div className="pull-right">
                                <button
                                  id="btnFirst"
                                  onClick={() => this.PaginateData(1)}
                                  className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                                >
                                  First
                                </button>
                                <button
                                  id="btnPrevious"
                                  onClick={() => this.PreviousPageData()}
                                  className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                                >
                                  Previous
                                </button>
                                {this.state.totalPages !== "0" ? (
                                  <button
                                    id="bnt1"
                                    onClick={() => this.GetPageData("1")}
                                    className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                                  >
                                    1
                                  </button>
                                ) : null}
                                {this.state.totalPages > "1" ? (
                                  <button
                                    id="bnt2"
                                    onClick={() => this.GetPageData("2")}
                                    className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                                  >
                                    2
                                  </button>
                                ) : null}
                                {this.state.totalPages > "2" ? (
                                  <button
                                    id="bnt3"
                                    onClick={() => this.GetPageData("3")}
                                    className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                                  >
                                    3
                                  </button>
                                ) : null}
                                {this.state.totalPages > "3" ? (
                                  <button
                                    id="bnt4"
                                    onClick={() => this.GetPageData("4")}
                                    className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                                  >
                                    4
                                  </button>
                                ) : null}
                                {this.state.totalPages > "4" ? (
                                  <button
                                    id="bnt5"
                                    onClick={() => this.GetPageData("5")}
                                    className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                                  >
                                    5
                                  </button>
                                ) : null}
                                <button
                                  id="btnNext"
                                  onClick={() => this.NextPageData()}
                                  className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                                >
                                  Next
                                </button>
                                <button
                                  id="btnLast"
                                  onClick={() =>
                                    this.PaginateData(this.state.totalPages)
                                  }
                                  className="btn btn-rounded btn-sm btn-outline-primary"
                                >
                                  Last
                                </button>
                              </div>
                              <div className="row">
                                <div className="col-6">
                                  <label className="btn btn-primary  mt-2 pulll-left">
                                    {this.state.pageNo *
                                      this.state.itemsPerPage -
                                      this.state.itemsPerPage}
                                    -
                                    {this.state.pageNo === this.state.totalPages
                                      ? this.state.totalItems
                                      : this.state.pageNo *
                                        this.state.itemsPerPage}
                                    / {this.state.totalItems} items
                                  </label>
                                </div>
                                <div className="col-6">
                                  <div className="row">
                                    <div className="col-3">
                                      <select
                                        className="form-control form-control-sm mt-3"
                                        name="itemsPerPage"
                                        onChange={this.onChange}
                                      >
                                        <option value="20">20</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="500">500</option>
                                      </select>
                                    </div>
                                    <div className="col-9">
                                      <button className="btn btn-primary mt-2 pull-right col-8">
                                        Page: {this.state.pageNo} /
                                        {this.state.totalPages}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tfoot>
                      </Table>
                    </CardBody>
                  </>
                )}
              </Card>
            </div>
          </div>
        </div>
        <Modal
          isOpen={this.state.modelVisible}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <a
            href={this.state.image}
            target="_blank"
            style={{ height: "400px", width: "400px" }}
          >
            <img
              className="no-borderRadiusImportant"
              src={this.state.image}
              alt="P"
              width="400px"
              height="400px"
              style={{
                borderRadius: "5px!important",
                height: "400px",
                width: "400px",
              }}
            />
          </a>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withRouter(Items);
