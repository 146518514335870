import React, { Component } from "react";
import { withRouter } from "react-router";
import axios from "axios";
import isEmpty from "../../../../Validations/is-empty";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import "react-confirm-alert/src/react-confirm-alert.css";
// import _ from "lodash";
import "jspdf-autotable";
import CheckPermission from "../../components/CheckPermission";
import DataGrid from "../../../Component/DataGrid";
import { TextInput } from "../../../Component/Form";
import { toast } from "react-toastify";
const URL = process.env.REACT_APP_API_URL;
class FacilityTypes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_title: "Add Facility Type",
      button_title: "Add",
      action: "Save",
      name: "",
      status: "Active",
      permissionArray: [],
      data: [],
      reload: false,
      id: 0,
      list: ["Sr", "Name"],
      columns: [
        {
          column_name: "Sr",
          field_name: "id",
          width: "15%",
          type: "label",
        },
        {
          column_name: "Name",
          field_name: "type",
          width: "40%",
          type: "label",
        },
      ],
    };
    this.Save = this.Save.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  CheckKey = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      this.Save();
    }
  };

  UpdateData = (obj) => {
    this.setState({
      id: obj.id,
      type: obj.type,
      modal_title: "Update Facility Type",
      button_title: "Update",
      action: "Update",
    });
  };

  AddData = () => {
    this.setState({
      id: 0,
      type: "",
      modal_title: "Add Facility Type",
      button_title: "Add",
      action: "Save",
    });
  };

  componentWillMount() {}

  async Save() {
    if (!isEmpty(this.state.type)) {
      const data = { type: this.state.type };
      if (this.state.action === "Save") {
        toast.success("Creating facility type ...");
        axios
          .post(`${URL}/api/staff/facility_type`, data)
          .then(async (res) => {
            this.setState({
              id: "",
              type: "",
              action: "Save",
              modal_title: "Add Facility Type",
              button_title: "Add",
              reload: true,
            });
            toast.success("Facility type created successfully!");
          })
          .catch((err) => {
            console.log(err.response);
          });
      } else {
        toast.success("Updating facility type...");
        axios
          .put(`${URL}/api/staff/facility_type/${this.state.id}`, data)

          .then(async (res) => {
            this.setState({
              id: "",
              type: "",
              action: "Save",
              modal_title: "Add Type",
              button_title: "Add",
              reload: true,
            });
            toast.success("Facility type upadted successfully!");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      toast.error("Please enter a type name first!");
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    return (
      <React.Fragment>
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            marginBottom: "20px",
          }}
        >
          <i
            className="flaticon-log-format  "
            style={{
              fontSize: "35px",
              color: "black",
              fontWeight: "900",
            }}
          ></i>
          <h3 id="modal" style={{ marginBottom: "15px" }}>
            <span style={{ marginBottom: "15px" }}>Facility Types</span>
          </h3>
        </div>
        <div className="page-content">
          <div className="row">
            {(CheckPermission("create-facility-type") ||
              (CheckPermission("update-facility-type") &&
                this.state.action !== "Save")) && (
              <div className="col-sm-12 col-md-6 col-lg-4">
                <Card>
                  <CardHeader title={this.state.modal_title}></CardHeader>
                  <CardBody>
                    <TextInput
                      label="Type Name *"
                      name="type"
                      width="col-12"
                      CheckKey={this.CheckKey}
                      type="text"
                      value={this.state.type}
                      icon="flaticon-calendar"
                      onChange={this.onChange}
                      includeIcon={true}
                    />

                    <button
                      className="btn btn-primary"
                      onClick={() => this.Save()}
                      style={{ float: "right" }}
                    >
                      {this.state.button_title}
                    </button>

                    <button
                      className="btn btn-danger"
                      onClick={() => this.AddData()}
                      name="cancel"
                      style={{ float: "left" }}
                    >
                      Cancel
                    </button>
                  </CardBody>
                </Card>
              </div>
            )}

            <div className="col-sm-12 col-md-6 col-lg-8">
              <DataGrid
                end_point="facility_type"
                title="Facility Type"
                file_name="Facility Type Report"
                new_action="on_same_page"
                title_small="facility type"
                list={this.state.list}
                columns={this.state.columns}
                permission_label="facility-type"
                AddData={this.AddData}
                UpdateData={this.UpdateData}
                reload={this.state.reload}
                setReload={() => {
                  this.setState({ reload: false });
                }}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(FacilityTypes);
