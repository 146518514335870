import React, { Component } from "react";
import { withRouter } from "react-router";
import ReactToPrint from "react-to-print";

import Select from "react-select";
import axios from "axios";
import { Form, Alert } from "react-bootstrap";
import { Card, CardBody } from "../../../../../../_metronic/_partials/controls";
import moment from "moment";
import { Button, CircularProgress } from "@material-ui/core";
import _ from "lodash";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Grid from "../../../../Component/Grid";
import MakeCSVData from "../../../../Component/Grid/MakeCSVData";
import ExportCsv from "../../../../ECommerce/components/Export";
const URL = process.env.REACT_APP_API_URL;
class Attendance extends Component {
  constructor(props) {
    super(props);
    var currentDate = new Date();
    var endDate = new Date();
    var dd = String(currentDate.getDate()).padStart(2, "0");
    var enddd = String(currentDate.getDate()).padStart(2, "0");

    var mm = String(currentDate.getMonth() + 1).padStart(2, "0");
    var yyyy = currentDate.getFullYear();
    currentDate = yyyy + "-" + mm + "-" + dd;
    endDate = yyyy + "-" + mm + "-" + enddd;
    this.state = {
      data: [],
      loading: true,
      employee_id: "0",
      employee_name: "All",
      pageNo: "1",
      itemsPerPage: "20",
      maxSize: 0,
      totalPages: "0",
      totalItems: 0,
      startDate: currentDate,
      endDate: endDate,
      search: "",
      URL: `${URL}/api/staff/attendance/report`,
      anchorEl: null,
      anchorEls: null,
      columns: [
        {
          column_name: "Sr",
          field_name: "id",
          width: "5%",
          type: "label",
        },
        {
          column_name: "Guard Name",
          field_name: "name",
          width: "25%",
          type: "object",
          object_name: ["user", "profile"],
        },
        {
          column_name: "In At",
          field_name: "in_at",
          width: "15%",
          type: "date_time",
        },
        {
          column_name: "In Method",
          field_name: "in_attendance_through",
          width: "14%",
          type: "label",
        },
        {
          column_name: "Out At",
          field_name: "out_at",
          width: "15%",
          type: "date_time",
        },
        {
          column_name: "Out Method",
          field_name: "out_attendance_through",
          width: "16%",
          type: "label",
        },
        {
          column_name: "Hours",
          field_name: "in_at",
          field_name1: "out_at",
          width: "10%",
          type: "time_difference",
        },
      ],
      guards: [],
    };
    this.onChange = this.onChange.bind(this);
  }

  GetGuards = () => {
    axios
      .get(`${URL}/api/staff/client_staff?role=guard&page=1&limit=1000`)
      .then(async (res) => {
        var newCategories = [{ id: "0", name: "All" }];

        for (var i = 0; i < res.data.data.length; i++) {
          newCategories.push({
            id: res.data.data[i].id + "",
            name: res.data.data[i].profile.name,
          });
        }
        await this.setState({
          guards: newCategories,
        });
      });
  };

  async GetTotalPages() {
    var URL = "";
    if (this.state.employee_id === "0") {
      URL =
        this.state.URL +
        `?&startDate=${this.state.startDate} 00:00&endDate=${this.state.endDate} 23:59`;
    } else {
      URL =
        this.state.URL +
        `?startDate=${this.state.startDate} 00:00&endDate=${this.state.endDate} 23:59&guard=${this.state.employee_id}`;
    }
    await axios
      .get(URL)
      .then((res) => {
        this.setState({
          data: res.data,
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  GetPaginatedData = () => {
    var URL = "";
    if (this.state.employee_id === "0") {
      URL =
        this.state.URL +
        `?page=${this.state.pageNo}&limit=${this.state.itemsPerPage}&startDate=${this.state.startDate}&endDate=${this.state.endDate}`;
    } else {
      URL =
        this.state.URL +
        `?page=${this.state.pageNo}&limit=${this.state.itemsPerPage}&startDate=${this.state.startDate}&endDate=${this.state.endDate}&staff=${this.state.employee_id}`;
    }
    axios.get(URL).then((res) => {
      this.setState(
        {
          data: res.data.data,
          totalPages: Math.ceil(res.data.meta.total / res.data.meta.per_page),
          totalItems: res.data.meta.total,
        },
        () => {
          this.SetButtons();
        }
      );
    });
  };

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmitValue = (val) => {
    this.setState({ search: val });
  };

  componentWillMount() {
    this.GetGuards();
    this.GetTotalPages();
  }

  filter = () => {
    this.setState({ loading: true });
    this.GetTotalPages();
  };

  handleClick = (path) => {
    const char = this.state.data;
    const ch = _.orderBy(char, [path], [this.state.sort ? "asc" : "desc"]);
    this.setState({ data: [] });
    this.setState({ data: ch });
    this.setState({ sort: !this.state.sort });
  };

  exportPDF = () => {
    const unit = "pt";
    const size = "A4";
    const orientation = "portrait";

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Attandance";
    const headers = [["sr#", "date", "name", "type"]];

    const data = this.state.data.map((obj, k) => [
      k + 1,
      obj.created_at,
      obj.user.profile.name,
      obj.user.role_id,
      obj.type,
    ]);
    console.log(data, "data from export");

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(
      `Attendance_Report_${this.state.startDate}_to_${this.state.endDate}` +
        ".pdf"
    );
  };

  showClick = (v) => {
    let newArray = [];
    if (this.state.show.includes(v)) {
      newArray = this.state.show.filter((e) => e !== v);
      this.setState({ show: newArray });
    } else {
      newArray = this.state.show;
      newArray.push(v);
      this.setState({ show: newArray });
    }
  };

  GuardChanges = (val) => {
    this.setState({ employee_id: val.id, employee_name: val.name });
  };

  render() {
    const customStylesForFeild = {
      control: (base) => ({
        ...base,
        height: 33,
        minHeight: 33,
        border: "1px solid #E4E6EF",
        borderRadius: "3px",
        fontSize: 10.5,
        fontWeight: 400,
        paddingTop: -25,
      }),
    };
    const handleClicks = (event) => {
      this.setState({ anchorEl: event.currentTarget });
    };
    const handleClicks1 = (event) => {
      this.setState({ anchorEls: event.currentTarget });
    };
    const handleClose = () => {
      this.setState({ anchorEl: null });
    };
    const handleCloses = () => {
      this.setState({ anchorEls: null });
    };
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const exportToCSV = (csvData, fileName) => {
      let d = [];
      csvData.map((v, k) =>
        d.push({
          sr: k + 1,
          date: v.created_at,
          name: v.user.profile.name,
          type: v.type,
        })
      );

      const ws = XLSX.utils.json_to_sheet(csvData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <div className="row">
            <span className="svg-icon svg-icon-primary svg-icon-3x mr-2">
              {" "}
              <svg
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
                className="kt-svg-icon"
              >
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <rect id="bound" x="0" y="0" width="24" height="24" />
                  <path
                    d="M3.5,21 L20.5,21 C21.3284271,21 22,20.3284271 22,19.5 L22,8.5 C22,7.67157288 21.3284271,7 20.5,7 L10,7 L7.43933983,4.43933983 C7.15803526,4.15803526 6.77650439,4 6.37867966,4 L3.5,4 C2.67157288,4 2,4.67157288 2,5.5 L2,19.5 C2,20.3284271 2.67157288,21 3.5,21 Z"
                    id="Combined-Shape"
                    fill="#000000"
                    opacity="0.3"
                  />
                  <path
                    d="M12,13 C10.8954305,13 10,12.1045695 10,11 C10,9.8954305 10.8954305,9 12,9 C13.1045695,9 14,9.8954305 14,11 C14,12.1045695 13.1045695,13 12,13 Z"
                    id="Mask"
                    fill="#000000"
                    opacity="0.3"
                  />
                  <path
                    d="M7.00036205,18.4995035 C7.21569918,15.5165724 9.36772908,14 11.9907452,14 C14.6506758,14 16.8360465,15.4332455 16.9988413,18.5 C17.0053266,18.6221713 16.9988413,19 16.5815,19 C14.5228466,19 11.463736,19 7.4041679,19 C7.26484009,19 6.98863236,18.6619875 7.00036205,18.4995035 Z"
                    id="Mask-Copy"
                    fill="#000000"
                    opacity="0.3"
                  />
                </g>
              </svg>
            </span>
            <h3 style={{ marginBottom: "2%" }} className="mt-3">
              Attendance List
            </h3>
          </div>

          <div className="row">
            <div className="col-sm-6 col-md-8 col-lg-8"></div>
            <div
              className="col-sm-6 col-md-4 col-lg-4"
              style={{ float: "right" }}
            >
              {this.state.error && this.state.error ? (
                <Alert variant="danger">{this.state.error}</Alert>
              ) : null}
              {this.state.msg && this.state.msg ? (
                <Alert variant="success">{this.state.msg}</Alert>
              ) : null}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Card ref={(el) => (this.componentRef = el)}>
                <div className="row mt-5 ml-5 mr-5 notPrint">
                  <div className="col-lg-12 align-text-bottom">
                    <h4>Filter</h4>
                  </div>
                  <div className=" col-lg-3 col-sm-6 col-md-4">
                    <Form.Group>
                      Guard
                      <Select
                        styles={customStylesForFeild}
                        name="id"
                        options={this.state.guards}
                        onChange={this.GuardChanges}
                        value={this.state?.guards?.filter(
                          ({ id }) => id === this.state.employee_id
                        )}
                        getOptionLabel={({ name }) => name}
                        getOptionValue={({ id }) => id}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-md-4">
                    <Form.Group>
                      Start Date:
                      <input
                        className="form-control form-control-sm"
                        type="date"
                        value={this.state.startDate}
                        name="startDate"
                        onChange={this.onChange}
                      ></input>
                    </Form.Group>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-md-4">
                    <Form.Group>
                      End Date:
                      <input
                        className="form-control form-control-sm"
                        type="date"
                        value={this.state.endDate}
                        name="endDate"
                        onChange={this.onChange}
                      ></input>
                    </Form.Group>
                  </div>
                  <div className="col-1 d-flex">
                    <ExportCsv
                      csvData={MakeCSVData(this.state.data, this.state.columns)}
                      fileName={`Attendance_Report_${this.state.startDate}_to_${this.state.endDate}`}
                    />
                  </div>
                  <div className=" col-lg-1 mt-5 ">
                    <ReactToPrint
                      trigger={() => {
                        return (
                          <Button
                            className="ml-5"
                            color="secondary"
                            variant="outlined"
                            onClick={this.print}
                          >
                            Print
                          </Button>
                        );
                      }}
                      content={() => this.componentRef}
                    />
                  </div>
                  {this.state.loading ? (
                    <div className="col-lg-1 mt-5">
                      <Button>
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </Button>
                    </div>
                  ) : (
                    <div className=" col-lg-1 mt-5 ">
                      <Button
                        className="ml-5"
                        color="secondary"
                        variant="contained"
                        onClick={this.filter}
                      >
                        Load
                      </Button>
                    </div>
                  )}
                </div>
                <div className="row mt-5 ml-5 mr-5 print d-none ">
                  <div className="col-12">
                    <h4
                      className="text-center mt-5 mb-5"
                      style={{ marginBottom: "25px" }}
                    >
                      Attendance Report
                    </h4>
                  </div>

                  <div className="col-4">
                    <Form.Group>
                      Gaurd:
                      <br />
                      {this.state.employee_name}
                    </Form.Group>
                  </div>
                  <div className="col-4">
                    <Form.Group>
                      Start Date:
                      <br />
                      {moment(this.state.startDate).format("YYYY-MM-DD")}
                    </Form.Group>
                  </div>
                  <div className="col-4">
                    <Form.Group>
                      End Date:
                      <br />
                      {moment(this.state.endDate).format("YYYY-MM-DD")}
                    </Form.Group>
                  </div>
                </div>
                <CardBody>
                  <Grid
                    columns={this.state.columns}
                    data={this.state.data}
                    dataUpdated={(data) => {
                      this.setState({ data });
                    }}
                  />

                  <div
                    style={{
                      height: "20vh",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {this.state.loading ? (
                      <CircularProgress
                        color="secondary"
                        size={100}
                        thickness={4}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Attendance);
